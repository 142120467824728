import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import _ from "lodash";
import { Grid, Segment, Header, Button, Dimmer, Modal, Icon } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { useCreateEquipmentMutation, useUpdateEquipmentMutation } from "../equipmentService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { getMaxDecimalPlaces, removeAccents, reparseNumber, validateNumber } from "modules/common/utils";
import { PE6ModeOptions } from "modules/sensor/utils";
import { identity, identityNull } from "modules/common/utils/form";
import { process_auto_unit, standbyThresholdUnit } from "../utils";
import { checkRights } from "modules/auth/utils";
import history_app from "history_app";

import Back from "modules/common/components/back";
import { DropDownAdapter, InputAdapter, TextAreaAdapter } from "modules/common/components/form";
import TagAddModalForm from "modules/tag/components/TagAddModalForm";
import MessageDisplay from "modules/common/components/MessageDisplay";
import SyntheticFormula from "./SyntheticFormula";

const Equipment = (props) => {
    const defaultManufactureYear = moment().year();
    const { id_eqpmt } = useParams();
    const { equipment, zone, measurementtype, machinetype, machine, equipmenttype, tag, category, sensortype, unit, org, auth, notification } =
        useSelector((state) => state);

    const current_equipment = useMemo(() => {
        return props.current_eqt || equipment.equipment.default;
        //eslint-disable-next-line
    }, [props.current_eqt]);

    const [kinematicOpen, setKinematicOpen] = useState(false);

    const rights = checkRights(current_equipment, auth.rights);
    // TODO CHANGE for add Equipment. Site not define. Need another way to get right.
    // Now: validate btn enable for add. Backend check rights
    const can_change = _.includes(rights, "can_change");
    const [createEquipment, create] = useCreateEquipmentMutation();
    const [updateEquipment, update] = useUpdateEquipmentMutation();

    useEffect(() => {
        if (create.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create equipment`), toast_options);
            history_app.push(`/equipments/${create.data.id}`);
        }
        if (create.isError) {
            const external_id_err = create?.error?.data?.external_id?.[0] ?? null;
            const machine_err = create?.error?.data?.machine?.[0] ?? null;
            const eqt_err = create?.error?.data?.equipment?.[0] ?? null;
            const formula_err = create?.error?.data?.formula?.[0] ?? null;
            if (external_id_err) {
                toast.error(i18n._(external_id_err), toast_options_err);
            } else if (machine_err) {
                toast.error(i18n._(machine_err), toast_options_err);
            } else if (eqt_err) {
                toast.error(i18n._(eqt_err), toast_options_err);
            } else if (formula_err) {
                toast.error(i18n._(formula_err), toast_options_err);
            } else {
                toast.error(i18n._(t`cannot create equipment`), toast_options_err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError, create.isLoading]);

    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update equipment`), toast_options);
        }
        if (update.isError) {
            const external_id_err = update?.error?.data?.external_id?.[0] ?? null;
            const machine_err = update?.error?.data?.machine?.[0] ?? null;
            const eqt_err = update?.error?.data?.equipment?.[0] ?? null;
            const formula_err = update?.error?.data?.formula?.[0] ?? null;
            if (external_id_err) {
                toast.error(i18n._(external_id_err), toast_options_err);
            } else if (machine_err) {
                toast.error(i18n._(machine_err), toast_options_err);
            } else if (eqt_err) {
                toast.error(i18n._(eqt_err), toast_options_err);
            } else if (formula_err) {
                toast.error(i18n._(formula_err), toast_options_err);
            } else {
                toast.error(i18n._(t`cannot update equipment`), toast_options_err);
            }
        }
        //eslint-disable-next-line
    }, [update.isSuccess, update.isError, update.isLoading]);

    const submitForm = async (formData) => {
        const equipment = process_auto_unit(formData.equipment, true);

        const rewriteNumberEquipment = {
            ...equipment,
            manufacture_year: reparseNumber(equipment?.manufacture_year),
            dataflow_set: _.map(equipment?.dataflow_set, (df) => {
                return {
                    ...df,
                    standby_threshold: reparseNumber(df?.standby_threshold),
                    measurement_set: _.map(df?.measurement_set, (m) => {
                        return {
                            ...m,
                            offset: reparseNumber(m?.offset),
                            factor: reparseNumber(m?.factor),
                            minGauge: reparseNumber(m?.minGauge),
                            maxGauge: reparseNumber(m?.maxGauge)
                        };
                    }),
                    syndataflow:
                        df.syndataflow === null
                            ? null
                            : {
                                  ...df.syndataflow,
                                  formula: _.map(df.syndataflow?.formula, (item) => {
                                      if (item.type === "factor") {
                                          return {
                                              ...item,
                                              factor: reparseNumber(item.factor)
                                          };
                                      }
                                      return item;
                                  })
                              }
                };
            })
        };

        if (_.get(equipment, "id") === undefined) {
            createEquipment({ org: org.current, data: _.omit(rewriteNumberEquipment, ["image"]) });
        } else {
            updateEquipment({ org: org.current, data: _.omit(rewriteNumberEquipment, ["image"]) });
        }
    };

    const { has_pe6, last_kinematic_version, has_elec_expert } = useMemo(() => {
        //List all sensortypes from all sensors of current equipment
        const eqt_sensortypes = _.chain(current_equipment)
            .get("dataflow_set", [])
            .map((df) => {
                return _.get(df, "channel.sensor.sensor_type");
            })
            .value();
        // 4 & 5 values means pe6 V1 && V2
        // Needed to check if it's pe6 pump
        const has_pe6 = _.chain([4, 5, 61]).intersection(eqt_sensortypes).size().value() > 0;

        const last_kinematic_version = _.chain(machine)
            .get("machines", [])
            .find({ id: _.get(current_equipment, "machine.id") })
            .get("last_kinematic_version", null)
            .value();
        const has_elec_expert = _.chain(current_equipment).get("dataflow_set", []).find({ dataflowspec_tech: 12 }).value() !== undefined; // 12 => elec_expert

        return { has_pe6, last_kinematic_version, has_elec_expert };
    }, [current_equipment, machine]);

    const eqtTypesOptions = useMemo(() => {
        return _.chain(equipmenttype.equipmenttypes)
            .map(({ key, text, value }) => ({
                key,
                value,
                text: i18n._(text)
            }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [equipmenttype.equipmenttypes]);

    const siteOptions = useMemo(() => {
        return _.chain(props.sites)
            .map(({ key, text, value }) => ({ key, text, value }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [props.sites]);

    const usageOptions = useMemo(() => {
        return _.chain(props.usages)
            .map(({ key, text, value }) => ({ key, text, value }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [props.usages]);

    const tagOptions = useMemo(() => {
        return _.chain(tag.tags)
            .map(({ key, text, value }) => ({ key, text, value }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [tag.tags]);

    const machineTypesOptions = useMemo(() => {
        return _.chain(machinetype.machinetypes)
            .map(({ key, text, value }) => ({
                key,
                value,
                text: i18n._(text)
            }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [machinetype.machinetypes]);

    const restrictedMtTypeOptions = useMemo(() => {
        return _.chain(measurementtype.measurementtypes)
            .filter((item) => !_.includes([22, 19, 18, 8, 26, 13, 11, 14, 60], item.id))
            .value(); // Remove MT_TYPE
    }, [measurementtype.measurementtypes]);

    const retrieveZoneOptionsFromSite = useCallback(
        (site_id) => {
            if (!_.isFinite(site_id)) {
                return [];
            }
            return _.chain(zone.zones)
                .filter((zone) => {
                    return zone.site_id === site_id;
                })
                .map(({ key, text, value }) => ({
                    key,
                    value,
                    text: i18n._(text)
                }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        },
        [zone.zones]
    );

    const retrieveDataFromDataflow = useCallback(
        (df) => {
            if (df === undefined) {
                return {
                    df_sensor: null,
                    df_sensortype: null,
                    df_category: null,
                    df_category_tech: null,
                    mode: null,
                    channel: null,
                    connector: null,
                    formula: null
                };
            }
            //sensor
            const df_sensor = df?.channel?.sensor ?? null;
            // TODO retrieve sensor from channel sensor id
            //const sensor = _.find(sensor.sensors, { id: df_sensor });
            // sensor_type
            const df_sensortype = _.chain(sensortype.full_sensortypes)
                .find({ id: _.get(df, "channel.sensor.sensor_type", null) })
                .value();
            // category
            const df_category = _.chain(category.categories).find({ id: df.dataflowspec }).value();
            // category_tech
            const df_category_tech = _.chain(category.categories).find({ id: df.dataflowspec_tech }).value();
            //mode du capteur (null si autre que PE6)
            // extract mode from tech_info
            const mode = _.chain(PE6ModeOptions)
                .find({ key: _.get(df, "channel.tech_info.mode") })
                .value();
            //channel du capteur (null si autre que PE6)
            const channel = _.get(df, "channel.tech_info.channel", null);
            //connector
            const connector = _.get(df, "channel.tech_info.connector", null) || _.get(df, "channel.connector", null);
            //synthetic part
            const formula = _.get(df, "syndataflow.formula", null);

            return { df_sensor, df_sensortype, df_category, df_category_tech, mode, channel, connector, formula };
        },
        [category.categories, sensortype.full_sensortypes]
    );

    const categoryOptions = useMemo(() => {
        return _.chain(category.categories)
            .reduce((res, cat) => {
                if (_.includes(["elec", "gas", "water"], _.get(cat, "name"))) {
                    res.push({
                        ...cat,
                        text: i18n._(cat.text)
                    });
                }
                return res;
            }, [])
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [category.categories]);

    const categoryOptionsPulse = useMemo(() => {
        return _.chain(category.categories)
            .reduce((res, cat) => {
                if (_.includes(["elec", "gas", "water", "calories", "frigories"], _.get(cat, "name"))) {
                    res.push({
                        ...cat,
                        text: i18n._(cat.text)
                    });
                }
                return res;
            }, [])
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, [category.categories]);

    const retrieveDataFromMeasure = useCallback(
        (measure, df_category) => {
            const m_measurementtype = _.chain(measurementtype.measurementtypes)
                .find({
                    id: measure.measurementtype
                })
                .value();

            const mt_base_unit = _.get(m_measurementtype, "unit", undefined);

            const mt_unit = _.chain(unit.units).find({ id: mt_base_unit }).value();

            let factor_symbol = _.get(mt_unit, "symbol", ""); //default_value of base_unit

            const b_unit_id = _.get(mt_unit, "base_unit", null);
            if (b_unit_id) {
                const b_unit = _.chain(unit.units).find({ id: b_unit_id }).value();
                factor_symbol = _.get(b_unit, "symbol", "");
            }

            const filteredUnitOptions = _.reduce(
                unit.units,
                (res, unit) => {
                    if (unit.value === -1) {
                        //specific case for Auto unit
                        res.push(unit);
                    } else if (unit.id === mt_base_unit) {
                        res.push(unit);
                    } else if (unit.base_unit === mt_base_unit) {
                        res.push(unit);
                    }
                    return res;
                },
                []
            );

            //Used by synthetic part for mt_type selection && pulse ?
            const filteredMtTypeOptions = _.chain(measurementtype.measurementtypes)
                .filter((item) => !_.includes([22, 19, 18, 8, 26, 13, 11, 14, 60], item.id)) // Remove MT_TYPE
                .filter((mt_type) => mt_type.datapoint_type === 3) //only time_integral ?
                // Remove reactive because main mt_type is p_act_import
                .filter((mt_type) => !_.includes(["p_react_import", "p_react_import+", "p_react_import-"], mt_type.name))
                .reduce((res, mt_type) => {
                    //current dataflowspec of dataflow
                    if (df_category && _.includes(mt_type.dataflowspec_set, df_category.id)) {
                        //General case. Most of mt_type fill this condition
                        res.push(mt_type);
                    }
                    return res;
                }, [])
                .value();

            // Used to display dataflow Field 'standby_threshold'
            // Only display on p_act_import, e_act_counter, water_import, index nm3.
            const display_standby_threshold = _.includes(
                ["p_act_import", "e_act_counter", "water_import", "index_nm3"],
                _.get(m_measurementtype, "name")
            );

            return { m_measurementtype, filteredMtTypeOptions, factor_symbol, filteredUnitOptions, display_standby_threshold };
        },
        [measurementtype.measurementtypes, unit.units]
    );

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        {id_eqpmt === undefined && <Back />}
                        {id_eqpmt !== undefined && <Back target={"/equipments"} />}
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_eqpmt === undefined && <Trans>add equipment and calculated measure</Trans>}
                            {id_eqpmt !== undefined && <Trans>update equipment and calculated measure</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={15} largeScreen={14} widescreen={12}>
                        <Segment basic>
                            <Form
                                onSubmit={submitForm}
                                mutators={{
                                    ...arrayMutators
                                }}
                                initialValues={{ equipment: current_equipment }}
                                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                    const equipment_type = _.get(values, "equipment.equipment_type", null);
                                    const machine_type = _.get(current_equipment, "machine.machine_type", null);
                                    return (
                                        <form onSubmit={handleSubmit} className="ui form">
                                            <Grid centered>
                                                <Grid.Row stretched>
                                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                                        <Header as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                                                            <Trans>general information</Trans>
                                                        </Header>
                                                        <Segment attached>
                                                            <Grid verticalAlign="top">
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="equipment.name"
                                                                        placeholder={i18n._(t`enter name of equipment`)}
                                                                        label={i18n._(t`name`)}
                                                                        isRequired={true}
                                                                        defaultValue=""
                                                                        component={InputAdapter}
                                                                        validate={(value) => (!value ? <Trans>name is required</Trans> : undefined)}
                                                                        customAction={(data) => {
                                                                            const df_set = _.chain(values)
                                                                                .get("equipment.dataflow_set", [])
                                                                                .map((dataflow) => {
                                                                                    return { ...dataflow, name: data };
                                                                                })
                                                                                .value();
                                                                            if (_.size(df_set) > 0) {
                                                                                form.change("equipment.dataflow_set", df_set);
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="equipment.equipment_type"
                                                                        label={i18n._(t`equipment_type`)}
                                                                        placeholder={i18n._(t`select equipment_type of equipment`)}
                                                                        options={eqtTypesOptions}
                                                                        isRequired={true}
                                                                        component={DropDownAdapter}
                                                                        //PE6 case: check if we let disable or not ?
                                                                        disabled={
                                                                            id_eqpmt !== undefined &&
                                                                            current_equipment.machine !== null &&
                                                                            has_pe6 === false
                                                                        }
                                                                        validate={(value) =>
                                                                            !value ? <Trans>equipment type is required</Trans> : undefined
                                                                        }
                                                                    />
                                                                </Grid.Column>
                                                                {/*Equipment_type === 7 means rotating machine */}
                                                                {(current_equipment.machine !== null || (has_pe6 && equipment_type === 7)) && (
                                                                    <>
                                                                        <Grid.Column width={16}>
                                                                            <Field
                                                                                name="equipment.machine.machine_type"
                                                                                label={i18n._(t`machine_type`)}
                                                                                placeholder={i18n._(t`select machine_type of equipment`)}
                                                                                options={machineTypesOptions}
                                                                                isRequired={true}
                                                                                component={DropDownAdapter}
                                                                                validate={(value) =>
                                                                                    !value ? <Trans>machine type is required</Trans> : undefined
                                                                                }
                                                                            />
                                                                        </Grid.Column>
                                                                    </>
                                                                )}
                                                                {((has_pe6 && machine_type === 7) ||
                                                                    (!has_pe6 && current_equipment.machine !== null)) &&
                                                                    can_change &&
                                                                    notification.srv_status.db_status === "rw" && (
                                                                        <>
                                                                            <Grid.Column width={16} textAlign="center">
                                                                                <Modal
                                                                                    open={kinematicOpen}
                                                                                    onClose={() => setKinematicOpen(false)}
                                                                                    onOpen={() => setKinematicOpen(true)}
                                                                                    trigger={
                                                                                        <Button icon labelPosition={"left"} type="button">
                                                                                            <Icon
                                                                                                name={
                                                                                                    last_kinematic_version === 0
                                                                                                        ? "gg"
                                                                                                        : "calendar alternate outline"
                                                                                                }
                                                                                            />
                                                                                            {last_kinematic_version === 0 && (
                                                                                                <Trans>initial kinematic</Trans>
                                                                                            )}
                                                                                            {last_kinematic_version !== 0 && (
                                                                                                <Trans>event creation</Trans>
                                                                                            )}
                                                                                        </Button>
                                                                                    }
                                                                                >
                                                                                    <Modal.Content>
                                                                                        <Trans>validate redirection to kinematic edition</Trans>
                                                                                    </Modal.Content>
                                                                                    <Modal.Actions>
                                                                                        <Button
                                                                                            negative
                                                                                            type="button"
                                                                                            icon="arrow left"
                                                                                            onClick={() => setKinematicOpen(false)}
                                                                                        />
                                                                                        <Button
                                                                                            type="button"
                                                                                            positive
                                                                                            icon
                                                                                            labelPosition="right"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                if (last_kinematic_version === 0) {
                                                                                                    history_app.push(
                                                                                                        `/kinematic/${_.get(
                                                                                                            current_equipment,
                                                                                                            "machine.id"
                                                                                                        )}/change`
                                                                                                    );
                                                                                                } else {
                                                                                                    history_app.push(
                                                                                                        `/machines/${_.get(
                                                                                                            current_equipment,
                                                                                                            "machine.id"
                                                                                                        )}/events/add`
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <Icon name="checkmark" />
                                                                                            {last_kinematic_version === 0 && (
                                                                                                <Trans>initial kinematic</Trans>
                                                                                            )}
                                                                                            {last_kinematic_version !== 0 && (
                                                                                                <Trans>event creation</Trans>
                                                                                            )}
                                                                                        </Button>
                                                                                    </Modal.Actions>
                                                                                </Modal>
                                                                            </Grid.Column>
                                                                        </>
                                                                    )}
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="equipment.site"
                                                                        label={i18n._(t`site`)}
                                                                        options={siteOptions}
                                                                        placeholder={i18n._(t`select site of equipment`)}
                                                                        isRequired={true}
                                                                        disabled={!_.isUndefined(id_eqpmt)}
                                                                        component={DropDownAdapter}
                                                                        validate={(value) => {
                                                                            if (!_.isFinite(value)) {
                                                                                return <Trans>site is required</Trans>;
                                                                            } else if (!_.includes(auth.rights?.sites_rw, value)) {
                                                                                return <Trans>insufficient site rights</Trans>;
                                                                            } else {
                                                                                return undefined;
                                                                            }
                                                                        }}
                                                                        customAction={(data) => {
                                                                            //reset zone field
                                                                            form.change("equipment.zone", null);
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="equipment.zone"
                                                                        label={i18n._(t`zone`)}
                                                                        placeholder={i18n._(t`select zone of equipment`)}
                                                                        options={retrieveZoneOptionsFromSite(values.equipment.site)}
                                                                        isRequired={true}
                                                                        disabled={!values.equipment.site}
                                                                        component={DropDownAdapter}
                                                                        validate={(value) => (!value ? <Trans>zone is required</Trans> : undefined)}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={16}>
                                                                    <Field
                                                                        name="equipment.usage"
                                                                        label={i18n._(t`usage`)}
                                                                        placeholder={i18n._(t`select usage of equipment`)}
                                                                        options={usageOptions}
                                                                        isRequired={true}
                                                                        component={DropDownAdapter}
                                                                        validate={(value) => (!value ? <Trans>usage is required</Trans> : undefined)}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={can_change ? 15 : 16}>
                                                                    <Grid verticalAlign="bottom">
                                                                        <Grid.Row>
                                                                            <Grid.Column
                                                                                mobile={can_change ? 14 : 16}
                                                                                tablet={can_change ? 14 : 16}
                                                                                computer={can_change ? 15 : 16}
                                                                            >
                                                                                <Field
                                                                                    name="equipment.tag_set"
                                                                                    label={i18n._(t`tags`)}
                                                                                    placeholder={i18n._(t`choose tags`)}
                                                                                    options={tagOptions}
                                                                                    multipleselect={1}
                                                                                    component={DropDownAdapter}
                                                                                    noResultsMessage={i18n._(t`no tags found`)}
                                                                                />
                                                                            </Grid.Column>
                                                                            {notification.srv_status.db_status === "rw" && can_change && (
                                                                                <Grid.Column width={1}>
                                                                                    <TagAddModalForm form={form} />
                                                                                </Grid.Column>
                                                                            )}
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Segment>
                                                    </Grid.Column>
                                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                                        <Header as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                                                            <Trans>secondary information</Trans>
                                                        </Header>
                                                        <Segment attached>
                                                            <Grid verticalAlign="top" centered>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.internal_ref"
                                                                        placeholder={i18n._(t`enter internal_ref of equipment`)}
                                                                        label={i18n._(t`internal_ref`)}
                                                                        component={InputAdapter}
                                                                        defaultValue=""
                                                                        parse={identity}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.brand"
                                                                        placeholder={i18n._(t`enter brand of equipment`)}
                                                                        label={i18n._(t`brand`)}
                                                                        component={InputAdapter}
                                                                        defaultValue=""
                                                                        parse={identity}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.model"
                                                                        placeholder={i18n._(t`enter model of equipment`)}
                                                                        label={i18n._(t`model`)}
                                                                        component={InputAdapter}
                                                                        defaultValue=""
                                                                        parse={identity}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.manufacturer_SN"
                                                                        placeholder={i18n._(t`enter manufacturer_sn of equipment`)}
                                                                        label={i18n._(t`manufacturer_SN`)}
                                                                        component={InputAdapter}
                                                                        defaultValue=""
                                                                        parse={identity}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.manufacture_year"
                                                                        placeholder={i18n._(t`enter manufacture_year of equipment`)}
                                                                        label={i18n._(t`manufacture_year`)}
                                                                        component={InputAdapter}
                                                                        defaultValue={defaultManufactureYear}
                                                                        parse={identityNull}
                                                                        validate={(value) => {
                                                                            return validateNumber(value, i18n, false, true, true);
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.maintenance_manager"
                                                                        placeholder={i18n._(t`enter maintenance_manager of equipment`)}
                                                                        label={i18n._(t`maintenance_manager`)}
                                                                        component={InputAdapter}
                                                                        defaultValue=""
                                                                        parse={identity}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.description"
                                                                        placeholder={i18n._(t`enter description of your equipment`)}
                                                                        label={i18n._(t`description`)}
                                                                        component={TextAreaAdapter}
                                                                        defaultValue=""
                                                                        parse={identity}
                                                                        rows={3}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="equipment.external_id"
                                                                        placeholder={i18n._(t`enter external_id of equipment`)}
                                                                        label={i18n._(t`external_id`)}
                                                                        isRequired={false}
                                                                        defaultValue={null}
                                                                        component={InputAdapter}
                                                                        helperText={`${i18n._(t`Custom identifier used in external APIs`)}`}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Header attached="top" block as="h3" textAlign="center">
                                                            <Trans>Measures</Trans>
                                                        </Header>
                                                        <Segment attached secondary>
                                                            <Dimmer.Dimmable dimmed={true} style={{ zIndex: 0, overflow: "visible" }}>
                                                                <Dimmer
                                                                    inverted
                                                                    active={id_eqpmt === undefined && _.get(values, "equipment.site", null) === null}
                                                                >
                                                                    <MessageDisplay
                                                                        message={i18n._(t`select site`)}
                                                                        level="warning"
                                                                        iconName="warning circle"
                                                                        isLoading={false}
                                                                        attached={false}
                                                                    />
                                                                </Dimmer>
                                                                <Grid verticalAlign="top">
                                                                    <FieldArray name="equipment.dataflow_set">
                                                                        {(df_field) => {
                                                                            return df_field.fields.map((df_name, df_index) => {
                                                                                const df = df_field.fields.value[df_index];

                                                                                const {
                                                                                    df_sensor,
                                                                                    df_sensortype,
                                                                                    df_category,
                                                                                    df_category_tech,
                                                                                    mode,
                                                                                    channel,
                                                                                    connector,
                                                                                    formula
                                                                                } = retrieveDataFromDataflow(df);

                                                                                if (
                                                                                    has_pe6 &&
                                                                                    has_elec_expert &&
                                                                                    _.get(df_category_tech, "name") === "elec"
                                                                                ) {
                                                                                    return null;
                                                                                }

                                                                                const output_measurementtype =
                                                                                    values.equipment?.dataflow_set?.[0]?.measurement_set?.[0]
                                                                                        ?.measurementtype ?? null;
                                                                                const output_cat =
                                                                                    values.equipment?.dataflow_set?.[0]?.dataflowspec ?? null;
                                                                                const eqt_site = values.equipment?.site ?? null;

                                                                                const init_formula =
                                                                                    current_equipment?.dataflow_set?.[0]?.syndataflow?.formula ??
                                                                                    null;
                                                                                const sameFormulaWithInit = _.isEqual(formula, init_formula);
                                                                                return (
                                                                                    <React.Fragment key={df_name}>
                                                                                        {/* Synthetic Part */}
                                                                                        {(id_eqpmt === undefined || formula) && (
                                                                                            <SyntheticFormula
                                                                                                df_name={df_name}
                                                                                                df={df}
                                                                                                id_eqpmt={id_eqpmt}
                                                                                                changeForm={form.change}
                                                                                                site={eqt_site}
                                                                                                output_cat={output_cat}
                                                                                                output_measurementtype={output_measurementtype}
                                                                                                categoryOptions={categoryOptions}
                                                                                                sameFormulaWithInit={sameFormulaWithInit}
                                                                                            />
                                                                                        )}
                                                                                        <Grid.Column
                                                                                            mobile={16}
                                                                                            tablet={16}
                                                                                            computer={
                                                                                                df_field.fields.length === 1 ||
                                                                                                (has_pe6 &&
                                                                                                    df_field.fields.length > 1 &&
                                                                                                    has_elec_expert)
                                                                                                    ? 16
                                                                                                    : 8
                                                                                            }
                                                                                        >
                                                                                            <Grid verticalAlign="top" centered>
                                                                                                <Grid.Column width={16}>
                                                                                                    <FieldArray name={`${df_name}.measurement_set`}>
                                                                                                        {(m_field) => {
                                                                                                            return m_field.fields.map(
                                                                                                                (m_name, m_index) => {
                                                                                                                    const measure =
                                                                                                                        m_field.fields.value[m_index];

                                                                                                                    const {
                                                                                                                        m_measurementtype,
                                                                                                                        filteredMtTypeOptions,
                                                                                                                        factor_symbol,
                                                                                                                        filteredUnitOptions,
                                                                                                                        display_standby_threshold
                                                                                                                    } = retrieveDataFromMeasure(
                                                                                                                        measure,
                                                                                                                        df_category
                                                                                                                    );

                                                                                                                    return (
                                                                                                                        <Segment basic key={m_name}>
                                                                                                                            {/* Text Header only for update/not synthetic/not pulse tech */}
                                                                                                                            {id_eqpmt !== undefined &&
                                                                                                                                formula === null &&
                                                                                                                                _.get(
                                                                                                                                    df_category_tech,
                                                                                                                                    "name",
                                                                                                                                    null
                                                                                                                                ) !== "pulse" && (
                                                                                                                                    <Header
                                                                                                                                        as="h5"
                                                                                                                                        block
                                                                                                                                        attached="top"
                                                                                                                                        textAlign="center"
                                                                                                                                    >
                                                                                                                                        {`${i18n._(
                                                                                                                                            _.get(
                                                                                                                                                m_measurementtype,
                                                                                                                                                "text",
                                                                                                                                                ""
                                                                                                                                            )
                                                                                                                                        )} - ${i18n._(
                                                                                                                                            t`sensor`
                                                                                                                                        )} ${_.get(
                                                                                                                                            df_sensortype,
                                                                                                                                            "text",
                                                                                                                                            ""
                                                                                                                                        )} ${_.get(
                                                                                                                                            df_sensor,
                                                                                                                                            "identifier",
                                                                                                                                            ""
                                                                                                                                        )} ${
                                                                                                                                            _.isUndefined(
                                                                                                                                                mode
                                                                                                                                            )
                                                                                                                                                ? ""
                                                                                                                                                : ` - ${i18n._(
                                                                                                                                                      t`connector`
                                                                                                                                                  )} ${
                                                                                                                                                      connector ||
                                                                                                                                                      ""
                                                                                                                                                  } ${
                                                                                                                                                      channel
                                                                                                                                                          ? `${i18n._(
                                                                                                                                                                t`channel`
                                                                                                                                                            )} ${channel}`
                                                                                                                                                          : ""
                                                                                                                                                  }`
                                                                                                                                        }`}
                                                                                                                                    </Header>
                                                                                                                                )}
                                                                                                                            {id_eqpmt !== undefined &&
                                                                                                                                _.get(
                                                                                                                                    df_category_tech,
                                                                                                                                    "name",
                                                                                                                                    null
                                                                                                                                ) === "pulse" && (
                                                                                                                                    <Header
                                                                                                                                        as="h5"
                                                                                                                                        block
                                                                                                                                        attached="top"
                                                                                                                                        textAlign="center"
                                                                                                                                    >
                                                                                                                                        {`${i18n._(
                                                                                                                                            _.get(
                                                                                                                                                df_category_tech,
                                                                                                                                                "name",
                                                                                                                                                ""
                                                                                                                                            )
                                                                                                                                        )} - ${i18n._(
                                                                                                                                            t`sensor`
                                                                                                                                        )} ${_.get(
                                                                                                                                            df_sensortype,
                                                                                                                                            "text",
                                                                                                                                            ""
                                                                                                                                        )} ${_.get(
                                                                                                                                            df_sensor,
                                                                                                                                            "identifier",
                                                                                                                                            ""
                                                                                                                                        )} - ${i18n._(
                                                                                                                                            t`channel`
                                                                                                                                        )} ${
                                                                                                                                            connector ||
                                                                                                                                            ""
                                                                                                                                        }`}
                                                                                                                                    </Header>
                                                                                                                                )}
                                                                                                                            <Segment
                                                                                                                                attached={
                                                                                                                                    id_eqpmt !==
                                                                                                                                    undefined
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Grid verticalAlign="top">
                                                                                                                                    {/* Pulse Part: Assume only one measure in dataflow */}
                                                                                                                                    {m_index === 0 &&
                                                                                                                                        _.get(
                                                                                                                                            df_category_tech,
                                                                                                                                            "name",
                                                                                                                                            null
                                                                                                                                        ) ===
                                                                                                                                            "pulse" && (
                                                                                                                                            <Grid.Column
                                                                                                                                                mobile={
                                                                                                                                                    16
                                                                                                                                                }
                                                                                                                                                tablet={
                                                                                                                                                    8
                                                                                                                                                }
                                                                                                                                                computer={
                                                                                                                                                    8
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <Field
                                                                                                                                                    name={`${df_name}.dataflowspec`}
                                                                                                                                                    label={i18n._(
                                                                                                                                                        t`output category`
                                                                                                                                                    )}
                                                                                                                                                    placeholder={i18n._(
                                                                                                                                                        t`select category`
                                                                                                                                                    )}
                                                                                                                                                    //only specific categories for pulse
                                                                                                                                                    options={
                                                                                                                                                        categoryOptionsPulse
                                                                                                                                                    }
                                                                                                                                                    isRequired={
                                                                                                                                                        true
                                                                                                                                                    }
                                                                                                                                                    component={
                                                                                                                                                        DropDownAdapter
                                                                                                                                                    }
                                                                                                                                                    customAction={(
                                                                                                                                                        data
                                                                                                                                                    ) => {
                                                                                                                                                        form.change(
                                                                                                                                                            `${df_name}.measurement_set[0].measurementtype`,
                                                                                                                                                            null
                                                                                                                                                        );
                                                                                                                                                        form.change(
                                                                                                                                                            `${df_name}.measurement_set[0].display_unit`,
                                                                                                                                                            null
                                                                                                                                                        );
                                                                                                                                                    }}
                                                                                                                                                    validate={(
                                                                                                                                                        value
                                                                                                                                                    ) => {
                                                                                                                                                        if (
                                                                                                                                                            !value
                                                                                                                                                        )
                                                                                                                                                            return (
                                                                                                                                                                <Trans>
                                                                                                                                                                    output
                                                                                                                                                                    category
                                                                                                                                                                    is
                                                                                                                                                                    required
                                                                                                                                                                </Trans>
                                                                                                                                                            );
                                                                                                                                                        if (
                                                                                                                                                            value ===
                                                                                                                                                            8
                                                                                                                                                        )
                                                                                                                                                            return (
                                                                                                                                                                <Trans>
                                                                                                                                                                    you
                                                                                                                                                                    need
                                                                                                                                                                    to
                                                                                                                                                                    change
                                                                                                                                                                    default
                                                                                                                                                                    output
                                                                                                                                                                    category
                                                                                                                                                                </Trans>
                                                                                                                                                            );
                                                                                                                                                        return undefined;
                                                                                                                                                    }}
                                                                                                                                                    cssOverride={
                                                                                                                                                        true
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                            </Grid.Column>
                                                                                                                                        )}
                                                                                                                                    {/* Display measuremettype selector for synthetic and pulse device */}
                                                                                                                                    {(id_eqpmt ===
                                                                                                                                        undefined ||
                                                                                                                                        formula !==
                                                                                                                                            null ||
                                                                                                                                        _.get(
                                                                                                                                            df_category_tech,
                                                                                                                                            "name",
                                                                                                                                            null
                                                                                                                                        ) ===
                                                                                                                                            "pulse") && (
                                                                                                                                        <Grid.Column
                                                                                                                                            mobile={
                                                                                                                                                16
                                                                                                                                            }
                                                                                                                                            tablet={8}
                                                                                                                                            computer={
                                                                                                                                                8
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <Field
                                                                                                                                                name={`${m_name}.measurementtype`}
                                                                                                                                                label={i18n._(
                                                                                                                                                    t`output measurementtype`
                                                                                                                                                )}
                                                                                                                                                placeholder={i18n._(
                                                                                                                                                    t`select measurementtype for measure`
                                                                                                                                                )}
                                                                                                                                                options={
                                                                                                                                                    (id_eqpmt ===
                                                                                                                                                        undefined &&
                                                                                                                                                        formula ===
                                                                                                                                                            null) ||
                                                                                                                                                    formula !==
                                                                                                                                                        null ||
                                                                                                                                                    _.get(
                                                                                                                                                        df_category_tech,
                                                                                                                                                        "name",
                                                                                                                                                        null
                                                                                                                                                    ) ===
                                                                                                                                                        "pulse"
                                                                                                                                                        ? filteredMtTypeOptions
                                                                                                                                                        : restrictedMtTypeOptions
                                                                                                                                                }
                                                                                                                                                isRequired={
                                                                                                                                                    true
                                                                                                                                                }
                                                                                                                                                component={
                                                                                                                                                    DropDownAdapter
                                                                                                                                                }
                                                                                                                                                disabled={
                                                                                                                                                    _.get(
                                                                                                                                                        df_category,
                                                                                                                                                        "name",
                                                                                                                                                        null
                                                                                                                                                    ) ===
                                                                                                                                                        "pulse" ||
                                                                                                                                                    _.isUndefined(
                                                                                                                                                        df_category
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                customAction={(
                                                                                                                                                    data
                                                                                                                                                ) => {
                                                                                                                                                    if (
                                                                                                                                                        id_eqpmt ===
                                                                                                                                                        undefined
                                                                                                                                                    ) {
                                                                                                                                                        //set dataflowspec_tech when dataflowspec change
                                                                                                                                                        form.change(
                                                                                                                                                            `${m_name}.measurementtype_tech`,
                                                                                                                                                            data
                                                                                                                                                        );
                                                                                                                                                    }

                                                                                                                                                    form.change(
                                                                                                                                                        `${m_name}.display_unit`,
                                                                                                                                                        null
                                                                                                                                                    );
                                                                                                                                                    /*form.change(
                                                                                                                                                        `${df_name}.syndataflow.formula`,
                                                                                                                                                        null
                                                                                                                                                    );*/
                                                                                                                                                }}
                                                                                                                                                value={
                                                                                                                                                    measure.measurementtype
                                                                                                                                                }
                                                                                                                                                validate={(
                                                                                                                                                    value
                                                                                                                                                ) =>
                                                                                                                                                    !value ? (
                                                                                                                                                        <Trans>
                                                                                                                                                            measurementtype
                                                                                                                                                            is
                                                                                                                                                            required
                                                                                                                                                        </Trans>
                                                                                                                                                    ) : undefined
                                                                                                                                                }
                                                                                                                                                cssOverride={
                                                                                                                                                    true
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </Grid.Column>
                                                                                                                                    )}
                                                                                                                                    {_.isNull(
                                                                                                                                        formula
                                                                                                                                    ) && (
                                                                                                                                        <Grid.Column
                                                                                                                                            mobile={
                                                                                                                                                16
                                                                                                                                            }
                                                                                                                                            tablet={8}
                                                                                                                                            computer={
                                                                                                                                                8
                                                                                                                                            }
                                                                                                                                        >
                                                                                                                                            <Field
                                                                                                                                                name={`${m_name}.offset`}
                                                                                                                                                placeholder={i18n._(
                                                                                                                                                    t`enter offset for measure`
                                                                                                                                                )}
                                                                                                                                                label={i18n._(
                                                                                                                                                    t`offset`
                                                                                                                                                )}
                                                                                                                                                component={
                                                                                                                                                    InputAdapter
                                                                                                                                                }
                                                                                                                                                helperText={`${i18n._(
                                                                                                                                                    t`offset helper text`
                                                                                                                                                )}`}
                                                                                                                                                parse={
                                                                                                                                                    identityNull
                                                                                                                                                }
                                                                                                                                                inputMode="decimal"
                                                                                                                                                defaultValue={
                                                                                                                                                    0
                                                                                                                                                }
                                                                                                                                                isRequired={
                                                                                                                                                    true
                                                                                                                                                }
                                                                                                                                                validate={(
                                                                                                                                                    value
                                                                                                                                                ) => {
                                                                                                                                                    const numValidate =
                                                                                                                                                        validateNumber(
                                                                                                                                                            value,
                                                                                                                                                            i18n,
                                                                                                                                                            false,
                                                                                                                                                            false
                                                                                                                                                        );
                                                                                                                                                    if (
                                                                                                                                                        numValidate !==
                                                                                                                                                        undefined
                                                                                                                                                    ) {
                                                                                                                                                        return numValidate;
                                                                                                                                                    }
                                                                                                                                                    const val =
                                                                                                                                                        parseFloat(
                                                                                                                                                            value
                                                                                                                                                        );
                                                                                                                                                    const max_dec = 2;
                                                                                                                                                    const max_digit = 12;
                                                                                                                                                    const max_val =
                                                                                                                                                        Math.pow(
                                                                                                                                                            10,
                                                                                                                                                            max_digit -
                                                                                                                                                                max_dec
                                                                                                                                                        );
                                                                                                                                                    if (
                                                                                                                                                        _.isFinite(
                                                                                                                                                            val
                                                                                                                                                        )
                                                                                                                                                    ) {
                                                                                                                                                        if (
                                                                                                                                                            val >
                                                                                                                                                            max_val
                                                                                                                                                        ) {
                                                                                                                                                            return (
                                                                                                                                                                <Trans>
                                                                                                                                                                    The
                                                                                                                                                                    offset
                                                                                                                                                                    value
                                                                                                                                                                    must
                                                                                                                                                                    be
                                                                                                                                                                    less
                                                                                                                                                                    than{" "}
                                                                                                                                                                    {
                                                                                                                                                                        max_val
                                                                                                                                                                    }
                                                                                                                                                                </Trans>
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                        const decPart =
                                                                                                                                                            getMaxDecimalPlaces(
                                                                                                                                                                value
                                                                                                                                                            );
                                                                                                                                                        if (
                                                                                                                                                            decPart >
                                                                                                                                                            max_dec
                                                                                                                                                        ) {
                                                                                                                                                            return (
                                                                                                                                                                <Trans>
                                                                                                                                                                    Make
                                                                                                                                                                    sure
                                                                                                                                                                    there
                                                                                                                                                                    are
                                                                                                                                                                    no
                                                                                                                                                                    more
                                                                                                                                                                    than{" "}
                                                                                                                                                                    {
                                                                                                                                                                        max_dec
                                                                                                                                                                    }{" "}
                                                                                                                                                                    decimal
                                                                                                                                                                    places
                                                                                                                                                                </Trans>
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                        if (
                                                                                                                                                            parseInt(
                                                                                                                                                                val
                                                                                                                                                            ).toString()
                                                                                                                                                                .length >
                                                                                                                                                            max_digit -
                                                                                                                                                                max_dec
                                                                                                                                                        ) {
                                                                                                                                                            return (
                                                                                                                                                                <Trans>
                                                                                                                                                                    Make
                                                                                                                                                                    sure
                                                                                                                                                                    you
                                                                                                                                                                    have
                                                                                                                                                                    only{" "}
                                                                                                                                                                    {max_digit -
                                                                                                                                                                        max_dec}{" "}
                                                                                                                                                                    digits
                                                                                                                                                                    before
                                                                                                                                                                    the
                                                                                                                                                                    decimal
                                                                                                                                                                    part
                                                                                                                                                                </Trans>
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                        return undefined;
                                                                                                                                                    }
                                                                                                                                                    return (
                                                                                                                                                        <Trans>
                                                                                                                                                            invalid
                                                                                                                                                            offset
                                                                                                                                                            value
                                                                                                                                                        </Trans>
                                                                                                                                                    );
                                                                                                                                                }}
                                                                                                                                                cssOverride={
                                                                                                                                                    true
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </Grid.Column>
                                                                                                                                    )}
                                                                                                                                    <Grid.Column
                                                                                                                                        mobile={16}
                                                                                                                                        tablet={8}
                                                                                                                                        computer={8}
                                                                                                                                    >
                                                                                                                                        <Field
                                                                                                                                            name={`${m_name}.factor`}
                                                                                                                                            placeholder={i18n._(
                                                                                                                                                t`enter factor for measure`
                                                                                                                                            )}
                                                                                                                                            label={i18n._(
                                                                                                                                                t`factor`
                                                                                                                                            )}
                                                                                                                                            component={
                                                                                                                                                InputAdapter
                                                                                                                                            }
                                                                                                                                            helperText={`${i18n._(
                                                                                                                                                t`factor used to convert raw values received by sensor to`
                                                                                                                                            )} ${factor_symbol}`}
                                                                                                                                            parse={
                                                                                                                                                identityNull
                                                                                                                                            }
                                                                                                                                            inputMode="decimal"
                                                                                                                                            defaultValue={
                                                                                                                                                1
                                                                                                                                            }
                                                                                                                                            isRequired={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                            validate={(
                                                                                                                                                value
                                                                                                                                            ) => {
                                                                                                                                                const numValidate =
                                                                                                                                                    validateNumber(
                                                                                                                                                        value,
                                                                                                                                                        i18n,
                                                                                                                                                        false,
                                                                                                                                                        false
                                                                                                                                                    );
                                                                                                                                                if (
                                                                                                                                                    numValidate !==
                                                                                                                                                    undefined
                                                                                                                                                ) {
                                                                                                                                                    return numValidate;
                                                                                                                                                }

                                                                                                                                                const max_dec = 8;
                                                                                                                                                const max_digit = 16;
                                                                                                                                                const uniform_str =
                                                                                                                                                    String(
                                                                                                                                                        value
                                                                                                                                                    ).replace(
                                                                                                                                                        ",",
                                                                                                                                                        "."
                                                                                                                                                    );
                                                                                                                                                const [
                                                                                                                                                    integerPart,
                                                                                                                                                    decimalPart
                                                                                                                                                ] =
                                                                                                                                                    uniform_str.split(
                                                                                                                                                        "."
                                                                                                                                                    );

                                                                                                                                                const max_val =
                                                                                                                                                    Math.pow(
                                                                                                                                                        10,
                                                                                                                                                        max_digit -
                                                                                                                                                            max_dec
                                                                                                                                                    );

                                                                                                                                                if (
                                                                                                                                                    _.isFinite(
                                                                                                                                                        parseInt(
                                                                                                                                                            integerPart
                                                                                                                                                        )
                                                                                                                                                    ) &&
                                                                                                                                                    parseInt(
                                                                                                                                                        integerPart
                                                                                                                                                    ) >
                                                                                                                                                        max_val
                                                                                                                                                ) {
                                                                                                                                                    return (
                                                                                                                                                        <Trans>
                                                                                                                                                            The
                                                                                                                                                            factore
                                                                                                                                                            value
                                                                                                                                                            must
                                                                                                                                                            be
                                                                                                                                                            less
                                                                                                                                                            than{" "}
                                                                                                                                                            {
                                                                                                                                                                max_val
                                                                                                                                                            }
                                                                                                                                                        </Trans>
                                                                                                                                                    );
                                                                                                                                                }

                                                                                                                                                if (
                                                                                                                                                    _.size(
                                                                                                                                                        decimalPart
                                                                                                                                                    ) >
                                                                                                                                                    max_dec
                                                                                                                                                ) {
                                                                                                                                                    return (
                                                                                                                                                        <Trans>
                                                                                                                                                            Make
                                                                                                                                                            sure
                                                                                                                                                            there
                                                                                                                                                            are
                                                                                                                                                            no
                                                                                                                                                            more
                                                                                                                                                            than{" "}
                                                                                                                                                            {
                                                                                                                                                                max_dec
                                                                                                                                                            }{" "}
                                                                                                                                                            decimal
                                                                                                                                                            places
                                                                                                                                                        </Trans>
                                                                                                                                                    );
                                                                                                                                                }
                                                                                                                                                return undefined;
                                                                                                                                            }}
                                                                                                                                            cssOverride={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Grid.Column>
                                                                                                                                    <Grid.Column
                                                                                                                                        mobile={16}
                                                                                                                                        tablet={8}
                                                                                                                                        computer={8}
                                                                                                                                    >
                                                                                                                                        <Field
                                                                                                                                            name={`${m_name}.minGauge`}
                                                                                                                                            placeholder={i18n._(
                                                                                                                                                t`enter minGauge for measure`
                                                                                                                                            )}
                                                                                                                                            label={i18n._(
                                                                                                                                                t`minGauge`
                                                                                                                                            )}
                                                                                                                                            component={
                                                                                                                                                InputAdapter
                                                                                                                                            }
                                                                                                                                            parse={
                                                                                                                                                identityNull
                                                                                                                                            }
                                                                                                                                            inputMode="numeric"
                                                                                                                                            defaultValue={
                                                                                                                                                0
                                                                                                                                            }
                                                                                                                                            isRequired={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                            validate={(
                                                                                                                                                value
                                                                                                                                            ) => {
                                                                                                                                                return validateNumber(
                                                                                                                                                    value,
                                                                                                                                                    i18n,
                                                                                                                                                    true,
                                                                                                                                                    false,
                                                                                                                                                    true
                                                                                                                                                );
                                                                                                                                            }}
                                                                                                                                            cssOverride={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Grid.Column>
                                                                                                                                    <Grid.Column
                                                                                                                                        mobile={16}
                                                                                                                                        tablet={8}
                                                                                                                                        computer={8}
                                                                                                                                    >
                                                                                                                                        <Field
                                                                                                                                            name={`${m_name}.maxGauge`}
                                                                                                                                            placeholder={i18n._(
                                                                                                                                                t`enter maxGauge for measure`
                                                                                                                                            )}
                                                                                                                                            label={i18n._(
                                                                                                                                                t`maxGauge`
                                                                                                                                            )}
                                                                                                                                            component={
                                                                                                                                                InputAdapter
                                                                                                                                            }
                                                                                                                                            parse={
                                                                                                                                                identityNull
                                                                                                                                            }
                                                                                                                                            defaultValue={
                                                                                                                                                100
                                                                                                                                            }
                                                                                                                                            isRequired={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                            validate={(
                                                                                                                                                value
                                                                                                                                            ) => {
                                                                                                                                                return validateNumber(
                                                                                                                                                    value,
                                                                                                                                                    i18n,
                                                                                                                                                    true,
                                                                                                                                                    false,
                                                                                                                                                    true
                                                                                                                                                );
                                                                                                                                            }}
                                                                                                                                            cssOverride={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Grid.Column>
                                                                                                                                    <Grid.Column
                                                                                                                                        mobile={16}
                                                                                                                                        tablet={8}
                                                                                                                                        computer={8}
                                                                                                                                    >
                                                                                                                                        <Field
                                                                                                                                            name={`${m_name}.display_unit`}
                                                                                                                                            label={i18n._(
                                                                                                                                                t`display_unit`
                                                                                                                                            )}
                                                                                                                                            placeholder={i18n._(
                                                                                                                                                t`select display unit for measure`
                                                                                                                                            )}
                                                                                                                                            options={
                                                                                                                                                filteredUnitOptions
                                                                                                                                            }
                                                                                                                                            disabled={
                                                                                                                                                _.get(
                                                                                                                                                    df_category,
                                                                                                                                                    "name",
                                                                                                                                                    null
                                                                                                                                                ) ===
                                                                                                                                                    "pulse" ||
                                                                                                                                                _.isUndefined(
                                                                                                                                                    df_category
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            component={
                                                                                                                                                DropDownAdapter
                                                                                                                                            }
                                                                                                                                            upward={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                            cssOverride={
                                                                                                                                                true
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Grid.Column>
                                                                                                                                    {_.isNull(
                                                                                                                                        formula
                                                                                                                                    ) &&
                                                                                                                                        display_standby_threshold && (
                                                                                                                                            <Grid.Column
                                                                                                                                                mobile={
                                                                                                                                                    16
                                                                                                                                                }
                                                                                                                                                tablet={
                                                                                                                                                    8
                                                                                                                                                }
                                                                                                                                                computer={
                                                                                                                                                    8
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <Field
                                                                                                                                                    name={`${df_name}.standby_threshold`}
                                                                                                                                                    placeholder={i18n._(
                                                                                                                                                        t`enter standby threshold here`
                                                                                                                                                    )}
                                                                                                                                                    unit={standbyThresholdUnit(
                                                                                                                                                        factor_symbol
                                                                                                                                                    )}
                                                                                                                                                    unitposition={
                                                                                                                                                        "right"
                                                                                                                                                    }
                                                                                                                                                    label={i18n._(
                                                                                                                                                        t`Standby threshold`
                                                                                                                                                    )}
                                                                                                                                                    component={
                                                                                                                                                        InputAdapter
                                                                                                                                                    }
                                                                                                                                                    parse={
                                                                                                                                                        identityNull
                                                                                                                                                    }
                                                                                                                                                    defaultValue={
                                                                                                                                                        null
                                                                                                                                                    }
                                                                                                                                                    validate={(
                                                                                                                                                        value
                                                                                                                                                    ) => {
                                                                                                                                                        return validateNumber(
                                                                                                                                                            value,
                                                                                                                                                            i18n,
                                                                                                                                                            false,
                                                                                                                                                            true
                                                                                                                                                        );
                                                                                                                                                    }}
                                                                                                                                                    cssOverride={
                                                                                                                                                        true
                                                                                                                                                    }
                                                                                                                                                    helperText={
                                                                                                                                                        <Trans>
                                                                                                                                                            <p>
                                                                                                                                                                The
                                                                                                                                                                standby
                                                                                                                                                                threshold
                                                                                                                                                                corresponds
                                                                                                                                                                to
                                                                                                                                                                the
                                                                                                                                                                maximum
                                                                                                                                                                power
                                                                                                                                                                consumed
                                                                                                                                                                by
                                                                                                                                                                the
                                                                                                                                                                equipment
                                                                                                                                                                when
                                                                                                                                                                it
                                                                                                                                                                is
                                                                                                                                                                in
                                                                                                                                                                standby.
                                                                                                                                                            </p>
                                                                                                                                                            <p>
                                                                                                                                                                The
                                                                                                                                                                energy
                                                                                                                                                                consumed
                                                                                                                                                                by
                                                                                                                                                                the
                                                                                                                                                                equipment
                                                                                                                                                                below
                                                                                                                                                                this
                                                                                                                                                                threshold
                                                                                                                                                                is
                                                                                                                                                                then
                                                                                                                                                                calculated
                                                                                                                                                                and
                                                                                                                                                                displayed
                                                                                                                                                                in
                                                                                                                                                                the
                                                                                                                                                                detail
                                                                                                                                                                view.
                                                                                                                                                            </p>
                                                                                                                                                        </Trans>
                                                                                                                                                    }
                                                                                                                                                />
                                                                                                                                            </Grid.Column>
                                                                                                                                        )}
                                                                                                                                </Grid>
                                                                                                                            </Segment>
                                                                                                                        </Segment>
                                                                                                                    );
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                    </FieldArray>
                                                                                                </Grid.Column>
                                                                                            </Grid>
                                                                                        </Grid.Column>
                                                                                    </React.Fragment>
                                                                                );
                                                                            });
                                                                        }}
                                                                    </FieldArray>
                                                                </Grid>
                                                            </Dimmer.Dimmable>
                                                        </Segment>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                {(can_change || (id_eqpmt === undefined && _.size(auth.rights?.sites_rw) > 0)) &&
                                                    notification.srv_status.db_status === "rw" && (
                                                        <Grid.Row>
                                                            <Grid.Column mobile={16} tablet={15} computer={15}>
                                                                <Button
                                                                    type="submit"
                                                                    positive
                                                                    content={i18n._(t`validate`)}
                                                                    disabled={submitting || pristine || invalid}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                            </Grid>
                                        </form>
                                    );
                                }}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Equipment;
