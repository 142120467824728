import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import tinycolor from "tinycolor2";
import moment from "moment";

import Palette from "modules/common/components/graphic/Colors";

import { dynamicValueUnit } from "modules/data/utils";
import { display_color } from "modules/globalview/globalviewMiddleware";

export const overviewApi = defaultApi.injectEndpoints({
    reducerPath: "apiAlert",
    endpoints: (build) => ({
        getDetail: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, measurement, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `/measurements/${measurement.id}/detail?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response, meta, arg) => {
                const max_measure = _.chain(response).maxBy("[1]").last().value();
                const unit = arg?.measurement?.display_unit?.intensive ?? arg?.measurement?.display_unit?.symbol;
                const auto_unit = arg?.measurement?.auto_unit ?? true;
                const { factor, new_unit } = dynamicValueUnit(max_measure, unit, auto_unit);

                const color = tinycolor(Palette.circles[0]).setAlpha(0.4).toString();

                const new_data = {
                    name: arg?.measurement?.name ?? "-",
                    disabled: false,
                    strokeWidth: 20,
                    data:
                        _.chain(response)
                            .map((value) => {
                                const y_val = _.isFinite(value[1]) ? value[1] * factor : null;
                                return {
                                    name: arg?.measurement?.name ?? "-",
                                    x: value[0] * 1000,
                                    t: value[0] * 1000,
                                    y: y_val,
                                    y_real: y_val,
                                    color,
                                    unit: new_unit
                                };
                            })
                            .value() || [],
                    color,
                    unit: new_unit,
                    isEvent: false
                };

                //return array instead of serie to stay ISO with overviewMiddle used by old tab system
                return [new_data];
            }
        }),
        getSummary: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, measurement, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `/measurements/${measurement.id}/summary?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response, meta, arg) => {
                const max_measure = _.chain(response).maxBy("[3]").last().value();
                const unit = arg?.measurement?.display_unit?.intensive ?? arg?.measurement?.display_unit?.symbol;
                const auto_unit = arg?.measurement?.auto_unit ?? true;
                const { factor, new_unit } = dynamicValueUnit(max_measure, unit, auto_unit);
                const process_summary = _.chain(response)
                    .reduce(
                        (res, record) => {
                            //minimum part
                            const min_data = _.get(res.min, record[0]);
                            if (min_data === undefined) {
                                res.min[record[0]] = [record[1]];
                            } else {
                                res.min[record[0]].push(record[1]);
                            }
                            //avg part
                            const avg_data = _.get(res.avg, record[0]);
                            if (avg_data === undefined) {
                                res.avg[record[0]] = [record[2]];
                            } else {
                                res.avg[record[0]].push(record[2]);
                            }
                            //max part
                            const max_data = _.get(res.max, record[0]);
                            if (max_data === undefined) {
                                res.max[record[0]] = [record[3]];
                            } else {
                                res.max[record[0]].push(record[3]);
                            }
                            return res;
                        },
                        { min: {}, avg: {}, max: {} }
                    )
                    .map((data, key) => {
                        let remap_data = [];
                        const color = tinycolor(display_color[key]).setAlpha(0.4).toString();

                        switch (key) {
                            case "min":
                                remap_data = _.map(data, (item, date) => {
                                    const val = _.chain(item)
                                        .filter((val) => val !== null)
                                        .min()
                                        .value();
                                    const y_val = _.isFinite(val) ? factor * val : null;

                                    return {
                                        x: moment(date).unix() * 1000,
                                        t: moment(date).unix() * 1000,
                                        y: y_val,
                                        y_real: y_val,
                                        type: key,
                                        color,
                                        unit: new_unit
                                    };
                                });
                                break;
                            case "avg":
                                remap_data = _.map(data, (item, date) => {
                                    const val = _.chain(item)
                                        .filter((val) => val !== null)
                                        .mean()
                                        .value();
                                    const y_val = _.isFinite(val) ? factor * val : null;
                                    return {
                                        x: moment(date).unix() * 1000,
                                        t: moment(date).unix() * 1000,
                                        y: y_val,
                                        y_real: y_val,
                                        type: key,
                                        color,
                                        unit: new_unit
                                    };
                                });
                                break;
                            case "max":
                                remap_data = _.map(data, (item, date) => {
                                    const val = _.chain(item)
                                        .filter((val) => val !== null)
                                        .max()
                                        .value();
                                    const y_val = _.isFinite(val) ? factor * val : null;
                                    return {
                                        x: moment(date).unix() * 1000,
                                        t: moment(date).unix() * 1000,
                                        y: y_val,
                                        y_real: y_val,
                                        type: key,
                                        color,
                                        unit: new_unit
                                    };
                                });
                                break;
                            default:
                                remap_data = [];
                                break;
                        }
                        return {
                            title: key,
                            data: remap_data,
                            disabled: false,
                            strokeWidth: 20,
                            isEmpty: _.size(remap_data) === 0,
                            color,
                            unit: new_unit,
                            max_measure
                        };
                    })
                    .value();

                return process_summary;
            }
        }),
        getTotalConsumption: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, measurement, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `/measurements/${measurement.id}/total_consumption?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response, meta, arg) => {
                return { measure: arg.measurement, data: response };
            }
        }),
        getConsumption: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, measurement, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `/measurements/${measurement.id}/consumptions?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response, meta, arg) => {
                const max_measure = _.chain(response).maxBy("[1]").last().value();
                const auto_unit = arg?.measurement?.auto_unit ?? true;
                const unit = arg?.measurement?.display_unit?.symbol;
                const { factor, new_unit } = dynamicValueUnit(max_measure, unit, auto_unit);
                const color = tinycolor(Palette.named.green).setAlpha(0.4).toString();

                const data = {
                    name: arg?.measurement?.name ?? "-",
                    disabled: false,
                    strokeWidth: 20,
                    data:
                        _.chain(response)
                            .map((value) => {
                                const y_val = _.isFinite(value[1]) ? value[1] * factor : null;
                                return {
                                    x: moment(value[0]).unix() * 1000,
                                    t: moment(value[0]).unix() * 1000,
                                    y: y_val,
                                    y_real: y_val,
                                    color,
                                    unit: new_unit,
                                    factor
                                };
                            })
                            .value() || [],
                    color,
                    unit: new_unit
                };

                return [data];
            }
        })
    }),
    overrideExisting: false
});

export const { useGetDetailQuery, useGetSummaryQuery, useGetTotalConsumptionQuery, useGetConsumptionQuery } = overviewApi;
