import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Button, Icon, Grid, Divider, Segment, Header, Container } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { reparseNumber } from "modules/common/utils";
import history_app from "history_app";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import {
    useGetFileImportQuery,
    useGetFileImportsQuery,
    useCreateFileImportMutation,
    useUpdateFileImportMutation
} from "modules/import/fileImportsService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetImportQuery } from "../importService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetZonesQuery } from "modules/area/areaService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Back from "modules/common/components/back";
import ConfigDetailsForm from "./fileForms/ConfigDetailsForm";
import ConfigParamsForm from "./fileForms/ConfigParamsForm";
import ConfigFileFieldsForm from "./fileForms/ConfigFileFieldsForm";

const File = () => {
    const { id_source, mode, id_file } = useParams();
    const { org, auth, notification } = useSelector((state) => state);
    const user_rights = auth.rights;

    const files = useGetFileImportsQuery({ org: org.current }, { skip: !org.current });
    const importSource = useGetImportQuery({ org: org.current, id: id_source }, { skip: !org.current || id_source === undefined });
    const file = useGetFileImportQuery({ org: org.current, id: id_file }, { skip: id_file === undefined });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });

    const [createImportFile, create] = useCreateFileImportMutation();
    const [updateImportFile, update] = useUpdateFileImportMutation();

    // Create import file
    useEffect(() => {
        if (create.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`Import creation success`), toast_options);
            history_app.push(`/imports/${id_source}/change`);
        }
        if (create.isError) {
            let error = t`Unable to create import`;
            const ErrContent = () => {
                const dataErr = _.map(create.error?.data, (content, errKey) => {
                    return <div key={errKey}>{content?.[0] ?? errKey}</div>;
                });

                if (_.size(dataErr) > 0) {
                    return dataErr;
                }
                return null;
            };

            if (ErrContent) {
                toast.error(<ErrContent />, toast_options_err);
            } else {
                toast.error(i18n._(error), toast_options_err);
            }
        }
    }, [id_source, create.isLoading, create.isSuccess, create.isError, create.error]);

    // Update import file
    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`Import update success`), toast_options);
        }
        if (update.isError) {
            let error = t`Unable to update import`;
            const ErrContent = () => {
                const dataErr = _.map(update.error?.data, (content, errKey) => {
                    return <div key={errKey}>{content?.[0] ?? errKey}</div>;
                });

                if (_.size(dataErr) > 0) {
                    return dataErr;
                }
                return null;
            };

            if (ErrContent) {
                toast.error(<ErrContent />, toast_options_err);
            } else {
                toast.error(i18n._(error), toast_options_err);
            }
        }
    }, [update.isLoading, update.isSuccess, update.isError, update.error]);

    let err_list = [importSource.isError, files.isError, measurementtypes.isError, categories.isError, sites.isError, units.isError, zones.isError];
    let status_list = [
        importSource.isSuccess,
        files.isSuccess,
        measurementtypes.isSuccess,
        categories.isSuccess,
        sites.isSuccess,
        units.isSuccess,
        zones.isSuccess
    ];
    if (id_file !== undefined) {
        err_list = [...err_list, file.isError];
        status_list = [...status_list, file.isSuccess];
    }

    const can_change = notification.srv_status.db_status === "rw" && _.includes(["add", "change"], mode);

    const default_first_site = useMemo(() => {
        if (sites.isSuccess) {
            return _.size(sites.data) === 1 ? sites.data[0].id : null;
        }
        return null;
    }, [sites]);

    const default_first_zone = useMemo(() => {
        if (zones.isSuccess && default_first_site) {
            return _.chain(zones.data)
                .filter((zone) => {
                    return zone.site_id === default_first_site;
                })
                .thru((data) => {
                    if (_.size(data) === 1) {
                        return data[0].id;
                    } else {
                        return null;
                    }
                })
                .value();
        }
        return null;
    }, [zones, default_first_site]);

    const initialValues = useMemo(() => {
        let defaultValues = {
            source: parseInt(id_source),
            //detail
            is_active: true,
            name: "",
            description: "",
            timezone: "UTC",
            field_identification: 1,
            has_header: false,
            timestamp_column: "",
            datetime_format: "",
            frequency: 0,
            dayofmonth: null,
            dayofweek: null,
            hour: null,
            minute: null,
            timestamp_filter: 0,
            //params
            subdirectory: "",
            filename: "",
            semaphore_filename: "",
            encoding: "utf-8",
            import_type: 0,
            dialect_delimiter: ",",
            dialect_quotechar: 1,
            dialect_decimal: ".",
            post_process: 1,
            post_process_param: "",
            new_fields: id_file === undefined ? [{ name: "", site: default_first_site, zone: default_first_zone }] : []
        };

        return { ...defaultValues, ...file.data };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file.data, default_first_site, default_first_zone]);

    const submitForm = async (data) => {
        const formData = {
            ...data,
            has_header: data.field_identification === 2 ? false : data.has_header,
            post_process_param: _.includes([2, 4], data.post_process) ? "" : data.post_process_param,
            dayofmonth: _.includes([0, 1, 3, 4], data.frequency) ? null : reparseNumber(data.dayofmonth),
            dayofweek: _.includes([0, 2, 3, 4], data.frequency) ? null : reparseNumber(data.dayofweek),
            hour: data.frequency === 3 ? 0 : reparseNumber(data.hour),
            minute: reparseNumber(data.minute),
            file_fields: id_file === undefined ? data.new_fields : data.file_fields,
            new_fields: id_file === undefined ? [] : data.new_fields,
            //force timestamp_filter to 0 if enedis or grtGaz in change case
            timestamp_filter: _.includes([1, 2], data.import_type) && data.timestamp_filter !== 0 ? 0 : data.timestamp_filter
        };

        if (user_rights.is_admin && notification.srv_status.db_status === "rw") {
            if (id_file) {
                await updateImportFile({ org: org.current, id: id_file, data: formData, source: parseInt(id_source) });
            } else {
                await createImportFile({ org: org.current, data: formData, source: parseInt(id_source) });
            }
        }
    };

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target={id_source ? `/imports/${id_source}/change` : `/imports/${file?.data?.source}/change`} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_file === undefined && <Trans>Add a new import</Trans>}
                            {id_file !== undefined && (
                                <>
                                    {can_change && <Trans>Update import</Trans>}
                                    {!can_change && <Trans>View import</Trans>}
                                </>
                            )}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    {(() => {
                        if (_.some(err_list)) {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        } else if (_.every(status_list)) {
                            const can_actions = user_rights.is_admin || importSource.data?.owner === auth.user.user_id;

                            return (
                                <Grid.Column width={15}>
                                    <Container>
                                        <Form
                                            onSubmit={submitForm}
                                            initialValues={initialValues}
                                            mutators={{ ...arrayMutators }}
                                            render={({ handleSubmit, submitting, pristine, invalid, values, form }) => {
                                                return (
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                        <Grid centered stackable>
                                                            <Grid.Row stretched>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <ConfigDetailsForm
                                                                        values={values}
                                                                        form={form}
                                                                        sites={sites}
                                                                        files={files}
                                                                        id_file={id_file}
                                                                        can_change={can_actions && can_change}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                                                    <ConfigParamsForm
                                                                        id_file={id_file}
                                                                        mode={mode}
                                                                        can_change={can_actions && can_change}
                                                                        values={values}
                                                                        form={form}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <ConfigFileFieldsForm
                                                                        mode={mode}
                                                                        can_change={can_actions && can_change}
                                                                        values={values}
                                                                        sites={sites}
                                                                        id_file={id_file}
                                                                        measurementtypes={measurementtypes}
                                                                        dataflowspec={categories}
                                                                        units={units}
                                                                        file={file}
                                                                        form={form}
                                                                        zones={zones}
                                                                        default_first_site={default_first_site}
                                                                        default_first_zone={default_first_zone}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            {can_actions && can_change && (
                                                                <Grid.Row>
                                                                    <Grid.Column width={16} textAlign="right">
                                                                        <>
                                                                            <Divider />
                                                                            <Button
                                                                                type="submit"
                                                                                positive
                                                                                icon
                                                                                labelPosition="right"
                                                                                disabled={submitting || pristine || invalid}
                                                                            >
                                                                                <Icon name="check" />
                                                                                <Trans>validate</Trans>
                                                                            </Button>
                                                                        </>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )}
                                                        </Grid>
                                                    </form>
                                                );
                                            }}
                                        />
                                    </Container>
                                </Grid.Column>
                            );
                        } else {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`loading data`)}
                                        level="info"
                                        iconName="circle notched"
                                        isLoading={true}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        }
                    })()}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(File);
