import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Label, Dropdown, List, Divider } from "semantic-ui-react";
import { useSelector } from "react-redux";

import i18n from "modules/i18n/i18nConfig";
import { categoriesExclusion } from "../DashboardFilter";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetUsagesQuery } from "modules/usage/usageService";
import { useGetTagsQuery } from "modules/tag/tagService";

// limit for displaying string in label
const MAXLENGTHSTRING = 20;

export const Infos = (props) => {
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });

    const { equipment, display } = props;

    const tag_list = useMemo(() => {
        let tag_list = [];
        if (sites.isSuccess) {
            const eqt_site = _.find(sites.data, { id: equipment?.site });
            if (eqt_site) {
                tag_list.push({ tag: true, color: "blue", content: eqt_site?.text ?? "-" });
            }
        }
        if (zones.isSuccess) {
            const eqt_zone = _.find(zones.data, { id: equipment?.zone });
            if (eqt_zone) {
                tag_list.push({ tag: true, color: "teal", content: eqt_zone?.name ?? "-" });
            }
        }

        if (categories.isSuccess) {
            _.chain(equipment)
                .get("dataflowspec_set", [])
                .each((df_id) => {
                    const eqt_category = _.find(categories.data, { id: df_id });

                    if (eqt_category && !_.includes(categoriesExclusion, eqt_category?.name)) {
                        tag_list.push({ tag: true, color: "purple", content: i18n._(eqt_category?.text ?? "-") });
                    }
                })
                .value();
        }

        if (usages.isSuccess) {
            const eqt_usage = _.find(usages.data, { id: equipment?.usage });
            if (eqt_usage) {
                tag_list.push({ tag: true, color: "violet", content: i18n._(eqt_usage?.text ?? "-") });
            }
        }

        if (tags.isSuccess) {
            _.chain(equipment)
                .get("tag_set", [])
                .each((tag_id) => {
                    const eqt_tag = _.find(tags.data, { id: tag_id });
                    if (eqt_tag) {
                        tag_list.push({ tag: true, color: "grey", content: eqt_tag?.word ?? "-" });
                    }
                })
                .value();
        }
        return tag_list;
        // eslint-disable-next-line
    }, [equipment, categories, sites, tags, usages, zones, current_lng]);

    return (
        <>
            {display === "label" && (
                <Label.Group>
                    {_.map(tag_list, (tagItem, index) => (
                        <Label key={index} tag={tagItem.tag} color={tagItem.color}>
                            {String(tagItem.content).length < MAXLENGTHSTRING
                                ? tagItem.content
                                : `${String(tagItem.content).substring(0, MAXLENGTHSTRING)}...`}
                        </Label>
                    ))}
                </Label.Group>
            )}
            {display === "button" && (
                <Dropdown button compact className="icon" icon="tags" direction="left">
                    <Dropdown.Menu>
                        <Dropdown.Header icon="tags" content="Tags" />
                        <Dropdown.Divider />
                        {_.map(tag_list, (tagItem, index) => (
                            <Dropdown.Item
                                key={index}
                                text={
                                    tagItem.content.length < MAXLENGTHSTRING ? tagItem.content : `${tagItem.content.substring(0, MAXLENGTHSTRING)}...`
                                }
                                value={
                                    tagItem.content.length < MAXLENGTHSTRING ? tagItem.content : `${tagItem.content.substring(0, MAXLENGTHSTRING)}...`
                                }
                                label={{
                                    color: tagItem.color,
                                    empty: true,
                                    circular: true
                                }}
                            />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {display === "list" && (
                <List>
                    <List.Item>
                        <List.Content>
                            <List.Header>Informations</List.Header>
                            <Divider />
                        </List.Content>
                    </List.Item>
                    {_.map(tag_list, (tagItem, index) => (
                        <List.Item key={index}>
                            <List.Content>
                                <Label color={tagItem.color} empty circular /> 
                                {tagItem.content.length < MAXLENGTHSTRING ? tagItem.content : `${tagItem.content.substring(0, MAXLENGTHSTRING)}...`}
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            )}
        </>
    );
};

Infos.propTypes = {
    equipment: PropTypes.object.isRequired,
    display: PropTypes.string.isRequired
};

export default React.memo(Infos);
