import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { refreshTimestampUrlParam } from "apis/PowerAdapt";
import cookie from "react-cookies";
import _ from "lodash";
import { defaultLng } from "modules/i18n/i18nConfig";

export const userApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_user",
    keepUnusedDataFor: 600,
    tagTypes: ["Users", "User", "UserRights", "LoginHistory"],
    endpoints: (build) => ({
        getUsers: build.query({
            query: (data) => {
                const current_org = _.get(data, "org.name", "");
                return `users?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Users", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                const users = _.map(response, (user) => {
                    return { ...user, key: user.id, text: user.email, value: user.id };
                });

                return users;
            }
        }),
        getUser: build.query({
            query: ({ org_name, user_id }) => {
                return `users/${user_id}?org=${org_name}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "User", id: arg.user_id }];
                return [];
            }
        }),
        addUser: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `users?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Users", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateUser: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `users/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    //TODO Refresh list for admin
                    return [
                        { type: "Users", id: arg.org.id },
                        { type: "User", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteUser: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `users/${data}?org=${current_org}${confirm ? "&confirm=yes" : ""}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    const { confirm } = arg;
                    if (confirm) {
                        return [
                            { type: "Users", id: arg.org.id },
                            { type: "User", id: arg.data.id }
                        ];
                    }
                }
                return [];
            }
        }),
        getUserRights: build.query({
            query: ({ org, user_id }) => {
                const current_org = _.get(org, "name", null);
                return `/users/${user_id}/siterights?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 0,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "UserRights", id: arg.user_id }];
                return [];
            }
        }),
        setUserRights: build.mutation({
            query: ({ org, user_id, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `users/${user_id}/siterights?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    //TODO Refresh list for admin
                    return [{ type: "UserRights", id: arg.user_id }];
                }
                return [];
            }
        }),
        passwordChange: build.mutation({
            query: ({ org, user_id, lng, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `users/${user_id}/password/${lng}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            }
        }),
        getPasswordHints: build.query({
            query: ({ org, user_id, lng }) => {
                const current_org = _.get(org, "name", null);
                return `/users/${user_id}/password_hints/${lng}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 0
        }),
        otpGenerate: build.query({
            query: ({ org, user_id }) => {
                const current_org = _.get(org, "name", null);
                return `users/${user_id}/otp_generate?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 0
        }),
        otpQrCode: build.query({
            query: ({ org, user_id, secret }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `users/${user_id}/otp_qrcode?org=${current_org}&secret=${secret}&${refreshTimestampUrlParam()}`,
                    responseHandler: (response) => {
                        return response.text();
                    }
                };
            },
            keepUnusedDataFor: 0,
            transformResponse: async (response) => {
                const dataUrl = `data:image/svg+xml;base64,${btoa(response)}`;
                return dataUrl;
            }
        }),
        otpSet: build.mutation({
            query: ({ org, user_id, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `users/${user_id}/set_otp?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.from_users) {
                    //TODO Refresh list for admin
                    return [
                        { type: "Users", id: arg.org.id },
                        { type: "User", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        otpCheck: build.mutation({
            query: ({ org, user_id, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `users/${user_id}/check_otp?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            }
        }),
        getLoginHistory: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `loginhistory?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "LoginHistory", id: arg.org?.id }];
                return [];
            }
        })
    })
});

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useUpdateUserMutation,
    useAddUserMutation,
    useDeleteUserMutation,
    useGetPasswordHintsQuery,
    useOtpGenerateQuery,
    useOtpQrCodeQuery,
    useOtpSetMutation,
    useOtpCheckMutation,
    useGetUserRightsQuery,
    useSetUserRightsMutation,
    usePasswordChangeMutation,
    useGetLoginHistoryQuery
} = userApi;
