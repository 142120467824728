import { t } from "@lingui/macro";

/**
 * Define start && end for AnalyseRatio request on server
 * @function processTimeFromHistoricTypeRatio
 * @param {Object} end_date - Date selector in analyse
 * @param {Number} historyType - Historic type of export
 * @returns
 */
export const processTimeFromHistoricTypeRatio = (end_date, historyType) => {
    switch (historyType) {
        case 0: {
            const end = end_date.clone().startOf("day");
            return { start: end.clone().subtract(1, "day"), end };
        }
        case 1: {
            const end = end_date.clone().startOf("day");
            return { start: end.clone().subtract(7, "day"), end };
        }
        case 2: {
            const end = end_date.clone().startOf("day");
            return { start: end.clone().subtract(30, "day"), end };
        }
        case 3: {
            const end = end_date.clone().startOf("isoweek");
            return { start: end.clone().subtract(1, "week"), end };
        }
        case 4: {
            const end = end_date.clone().startOf("isoweek");
            return { start: end.clone().subtract(4, "week"), end };
        }
        case 5: {
            const end = end_date.clone().startOf("month");
            return { start: end.clone().subtract(1, "month"), end };
        }
        case 6: {
            const end = end_date.clone().startOf("month");
            return { start: end.clone().subtract(3, "month"), end };
        }
        case 7: {
            const end = end_date.clone().startOf("month");
            return { start: end.clone().subtract(12, "month"), end };
        }
        default: {
            const end = end_date.clone().startOf("day");
            return { start: end.clone().subtract(1, "day"), end };
        }
    }
};

export const historic_options = [
    { key: 0, value: 0, text: t`lastday` },
    { key: 1, value: 1, text: t`last 7 days` },
    { key: 2, value: 2, text: t`last 30 days` },
    { key: 3, value: 3, text: t`last week` },
    { key: 4, value: 4, text: t`last 4 weeks` },
    { key: 5, value: 5, text: t`last month` },
    { key: 6, value: 6, text: t`last 3 months` },
    { key: 7, value: 7, text: t`last 12 months` }
];

export const aggregate_options = [
    { key: "day", value: 1, text: t`by_day` },
    { key: "week", value: 2, text: t`by_week` },
    { key: "month", value: 3, text: t`by_month` }
];

export const comparison_options = [
    { key: 0, value: 0, text: t`no comparison` },
    { key: 1, value: 1, text: t`previous period` },
    { key: 2, value: 2, text: t`same period last year` },
    { key: 3, value: 3, text: t`same period past month` }
];

export const graphicTypeOptions = [
    { key: 1, value: 1, text: t`stack bar` },
    { key: 2, value: 2, text: t`pie chart` },
    { key: 3, value: 3, text: t`ratio` }
];

export const advancedUnitsOptions = [
    { key: 1, value: "kWh", text: t`kWh` },
    { key: 2, value: "Wh", text: t`Wh` },
    { key: 3, value: "MWh", text: t`MWh` },
    { key: 4, value: "cost", text: t`Cost` },
    { key: 5, value: "kcost", text: t`kCost` },
    { key: 6, value: "kgCO2e", text: t`kgCO₂e` },
    { key: 7, value: "tCO2e", text: t`tCO₂e` },
    { key: 8, value: "m³", text: t`m³` },
    { key: 9, value: "dm³", text: t`dm³` },
    { key: 10, value: "l", text: t`l` },
    { key: 11, value: "Nm³", text: t`Nm³` },
    { key: 12, value: "TV", text: t`TV` }
];

export const denominatorUnitsOptions = [
    { key: -1, value: "no denominator type", text: t`no denominator type` },
    { key: 1, value: "measurement", text: t`measurement` },
    { key: 2, value: "dd_cool", text: t`dd_cool` },
    { key: 3, value: "dd_heat", text: t`dd_heat` },
    { key: 4, value: "hour", text: t`hour` },
    { key: 5, value: "hour*users", text: t`hour*users` },
    { key: 6, value: "ton", text: t`ton` },
    { key: 7, value: "unit", text: t`unit` },
    { key: 8, value: "users", text: t`users` }
];

export const groupByOptions = [
    { key: 1, value: 1, text: t`no group` },
    { key: 2, value: 2, text: t`by category` },
    { key: 3, value: 3, text: t`by site` },
    { key: 4, value: 4, text: t`by zone` },
    { key: 5, value: 5, text: t`by usage` },
    { key: 6, value: 6, text: t`by tag` }
];

export const process_analysis_period = (ref_end, comparison, historic) => {
    if (!ref_end.isValid()) {
        return { ref_end: null, ref_start: null, comp_end: null, comp_start: null };
    }
    const ref_time = processTimeFromHistoricTypeRatio(ref_end, historic);
    let comp_time = null;
    switch (comparison) {
        case comparison_options[1].value:
            comp_time = processTimeFromHistoricTypeRatio(ref_time.start, historic);
            break;
        case comparison_options[2].value:
            comp_time = processTimeFromHistoricTypeRatio(ref_end.clone().subtract(1, "year"), historic);
            break;
        case comparison_options[3].value:
            comp_time = processTimeFromHistoricTypeRatio(ref_end.clone().subtract(1, "month"), historic);
            break;
        default:
            break;
    }
    return { ref_time, comp_time };
};

//Reset section fields where graphe_type not a ratio
export const clearFieldsGraphType12 = {
    equipment_name: null,
    numerator_factor: null,
    numerator_factor_unit: null,
    denominator: null,
    denominator_factor: null,
    denominator_measurement: null,
    denominator_factor_unit: null
};

//Reset section fields where graphe_type is pie chart
export const clearFieldsGraphType2 = {
    include_synthetic_table: false,
    include_alerts: false
};

export const colorMappingThreshold = {
    reference_level: "orange",
    threshold_max: "red",
    threshold_min: "blue"
};

export const keyToReparseInAnalysis = ["denominator_factor", "numerator_factor", "reference_level", "threshold_min", "threshold_max"];
