import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { refreshTimestampUrlParam } from "apis/PowerAdapt";
import cookie from "react-cookies";
import _ from "lodash";
import tinycolor from "tinycolor2";

import Palette from "modules/common/components/graphic/Colors";
import moment from "moment";
import { defaultLng } from "modules/i18n/i18nConfig";

const APEPaletteColor = {
    c_tracking: Palette.named.blue,
    c_adjust: Palette.named.red,
    c_eco: Palette.named.green,
    c_eco_currency: Palette.named.grey
};

export const energySavingApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_energysaving",
    keepUnusedDataFor: 600,
    tagTypes: ["EnergySavings", "EnergySaving", "EnergySavingSavedConsumption"],
    endpoints: (build) => ({
        getEnergySavings: build.query({
            query: (data) => {
                const current_org = _.get(data, "org.name", "");
                return `energysavingactions?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "EnergySavings", id: arg.org.id }];
                return [];
            }
        }),
        getEnergySaving: build.query({
            query: ({ org, energysaving_id }) => {
                const current_org = _.get(org, "name", "");
                return `energysavingactions/${energysaving_id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "EnergySaving", id: arg.energysaving_id }];
            }
        }),
        createEnergySaving: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `energysavingactions?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "EnergySavings", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateEnergySaving: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `energysavingactions/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "EnergySavings", id: arg.org.id },
                        { type: "EnergySaving", id: arg.data.id },
                        { type: "EnergySavingSavedConsumption", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteEnergySaving: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `energysavingactions/${data.id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "EnergySavings", id: arg.org.id }];
                }
                return [];
            }
        }),
        getSavedConsumption: build.query({
            query: ({ org, energysaving_id, start, end }) => {
                const current_org = _.get(org, "name", "");
                return `energysavingactions/${energysaving_id}/saved_consumption?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "EnergySavingSavedConsumption", id: arg.energysaving_id }];
            },
            transformResponse: (response) => {
                //Rewrite series for react-vis
                const process_graphic = _.reduce(
                    response,
                    (res, item, key) => {
                        if (_.includes(["c_adjust", "c_tracking", "c_eco", "c_eco_currency"], key)) {
                            const color = tinycolor(APEPaletteColor[key])
                                .setAlpha(key === "c_adjust" ? 1 : 0.6)
                                .toString();
                            res[key] = {
                                name: key,
                                title: key,
                                disabled: false,
                                strokeWidth: 20,
                                color,
                                data: _.map(item, (record) => ({
                                    x: moment(record[0], "YYYY-MM-DD", true).unix() * 1000,
                                    y: _.isFinite(record[1]) ? record[1] : null
                                }))
                            };
                        }
                        return res;
                    },
                    {}
                );

                //Rewrite series for synthesis table
                const process_synthetis = _.chain(response)
                    .get("c_tracking", [])
                    .reduce((res, tracking, idx) => {
                        //Get tracking serie && define an array based on date for synthesis
                        let record = {
                            id: idx,
                            date: tracking?.[0],
                            c_tracking: tracking?.[1],
                            c_adjust: null,
                            c_eco: null,
                            c_eco_currency: null
                        };

                        const adjust = _.chain(response).get("c_adjust", []).find([0, tracking?.[0]]).last().defaultTo(null).value();
                        if (_.isFinite(adjust)) {
                            record["c_adjust"] = adjust;
                        }
                        const eco = _.chain(response).get("c_eco", []).find([0, tracking?.[0]]).last().defaultTo(null).value();
                        if (_.isFinite(eco)) {
                            record["c_eco"] = eco;
                        }
                        const eco_currency = _.chain(response).get("c_eco_currency", []).find([0, tracking?.[0]]).last().defaultTo(null).value();
                        if (_.isFinite(eco)) {
                            record["c_eco_currency"] = eco_currency;
                        }

                        res.push(record);

                        return res;
                    }, [])
                    .value();

                const new_response = { ...response, graphic: process_graphic, synthesis: process_synthetis };

                return new_response;
            }
        })
    })
});

export const {
    useGetEnergySavingsQuery,
    useGetEnergySavingQuery,
    useCreateEnergySavingMutation,
    useUpdateEnergySavingMutation,
    useDeleteEnergySavingMutation,
    useGetSavedConsumptionQuery
} = energySavingApi;
