import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { identity } from "lodash";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Button, Header, Segment, Grid, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { nextStep, previousStep, setTransmission } from "../kinematicSlice";
import { InputAdapter, RadioAdapter, ToggleAdapter } from "modules/common/components/form";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { reparseNumber, validateNumber } from "modules/common/utils";

const KinematicTransmissionForm = (props) => {
    const { machine, mode } = props;
    const dispatch = useDispatch();
    const { component, kinematic } = useSelector((state) => state);

    const belt_component = _.chain(component.components)
        .filter({ machine: _.get(machine, "id") })
        .find({ comp_type: 5 })
        .value();

    const gearbox_component = _.chain(component.components)
        .filter({ machine: _.get(machine, "id") })
        .find({ comp_type: 3 })
        .value();

    const beltAfter = _.get(gearbox_component, "description.beltAfter");

    const onSubmitForm = async (data) => {
        const gearbox = _.get(data, "gearbox");
        const new_data = {
            ...data,
            belt: _.mapValues(data.belt, (item, key) => {
                if (_.includes(["beltLength", "inputPulleyDiameter", "outputPulleyDiameter"], key)) {
                    return reparseNumber(item);
                } else {
                    return item;
                }
            }),
            gearbox: {
                ...gearbox,
                beltAfter: _.get(data, "beltAfter"),
                firstShaftTeeth: reparseNumber(gearbox?.firstShaftTeeth),
                outputShaftTeeth: reparseNumber(gearbox?.outputShaftTeeth),
                intermediateShafts: _.map(gearbox?.intermediateShafts, (shaft) => {
                    return _.mapValues(shaft, (item) => {
                        return reparseNumber(item);
                    });
                })
            }
        };

        await dispatch(setTransmission(new_data));
        await dispatch(nextStep());
    };

    const initialValues = useMemo(() => {
        return (
            kinematic.kinematic.transmission || {
                beltAfter: _.isUndefined(beltAfter) ? 0 : beltAfter,
                belt: {
                    db_info: _.omit(belt_component, "description"),
                    ..._.get(belt_component, "description")
                },
                gearbox: {
                    db_info: _.omit(gearbox_component, "description"),
                    intermediateShafts: [],
                    ..._.get(gearbox_component, "description")
                }
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kinematic.kinematic.transmission]);

    return (
        <>
            <Form
                onSubmit={onSubmitForm}
                mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                render={({
                    handleSubmit,
                    form: {
                        mutators: { push, pop }
                    },
                    invalid,
                    values
                }) => {
                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Header as={"h3"} attached="top" block textAlign="center">
                                <Trans>transmission info</Trans>
                            </Header>
                            <Segment attached>
                                <Grid centered>
                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <Grid columns={2}>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Field
                                                        name="hasBelt"
                                                        label={i18n._(t`la machine possède une courroie`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        numbered={true}
                                                        defaultValue={+!_.isUndefined(belt_component)}
                                                        disabled={mode === "view"}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Field
                                                        name="hasGearbox"
                                                        label={i18n._(t`la machine possède un réducteur`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        numbered={true}
                                                        defaultValue={+!_.isUndefined(gearbox_component)}
                                                        disabled={mode === "view"}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {_.get(values, "hasBelt", 0) === 1 && _.get(values, "hasGearbox", 0) === 1 && (
                                                <>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Field
                                                                name="beltAfter"
                                                                type="radio"
                                                                label={i18n._(t`la courroie est entre le moteur et le réducteur`)}
                                                                labelposition="right"
                                                                component={RadioAdapter}
                                                                value={0}
                                                                disabled={mode === "view"}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Field
                                                                name="beltAfter"
                                                                type="radio"
                                                                label={i18n._(t`la courroie est après le réducteur`)}
                                                                labelposition="right"
                                                                component={RadioAdapter}
                                                                value={1}
                                                                disabled={mode === "view"}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                            <Segment attached>
                                {/* BELT DESCRIPTION */}
                                {_.get(values, "hasBelt", 0) === 1 && (
                                    <>
                                        <Header as="h3" block attached="top" textAlign="center">
                                            <Trans>belt</Trans>
                                        </Header>
                                        <Segment attached>
                                            <Grid centered>
                                                <Grid.Column width={16}>
                                                    <Field
                                                        name="belt.name"
                                                        placeholder={i18n._(t`enter name of belt`)}
                                                        label={i18n._(t`name`)}
                                                        isRequired={true}
                                                        component={InputAdapter}
                                                        parse={identity}
                                                        defaultValue={_.get(belt_component, "description.name", "")}
                                                        validate={(value) => {
                                                            if (!value) return <Trans>Required field</Trans>;
                                                            if (_.size(value) >= 50) {
                                                                return <Trans>Ensure this field has no more than 50 characters</Trans>;
                                                            }
                                                            return undefined;
                                                        }}
                                                        disabled={mode === "view"}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={6} computer={6}>
                                                    <Field
                                                        name="belt.beltLength"
                                                        placeholder={i18n._(t`enter belt length`)}
                                                        label={i18n._(t`belt_length`)}
                                                        component={InputAdapter}
                                                        isRequired={true}
                                                        parse={identityNull}
                                                        inputMode="decimal"
                                                        unit={"mm"}
                                                        defaultValue={null}
                                                        validate={(value) => {
                                                            if (mode === "view") return undefined;
                                                            return validateNumber(value, i18n, false, false);
                                                        }}
                                                        disabled={mode === "view"}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={5} computer={5}>
                                                    <Field
                                                        name="belt.inputPulleyDiameter"
                                                        placeholder={i18n._(t`enter input pulley diameter`)}
                                                        label={i18n._(t`input_pulley_diameter`)}
                                                        component={InputAdapter}
                                                        isRequired={true}
                                                        parse={identityNull}
                                                        inputMode="decimal"
                                                        unit={"mm"}
                                                        defaultValue={null}
                                                        validate={(value) => {
                                                            if (mode === "view") return undefined;
                                                            return validateNumber(value, i18n, false, false);
                                                        }}
                                                        disabled={mode === "view"}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={5} computer={5}>
                                                    <Field
                                                        name="belt.outputPulleyDiameter"
                                                        placeholder={i18n._(t`enter output pulley diameter`)}
                                                        label={i18n._(t`output_pulley_diameter`)}
                                                        component={InputAdapter}
                                                        isRequired={true}
                                                        parse={identityNull}
                                                        inputMode="decimal"
                                                        unit={"mm"}
                                                        defaultValue={null}
                                                        validate={(value) => {
                                                            if (mode === "view") return undefined;
                                                            return validateNumber(value, i18n, false, false);
                                                        }}
                                                        disabled={mode === "view"}
                                                    />
                                                </Grid.Column>
                                            </Grid>
                                        </Segment>
                                    </>
                                )}
                                {/* GEARBOX DESCRIPTION */}
                                {_.get(values, "hasGearbox", 0) === 1 && (
                                    <>
                                        <Header as="h3" block textAlign="center" attached="top">
                                            <Trans>gearbox</Trans>
                                        </Header>
                                        <Segment attached>
                                            <Grid centered>
                                                <Grid.Column width={16}>
                                                    <Field
                                                        name="gearbox.name"
                                                        placeholder={i18n._(t`enter name of gearbox`)}
                                                        label={i18n._(t`name`)}
                                                        isRequired={true}
                                                        component={InputAdapter}
                                                        parse={identity}
                                                        defaultValue={_.get(gearbox_component, "description.name", "")}
                                                        validate={(value) => {
                                                            if (!value) return <Trans>Required field</Trans>;
                                                            if (_.size(value) >= 50) {
                                                                return <Trans>Ensure this field has no more than 50 characters</Trans>;
                                                            }
                                                            return undefined;
                                                        }}
                                                        disabled={mode === "view"}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={16}>
                                                    <Segment>
                                                        <Label attached="top left" color="blue">
                                                            <Trans>gearbox input shaft</Trans>
                                                        </Label>
                                                        <Field
                                                            name="gearbox.firstShaftTeeth"
                                                            placeholder={i18n._(t`enter input shaft teeth`)}
                                                            label={i18n._(t`Nbre de dents de l'arbre d'entrée`)}
                                                            component={InputAdapter}
                                                            isRequired={true}
                                                            parse={identityNull}
                                                            inputMode="numeric"
                                                            defaultValue={1}
                                                            validate={(value) => {
                                                                if (mode === "view") return undefined;
                                                                return validateNumber(value, i18n, false, false, true);
                                                            }}
                                                            disabled={mode === "view"}
                                                        />
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={16}>
                                                    <Segment>
                                                        <Label attached="top left" color="blue">
                                                            <Trans>gearbox intermediate shafts</Trans>
                                                        </Label>
                                                        {mode === "change" && (
                                                            <Segment basic>
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        const intermediate_size = _.chain(values)
                                                                            .get("gearbox.intermediateShafts")
                                                                            .size()
                                                                            .value();
                                                                        push(
                                                                            "gearbox.intermediateShafts",
                                                                            _.get(
                                                                                gearbox_component,
                                                                                `description.intermediateShafts[${intermediate_size}]`,
                                                                                { inputShaftTeeth: 1, outputShaftTeeth: 1 }
                                                                            )
                                                                        );
                                                                    }}
                                                                >
                                                                    <Trans>add shaft</Trans>
                                                                </Button>
                                                                <Button type="button" onClick={() => pop("gearbox.intermediateShafts")}>
                                                                    <Trans>remove shaft</Trans>
                                                                </Button>
                                                            </Segment>
                                                        )}
                                                        <FieldArray name="gearbox.intermediateShafts">
                                                            {({ fields }) => {
                                                                if (fields.length === 0) {
                                                                    return (
                                                                        <Segment basic>
                                                                            <MessageDisplay
                                                                                message={i18n._(t`no intermediate shafts`)}
                                                                                level="info"
                                                                                iconName="info circle"
                                                                                isLoading={false}
                                                                                attached={false}
                                                                            />
                                                                        </Segment>
                                                                    );
                                                                }
                                                                return fields.map((name, index) => {
                                                                    return (
                                                                        <Segment basic key={name}>
                                                                            <Grid columns={3} verticalAlign="bottom">
                                                                                <Grid.Row>
                                                                                    <Grid.Column>
                                                                                        <Field
                                                                                            name={`${name}.inputShaftTeeth`}
                                                                                            placeholder={i18n._(t`enter input shaft teeth`)}
                                                                                            label={i18n._(t`intermediateInputShaftTeeth`)}
                                                                                            component={InputAdapter}
                                                                                            parse={identityNull}
                                                                                            inputMode="numeric"
                                                                                            defaultValue={1}
                                                                                            validate={(value) => {
                                                                                                if (mode === "view") return undefined;
                                                                                                return validateNumber(
                                                                                                    value,
                                                                                                    i18n,
                                                                                                    false,
                                                                                                    false,
                                                                                                    true
                                                                                                );
                                                                                            }}
                                                                                            disabled={mode === "view"}
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    <Grid.Column>
                                                                                        <Field
                                                                                            name={`${name}.outputShaftTeeth`}
                                                                                            placeholder={i18n._(t`enter output shaft teeth`)}
                                                                                            label={i18n._(t`intermediateOutputShaftTeeth`)}
                                                                                            component={InputAdapter}
                                                                                            parse={identityNull}
                                                                                            inputMode="numeric"
                                                                                            defaultValue={1}
                                                                                            validate={(value) => {
                                                                                                if (mode === "view") return undefined;
                                                                                                return validateNumber(
                                                                                                    value,
                                                                                                    i18n,
                                                                                                    false,
                                                                                                    false,
                                                                                                    true
                                                                                                );
                                                                                            }}
                                                                                            disabled={mode === "view"}
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    {mode === "change" && (
                                                                                        <Grid.Column>
                                                                                            {index === fields.length - 1 && (
                                                                                                <Button
                                                                                                    icon="trash"
                                                                                                    color="red"
                                                                                                    onClick={() => fields.remove(index)}
                                                                                                />
                                                                                            )}
                                                                                        </Grid.Column>
                                                                                    )}
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                        </Segment>
                                                                    );
                                                                });
                                                            }}
                                                        </FieldArray>
                                                    </Segment>
                                                </Grid.Column>
                                                <Grid.Column width={16}>
                                                    <Segment>
                                                        <Label attached="top left" color="blue">
                                                            <Trans>gearbox output shaft</Trans>
                                                        </Label>
                                                        <Field
                                                            name="gearbox.outputShaftTeeth"
                                                            placeholder={i18n._(t`enter output shaft teeth`)}
                                                            label={i18n._(t`Nbre de dents de l'arbre de sortie`)}
                                                            component={InputAdapter}
                                                            isRequired={true}
                                                            parse={identityNull}
                                                            inputMode="numeric"
                                                            defaultValue={1}
                                                            validate={(value) => {
                                                                if (mode === "view") return undefined;
                                                                return validateNumber(value, i18n, false, false, true);
                                                            }}
                                                            disabled={mode === "view"}
                                                        />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid>
                                        </Segment>
                                    </>
                                )}
                            </Segment>
                            <Segment attached>
                                <Grid columns={2}>
                                    <Grid.Column textAlign="left">
                                        <Button
                                            type="button"
                                            icon="left arrow"
                                            compact
                                            labelPosition="left"
                                            content={i18n._(t`previous`)}
                                            onClick={(e) => {
                                                dispatch(previousStep());
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button
                                            type="submit"
                                            disabled={mode === "change" && (invalid || _.isUndefined(machine))}
                                            icon="right arrow"
                                            labelPosition="right"
                                            content={i18n._(t`next`)}
                                            compact
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </form>
                    );
                }}
            />
        </>
    );
};

export default KinematicTransmissionForm;
