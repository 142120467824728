import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import { Button, Divider, Icon, Modal, Segment, Dropdown } from 'semantic-ui-react';
import { Field, Form } from 'react-final-form';

import i18n from 'modules/i18n/i18nConfig';
import { aggregate_options, comparison_options, historic_options } from '../utils';

import { useUpdateAdvancedAnalysisMutation } from '../analysisAdvancedService';

import { DropDownAdapter, InputAdapter, ToggleAdapter } from 'modules/common/components/form';
import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';

const AnalysisAdvancedUpdate = (props) => {
    const { current_advanced, disabled } = props;
    const { org } = useSelector((state) => state);
    const [openModal, setOpenModal] = useState(false);
    const [updateAdvancedAnalysis, update] = useUpdateAdvancedAnalysisMutation();

    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update advanced_analysis`), toast_options);
        }
        if (update.isError) {
            toast.error(i18n._(t`cannot update advanced_analysis`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isSuccess, update.isError, update.isLoading]);

    const onSubmitForm = async (data, form) => {
        try {
            if (!disabled) {
                const action = await updateAdvancedAnalysis({ org: org.current, data });
                const error = _.get(action, 'error', null);
                if (!error) {
                    resetForm(form);
                    setOpenModal(false);
                }
            }
        } catch (error) { }

    };

    const resetForm = (form) => {
        form.reset();
        form.getRegisteredFields()
            .forEach(field => {
                form.resetFieldState(field);
            });
    };

    const initialValues = useMemo(() => {
        return current_advanced;
    }, [current_advanced]);

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                const aggregation = values?.aggregation ?? null;
                const comparison = values?.comparison ?? null;
                const historic = values?.historic ?? null;

                const filter_historic_options = _.chain(historic_options)
                    .filter(item => item.value !== 0) //exclude first choice 'yesterday'
                    .reduce((res, item) => {
                        if (aggregation === 1) {
                            if (item.value === 7) return res;
                            if (comparison !== 0 && item.value === 6) return res;
                        } else if (aggregation === 2) {
                            if (item.value < 4) return res;
                        } else if (aggregation === 3) {
                            if (item.value < 6) return res;
                        }
                        res.push(item);
                        return res;
                    }, [])
                    .value();

                const filter_aggregation_options = _.chain(aggregate_options)
                    .reduce((res, item) => {
                        if (historic === 7) {
                            if (item.value === 1) return res;
                        } else if (historic === 6) {
                            if (item.value === 1 && comparison > 0) return res;
                        }
                        res.push(item);
                        return res;
                    }, [])
                    .value();

                return (
                    <Modal open={openModal}
                        centered={false}
                        closeOnDimmerClick
                        closeOnEscape
                        onClose={(e) => {
                            resetForm(form);
                            setOpenModal(false);
                        }}
                        trigger={
                            <Dropdown.Item
                                onClick={(e) => { setOpenModal(true); }}>
                                <Icon name={disabled ? "eye" : "edit"} />
                                {!disabled && (<Trans>Edit analysis advanced</Trans>)}
                                {disabled && (<Trans>View analysis advanced</Trans>)}
                            </Dropdown.Item>
                        }>
                        <Modal.Header>
                            {!disabled && (<Trans>Edit analysis advanced</Trans>)}
                            {disabled && (<Trans>View analysis advanced</Trans>)}
                        </Modal.Header>
                        <Modal.Content>
                            <form onSubmit={handleSubmit} className="ui form">
                                <Field
                                    name="name"
                                    placeholder={i18n._(t`enter name of advanced analysis`)}
                                    label={i18n._(t`name`)}
                                    isRequired={true}
                                    component={InputAdapter}
                                    validate={(value) => {
                                        if (!value) {
                                            return <Trans>advanced analysis name is required</Trans>;
                                        }
                                        return undefined;
                                    }}
                                    onKeyDown={(e) => {
                                        //Hack to prevent parent dropdown tabspace interaction
                                        if (e.keyCode === 32) {
                                            e.stopPropagation();
                                        }
                                    }}
                                    disabled={disabled}
                                />
                                <Field
                                    name="historic"
                                    label={i18n._(t`historic`)}
                                    options={filter_historic_options}
                                    component={DropDownAdapter}
                                    defaultValue={historic_options[1].value}
                                    disabled={disabled}
                                    customAction={(data) => {
                                        const aggregation = _.get(form.getFieldState('aggregation'), 'value', 1);
                                        if (aggregation === 1 && data === 7) {
                                            //No day aggregation when historic is "last 12 months"
                                            form.change('aggregation', 2);
                                        }
                                    }}
                                />
                                <Field
                                    name="aggregation"
                                    label={i18n._(t`aggregation`)}
                                    isRequired={true}
                                    options={filter_aggregation_options}
                                    component={DropDownAdapter}
                                    customAction={(data) => {
                                        //reset historic choice based on aggregate && comparison
                                        const historic = _.get(form.getFieldState('historic'), 'value', 1);
                                        const comparison = _.get(form.getFieldState('comparison'), 'value', 0);
                                        if (data === 1) {
                                            if (historic === 7 || (comparison !== 0 && historic === 6)) {
                                                form.change('historic', 1);
                                            }
                                        } else if (data === 2) {
                                            if (historic < 4) form.change('historic', 4);
                                        } else if (data === 3) {
                                            if (historic < 6) form.change('historic', 6);
                                        }
                                    }}
                                    validate={(value) => {
                                        if (!value) return <Trans>aggregation is required</Trans>;
                                        return undefined;
                                    }}
                                    defaultValue={aggregate_options[0].value}
                                    disabled={disabled}
                                />
                                <Field
                                    name="comparison"
                                    label={i18n._(t`comparison`)}
                                    options={comparison_options}
                                    component={DropDownAdapter}
                                    customAction={(data) => {
                                        if (data !== 0) {
                                            //reset historic choice based on aggregate && comparison
                                            const historic = _.get(form.getFieldState('historic'), 'value', 1);
                                            const aggregation = _.get(form.getFieldState('aggregation'), 'value', 'day');
                                            if (aggregation === 1 && historic === 6) {
                                                form.change('historic', 1);
                                            }
                                        }
                                    }}
                                    defaultValue={comparison_options[0].value}
                                    disabled={disabled}
                                />
                                <Field
                                    name="is_public"
                                    label={i18n._(t`is_public`)}
                                    labelposition="right"
                                    component={ToggleAdapter}
                                    disabled={disabled}
                                />
                                <Divider />
                                <Segment attached basic textAlign='right'>
                                    <Button
                                        type="button"
                                        negative
                                        icon
                                        labelPosition="left"
                                        onClick={(e) => {
                                            resetForm(form);
                                            setOpenModal(false);
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                    {!disabled && (
                                        <Button
                                            positive
                                            icon
                                            labelPosition="right"
                                            type="submit"
                                            disabled={submitting || pristine || invalid}
                                        >
                                            <Icon name="send" />
                                            <Trans>Confirm</Trans>
                                        </Button>
                                    )}
                                </Segment>
                            </form>
                        </Modal.Content>
                    </Modal>

                );
            }} />
    );
};

export default React.memo(AnalysisAdvancedUpdate);
