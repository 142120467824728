import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';
import { t, Trans } from "@lingui/macro";
import { Grid, Segment, Header, Input, Button, Icon, Popup } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';
import { useGetUsagesQuery } from 'modules/usage/usageService';
import { setSearchNameFilter, setStickPage, setPage, setItemsPerPage } from 'modules/usage/usageSlice';

import Back from 'modules/common/components/back';
import TableEnhanced from 'modules/common/components/TableEnhanced';
import MessageDisplay from 'modules/common/components/MessageDisplay';
import UsageModalForm from './UsageModalForm';
import DeleteUsage from './DeleteUsage';

const Usages = () => {
    const [localSearchName, setLocalSearchName] = useState("");
    const [showAllDescription, setShowAllDescription] = useState({});

    const { org, auth, usage, notification } = useSelector(state => state);
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const user_rights = auth.rights;

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await setLocalSearchName(usage.filter.searchName);
            usage.pagination.stickPage && await dispatch(setStickPage(false));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "description", label: i18n._(t`description`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const usages_list = _.chain(usages.data)
        .reduce((res, item) => {
            if (usage.filter.searchName === "") {
                res.push(item);
            } else if (_.includes(item.name.toLowerCase(), usage.filter.searchName.toLowerCase())) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const can_change = notification.srv_status.db_status === "rw" && user_rights.is_admin;
            const can_delete = notification.srv_status.db_status === "rw" && user_rights.is_admin;

            res.push({
                ...item,
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                description: {
                    render:
                        <span style={customStyle}>
                            {/* Description cuted if more than 100 characters  */}
                            {_.get(item, "description", "-").length > 100 &&
                                <>
                                    {_.get(item, "description", "-").slice(0, 100)}
                                    <Popup trigger={
                                        <span onClick={() => setShowAllDescription({ [item.id]: !showAllDescription?.[item.id] })}>
                                            {!showAllDescription?.[item.id] &&
                                                <>
                                                    ...
                                                    <Icon
                                                        style={{ cursor: "pointer" }}
                                                        size={"large"}
                                                        color="grey"
                                                        name="arrow alternate circle right outline"
                                                        onClick={() => setShowAllDescription({ [item.id]: !showAllDescription?.[item.id] })}
                                                    />
                                                </>
                                            }
                                        </span>}>
                                        <Popup.Content>
                                            <Trans >See all text</Trans>
                                        </Popup.Content>
                                    </Popup>
                                    {showAllDescription?.[item.id] &&
                                        <span style={{ whiteSpace: 'pre-line' }}>{_.get(item, "description", "-").slice(100)}
                                            {showAllDescription?.[item.id] &&
                                                <Popup trigger={
                                                    <span onClick={() => setShowAllDescription({ [item.id]: !showAllDescription?.[item.id] })}>
                                                        {showAllDescription?.[item.id] &&
                                                            <Icon
                                                                style={{ cursor: "pointer" }}
                                                                size={"large"}
                                                                color="grey"
                                                                name="arrow alternate circle left outline"
                                                                onClick={() => setShowAllDescription({ [item.id]: !showAllDescription?.[item.id] })}
                                                            />
                                                        }
                                                    </span>}>
                                                    <Popup.Content>
                                                        <Trans >Hide text</Trans>
                                                    </Popup.Content>
                                                </Popup>
                                            }
                                        </span>
                                    }
                                </>
                            }

                            {/* Text description under 100 characters  */}
                            {_.get(item, "description", "-").length < 100 &&
                                _.get(item, "description", "-")

                            }
                        </span>,
                    value: _.get(item, "description", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <Button.Group>
                            {<UsageModalForm item={item} canChange={can_change} />}
                            {<DeleteUsage item={item} canDelete={can_delete} />}
                        </Button.Group>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                {/* Header  */}
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/manage" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Usages lists</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>

                {/* check usages status */}
                {usages.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {usages.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}

                {/* main content  */}
                {usages.isSuccess &&
                    <Grid.Row >
                        <Grid.Column width={15}>
                            <Grid celled>
                                <Grid.Column mobile={16} tablet={3} computer={3}>
                                    <Input
                                        fluid
                                        icon="search"
                                        placeholder={i18n._(t`search usage`)}
                                        onChange={(e, { value }) => {
                                            setLocalSearchName(value);
                                            dispatch(setSearchNameFilter(value));
                                        }}
                                        value={localSearchName}
                                    />
                                </Grid.Column>
                                {notification.srv_status.db_status === "rw" && user_rights.is_admin && (
                                    <Grid.Column width={16}>
                                        <UsageModalForm canChange={user_rights.is_admin} />
                                    </Grid.Column>
                                )}

                                {/* Table  */}
                                <Grid.Column width={16}>
                                    <TableEnhanced
                                        headCells={headCells}
                                        rows={usages_list}
                                        order="asc"
                                        orderBy="name"
                                        page={usage.pagination.page}
                                        rowsPerPage={usage.pagination.itemsPerPage}
                                        setPage={(page) => { dispatch(setPage(page)); }}
                                        setItemsPerPage={(items) => { dispatch(setItemsPerPage(items)); }}
                                        textItemPerPages={i18n._(t`items per page`)}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        </Segment>
    );
};

export default Usages;
