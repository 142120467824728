import React, { useMemo, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import _ from "lodash";
import { Segment, Grid, Icon, Button, Message, Header, Popup, List } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { identity, identityNull } from "modules/common/utils/form";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import {
    useGetOrganizationPasswdPolicyQuery,
    useUpdateOrganizationPasswdPolicyMutation,
    useAddOrganizationPasswdPolicyMutation,
    useDeleteOrganizationPasswdPolicyMutation
} from "../organizationService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { InputAdapter, ToggleAdapter, RadioAdapter } from "modules/common/components/form";

const SecurityPolicy = (props) => {
    const { can_change, active, org } = props;
    const [noPolicyForm, setNoPolicyForm] = useState(true);

    const passwordPolicy = useGetOrganizationPasswdPolicyQuery({ org: org.current }, { skip: !org.current || !active });

    const [updateOrganizationPasswdPolicy, update] = useUpdateOrganizationPasswdPolicyMutation();
    const [addOrganizationPasswdPolicy, create] = useAddOrganizationPasswdPolicyMutation();
    const [deleteOrganizationPasswdPolicy, deleted] = useDeleteOrganizationPasswdPolicyMutation();

    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`Security policy defined`), toast_options);
            passwordPolicy.refetch();
        }
        if (create.isError) {
            toast.error(i18n._(t`Can't define security policy`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create]);

    // Update Organization security Policy
    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update security policy`), toast_options);
        }
        if (update.isError) {
            toast.error(i18n._(t`Can't update security policy`), toast_options_err);
        }
    }, [update]);

    // Delete Organization security Policy
    useEffect(() => {
        if (deleted.isSuccess) {
            toast.success(i18n._(t`Successful delete security policy`), toast_options);
        }
        if (deleted.isError) {
            toast.error(i18n._(t`Can't delete security policy`), toast_options_err);
        }
    }, [deleted]);

    useEffect(() => {
        if (passwordPolicy.isSuccess) {
            setNoPolicyForm(!passwordPolicy.data);
        }
    }, [passwordPolicy]);

    const initialValues = useMemo(() => {
        if (passwordPolicy.isSuccess) {
            return {
                policy: {
                    history_depth: 0,
                    max_age: 0,
                    ...passwordPolicy.data
                },
                checkHistory: (!!passwordPolicy.data?.history_depth > 0) * 1,
                checkMaxAge: (!!passwordPolicy.data?.max_age > 0) * 1
            };
        }
        return {
            policy: {
                history_depth: 0,
                max_age: 0
            },
            checkHistory: 0,
            checkMaxAge: 0
        };
    }, [passwordPolicy]);

    const submitForm = async (formData) => {
        const policyForm = _.mapValues(formData.policy, (item, key) => {
            if (key !== "two_factors") {
                return reparseNumber(item);
            }
            return item;
        });

        if (can_change) {
            if (!passwordPolicy.data) {
                await addOrganizationPasswdPolicy({ org: org.current, data: policyForm });
            } else {
                await updateOrganizationPasswdPolicy({ org: org.current, data: policyForm });
            }
        }
    };

    return (
        <Segment basic>
            {passwordPolicy.isError && (
                <Grid.Row>
                    <Grid.Column width={15}>
                        <MessageDisplay
                            message={i18n._(t`error loading data`)}
                            level="error"
                            iconName="warning circle"
                            isLoading={false}
                            attached={false}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}

            {(passwordPolicy.isFetching || passwordPolicy.isLoading) && (
                <Grid.Row>
                    <Grid.Column width={15}>
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                    </Grid.Column>
                </Grid.Row>
            )}
            {passwordPolicy.isSuccess && (
                <>
                    {noPolicyForm && (
                        <>
                            <Message info attached="top">
                                <Message.Header>
                                    <Trans>You have no security policy</Trans>
                                </Message.Header>
                            </Message>
                            {can_change && (
                                <Message attached info>
                                    <Button
                                        type="button"
                                        basic
                                        color="teal"
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            setNoPolicyForm(false);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>Define security policy</Trans>
                                    </Button>
                                </Message>
                            )}
                        </>
                    )}
                    {!noPolicyForm && (
                        <Form
                            onSubmit={submitForm}
                            initialValues={initialValues}
                            render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                return (
                                    <form onSubmit={handleSubmit} className="ui form">
                                        <Segment.Group>
                                            <Message attached>
                                                <p>
                                                    <i>
                                                        <Trans>
                                                            This area is specifically designed for administrators to customize security measures in
                                                            accordance with the needs and standards of your organization.
                                                        </Trans>
                                                        <br />
                                                        <Trans>
                                                            Two-factor authentication (TOTP method) enhances security by requiring two distinct
                                                            methods to verify identity, thereby strengthening account protection.
                                                        </Trans>
                                                    </i>
                                                </p>
                                            </Message>
                                            <Segment padded secondary attached>
                                                <Grid verticalAlign="top" stackable>
                                                    <Grid.Column width={16}>
                                                        <Header style={{ display: "flex", alignItems: "center" }} as="h4">
                                                            <Icon circular color="grey" name="lock" />
                                                            <Trans>Password management</Trans>
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Row columns={3} width={15} verticalAlign="middle">
                                                        <Grid.Column>
                                                            <Field
                                                                name="policy.min_length"
                                                                placeholder={i18n._(t`Set minimum length of password`)}
                                                                label={i18n._(t`Password length`)}
                                                                parse={identityNull}
                                                                inputMode="numeric"
                                                                defaultValue={8}
                                                                isRequired={true}
                                                                component={InputAdapter}
                                                                disabled={!can_change}
                                                                validate={(value) => {
                                                                    const minLengthValidate = validateNumber(value, i18n, false, false, true);
                                                                    if (minLengthValidate !== undefined) {
                                                                        return minLengthValidate;
                                                                    }
                                                                    if (reparseNumber(value) > 100) {
                                                                        return <Trans>Maximum value is 100</Trans>;
                                                                    }
                                                                    if (reparseNumber(value) < 8) {
                                                                        return <Trans>Minimum value is 8</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Field
                                                                name="policy.min_nb_digits"
                                                                placeholder={i18n._(t`Set minimum number of digits`)}
                                                                label={i18n._(t`Minimum digits`)}
                                                                parse={identityNull}
                                                                inputMode="numeric"
                                                                defaultValue={0}
                                                                isRequired={true}
                                                                component={InputAdapter}
                                                                disabled={!can_change}
                                                                validate={(value) => {
                                                                    const minDigitsValidate = validateNumber(value, i18n, false, false, true);
                                                                    if (minDigitsValidate !== undefined) {
                                                                        return minDigitsValidate;
                                                                    }
                                                                    if (reparseNumber(value) > 100) {
                                                                        return <Trans>Maximum value is 100</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Field
                                                                name="policy.min_nb_lower"
                                                                placeholder={i18n._(t`Set minimum number of lowercase characters`)}
                                                                label={i18n._(t`Minimum lowercase characters`)}
                                                                parse={identityNull}
                                                                inputMode="numeric"
                                                                defaultValue={0}
                                                                isRequired={true}
                                                                component={InputAdapter}
                                                                disabled={!can_change}
                                                                validate={(value) => {
                                                                    const minNbLowerValidate = validateNumber(value, i18n, false, false, true);
                                                                    if (minNbLowerValidate !== undefined) {
                                                                        return minNbLowerValidate;
                                                                    }
                                                                    if (reparseNumber(value) > 100) {
                                                                        return <Trans>Maximum value is 100</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Field
                                                                name="policy.min_nb_upper"
                                                                placeholder={i18n._(t`Set minimum number of uppercase characters`)}
                                                                label={i18n._(t`Minimum uppercase characters`)}
                                                                parse={identityNull}
                                                                inputMode="numeric"
                                                                defaultValue={0}
                                                                isRequired={true}
                                                                component={InputAdapter}
                                                                disabled={!can_change}
                                                                validate={(value) => {
                                                                    const minNbUpperValidate = validateNumber(value, i18n, false, false, true);
                                                                    if (minNbUpperValidate !== undefined) {
                                                                        return minNbUpperValidate;
                                                                    }
                                                                    if (reparseNumber(value) > 100) {
                                                                        return <Trans>Maximum value is 100</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Field
                                                                name="policy.min_nb_special"
                                                                placeholder={i18n._(t`Set minimum number of special characters`)}
                                                                label={i18n._(t`Minimum special characters`)}
                                                                parse={identityNull}
                                                                defaultValue={0}
                                                                isRequired={true}
                                                                component={InputAdapter}
                                                                disabled={!can_change}
                                                                validate={(value) => {
                                                                    const specialValidate = validateNumber(value, i18n, false, false, true);
                                                                    if (specialValidate !== undefined) {
                                                                        return specialValidate;
                                                                    }
                                                                    if (reparseNumber(value) > 100) {
                                                                        return <Trans>Maximum value is 100</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                            <Segment padded secondary>
                                                <Grid verticalAlign="top" stackable>
                                                    <Grid.Column width={16}>
                                                        <Header style={{ display: "flex", alignItems: "center" }} as="h4">
                                                            <Icon circular color="grey" name="dont" />
                                                            <Trans>Security controls</Trans>
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Row columns={3} width={15} verticalAlign="top">
                                                        <Grid.Column>
                                                            <Segment basic>
                                                                <Header dividing as="h5" style={{ marginBottom: ".5rem" }}>
                                                                    <Trans>Check password history</Trans>&nbsp;
                                                                    <Popup
                                                                        basic
                                                                        trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}
                                                                        position="top center"
                                                                    >
                                                                        <Segment basic>
                                                                            <p>
                                                                                <Trans>
                                                                                    When changing a password, the system can check whether the new
                                                                                    password has already been used.
                                                                                </Trans>
                                                                            </p>
                                                                            <p>
                                                                                <Trans>The possible rules are:</Trans>
                                                                            </p>
                                                                            <List as="ol">
                                                                                <List.Item as="li" value="-">
                                                                                    <Trans>No verification</Trans>
                                                                                </List.Item>
                                                                                <List.Item as="li" value="-">
                                                                                    <Trans>Check all previous passwords used</Trans>
                                                                                </List.Item>
                                                                                <List.Item as="li" value="-">
                                                                                    <Trans>Check the last 'N' passwords used</Trans>
                                                                                </List.Item>
                                                                            </List>
                                                                        </Segment>
                                                                    </Popup>
                                                                </Header>
                                                                <div>
                                                                    <Field
                                                                        name="checkHistory"
                                                                        type="radio"
                                                                        label={i18n._(t`No verification`)}
                                                                        component={RadioAdapter}
                                                                        value={0}
                                                                        defaultValue={0}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <Field
                                                                        name="checkHistory"
                                                                        type="radio"
                                                                        label={i18n._(t`All previous passwords used`)}
                                                                        component={RadioAdapter}
                                                                        value={-1}
                                                                        customAction={(data) => {
                                                                            form.change("policy.history_depth", -1);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <Field
                                                                        name="checkHistory"
                                                                        type="radio"
                                                                        label={i18n._(t`The last 'N' passwords used`)}
                                                                        component={RadioAdapter}
                                                                        value={1}
                                                                        customAction={(data) => {
                                                                            if (_.includes([-1, 0], values?.policy?.history_depth)) {
                                                                                form.change("policy.history_depth", 1);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {values.checkHistory >= 1 && (
                                                                    <Field
                                                                        name="policy.history_depth"
                                                                        placeholder={i18n._(t`number of previous passwords to check`)}
                                                                        parse={identityNull}
                                                                        inputMode="numeric"
                                                                        defaultValue={1}
                                                                        component={InputAdapter}
                                                                        disabled={!can_change}
                                                                        isRequired={true}
                                                                        unitposition="right"
                                                                        unit={<Icon name="lock" />}
                                                                        customAction={(data) => {
                                                                            if (data !== "" && reparseNumber(data) === 0) {
                                                                                form.change("checkHistory", 0);
                                                                            }
                                                                        }}
                                                                        validate={(value) => {
                                                                            const historyValidate = validateNumber(value, i18n, false, false, true);
                                                                            if (historyValidate !== undefined) {
                                                                                return historyValidate;
                                                                            }
                                                                            if (reparseNumber(value) > 50) {
                                                                                return <Trans>Maximum value is 50</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                )}
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Segment basic>
                                                                <Header dividing as="h5" style={{ marginBottom: ".5rem" }}>
                                                                    <Trans>Duration before expiration</Trans>
                                                                </Header>
                                                                <div>
                                                                    <Field
                                                                        name="checkMaxAge"
                                                                        type="radio"
                                                                        label={i18n._(t`No expiration`)}
                                                                        component={RadioAdapter}
                                                                        value={0}
                                                                        defaultValue={0}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <Field
                                                                        name="checkMaxAge"
                                                                        type="radio"
                                                                        label={i18n._(t`Set number of day before password expires`)}
                                                                        component={RadioAdapter}
                                                                        value={1}
                                                                    />
                                                                </div>
                                                                {values.checkMaxAge >= 1 && (
                                                                    <Field
                                                                        name="policy.max_age"
                                                                        placeholder={i18n._(t`Number of day before password expires`)}
                                                                        parse={identityNull}
                                                                        inputMode="numeric"
                                                                        defaultValue={1}
                                                                        customAction={(data) => {
                                                                            if (data !== "" && reparseNumber(data) === 0) {
                                                                                form.change("checkMaxAge", 0);
                                                                            }
                                                                        }}
                                                                        component={InputAdapter}
                                                                        disabled={!can_change}
                                                                        isRequired={true}
                                                                        unitposition="right"
                                                                        unit={<Trans>day</Trans>}
                                                                        validate={(value) => {
                                                                            const maxAgeValidate = validateNumber(value, i18n, false, false, true);
                                                                            if (maxAgeValidate !== undefined) {
                                                                                return maxAgeValidate;
                                                                            }
                                                                            if (reparseNumber(value) > 3650) {
                                                                                return <Trans>Maximum value is 3650</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                )}
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Field
                                                                helperText={
                                                                    <>
                                                                        <p>
                                                                            <Trans>
                                                                                The system will check the similarity of the new password with the user
                                                                                attributes (email address, last name, first name).
                                                                            </Trans>
                                                                        </p>
                                                                        <p>
                                                                            <Trans>
                                                                                A value of 0.1 rejects passwords unless they are substantially
                                                                                different from the user attributes, whereas a value of 1.0 rejects
                                                                                only passwords that are identical to an attribute’s value.
                                                                            </Trans>
                                                                        </p>
                                                                    </>
                                                                }
                                                                name="policy.max_similarity"
                                                                parse={identityNull}
                                                                defaultValue={0.7}
                                                                placeholder={i18n._(t`Set score for similarity`)}
                                                                label={i18n._(t`Password similarity`)}
                                                                component={InputAdapter}
                                                                disabled={!can_change}
                                                                isRequired={true}
                                                                unitposition="right"
                                                                unit={<Icon name="clone" />}
                                                                validate={(value) => {
                                                                    const similarityValidate = validateNumber(value, i18n, false, false);
                                                                    if (similarityValidate !== undefined) {
                                                                        return similarityValidate;
                                                                    }
                                                                    if (reparseNumber(value) > 1) {
                                                                        return <Trans>Maximum value is 1</Trans>;
                                                                    }
                                                                    if (reparseNumber(value) < 0.1) {
                                                                        return <Trans>Minimum value is 0.1</Trans>;
                                                                    }

                                                                    return undefined;
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                            <Segment padded secondary>
                                                <Grid verticalAlign="top" stackable>
                                                    <Grid.Column width={16}>
                                                        <Header style={{ display: "flex", alignItems: "center" }} as="h4">
                                                            <Icon circular color="grey" name="user secret" />
                                                            <Trans>Two factors authentication</Trans>
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Row>
                                                        <Field
                                                            name="policy.two_factors"
                                                            label={i18n._(t`enable ?`)}
                                                            parse={identity}
                                                            component={ToggleAdapter}
                                                            disabled={!can_change}
                                                        />
                                                    </Grid.Row>
                                                </Grid>
                                            </Segment>
                                        </Segment.Group>
                                        {can_change && (
                                            <Grid verticalAlign="top" centered stackable>
                                                <Grid.Column width={15} floated="right" textAlign="right">
                                                    {!passwordPolicy.data && (
                                                        <Button negative onClick={() => setNoPolicyForm(true)}>
                                                            <Trans>cancel</Trans>
                                                        </Button>
                                                    )}
                                                    {passwordPolicy.data && (
                                                        <Button
                                                            onClick={(e, data) => {
                                                                deleteOrganizationPasswdPolicy({ org: org.current });
                                                            }}
                                                            type="button"
                                                            negative
                                                            icon
                                                            labelPosition="right"
                                                        >
                                                            <Icon name="trash" />
                                                            <Trans>delete</Trans>
                                                        </Button>
                                                    )}
                                                    <Button
                                                        type="submit"
                                                        positive
                                                        icon
                                                        labelPosition="right"
                                                        disabled={submitting || pristine || invalid}
                                                    >
                                                        <Icon name="check" />
                                                        <Trans>validate</Trans>
                                                    </Button>
                                                </Grid.Column>
                                            </Grid>
                                        )}
                                    </form>
                                );
                            }}
                        />
                    )}
                </>
            )}
        </Segment>
    );
};

export default SecurityPolicy;
