import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    filter: {
        searchName: "",
        siteFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        stickPage: false
    },
};

const energySavingSlice = createSlice({
    name: "energysaving",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setSiteFilter: (state, action) => {
            state.filter.siteFilter = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setSearchNameFilter, setSiteFilter, setStickPage,
    setPage, setItemsPerPage, resetFilterWithPage,
} = energySavingSlice.actions;

export default energySavingSlice.reducer;