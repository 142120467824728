import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import { Grid } from "semantic-ui-react";
import { toast } from "react-toastify";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { WidgetGaugeType, WidgetType } from "../utils";
import { toast_options_err } from "modules/notification/notificationMiddleware";
import { useSaveLayoutMutation } from "../dashboardDndService";

import WidgetItem from "./WidgetItem";

export const ResponsiveBreakpoingWidth = 992;
const ReactGridLayout = WidthProvider(RGL);
const GridCols = 12;

const WidgetsGrid = (props) => {
    const { widgets, current_dashboard, org, disabled_dashboard } = props;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [layouts, setLayouts] = useState({ layout: [], responsive: [] });

    const initialLayout = useMemo(() => {
        return _.map(widgets, (wgt) => {
            let minW = 1;
            let minH = 1;
            // processing minW && minH for widget size.
            // prevent user to resize below these points
            switch (wgt.widget_type) {
                case WidgetType.STACK_BAR_RATIO[0]:
                case WidgetType.STACK_BAR_CONSUMPTION_BREAKDOWN[0]:
                case WidgetType.SPARKLINE_CONSUMPTION[0]:
                case WidgetType.SPARKLINE_CONSUMPTIONS[0]:
                    minW = 4;
                    minH = 5;
                    break;
                case WidgetType.PIE_CHART_CONSUMPTION_BREAKDOWN[0]:
                    minW = 2;
                    minH = 5;
                    break;
                case WidgetType.GAUGE_RATIO[0]:
                case WidgetType.GAUGE_CONSUMPTION_BREAKDOWN[0]:
                case WidgetType.GAUGE_CONSUMPTION[0]:
                    //Be aware of 'w' && 'h' default values in Widget Creation (w: 2, h:4)
                    switch (wgt.gauge_choices) {
                        case WidgetGaugeType.RECTILINEAR[0]:
                            minW = 2;
                            minH = 3;
                            break;
                        case WidgetGaugeType.DIAL[0]:
                            minW = 2;
                            minH = 4;
                            break;
                        case WidgetGaugeType.TEXT[0]:
                            minH = 3;
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }

            return {
                i: wgt.id.toString(),
                x: wgt?.x ?? 0, // Default 0
                y: wgt?.y ?? 0, // Default 0
                w: Math.max(wgt?.w ?? null, 1), // Default 1
                h: Math.max(wgt?.h ?? null, 1), // Default 1
                isDraggable: window.innerWidth >= ResponsiveBreakpoingWidth, //Draggable only md+ (>992px)
                isResizable: window.innerWidth >= ResponsiveBreakpoingWidth, //Resizable only md+
                minW,
                minH
            };
        });
    }, [widgets]);

    const handleResize = () => {
        const newWidth = window.innerWidth;
        setWindowWidth(newWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setLayouts({
            layout: initialLayout,
            //For responsive, remove drag && resize mode + full width in responsive
            responsive: _.map(initialLayout, (item) => ({
                ...item,
                w: GridCols,
                isDraggable: false,
                isResizable: false
            }))
        });
    }, [initialLayout]);

    const [saveLayout, saved] = useSaveLayoutMutation();

    useEffect(() => {
        if (saved.isError) {
            toast.error(i18n._(t`Can't save dashboard grid`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saved.isSuccess, saved.isError, saved.isLoading]);

    const onLayoutChange = (layout) => {
        if (windowWidth >= ResponsiveBreakpoingWidth) {
            const new_layout = _.map(layout, (item) => {
                return {
                    ...item,
                    isDraggable: true,
                    isResizable: true
                };
            });
            setLayouts({ ...layouts, layout: new_layout });
        }
    };

    return (
        <>
            <Grid.Column width={16}>
                <ReactGridLayout
                    {...props}
                    onLayoutChange={onLayoutChange}
                    layout={windowWidth >= ResponsiveBreakpoingWidth ? layouts.layout : layouts.responsive}
                    onResizeStop={(layout, oldItem, newItem) => {
                        if (_.isEqual(oldItem, newItem) || disabled_dashboard) return;
                        const layoutToSave = _.map(layout, (item) => ({
                            ..._.pick(item, ["x", "y", "w", "h"]),
                            id: parseInt(item.i)
                        }));
                        saveLayout({
                            org: org.current,
                            dashboard_id: current_dashboard?.id,
                            layout: layoutToSave
                        });
                    }}
                    onDragStop={(layout, oldItem, newItem) => {
                        if (_.isEqual(oldItem, newItem) || disabled_dashboard) return;
                        const layoutToSave = _.map(layout, (item) => ({
                            ..._.pick(item, ["x", "y", "w", "h"]),
                            id: parseInt(item.i)
                        }));
                        saveLayout({
                            org: org.current,
                            dashboard_id: current_dashboard?.id,
                            layout: layoutToSave
                        });
                    }}
                >
                    {_.map(widgets, (widget) => {
                        return <WidgetItem key={widget.id.toString()} widget={widget} {...props} />;
                    })}
                </ReactGridLayout>
            </Grid.Column>
        </>
    );
};

WidgetsGrid.defaultProps = {
    className: "layout",
    rowHeight: 40, //Define the minimum Height for a grid row. This param seems to impact the resize of last line
    onLayoutChange: function () {},
    compactType: "vertical",
    // This turns off rearrangement so items will not be pushed arround.
    preventCollision: false,
    margin: [8, 8],
    cols: GridCols,
    draggableCancel: ".grid-cancel-drag",
    draggableHandle: ".grid-handle-drag",
    useCSSTransforms: false
};

export default React.memo(WidgetsGrid);
