import _ from "lodash";
import axios from "axios";
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import moment from "moment";
import { equipmentApi } from "./equipmentService";

const initialState = {
    init_equipments: [],
    equipments: [],
    filter: {
        searchName: "",
        siteFilter: [],
        sensorFilter: [],
        zoneFilter: [],
        eqtTypeFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
    equipment: {
        current: null,
        default: {
            brand: "",
            description: "",
            equipment_type: null,
            external_id: null,
            image: null,
            internal_ref: "",
            machine: null,
            maintenance_manager: "",
            manufacture_year: moment().year(),
            model: "",
            name: "",
            site: null,
            tag_set: [],
            zone: null,
            dataflow_set: [
                {
                    name: "",
                    dataflowspec: null,
                    dataflowspec_tech: null,
                    syndataflow: {
                        syndataflowtype: 3,
                        formula: null
                    },
                    measurement_set: [
                        {
                            display_unit: -1,
                            factor: 1,
                            maxGauge: 1000,
                            measurementtype: null,
                            measurementtype_tech: null,
                            minGauge: 0,
                            offset: 0
                        }
                    ]
                },
            ]
        }
    }
};

/**
 * Send request to update image of equipment
 */
export const updateEquipmentImage = createAsyncThunk('equipment/updateEquipmentImage', async ({ equipment, data }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, 'name', null);
    try {
        const response = await PowerAdaptAPI.patch(`/equipments/${equipment.id}?org=${current_org}&${refreshTimestampUrlParam()}`, data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});


const equipmentSlice = createSlice({
    name: "equipment",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setSiteFilter: (state, action) => {
            state.filter.siteFilter = action.payload;
        },
        setSensorFilter: (state, action) => {
            state.filter.sensorFilter = action.payload;
        },
        setZoneFilter: (state, action) => {
            state.filter.zoneFilter = action.payload;
        },
        setEqtTypeFilter: (state, action) => {
            state.filter.eqtTypeFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.equipments = state.init_equipments;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        },
        resetCurrentEquipment: (state, action) => {
            state.equipment.current = null;
        }

    },
    extraReducers(builder) {
        builder
            .addCase(updateEquipmentImage.fulfilled, (state, action) => {
                if (state.equipment.current) {
                    state.equipment.current.image = action.payload.image;
                }
            })
            .addMatcher(equipmentApi.endpoints.getEquipments.matchFulfilled, (state, action) => {
                state.equipments = action.payload;
                state.init_equipments = action.payload;
            })
            .addMatcher(equipmentApi.endpoints.getEquipment.matchFulfilled, (state, action) => {
                state.equipment.current = action.payload;
            })
            /*.addMatcher(equipmentApi.endpoints.deleteEquipment.matchFulfilled, (state, action) => {
                const id = _.get(action, 'meta.arg.originalArgs.data');
                state.equipments = _.filter(state.equipments, (equipment) => equipment.id !== id);
            })*/
            .addMatcher(isAnyOf(setSearchNameFilter, setSiteFilter, setZoneFilter, setEqtTypeFilter, setSensorFilter), (state, action) => {
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }

            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    resetCurrentEquipment, setSearchNameFilter,
    setSiteFilter, setZoneFilter, setEqtTypeFilter, setSensorFilter,
    setItemsPerPage, setPage, setStickPage, resetFilterWithPage
} = equipmentSlice.actions;

export default equipmentSlice.reducer;
