import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { refreshTimestampUrlParam } from "apis/PowerAdapt";
import cookie from "react-cookies";
import _ from "lodash";
import { defaultLng } from "modules/i18n/i18nConfig";

export const dashboardDndApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_dashboardDnd",
    keepUnusedDataFor: 600,
    tagTypes: ["Dashboards", "Dashboard", "Widgets", "Widget", "WidgetData"],
    endpoints: (build) => ({
        getDashboards: build.query({
            query: (data) => {
                const current_org = _.get(data, "org.name", "");
                return `dashboards?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Dashboards", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                const advanced_dashboards_wrap = _.chain(response)
                    .map((dashboard) => {
                        return { ...dashboard, key: dashboard.id, value: dashboard.id, text: dashboard.name };
                    })
                    .orderBy("name");
                return advanced_dashboards_wrap.value();
            }
        }),
        createDashboard: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `dashboards?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Dashboards", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateDashboard: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `dashboards/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Dashboards", id: arg.org.id }];
                }
                return [];
            }
        }),
        patchDashboard: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `dashboards/${data.id}?org=${current_org}`,
                    method: "PATCH",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Dashboards", id: arg.org.id }];
                }
                return [];
            }
        }),
        deleteDashboard: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `dashboards/${data.id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Dashboards", id: arg.org.id }];
                }
                return [];
            }
        }),
        getWidgets: build.query({
            query: ({ org, dashboard_id }) => {
                const current_org = _.get(org, "name", "");
                return `/dashboards/${dashboard_id}/widgets?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "Widgets", id: arg.dashboard_id }];
            },
            transformResponse: (response, meta, arg) => {
                return _.reduce(
                    response,
                    (res, widget) => {
                        if (widget.dashboard === arg.dashboard_id) {
                            res.push(widget);
                        }
                        return res;
                    },
                    []
                );
            }
        }),
        getWidget: build.query({
            query: ({ org, dashboard_id, widget_id }) => {
                const current_org = _.get(org, "name", "");
                return `/dashboards/${dashboard_id}/widgets/${widget_id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "Widget", id: arg.widget_id }];
            }
        }),
        createWidget: build.mutation({
            query: ({ formdata, org, dashboard_id }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `/dashboards/${dashboard_id}/widgets?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: formdata
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Widgets", id: arg.dashboard_id }];
                }
                return [];
            }
        }),
        updateWidget: build.mutation({
            query: ({ formdata, org, dashboard_id }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `/dashboards/${dashboard_id}/widgets/${formdata.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: formdata
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Widget", id: result.id },
                        { type: "Widgets", id: result.dashboard_id }
                    ];
                }
                return [];
            }
        }),
        patchWidget: build.mutation({
            query: ({ formdata, org, dashboard_id }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `/dashboards/${dashboard_id}/widgets/${formdata.id}?org=${current_org}`,
                    method: "PATCH",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: formdata
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Widget", id: result.id },
                        { type: "Widgets", id: result.dashboard_id }
                    ];
                }
                return [];
            }
        }),
        deleteWidget: build.mutation({
            query: ({ org, widget_id, dashboard_id }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `/dashboards/${dashboard_id}/widgets/${widget_id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Widget", id: arg.widget_id },
                        { type: "Widgets", id: arg.dashboard_id }
                    ];
                }
                return [];
            }
        }),
        saveLayout: build.mutation({
            query: ({ org, dashboard_id, layout }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `/dashboards/${dashboard_id}/widgets/layout?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: layout
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Widgets", id: arg.dashboard_id }];
                }
                return [];
            }
        }),
        getWidgetData: build.query({
            query: ({ org, dashboard_id, widget_id, end }) => {
                const current_org = _.get(org, "name", "");
                return `/dashboards/${dashboard_id}/widgets/${widget_id}/data?org=${current_org}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "WidgetData", id: arg.widget_id }];
            }
        })
    })
});

export const {
    //Dahboard part
    useGetDashboardsQuery,
    useCreateDashboardMutation,
    usePatchDashboardMutation,
    useUpdateDashboardMutation,
    useDeleteDashboardMutation,
    useSaveLayoutMutation,
    //Widget part
    useGetWidgetsQuery,
    useDeleteWidgetMutation,
    useCreateWidgetMutation,
    useUpdateWidgetMutation,
    usePatchWidgetMutation,
    useGetWidgetDataQuery
} = dashboardDndApi;
