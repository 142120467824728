import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Trans, t } from '@lingui/macro';
import _ from "lodash";
import { Segment, Grid, Header, Divider, Table } from "semantic-ui-react";

import i18n from 'modules/i18n/i18nConfig';
import { useGetEnergySavingQuery, useGetSavedConsumptionQuery } from '../energySavingService';
import { clearTime, setTime } from 'modules/overview/overviewSlice';
import { dateRangeOptions, roundedDate } from 'modules/time/utils';

import Back from "modules/common/components/back";
import TimePanel from 'modules/common/components/TimePanel';
import GraphicConsumptions from './GraphicConsumptions';
import MessageDisplay from 'modules/common/components/MessageDisplay';
import { useGetSitesQuery } from 'modules/site/siteService';
import { format_period } from '../utils';
import Synthesis from './Synthesis';

const EnergySavingDetail = (props) => {
    const now = moment();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { org, overview } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const customStyle = { cursor: "default", "whiteSpace": "nowrap" };

    useEffect(() => {
        //care usage of overviewSlice for Energysaving timing
        return () => {
            dispatch(clearTime({ tab: 'ape' }));
        };
    }, [dispatch]);


    const { default_time, rangeOptions } = useMemo(() => {
        return {
            default_time: {
                start: roundedDate(now.clone().subtract(7, 'days').startOf("days"), 10),
                end: roundedDate(now.clone().startOf('minute'), 10),
                plage: '7d'
            },
            rangeOptions: _.filter(dateRangeOptions(), (item) => !_.includes(['24h', '48h', '72h'], item.value))
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rangeTime = overview.ape.time ? {
        start: moment(overview.ape.time.start), end: moment(overview.ape.time.end), plage: overview.ape.time.plage
    } : default_time;

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const energySaving = useGetEnergySavingQuery({ org: org.current, energysaving_id: id }, { skip: !org.current || !_.isFinite(parseInt(id)) });

    const params = useMemo(() => {
        const { start, end } = rangeTime;

        return {
            org: org.current,
            energysaving_id: parseInt(id),
            start: start.format("YYYY-MM-DD"),
            end: end.format("YYYY-MM-DD"),
            lng: current_lng
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, rangeTime, org]);

    let err_list = [sites.isError];
    let status_list = [sites.isSuccess];

    if (_.isFinite(parseInt(id))) {
        err_list = [...err_list, energySaving.isError];
        status_list = [...status_list, energySaving.isSuccess];
    }

    //skip if no org, no ID, other requests fail
    const savedConsumption = useGetSavedConsumptionQuery(params, { skip: !org.current || !_.isFinite(parseInt(id)) || !_.every(status_list) });
    const period_fmt = format_period(savedConsumption.data?.reference_period_start, savedConsumption.data?.reference_period_end, i18n, current_lng);
    const site = _.find(sites.data, { id: energySaving.data?.site });
    const unit_currency = site?.conversions?.currency ?? null;

    let savedConsumptionError = t`error loading data`;
    if (savedConsumption.isError && savedConsumption?.error?.data?.code) {
        savedConsumptionError = savedConsumption?.error?.data?.code;
    }

    return (() => {
        if (_.some(err_list)) {
            return (
                <MessageDisplay
                    message={i18n._(t`error loading data`)}
                    level="error"
                    iconName="warning circle"
                    isLoading={false}
                    attached={false}
                />
            );
        }
        else if (_.every(status_list)) {
            return (
                <Segment attached>
                    <Grid centered verticalAlign="middle">
                        <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                            <Grid.Column width={2}>
                                <Back />
                            </Grid.Column>
                            <Grid.Column width={12} textAlign="center">
                                <Header as="h1">
                                    {_.get(energySaving, 'data.name', '-')}
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={2} />
                        </Grid.Row>
                        {savedConsumption.isSuccess && (
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={10} computer={10}>
                                    <Divider horizontal><Trans>informations</Trans></Divider>
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{i18n._(t`Reference period`)}</Table.HeaderCell>
                                                <Table.HeaderCell>{i18n._(t`Implementation date`)}</Table.HeaderCell>
                                                <Table.HeaderCell>{i18n._(t`Start of follow-up period`)}</Table.HeaderCell>
                                                <Table.HeaderCell>{i18n._(t`Cumulative gain (${savedConsumption.data?.unit_consumption ?? "-"})`)}</Table.HeaderCell>
                                                {unit_currency && (<Table.HeaderCell>{i18n._(t`Cumulative gain (${unit_currency})`)}</Table.HeaderCell>)}
                                                <Table.HeaderCell>{i18n._(t`Cumulative gain (%)`)}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {period_fmt}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {(savedConsumption.data?.implementation_date ?? null) !== null
                                                        ? <span style={customStyle}>{`${moment(savedConsumption.data?.implementation_date).locale(current_lng).format("L")}`}</span>
                                                        : "-"}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {(savedConsumption.data?.tracking_date ?? null) !== null
                                                        ? <span style={customStyle}>{`${moment(savedConsumption.data?.tracking_date).locale(current_lng).format("L")}`}</span>
                                                        : "-"}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {_.isFinite(savedConsumption.data?.cumulative_gain) ?
                                                        <span style={customStyle}>{`${i18n.number(savedConsumption.data?.cumulative_gain, { maximumFractionDigits: 2 })}`}</span>
                                                        : "-"}
                                                </Table.Cell>
                                                {unit_currency && (<Table.Cell>
                                                    {_.isFinite(savedConsumption.data?.cumulative_gain_currency) ?
                                                        <span style={customStyle}>{`${i18n.number(savedConsumption.data?.cumulative_gain_currency, { maximumFractionDigits: 2 })}`}</span>
                                                        : "-"}
                                                </Table.Cell>)}
                                                <Table.Cell>
                                                    {_.isFinite(savedConsumption.data?.cumulative_gain_percent) ?
                                                        <span style={customStyle}>{`${i18n.number(savedConsumption.data?.cumulative_gain_percent, { maximumFractionDigits: 2 })}`}</span>
                                                        : "-"}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                <TimePanel
                                    time={rangeTime}
                                    rangeOptions={rangeOptions}
                                    accordion={true}
                                    action={({ start, end, plage }) => {
                                        dispatch(setTime({ start, end, plage, tab: 'ape' }));
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {savedConsumption.isError && (
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={14} computer={14} textAlign="center">
                                    <MessageDisplay message={i18n._(savedConsumptionError)} level="error" iconName="warning circle" isLoading={false} attached={false} />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        {savedConsumption.isLoading && (
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={14} computer={14} textAlign="center">
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        {savedConsumption.isSuccess && (
                            <Grid.Row>
                                <Grid.Column mobile={16} tablet={14} computer={14} textAlign="center">
                                    <GraphicConsumptions savedConsumption={savedConsumption.data} site={site} />
                                    <Synthesis savedConsumption={savedConsumption.data} site={site} />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                </Segment>
            );
        } else {
            return (
                <MessageDisplay
                    message={i18n._(t`loading data`)}
                    level="info"
                    iconName="circle notched"
                    isLoading={true}
                />
            );
        }
    })();
};

export default EnergySavingDetail;