import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import { Button, Modal, Icon } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteReportMutation } from "../reportService";


const ReportDelete = ({ org, report, setPage }) => {
    const dispatch = useDispatch();
    const report_id = report?.id ?? null;
    const [open, setOpen] = useState(false);
    const [deleteReport, { isError, isLoading, isSuccess }] = useDeleteReportMutation();

    useEffect(() => {
        if (isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (isSuccess) {
            toast.success(i18n._(t`successful delete report`), toast_options);
            //restart reports' table pagination 
            dispatch(setPage(1));
        }
        if (isError) {
            toast.error(i18n._(t`cannot delete report`), toast_options_err);
        }
    }, [dispatch, setPage, isError, isSuccess, isLoading]);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon="delete" />}
        >
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete report {report?.name ?? "-"}</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    labelPosition="right"
                    icon
                    positive
                    onClick={async (e) => {
                        await setOpen(false);
                        deleteReport({ org, report_id });
                    }}
                >
                    <Trans>validate</Trans>
                    <Icon name="trash" inverted />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

ReportDelete.propTypes = {
    report: PropTypes.object.isRequired,
};

export default React.memo(ReportDelete);
