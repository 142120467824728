import React, { useEffect, useMemo } from 'react';
import _ from "lodash";
import { toast } from 'react-toastify';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'react-final-form';
import { Icon, Button, Modal, Divider, Segment } from 'semantic-ui-react';

import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import { useAddNodeMutation } from 'modules/hierarchy/hierarchyService';
import { NodeActionType, remapForReactFlow } from 'modules/hierarchy/utils';

import { DropDownAdapter, InputAdapter } from 'modules/common/components/form';
import MessageDisplay from 'modules/common/components/MessageDisplay';


/**
 * Used for form validation. If errors, you can't submit form
 * @function validate
 * @param {object} values
 */
const validate = (values) => {
    const errors = {};
    if (values.equipment === -1 && !values.name) {
        errors.name = <Trans>node name is required</Trans>;
    }
    return errors;
};

const AddNode = (props) => {
    const { openModal, setOpenModal, i18n, data } = props;

    const { equipments, org, rangeTime: { start, end } } = data.extra;
    const { onNodeAction } = data.actions;
    const current_node = data.node_db;

    const [addNode, add] = useAddNodeMutation();

    useEffect(() => {
        if (add.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (add.isSuccess) {
            toast.success(i18n._(t`successful add node`), toast_options);
        }
        if (add.isError) {
            toast.error(i18n._(t`cannot add node`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [add.isSuccess, add.isError, add.isLoading]);

    const onSubmitForm = async (formData) => {
        const added_data = {
            ...formData,
            equipment: _.includes([null, -1], formData.equipment) ? null : formData.equipment,
        };
        const action = await addNode({
            org,
            data: added_data,
            diagram_id: current_node.diagram,
            start: start.format("YYYY-MM-DD"),
            end: end.format("YYYY-MM-DD")
        });
        const error = _.get(action, 'error', null);
        if (!error) {
            const nodes_edges = _.reduce(action.data, (res, node) => {
                const { node: remapNode, edge } = remapForReactFlow(node);
                if (edge) {
                    res.edges.push(edge);
                }
                res.nodes.push(remapNode);

                return res;
            }, { nodes: [], edges: [] });
            onNodeAction && await onNodeAction(nodes_edges, NodeActionType.Add, data.extra);


            await setOpenModal(false);
        }
    };

    //Define semantic equipment options
    const eqptsOptions = useMemo(() => {
        return [{ key: -1, value: -1, text: i18n._(t`No equipment`) }].concat(equipments);
    }, [equipments, i18n]);

    const initialValues = useMemo(() => {
        return {
            parent: current_node.path,
            diagram: current_node.diagram,
            equipment: -1,
            position_x: current_node.position_x + 30,
            position_y: current_node.position_y + 90,
            style: {}
        };
    }, [current_node]);

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                return (
                    <Modal open={openModal}
                        centered={false}
                        closeOnDimmerClick
                        closeOnEscape
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                    >
                        <Modal.Header>
                            <Trans>Add node</Trans>
                        </Modal.Header>
                        <Modal.Content>
                            <form onSubmit={handleSubmit} className="ui form">
                                <Field
                                    name="name"
                                    placeholder={i18n._(t`enter name of node`)}
                                    label={i18n._(t`name`)}
                                    component={InputAdapter}
                                />
                                <Field
                                    name="equipment"
                                    label={i18n._(t`equipment`)}
                                    options={eqptsOptions}
                                    component={DropDownAdapter}
                                />
                                <Divider />
                                {values.equipment === -1 && !values.name && (
                                    <Segment basic textAlign="center">
                                        <MessageDisplay
                                            message={i18n._(
                                                t`Enter a name if you don't select equipment`
                                            )}
                                            level="info"
                                            iconName="info circle"
                                            isLoading={false}
                                            attached={false}
                                        />
                                    </Segment>
                                )}
                                <Segment attached basic textAlign='right'>
                                    <Button
                                        type="button"
                                        negative
                                        icon
                                        labelPosition="left"
                                        onClick={(e) => {
                                            setOpenModal(false);
                                        }}
                                    >
                                        <Icon name="arrow left" />
                                        <Trans>cancel</Trans>
                                    </Button>
                                    <Button
                                        positive
                                        icon
                                        labelPosition="right"
                                        type="submit"
                                        disabled={submitting || pristine || invalid}
                                    >
                                        <Icon name="send" />
                                        <Trans>Confirm</Trans>
                                    </Button>
                                </Segment>
                            </form>
                        </Modal.Content>
                    </Modal>

                );
            }} />
    );
};

export default React.memo(AddNode);