import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { Grid, Button } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetConsumptionsQuery, useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import MessageDisplay from "modules/common/components/MessageDisplay";

const pollingInterval = 1000 * 60 * 10;

export const UsagePercent = (props) => {
    const { measure } = props;
    const org = useSelector((state) => state.org);
    const filtered_equipments = useSelector((state) => state.dash.filtered_equipments);
    //force re-render when lng changes
    useSelector((state) => state.i18n.current);

    const consumptions = useGetConsumptionsQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const success_list = [consumptions.isSuccess, categories.isSuccess, measurementtypes.isSuccess, measurements.isSuccess, dataflows.isSuccess];

    const fetching_list = [
        consumptions.isFetching,
        categories.isFetching,
        measurementtypes.isFetching,
        measurements.isFetching,
        dataflows.isFetching
    ];

    const error_list = [consumptions.isError, categories.isError, measurementtypes.isError, measurements.isError, dataflows.isError];

    useEffect(() => {
        const consumptionsTimer = setInterval(() => {
            consumptions.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(consumptionsTimer);
        };
    }, [consumptions, org]);

    const { category, calcByCatZone, calcByCat, current_conso_measure } = useMemo(() => {
        let process_data = { calcByCat: null, calcByCatZone: null, category: "", current_conso_measure: null };
        if (_.every(success_list)) {
            const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
            const category = _.find(categories.data, { id: dataflow?.dataflowspec })?.name ?? "";
            const filtered_equipments_ids = _.map(filtered_equipments, (item) => item.id);
            //Sum all consumptions based on current measurement
            const total_conso_cat = _.chain(measurements.data)
                .filter((m) => {
                    //Same measurementtype && Only measure in filtered_equipments based on dataflow
                    const df = _.find(dataflows.data, { id: m?.dataflow });
                    return m?.measurementtype === measure?.measurementtype?.id && _.includes(filtered_equipments_ids, df?.equipment);
                })
                .reduce((res, m) => {
                    const conso_measure = _.get(consumptions, `data[${m?.id}]`);
                    if (!_.isFinite(conso_measure)) return res;
                    res += conso_measure;
                    return res;
                }, null)
                .defaultTo(null)
                .value();

            process_data = { ...process_data, current_conso_measure: consumptions.data?.[measure?.id] ?? null };

            if (total_conso_cat > 0 && _.isFinite(process_data.current_conso_measure)) {
                process_data = { ...process_data, calcByCat: (process_data.current_conso_measure / total_conso_cat) * 100 };
            }

            //Sum all consumptions based on Zone of measurement (via dataflow)
            const total_conso_cat_zone = _.chain(measurements.data)
                .filter((m) => {
                    //Same measurementtype && Same Zone (based on dataflow)
                    const df = _.find(dataflows.data, { id: m?.dataflow });
                    return m?.measurementtype === measure?.measurementtype?.id && dataflow?.zone === df?.zone;
                })
                .reduce((res, m) => {
                    const conso_measure = _.get(consumptions, `data[${m?.id}]`);
                    if (!_.isFinite(conso_measure)) return res;
                    res += conso_measure;
                    return res;
                }, null)
                .defaultTo(null)
                .value();

            if (total_conso_cat_zone > 0 && _.isFinite(process_data.current_conso_measure)) {
                process_data = { ...process_data, calcByCatZone: (process_data.current_conso_measure / total_conso_cat_zone) * 100 };
            }

            return { ...process_data, category };
        }
        return process_data;
    }, [measure, measurements, dataflows, categories, success_list, filtered_equipments, consumptions]);

    if (_.some(fetching_list)) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (_.some(error_list)) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (_.every(success_list)) {
        if (!_.isFinite(current_conso_measure)) {
            return <MessageDisplay message={i18n._(t`no data 24h`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />;
        }

        return (
            <Grid centered>
                <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                    <Trans>weight consumption</Trans> <b>{i18n._(category)}</b>
                </Grid.Column>
                <Grid.Column width={7}>
                    <div className="right pwaUsagePercent">
                        <Button color="teal" circular>
                            {!_.isFinite(calcByCatZone) && "- %"}
                            {_.isFinite(calcByCatZone) && `${i18n.number(calcByCatZone, { maximumFractionDigits: 1 })} %`}
                        </Button>
                        <div className="pwaUsagePercentText">
                            <Trans>in area</Trans>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column width={7}>
                    <div className="left pwaUsagePercent">
                        <Button color="pink" circular>
                            {!_.isFinite(calcByCat) && "- %"}
                            {_.isFinite(calcByCat) && `${i18n.number(calcByCat, { maximumFractionDigits: 1 })} %`}
                        </Button>
                        <div className="pwaUsagePercentText">
                            <Trans>in selection</Trans>
                        </div>
                    </div>
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

UsagePercent.propTypes = {
    measure: PropTypes.object.isRequired
};

export default UsagePercent;
