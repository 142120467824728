import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    refetchTmst: null
};

const dashboardDnDSlice = createSlice({
    name: "dashDnD",
    initialState,
    reducers: {
        setRefetchRequest: (state, action) => {
            state.refetchTmst = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const { setRefetchRequest } = dashboardDnDSlice.actions;

export default dashboardDnDSlice.reducer;
