import React, { useEffect } from "react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Segment, Header, Button, Icon, Popup, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { useGetMailingsQuery } from '../mainlingService';
import { setItemsPerPage, setPage, setStickPage } from '../mailingSlice';

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeleteMailingsList from './DeleteMailingsList';


const Mailings = () => {
    const { org, notification, mailing } = useSelector(state => state);
    const mailings = useGetMailingsQuery({ org: org.current }, { skip: !org.current });

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {

            mailing.pagination.stickPage && await dispatch(setStickPage(false));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "members", label: i18n._(t`members`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }

    ];

    const can_change = notification.srv_status.db_status === "rw";

    const renderMembers = (members) => {
        if (_.size(members) === 0) {
            return <Label><Trans>No members in this mailing list</Trans></Label>;
        }
        return (
            <Label.Group>{
                _.map(members, (member, idx) => {
                    return <Label key={idx}>{member?.email ?? '-'}</Label>;
                }
                )}
            </Label.Group>
        );
    };

    const mailings_list = _.chain(mailing.mailings)
        .reduce((res, item) => {
            res.push(item);
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const members = _.get(item, "mailinglistmember_set", []);
            const members_val = _.chain(members).map((mailing) => mailing?.email ?? '').defaultTo([]).join(',').value();

            res.push({
                ...item,
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                members: {
                    render: renderMembers(members),
                    value: members_val, //string concat for members used to ordering header system
                    textAlign: "left",
                    datatype: "string",
                },
                actions: {
                    render: (() => (
                        <Button.Group>
                            <Popup
                                trigger={
                                    <Button
                                        icon={can_change ? "edit" : "eye"}
                                        onClick={async (e) => {
                                            await dispatch(setStickPage(true));
                                            if (can_change) {
                                                history_app.push(`mailing/${item.id}/change`);
                                            } else {
                                                history_app.push(`mailing/${item.id}/view`);
                                            }

                                        }}
                                    />
                                }
                            >
                                <Popup.Content>
                                    {can_change ? <Trans>Mailing list edition</Trans> : <Trans>mailing list view</Trans>}
                                </Popup.Content>
                            </Popup>
                            {can_change && (<DeleteMailingsList item={item} />)}
                        </Button.Group>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();


    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">

                {/* Header  */}
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/manage" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Mailings lists</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>

                {/* check mailings status */}
                {mailings.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {mailings.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}

                {/* Main Content  */}
                {mailings.isSuccess && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <Grid celled>
                                {can_change && (<Grid.Column width={16} style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Button
                                        icon
                                        labelPosition="left"
                                        onClick={async (e) => {
                                            history_app.push(`mailing/add`);
                                        }}
                                    >
                                        <Icon name="add" />
                                        <Trans>Add a new mailing list</Trans>
                                    </Button>
                                </Grid.Column>
                                )}
                                {/* Table  */}
                                <Grid.Column width={16}>
                                    <TableEnhanced
                                        headCells={headCells}
                                        rows={mailings_list}
                                        order="asc"
                                        orderBy="name"
                                        page={mailing.pagination.page}
                                        rowsPerPage={mailing.pagination.itemsPerPage}
                                        setPage={(page) => { dispatch(setPage(page)); }}
                                        setItemsPerPage={(items) => { dispatch(setItemsPerPage(items)); }}
                                        textItemPerPages={i18n._(t`items per page`)}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                )
                }
            </Grid>
        </Segment>
    );
};

export default Mailings;
