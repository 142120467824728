import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { setSelectedEquipment } from "modules/dashboard/dashboardSlice";

const Name = (props) => {
    const { equipment } = props;
    const selected_equipments = useSelector((state) => state.dash.selected_equipments);
    const dispatch = useDispatch();

    //id:16 => categorie digital input
    const hasDigitalInput = _.includes(equipment?.dataflowspec_set, 16);

    return (
        <div className={`ui ${hasDigitalInput ? "" : "checkbox"} pwaNameWidget`}>
            {!hasDigitalInput && (
                <input
                    type="checkbox"
                    onChange={() => {
                        dispatch(setSelectedEquipment(equipment?.id));
                    }}
                    checked={_.includes(selected_equipments, equipment?.id)}
                />
            )}
            <label style={{ cursor: "default" }}>{equipment?.name ?? "-"}</label>
        </div>
    );
};

export default Name;
