import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Header, Segment, Container, Divider, Message, Button, Modal, Popup } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHolding } from "@fortawesome/free-solid-svg-icons";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useGetAdvancedAnalysisQuery } from "modules/analysisAdvanced/analysisAdvancedService";
import { useGetMailingsQuery } from "modules/mailing/mainlingService";
import { useCreateReportMutation, useGetReportQuery, useGetReportsQuery, useUpdateReportMutation } from "../reportService";
import { daysOptions, frequencyOptions } from "modules/export/utils";
import history_app from "history_app";

import Back from "modules/common/components/back";
import { DropDownAdapter, InputAdapter, TextAreaAdapter, ToggleAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { ExportTimerAdapter } from "modules/export/components/Export";
import MailingAddModalForm from "modules/mailing/components/MailingAddModalForm";
import ReportAnalysisSelector from "./ReportAnalysisSelector";

const default_report = {
    analysis: [],
    day_of_month: null,
    day_of_week: null,
    description: "",
    frequency: frequencyOptions[0].value,
    hours: "00:00",
    hour: 0,
    minute: 0,
    is_active: true,
    mailinglist: [],
    month: null,
    name: ""
};

const Report = (props) => {
    const { id } = useParams();
    const { org, auth, notification, mailing } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);

    const org_id = _.get(org, "current.id", null);
    const user_id = _.get(auth, "user.user_id", null);

    const reports = useGetReportsQuery({ org: org.current }, { skip: !org.current });
    const advanced_analysis = useGetAdvancedAnalysisQuery({ org: org.current }, { skip: !org.current });
    const mailings = useGetMailingsQuery({ org: org.current }, { skip: !org.current });
    const report = useGetReportQuery({ org: org.current, report_id: id }, { skip: !org.current || id === undefined });

    const [createReport, create] = useCreateReportMutation();
    const [updateReport, update] = useUpdateReportMutation();

    useEffect(() => {
        if (create.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`successful create report`), toast_options);
            history_app.push(`/reports/${create.data.id}`);
        }
        if (create.isError) {
            //extract specific message from django server
            const external_id_err = update?.error?.data?.external_id?.[0] ?? null;
            if (external_id_err) {
                toast.error(i18n._(external_id_err), toast_options_err);
            } else {
                //default error
                toast.error(i18n._(t`cannot create report`), toast_options_err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError, create.isLoading]);

    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update report`), toast_options);
        }
        if (update.isError) {
            const external_id_err = update?.error?.data?.external_id?.[0] ?? null;
            if (external_id_err) {
                toast.error(i18n._(external_id_err), toast_options_err);
            } else {
                toast.error(i18n._(t`cannot update report`), toast_options_err);
            }
        }
        //eslint-disable-next-line
    }, [update.isSuccess, update.isError, update.isLoading]);

    let err_list = [reports.isError, advanced_analysis.isError, mailings.isError];
    if (id !== undefined) {
        err_list = [...err_list, report.isError];
    }

    let status_list = [reports.isSuccess, advanced_analysis.isSuccess, mailings.isSuccess];
    if (id !== undefined) {
        status_list = [...status_list, report.isSuccess];
    }

    const initialValues = useMemo(() => {
        return {
            ...default_report, //default_data
            organization: org_id, //default_override org (for creation)
            owner: user_id, //default_override user (for creation)
            ...report.data //put real data (undefined in creation)
        };
    }, [org_id, user_id, report.data]);

    const submitForm = async (formData, form) => {
        //rewrite hour/minute based on "hours" field
        const hours = _.get(formData, "hours");
        const hour = parseInt(hours.split(":")[0]);
        const minute = parseInt(hours.split(":")[1]);
        let data = {
            ..._.omit(formData, ["hours", "open"]), //remove "hours && open field" only for frontend
            hour,
            minute,
            analysis: _.map(formData.analysis, (analysis, idx) => {
                return { ...analysis, order: idx + 1 }; //rewrite analysis ordering before send to server
            })
        };
        data = _.mapValues(data, (item, key) => {
            if (key === "day_of_month") {
                return reparseNumber(item);
            }
            return item;
        });

        if (id === undefined) {
            createReport({ org: org.current, data });
        } else {
            updateReport({ org: org.current, data });
        }
    };

    const mailings_id = useMemo(() => {
        if (!mailing.mailings) return [];
        return _.map(mailing.mailings, (item) => {
            return item.id;
        });
    }, [mailing]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target={id !== undefined ? "/reports" : undefined} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id === undefined && <Trans>add report</Trans>}
                            {id !== undefined && <Trans>report</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {(() => {
                        if (_.some(err_list)) {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        } else if (_.every(status_list)) {
                            const is_admin = auth.rights?.is_admin;
                            return (
                                <Grid.Column width={16}>
                                    <Container>
                                        <Form
                                            onSubmit={submitForm}
                                            mutators={{ ...arrayMutators }}
                                            initialValues={initialValues}
                                            render={({
                                                handleSubmit,
                                                form,
                                                form: {
                                                    mutators: { push }
                                                },
                                                submitting,
                                                pristine,
                                                invalid,
                                                values
                                            }) => {
                                                const is_owner = auth.user.user_id === values?.owner;
                                                const analysis_count = _.size(values.analysis);
                                                const disabled = notification.srv_status.db_status !== "rw" || (id !== undefined && !is_owner);
                                                const not_visible_mailings = _.chain(values.mailinglist).difference(mailings_id).size().value() > 0;
                                                return (
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                        <Field
                                                            name="is_active"
                                                            label={i18n._(t`is_active`)}
                                                            labelposition="right"
                                                            component={ToggleAdapter}
                                                            disabled={disabled}
                                                        />
                                                        <Field
                                                            name="name"
                                                            placeholder={i18n._(t`enter name of report`)}
                                                            label={i18n._(t`name`)}
                                                            isRequired={true}
                                                            component={InputAdapter}
                                                            validate={(value) => {
                                                                const existing_name = _.chain(reports.data)
                                                                    .filter((report) => {
                                                                        return report.id !== parseInt(id);
                                                                    })
                                                                    .find({ name: value })
                                                                    .value();

                                                                if (existing_name) {
                                                                    return <Trans>a report with this name exists</Trans>;
                                                                }
                                                                if (!value) {
                                                                    return <Trans>report name is required</Trans>;
                                                                }
                                                                return undefined;
                                                            }}
                                                            disabled={disabled}
                                                        />
                                                        <Field
                                                            name="description"
                                                            placeholder={i18n._(t`enter description of your report`)}
                                                            label={i18n._(t`description`)}
                                                            component={TextAreaAdapter}
                                                            disabled={disabled}
                                                        />
                                                        <Divider horizontal>
                                                            <Trans>frequency</Trans>
                                                        </Divider>
                                                        <Message size="small">
                                                            <Message.Header>
                                                                <Trans>schedule report definition</Trans>
                                                            </Message.Header>
                                                            <p>
                                                                <Trans>schedule report definition content</Trans>
                                                            </p>
                                                        </Message>
                                                        <Field
                                                            name="frequency"
                                                            label={i18n._(t`frequency`)}
                                                            options={frequencyOptions}
                                                            component={DropDownAdapter}
                                                            isRequired
                                                            validate={(value) => {
                                                                if (!value) return <Trans>frequency is required</Trans>;
                                                                return undefined;
                                                            }}
                                                            disabled={disabled}
                                                        />
                                                        {values.frequency === "1" && (
                                                            <Field
                                                                name="day_of_week"
                                                                label={i18n._(t`dayofweek`)}
                                                                placeholder={i18n._(t`choose the day of the week`)}
                                                                options={daysOptions}
                                                                component={DropDownAdapter}
                                                                disabled={disabled}
                                                            />
                                                        )}
                                                        {values.frequency === "2" && (
                                                            <Field
                                                                name="day_of_month"
                                                                label={i18n._(t`dayofmonth`)}
                                                                placeholder={i18n._(t`choose the day of the month`)}
                                                                parse={identityNull}
                                                                inputMode="numeric"
                                                                component={InputAdapter}
                                                                disabled={disabled}
                                                                validate={(value) => {
                                                                    const dayValidate = validateNumber(value, i18n, false, false, true);
                                                                    if (dayValidate !== undefined) {
                                                                        return dayValidate;
                                                                    }
                                                                    const day = reparseNumber(value);
                                                                    if (day < 1 || day > 31) {
                                                                        return <Trans>invalid dayofmonth - number between 1 to 31</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                        )}
                                                        <Field
                                                            name="hours"
                                                            label={i18n._(t`hours`)}
                                                            values={values}
                                                            isRequired={true}
                                                            locale={current_lng}
                                                            component={ExportTimerAdapter}
                                                            disabled={disabled}
                                                        />
                                                        {id !== undefined && is_admin && initialValues.owner_email !== auth.user.email && (
                                                            <Grid verticalAlign="bottom">
                                                                <Grid.Column mobile={13} tablet={15} computer={15}>
                                                                    <Field
                                                                        name="owner_email"
                                                                        label={i18n._(t`owner_email`)}
                                                                        component={InputAdapter}
                                                                        disabled={true}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={1} textAlign="center">
                                                                    <Modal
                                                                        open={values.open}
                                                                        closeOnDimmerClick={false}
                                                                        onClose={() => form.change("open", false)}
                                                                        trigger={
                                                                            <Popup
                                                                                trigger={
                                                                                    <Button
                                                                                        basic
                                                                                        type="button"
                                                                                        icon
                                                                                        onClick={(e) => {
                                                                                            form.change("open", true);
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faHandHolding} className="icon" />
                                                                                    </Button>
                                                                                }
                                                                            >
                                                                                <Popup.Content>
                                                                                    <Trans>Take ownership</Trans>
                                                                                </Popup.Content>
                                                                            </Popup>
                                                                        }
                                                                    >
                                                                        <Modal.Header>
                                                                            <Trans>take ownership</Trans>
                                                                        </Modal.Header>
                                                                        <Modal.Content>
                                                                            <p>
                                                                                <Trans>
                                                                                    You will take ownership of the report. Following this change, you
                                                                                    must change the mailing lists
                                                                                </Trans>
                                                                            </p>
                                                                        </Modal.Content>
                                                                        <Modal.Actions>
                                                                            <Button
                                                                                type="button"
                                                                                negative
                                                                                onClick={(e, data) => {
                                                                                    form.change("open", false);
                                                                                }}
                                                                            >
                                                                                <Trans>cancel</Trans>
                                                                            </Button>
                                                                            <Button
                                                                                type="submit"
                                                                                positive
                                                                                onClick={() => {
                                                                                    //todo change owner
                                                                                    form.change("owner", auth.user.user_id);
                                                                                    form.change("owner_email", auth.user.email);
                                                                                    form.change("mailinglist", []);
                                                                                    form.change("open", false);
                                                                                }}
                                                                            >
                                                                                <Trans>validate</Trans>
                                                                            </Button>
                                                                        </Modal.Actions>
                                                                    </Modal>
                                                                </Grid.Column>
                                                            </Grid>
                                                        )}
                                                        <Grid verticalAlign="bottom">
                                                            <Grid.Column
                                                                mobile={!disabled ? 13 : 16}
                                                                tablet={!disabled ? 15 : 16}
                                                                computer={!disabled ? 15 : 16}
                                                            >
                                                                <Field
                                                                    name="mailinglist"
                                                                    label={i18n._(t`mailinglists`)}
                                                                    placeholder={i18n._(t`choose mailinglists`)}
                                                                    options={mailing.mailings}
                                                                    multipleselect={1}
                                                                    value={values.mailinglist}
                                                                    component={DropDownAdapter}
                                                                    noResultsMessage={i18n._(t`no mailinglists found`)}
                                                                    isRequired={true}
                                                                    validate={(value) => {
                                                                        if (_.size(value) === 0) return <Trans>Mailinglists required</Trans>;
                                                                        return undefined;
                                                                    }}
                                                                    disabled={disabled}
                                                                />
                                                            </Grid.Column>
                                                            {!disabled && (
                                                                <Grid.Column width={1} textAlign="center">
                                                                    <MailingAddModalForm form={form} mailings={mailing.mailings} />
                                                                </Grid.Column>
                                                            )}
                                                        </Grid>
                                                        {not_visible_mailings && (
                                                            <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                                                <Trans>
                                                                    Some mailing lists are present but you do not have permission to view them
                                                                </Trans>
                                                            </div>
                                                        )}
                                                        <Divider horizontal>
                                                            <Trans>analysis</Trans>
                                                        </Divider>
                                                        {!disabled && (
                                                            <Segment basic>
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => push("analysis", { analysis: null })}
                                                                    icon="add"
                                                                />
                                                            </Segment>
                                                        )}
                                                        {_.size(values.analysis) === 0 && (
                                                            <MessageDisplay
                                                                attached={false}
                                                                level="warning"
                                                                message={i18n._(t`no analysis`)}
                                                                isLoading={false}
                                                                iconName="info circle"
                                                            />
                                                        )}
                                                        <FieldArray name="analysis">
                                                            {(analysis_field) => {
                                                                return (
                                                                    <Grid>
                                                                        {analysis_field.fields.map((a_name, a_index) => {
                                                                            return (
                                                                                <Grid.Row key={a_index}>
                                                                                    <Grid.Column width={!disabled ? 14 : 16}>
                                                                                        <Field
                                                                                            key={a_name}
                                                                                            name={`${a_name}.analysis`}
                                                                                            placeholder={i18n._(t`choose analysis`)}
                                                                                            options={advanced_analysis?.data ?? []}
                                                                                            //component={DropDownAdapter}
                                                                                            component={ReportAnalysisSelector}
                                                                                            user_email={auth.user.email}
                                                                                            isRequired
                                                                                            validate={(value) => {
                                                                                                if (!value)
                                                                                                    return <Trans>Analysis is required</Trans>;
                                                                                                return undefined;
                                                                                            }}
                                                                                            disabled={disabled}
                                                                                        />
                                                                                    </Grid.Column>
                                                                                    {!disabled && (
                                                                                        <Grid.Column width={2}>
                                                                                            <Button.Group>
                                                                                                {a_index !== 0 && (
                                                                                                    <Button
                                                                                                        icon="arrow alternate circle up"
                                                                                                        type="button"
                                                                                                        onClick={(e, data) => {
                                                                                                            const swap_list = [...values.analysis];
                                                                                                            const temp = swap_list[a_index - 1];
                                                                                                            swap_list[a_index - 1] =
                                                                                                                swap_list[a_index];
                                                                                                            swap_list[a_index] = temp;
                                                                                                            form.change("analysis", swap_list);
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                                {analysis_count !== a_index + 1 && (
                                                                                                    <Button
                                                                                                        icon="arrow alternate circle down"
                                                                                                        type="button"
                                                                                                        onClick={(e, data) => {
                                                                                                            const swap_list = [...values.analysis];
                                                                                                            const temp = swap_list[a_index + 1];
                                                                                                            swap_list[a_index + 1] =
                                                                                                                swap_list[a_index];
                                                                                                            swap_list[a_index] = temp;
                                                                                                            form.change("analysis", swap_list);
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                                <Button
                                                                                                    icon="trash"
                                                                                                    type="button"
                                                                                                    onClick={() => {
                                                                                                        analysis_field.fields.remove(a_index);
                                                                                                    }}
                                                                                                />
                                                                                            </Button.Group>
                                                                                        </Grid.Column>
                                                                                    )}
                                                                                </Grid.Row>
                                                                            );
                                                                        })}
                                                                    </Grid>
                                                                );
                                                            }}
                                                        </FieldArray>
                                                        {!disabled && (
                                                            <>
                                                                <Divider />
                                                                <Button
                                                                    type="submit"
                                                                    content={i18n._(t`validate`)}
                                                                    disabled={submitting || pristine || invalid || _.size(values.analysis) === 0}
                                                                />
                                                            </>
                                                        )}
                                                    </form>
                                                );
                                            }}
                                        />
                                    </Container>
                                </Grid.Column>
                            );
                        } else {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            );
                        }
                    })()}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(Report);
