import _ from "lodash";
import moment from "moment";
import tinycolor from "tinycolor2";

import Palette from "modules/common/components/graphic/Colors";
import { dynamicValueUnit, reProcessUnit } from "modules/data/utils";
import { display_color } from "modules/globalview/globalviewMiddleware";
import {
    getClusters,
    getConsumption,
    getDetail,
    getElecDistribution,
    getOperatingHours,
    getOperatingPoints,
    getOperatingRaw,
    getSummary
} from "./overviewSlice";
import { remapIntensive } from "modules/data/utils";

const overviewPaletteColor = {
    p_act_import: Palette.named.blue,
    "p_react_import-": Palette.named.orange,
    "p_react_import+": Palette.named.green,
    i_moy_max: Palette.named.brown,
    i_moy_max_ph1: Palette.named.brown,
    i_moy_max_ph2: Palette.named.black,
    i_moy_max_ph3: Palette.named.grey,
    voltage_imbalance: Palette.named.brown,
    i0_ratio: Palette.named.brown,
    power_imbalance: Palette.named.brown,
    thd: Palette.named.brown,
    thd_ph1: Palette.named.red,
    thd_ph2: Palette.named.green,
    thd_ph3: Palette.named.blue
};

export const overviewMiddleware = (store) => (next) => (action) => {
    if (getDetail.fulfilled.match(action)) {
        const tab = _.get(action, "meta.arg.tab");
        const type = _.get(action, "meta.arg.type");

        const max_all_series = _.chain(action.payload)
            .reduce((res, serie) => {
                const max_measure = _.chain(serie).get("data").maxBy("[1]").last().value();
                res.push(max_measure);
                return res;
            }, [])
            .max()
            .value();

        const isEvent = _.includes(["trip", "sag", "swell"], type);

        const data = _.chain(action.payload)
            .map((record, index, measures_detail) => {
                const unit = _.get(record, "measure.display_unit.intensive", null) || _.get(record, "measure.display_unit.symbol", null);
                const mt_type_name = _.get(record, "measure.measurementtype.name", null);
                const auto_unit = _.get(record, "measure.auto_unit", true);
                let { factor, new_unit } = dynamicValueUnit(max_all_series, unit, auto_unit);
                if (_.includes(["p_react_import+", "p_react_import-", "p_react_import"], mt_type_name)) {
                    //retrieve "energy_active_index" for "reactive_energy_index" unit processing
                    const related_active_measure = _.chain(measures_detail)
                        .find((record) => {
                            return record?.measure?.measurementtype?.name === "p_act_import";
                        })
                        .get("measure")
                        .defaultTo(null)
                        .value();
                    const related_auto_unit = related_active_measure?.auto_unit ?? true; //default auto-unit if not exist
                    if (related_auto_unit === false) {
                        //Only reprocess measure for related measure with dynamic false
                        const related_unit = related_active_measure?.display_unit?.intensive || related_active_measure?.display_unit?.symbol || unit;
                        const related_dynamic = dynamicValueUnit(max_all_series, reProcessUnit(unit, related_unit), related_auto_unit);
                        factor = related_dynamic.factor;
                        new_unit = related_dynamic.new_unit;
                    }
                }

                const palette_color = _.get(overviewPaletteColor, mt_type_name, Palette.circles[index % Palette.circles.length]);
                const color = tinycolor(palette_color).setAlpha(0.4).toString();

                let title = !_.includes(["tabenergy", "tabelecdistri", "tabelecstate"], tab)
                    ? `${_.get(record, "measure.name", "-")}`
                    : `${_.get(record, "measure.measurementtype.name", "-")}`;
                title = remapIntensive(title);

                return {
                    name: title,
                    disabled: false,
                    strokeWidth: 20,
                    data:
                        _.chain(record)
                            .get("data")
                            .map((value) => {
                                const y_val = _.isFinite(value[1]) ? value[1] * factor : null;
                                return {
                                    name: title,
                                    x: value[0] * 1000,
                                    t: value[0] * 1000,
                                    y: y_val,
                                    y_real: y_val,
                                    color,
                                    unit: new_unit
                                };
                            })
                            .value() || [],
                    color,
                    unit: new_unit,
                    isEvent
                };
            })
            .value();
        action.payload = data;
    }

    if (getSummary.fulfilled.match(action)) {
        const max_all_series = _.chain(action.payload)
            .reduce((res, serie) => {
                const max_measure = _.chain(serie).get("data").maxBy("[1]").last().value();
                res.push(max_measure);
                return res;
            }, [])
            .max()
            .value();

        const m_unit = _.get(action.payload, "measure.display_unit.intensive", null) || _.get(action.payload, "measure.display_unit.symbol", null);
        const auto_unit = _.get(action.payload, "measure.auto_unit", true);
        const { factor, new_unit } = dynamicValueUnit(max_all_series, m_unit, auto_unit);
        const process_summary = _.chain(action.payload.data)
            .reduce(
                (res, record) => {
                    //minimum part
                    const min_data = _.get(res.min, record[0]);
                    if (min_data === undefined) {
                        res.min[record[0]] = [record[1]];
                    } else {
                        res.min[record[0]].push(record[1]);
                    }
                    //avg part
                    const avg_data = _.get(res.avg, record[0]);
                    if (avg_data === undefined) {
                        res.avg[record[0]] = [record[2]];
                    } else {
                        res.avg[record[0]].push(record[2]);
                    }
                    //max part
                    const max_data = _.get(res.max, record[0]);
                    if (max_data === undefined) {
                        res.max[record[0]] = [record[3]];
                    } else {
                        res.max[record[0]].push(record[3]);
                    }
                    return res;
                },
                { min: {}, avg: {}, max: {} }
            )
            .map((data, key) => {
                let remap_data = [];
                const color = tinycolor(display_color[key]).setAlpha(0.4).toString();

                switch (key) {
                    case "min":
                        remap_data = _.map(data, (item, date) => {
                            const val = _.chain(item)
                                .filter((val) => val !== null)
                                .min()
                                .value();
                            const y_val = _.isFinite(val) ? factor * val : null;

                            return {
                                x: moment(date).unix() * 1000,
                                t: moment(date).unix() * 1000,
                                y: y_val,
                                y_real: y_val,
                                type: key,
                                color,
                                unit: new_unit
                            };
                        });
                        break;
                    case "avg":
                        remap_data = _.map(data, (item, date) => {
                            const val = _.chain(item)
                                .filter((val) => val !== null)
                                .mean()
                                .value();
                            const y_val = _.isFinite(val) ? factor * val : null;
                            return {
                                x: moment(date).unix() * 1000,
                                t: moment(date).unix() * 1000,
                                y: y_val,
                                y_real: y_val,
                                type: key,
                                color,
                                unit: new_unit
                            };
                        });
                        break;
                    case "max":
                        remap_data = _.map(data, (item, date) => {
                            const val = _.chain(item)
                                .filter((val) => val !== null)
                                .max()
                                .value();
                            const y_val = _.isFinite(val) ? factor * val : null;
                            return {
                                x: moment(date).unix() * 1000,
                                t: moment(date).unix() * 1000,
                                y: y_val,
                                y_real: y_val,
                                type: key,
                                color,
                                unit: new_unit
                            };
                        });
                        break;
                    default:
                        remap_data = [];
                        break;
                }
                return {
                    title: key,
                    data: remap_data,
                    disabled: false,
                    strokeWidth: 20,
                    isEmpty: _.size(remap_data) === 0,
                    color,
                    unit: new_unit,
                    max_measure: max_all_series
                };
            })
            .value();
        action.payload = process_summary;
    }

    if (getConsumption.fulfilled.match(action)) {
        const tab = _.get(action, "meta.arg.tab");
        const max_measure = _.chain(action.payload).get("data").maxBy("[1]").last().value();
        const auto_unit = _.get(action.payload, "measure.auto_unit", true);
        const { factor, new_unit } = dynamicValueUnit(max_measure, _.get(action.payload, "measure.display_unit.symbol", null), auto_unit);
        const color = tinycolor(Palette.named.green).setAlpha(0.4).toString();

        const data = {
            name: tab !== "tabenergy" ? _.get(action.payload, "measure.name", "-") : _.get(action.payload, "measure.measurementtype.name", "-"),
            disabled: false,
            strokeWidth: 20,
            data:
                _.chain(action.payload)
                    .get("data")
                    .map((value) => {
                        const y_val = _.isFinite(value[1]) ? value[1] * factor : null;
                        return {
                            x: moment(value[0]).unix() * 1000,
                            t: moment(value[0]).unix() * 1000,
                            y: y_val,
                            y_real: y_val,
                            color,
                            unit: new_unit,
                            factor
                        };
                    })
                    .value() || [],
            color,
            unit: new_unit
        };

        action.payload = [data];
    }

    if (getOperatingHours.fulfilled.match(action)) {
        const color = tinycolor(Palette.named.green).setAlpha(0.4).toString();
        const unit = "%";
        const title = `${_.get(action.payload, "measure.measurementtype.name", "-")}`;

        const data = {
            name: title,
            disabled: false,
            strokeWidth: 20,
            data:
                _.chain(action.payload)
                    .get("data")
                    .map((value) => {
                        const y_val = _.isFinite(value[1]) ? (Math.min(value[1], 24) / 24) * 100 : null;

                        return {
                            x: moment(value[0]).unix() * 1000,
                            t: moment(value[0]).unix() * 1000,
                            y: y_val,
                            y_real: y_val,
                            color,
                            unit,
                            name: title
                        };
                    })
                    .value() || [],
            color,
            unit
        };

        action.payload = [data];
    }

    if (getElecDistribution.fulfilled.match(action)) {
        const { edges, weights } = _.chain(action.payload).get("data").value();

        const edges_len = _.size(edges);
        const weights_len = _.size(weights);
        if (weights_len > 0 && edges_len === weights_len + 1) {
            const color = tinycolor(Palette.circles[0]).setAlpha(0.4).toString();
            const unit = _.get(action.payload, "measure.display_unit.intensive");
            const auto_unit = _.get(action.payload, "measure.auto_unit", true);
            const maximum = _.chain(action.payload).get("data.edges").last().value();
            const { factor, new_unit } = dynamicValueUnit(maximum, unit, auto_unit);

            const title = `${_.get(action.payload, "measure.name", "-")} (${new_unit})`;
            const data = {
                name: _.get(action.payload, "measure.name", "-"),
                title,
                disabled: false,
                strokeWidth: 20,
                data: _.chain(action.payload)
                    .get("data.edges")
                    .reduce((res, value, idx) => {
                        if (weights[idx] !== undefined) {
                            res.push({
                                color,
                                unit: new_unit,
                                b0: _.round(value * factor, 3),
                                b1: _.round(edges[idx + 1] * factor, 3),
                                x: _.round(_.mean([value, edges[idx + 1]]) * factor, 3),
                                y: _.round(weights[idx] * 100, 3)
                            });
                        }

                        return res;
                    }, [])
                    .value(),
                color,
                unit: new_unit
            };
            action.payload = { edges: _.map(edges, (item) => (_.isFinite(item) ? item * factor : item)), weights, data: [data] };
        } else {
            action.payload = { edges: [], weights: [], data: [] };
        }
    }

    if (getOperatingRaw.fulfilled.match(action)) {
        const type = _.get(action, "meta.arg.type");

        const data = _.chain(action.payload)
            .get("data")
            .map((serie, key) => {
                const indexFromKey = key === "datapoints" ? 0 : 1;
                const color = tinycolor(Palette.circles[indexFromKey]).setAlpha(1).toString();
                const unit = type === "frequency" ? "Hz" : "A";
                const factor = 1;
                return {
                    indexFromKey,
                    name: key,
                    title: key,
                    color,
                    strokeStyle: "solid",
                    strokeWidth: 20,
                    disabled: false,
                    unit,
                    data: _.reduce(
                        serie,
                        (res, value, idx) => {
                            res.push({
                                title: key,
                                color,
                                x: value[0] * 1000,
                                t: value[0] * 1000,
                                y: value[1] === null ? null : value[1] * factor,
                                unit
                            });
                            return res;
                        },
                        []
                    )
                };
            })
            .orderBy("indexFromKey", "asc")
            .value();

        action.payload = data;
    }

    if (getOperatingPoints.fulfilled.match(action)) {
        const clusters = _.get(store.getState(), "overview.tabpredicthealthscore.clusters.data", []);
        const type = _.get(action, "meta.arg.type");
        const data_size = _.size(action.payload.data) || 0;
        const data = _.chain(action.payload)
            .get("data")
            .reduce((res, cluster, index) => {
                const color_from_cluster = _.chain(clusters).find({ name: cluster?.description }).get("color", null).value();
                const title = _.get(cluster, "description");
                // No cluster "-" use last Palette.circles color (cyan)
                const color = title === "-" ? tinycolor(Palette.circles[_.size(Palette.circles) - 1]).toString() : color_from_cluster;

                if (color === null) {
                    return res;
                }
                res.push({
                    index,
                    title,
                    disabled: (data_size === 1 || _.size(clusters) === 0) && title === "-" ? false : title === "-",
                    strokeWidth: 20,
                    color,
                    data: _.chain(cluster)
                        .get("points")
                        .map((record) => {
                            return {
                                title,
                                x: type === "freq_rms" ? record[1] : record[0],
                                t: type === "freq_rms" ? record[1] : record[0],
                                y: type === "freq_rms" ? record[0] : record[1],
                                y_real: type === "freq_rms" ? record[0] : record[1],
                                color
                            };
                        })
                        .value()
                });
                return res;
            }, [])
            .value();

        action.payload = data;
    }

    if (getClusters.fulfilled.match(action)) {
        const { start, end } = _.get(action, "meta.arg.rangeTime");
        action.payload = _.chain(action.payload.data)
            .orderBy(["id"], ["asc"]) //always ordering by id
            .map((cluster, index) => {
                return {
                    ...cluster,
                    key: cluster.id,
                    value: cluster.id,
                    text: cluster.name,
                    color: tinycolor(Palette.circles[index % Palette.circles.length]).toString()
                };
            })
            .reduce((res, cluster) => {
                const date_from = cluster.date_from ? moment(cluster.date_from) : start;
                const date_end = cluster.date_end ? moment(cluster.date_end) : end;
                if (date_end < start || date_from > end) {
                    return res;
                }
                res.push(cluster);

                return res;
            }, [])
            .value();
    }

    return next(action);
};
