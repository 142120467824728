import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

export const default_export = {
    is_active: true,
    name: "",
    description: "",
    frequency: "0",
    export_type: 1,
    format: 1,
    instant_values: false,
    historic: 1,
    hours: "00:00",
    send_protocol: "email",
    mailinglist: [],
    measurements: [],
    ftp_server: "",
    ftp_port: "",
    ftp_user: "",
    ftp_password: ""
};

const initialState = {
    filter: {
        searchName: "",
        frequencyFilter: [],
        protocolFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10
    }
};

const exportSlice = createSlice({
    name: "expt",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setFrequencyFilter: (state, action) => {
            state.filter.frequencyFilter = action.payload;
        },
        setProtocolFilter: (state, action) => {
            state.filter.protocolFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const { setSearchNameFilter, setFrequencyFilter, setProtocolFilter, setPage, setItemsPerPage, resetFilterWithPage } = exportSlice.actions;

export default exportSlice.reducer;
