import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';

export const categoryApi = defaultApi.injectEndpoints({
    reducerPath: 'apiCategory',
    endpoints: (build) => ({
        getCategories: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `categories?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                //Map data for semantic usage
                return _.reduce(response, (result, category) => {
                    //Dataflowspec filter
                    //Remove light/motion/battery/elec_expert/battery_status
                    //TODO CHANGE BY NAME instead of ID
                    //if (!_.includes([5, 9, 11, 12, 27], category.id)) {
                    result.push({
                        ...category,
                        key: category.id,
                        text: category.name,
                        value: category.id,
                        type: "category"
                    });
                    //}
                    return result;
                }, []);
            }
        }),
    }),
    overrideExisting: false,
});

export const { useGetCategoriesQuery } = categoryApi;