import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    filter: {
        searchName: ""
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
    productionData: {
        pagination: {
            page: 1,
            itemsPerPage: 10,
            // Used to get lastPage read
            stickPage: false
        }
    }
};

const importedDataSetsSlice = createSlice({
    name: "importedDataSets",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        // Production data
        setProductionDataPage: (state, action) => {
            state.productionData.pagination.page = action.payload;
        },
        setProductionDataItemsPerPage: (state, action) => {
            state.productionData.pagination.page = 1;
            state.productionData.pagination.itemsPerPage = action.payload;
        },
        setProductionDataStickPage: (state, action) => {
            state.productionData.pagination.stickPage = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const {
    setSearchNameFilter,
    setPage,
    setItemsPerPage,
    setStickPage,
    setProductionDataPage,
    setProductionDataItemsPerPage,
    setProductionDataStickPage
} = importedDataSetsSlice.actions;

export default importedDataSetsSlice.reducer;
