import React from "react";
import { Grid, Divider, Icon } from "semantic-ui-react";
import { Field } from "react-final-form";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

import { InputAdapter } from "modules/common/components/form";

const AlertPredict = () => {
    return (
        <>
            <Divider horizontal>
                <Trans>Machine health score</Trans>
            </Divider>
            <Grid centered stackable columns={3}>
                <Grid.Row>
                    <Grid.Column>
                        <Field
                            name="configuration.level_warning"
                            label={i18n._(t`First threshold`)}
                            component={InputAdapter}
                            parse={identityNull}
                            inputMode="decimal"
                            defaultValue={7}
                            placeholder={i18n._(t`Set value between 1 to 10`)}
                            unitposition="left"
                            unitcolor="yellow"
                            unit={<Icon name="warning sign" />}
                            disabled={true}
                            helperText={
                                <Trans>
                                    <p>A first alert will be sent if the health score value exceeds this threshold</p>
                                </Trans>
                            }
                            validate={(value, allValues) => {
                                const warningValidate = validateNumber(value, i18n, false, false);
                                if (warningValidate !== undefined) {
                                    return warningValidate;
                                }
                                const num = reparseNumber(value);
                                if (num < 1 || num > 10) {
                                    return <Trans>First threshold must be between 1 and 10 maximum</Trans>;
                                }
                                if (reparseNumber(value) > reparseNumber(allValues.configuration?.level_critical)) {
                                    return <Trans>First threshold can't be greater than second threshold</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Field
                            name="configuration.level_critical"
                            label={i18n._(t`Second threshold`)}
                            component={InputAdapter}
                            parse={identityNull}
                            inputMode="decimal"
                            defaultValue={9}
                            placeholder={i18n._(t`Set value between 1 to 10`)}
                            unitposition="left"
                            unitcolor="red"
                            unit={<Icon name="fire" />}
                            disabled={true}
                            helperText={
                                <Trans>
                                    <p>A second alert will be sent if the health score value exceeds this threshold</p>
                                </Trans>
                            }
                            validate={(value, allValues) => {
                                const criticalValidate = validateNumber(value, i18n, false, false);
                                if (criticalValidate !== undefined) {
                                    return criticalValidate;
                                }
                                const num = reparseNumber(value);
                                if (num < 1 || num > 10) {
                                    return <Trans>Second threshold must be between 1 and 10 maximum</Trans>;
                                }
                                if (reparseNumber(value) < reparseNumber(allValues.configuration?.level_warning)) {
                                    return <Trans>Second threshold can't be lower than first threshold</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Field
                            name="configuration.duration"
                            label={i18n._(t`Duration`)}
                            component={InputAdapter}
                            parse={identityNull}
                            inputMode="decimal"
                            defaultValue={1}
                            placeholder={i18n._(t`Set value between 1 to 48`)}
                            unitposition="right"
                            unit={"h"}
                            disabled={true}
                            helperText={
                                <Trans>
                                    <p>The alert will be sent only if the health score value remains beyond the threshold for this duration</p>
                                </Trans>
                            }
                            validate={(value) => {
                                const durationValidate = validateNumber(value, i18n, false, false);
                                if (durationValidate !== undefined) {
                                    return durationValidate;
                                }
                                const num = reparseNumber(value);
                                if (num < 1 || num > 48) {
                                    return <Trans>Duration must be between 1 hours and 48 hours maximum</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default AlertPredict;
