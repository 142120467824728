import React, { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Modal, Icon, Dropdown, Button } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { WidgetType, WidgetTypeOptions } from "../utils";

import StackBarConsumption from "./widgetsForms/StackBarConsumption";
import StackBarRatio from "./widgetsForms/StackBarRatio";
import PieChartConsumption from "./widgetsForms/PieChartConsumption";
import SparklineConsumption from "./widgetsForms/SparklineConsumption";
import GaugeRatio from "./widgetsForms/GaugeRatio";
import SingleMeasurementGauge from "./widgetsForms/SingleMeasurementGauge";
import GaugeConsumptionBreakdown from "./widgetsForms/GaugeConsumptionBreakdown";

const WidgetsCreateUpdatePanel = (props) => {
    const { current_dashboard, disabled, widget, setOpenModalWidgetForm } = props;
    const [widgetType, setWidgetType] = useState(null);

    useEffect(() => {
        if (widget) {
            setWidgetType(widget.widget_type);
        }
    }, [widget]);

    const onClose = () => {
        setOpenModalWidgetForm(false);
    };

    const renderWidgetsForms = (widget_type) => {
        switch (widget_type) {
            case WidgetType.STACK_BAR_CONSUMPTION_BREAKDOWN[0]:
                return (
                    <StackBarConsumption
                        widgetChoice={widget_type}
                        onClose={onClose}
                        current_dashboard={current_dashboard}
                        widget={widget}
                        disabled={disabled}
                    />
                );
            case WidgetType.STACK_BAR_RATIO[0]:
                return (
                    <StackBarRatio
                        widgetChoice={widget_type}
                        onClose={onClose}
                        current_dashboard={current_dashboard}
                        widget={widget}
                        disabled={disabled}
                    />
                );
            case WidgetType.PIE_CHART_CONSUMPTION_BREAKDOWN[0]:
                return (
                    <PieChartConsumption
                        widgetChoice={widget_type}
                        onClose={onClose}
                        current_dashboard={current_dashboard}
                        widget={widget}
                        disabled={disabled}
                    />
                );
            case WidgetType.SPARKLINE_CONSUMPTION[0]:
            case WidgetType.SPARKLINE_CONSUMPTIONS[0]:
                return (
                    <SparklineConsumption
                        widgetChoice={widget_type}
                        onClose={onClose}
                        current_dashboard={current_dashboard}
                        widget={widget}
                        disabled={disabled}
                    />
                );
            case WidgetType.GAUGE_RATIO[0]:
                return (
                    <GaugeRatio
                        widgetChoice={widget_type}
                        onClose={onClose}
                        current_dashboard={current_dashboard}
                        widget={widget}
                        disabled={disabled}
                    />
                );
            case WidgetType.GAUGE_CONSUMPTION[0]:
                return (
                    <SingleMeasurementGauge
                        widgetChoice={widget_type}
                        onClose={onClose}
                        current_dashboard={current_dashboard}
                        widget={widget}
                        disabled={disabled}
                    />
                );
            case WidgetType.GAUGE_CONSUMPTION_BREAKDOWN[0]:
                return (
                    <GaugeConsumptionBreakdown
                        widgetChoice={widget_type}
                        onClose={onClose}
                        current_dashboard={current_dashboard}
                        widget={widget}
                        disabled={disabled}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            open={true}
            centered={false}
            closeOnDimmerClick={false}
            closeOnEscape
            onClose={(e) => {
                setOpenModalWidgetForm(false);
            }}
            onOpen={() => {
                //prevent 'view' mode when press 'v' in form
                props.setIsHovered && props.setIsHovered(false);
            }}
        >
            <Modal.Header>
                {!disabled && widget === undefined && <Trans>Widget creation</Trans>}
                {!disabled && widget !== undefined && <Trans>Widget edition</Trans>}
                {disabled && <Trans>Widget visualization</Trans>}
            </Modal.Header>
            <Modal.Content>
                {!widget && (
                    <Dropdown
                        fluid
                        options={_.map(WidgetTypeOptions, ({ key, text, value }) => ({
                            key,
                            value,
                            text: i18n._(text)
                        }))}
                        placeholder={i18n._(t`Select widget`)}
                        selection
                        onChange={(e, { value }) => {
                            setWidgetType(value);
                        }}
                    />
                )}
                {widgetType && renderWidgetsForms(widgetType)}
            </Modal.Content>
            {!widget && !widgetType && (
                <Modal.Actions>
                    <Button
                        type="button"
                        negative
                        icon
                        labelPosition="left"
                        style={{ margin: "0px" }}
                        onClick={(e) => {
                            onClose();
                        }}
                    >
                        <Icon name="arrow left" />
                        <Trans>cancel</Trans>
                    </Button>
                </Modal.Actions>
            )}
        </Modal>
    );
};

export default WidgetsCreateUpdatePanel;
