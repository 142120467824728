import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import cookie from "react-cookies";
import _ from 'lodash';

export const tagApi = defaultApi.injectEndpoints({
    reducerPath: 'apiTag',
    endpoints: (build) => ({
        getTags: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `tags?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                //Map data for semantic usage
                return _.map(response, (tag) => {
                    return { ...tag, key: tag.id, text: tag.word, value: tag.id, name: tag.word, type: "tag" };
                });
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Tags", id: arg.org.id }];
                return [];
            },
        }),
        addTag: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `tags?org=${current_org}`,
                    method: 'POST',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    if (arg.local === undefined) {
                        // Use local: true for preventing invalidateTags query
                        return [{ type: "Tags", id: arg.org.id }];
                    }
                }
                return [];
            }
        }),
        updateTag: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `tags/${data.id}?org=${current_org}`,
                    method: 'PUT',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Tags", id: arg.org.id }];
                }
                return [];
            }
        }),
        deleteTag: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, "name", null);
                const url = `tags/${data}?org=${current_org}${confirm ? '&confirm=yes' : ''}&${refreshTimestampUrlParam()}`;
                return {
                    url,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    const { confirm } = arg;
                    if (confirm) {
                        return [
                            { type: "Tags", id: arg.org.id },
                            { type: "Tag", id: arg.data.id }
                        ];
                    }

                }
                return [];
            }
        })
    }),
    overrideExisting: false,
});

export const { useGetTagsQuery, useAddTagMutation, useUpdateTagMutation, useDeleteTagMutation } = tagApi;