import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';
import cookie from "react-cookies";

export const sensorApi = defaultApi.injectEndpoints({
    reducerPath: 'apiSensor',
    endpoints: (build) => ({
        getSensors: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `sensors?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 60,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Sensors", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                return _.map(response, (sensor) => {
                    return {
                        ...sensor,
                        key: sensor.id,
                        value: sensor.id,
                        text: sensor.identifier
                    };
                });
            }
        }),
        updateSensor: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `sensors/${data.id}?org=${current_org}`,
                    method: 'PUT',
                    credentials: "include",
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Sensors", id: arg.org.id },
                        { type: "Sensor", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteSensor: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `sensors/${data}?org=${current_org}${confirm ? '&confirm=yes' : ''}`,
                    method: 'DELETE',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    return [
                        { type: "Sensors", id: arg.org.id },
                        { type: "Sensor", id: arg.data }];
                }
                return [];
            }
        })
    }),
    overrideExisting: false,
});


export const { useGetSensorsQuery, useUpdateSensorMutation, useDeleteSensorMutation } = sensorApi;