import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Field, Form } from "react-final-form";
import { Button, Divider, Grid, Icon, Modal, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { mt_type_blacklist as default_mt_type_blacklist } from "modules/dashboard/utils";
import { removeAccents, reparseNumber, validateNumber } from "modules/common/utils";
import { identityNull } from "modules/common/utils/form";
import { roundedDate } from "modules/time/utils";

import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetUnitsQuery } from "modules/unit/unitService";

import { DateTimeAdapter, DropDownAdapter, InputAdapter } from "modules/common/components/form";
import { setComparisonParams } from "modules/dashboard/dashboardSlice";

//import MessageDisplay from "modules/common/components/MessageDisplay";

const mt_type_blacklist = _.reduce(
    default_mt_type_blacklist,
    (res, item) => {
        if (_.includes(["p_react_import", "p_react_import+", "p_react_import-"], item)) return res;
        res.push(item);
        return res;
    },
    []
);

const InstantComparison = (props) => {
    const dispatch = useDispatch();
    const now = moment();
    const { fromDashboard, setOpen } = props;
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);
    const selected_equipments_ids = useSelector((state) => state.dash.selected_equipments);

    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });

    const success_list = [measurements.isSuccess, measurementtypes.isSuccess, dataflows.isSuccess, equipments.isSuccess, units.isSuccess];

    const { mtTypesOptions, selected_measurements } = useMemo(() => {
        const process = { mtTypesOptions: [], selected_measurements: [] };
        if (_.size(selected_equipments_ids) > 0 && _.every(success_list)) {
            _.each(measurements.data, (measure) => {
                //Need dataflow to check if measure in selected equipments
                const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
                if (_.includes(selected_equipments_ids, dataflow?.equipment)) {
                    const mt_type = _.find(measurementtypes.data, { id: measure?.measurementtype });
                    //Limit measurements here with blacklist
                    if (mt_type && !_.includes(mt_type_blacklist, mt_type?.name)) {
                        process.selected_measurements.push({ ...measure, measurementtype: mt_type });
                        if (_.find(process.mtTypesOptions, { id: measure?.measurementtype })) return; //already exists mt_type in options
                        process.mtTypesOptions.push(mt_type);
                    }
                }
            });
            return process;
        }
        return process;
    }, [selected_equipments_ids, success_list, measurementtypes, measurements, dataflows]);

    const initialValues = useMemo(() => {
        return {
            days: 7,
            ref_start: roundedDate(now.clone().subtract(7, "days"), 10),
            ref_end: roundedDate(now.clone().startOf("minute"), 10),
            comp_start: roundedDate(now.clone().subtract(7, "days"), 10),
            comp_end: roundedDate(now.clone().startOf("minute"), 10),
            // Auto select measurementtype if only one
            mt_type_selected: _.size(mtTypesOptions) === 1 ? _.head(mtTypesOptions)?.id : null
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mtTypesOptions]);

    const submitForm = async (formData) => {
        let hasTimeIntegral = false;
        const m_list = _.reduce(
            selected_measurements,
            (res, measure) => {
                if (formData.mt_type_selected === measure?.measurementtype?.id) {
                    //Add extra info into measurement to do process in dashboard middleware
                    const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
                    const equipment = _.find(equipments.data, { id: dataflow?.equipment });
                    // Uniform usage of display_unit for all measurements (take base_unit from measurement type)
                    const display_unit = _.find(units.data, { id: measure?.measurementtype?.unit });
                    res.push({ ...measure, dataflow, equipment, display_unit, name: equipment?.name });
                    if (measure?.measurementtype?.datapoint_type === 3 && hasTimeIntegral === false) hasTimeIntegral = true;
                }
                return res;
            },
            []
        );

        // Put formData into redux store and use it in Comparison page
        await dispatch(
            setComparisonParams({
                ...formData,
                ref_start: formData.ref_start.toISOString(),
                ref_end: formData.ref_end.toISOString(),
                comp_start: formData.comp_start.toISOString(),
                comp_end: formData.comp_end.toISOString(),
                m_list,
                hasTimeIntegral
            })
        );

        // Go to comparison page if you are in dashboard
        if (fromDashboard) {
            history_app.push("/comparison");
        } else {
            setOpen(false);
        }
    };

    const validate = (values) => {
        const errors = {};
        const daysValidate = validateNumber(values?.days, i18n, false, false, true);
        if (daysValidate !== undefined) {
            errors.days = daysValidate;
            return errors;
        }
        const days = reparseNumber(values?.days);
        if (days < 1) {
            errors.days = <Trans>Minimum of day is 1</Trans>;
            return errors;
        }

        // Reference validator
        if (_.size(selected_equipments_ids) === 1) {
            if (values?.ref_start === undefined) {
                errors.ref_start = <Trans>Required field</Trans>;
                return errors;
            }
            if (typeof values?.ref_start === "string") {
                errors.ref_start = <Trans>Invalid format</Trans>;
                return errors;
            }
            if (values?.ref_end === undefined) {
                errors.ref_end = <Trans>Required field</Trans>;
                return errors;
            }
            if (typeof values?.ref_end === "string") {
                errors.ref_end = <Trans>Invalid format</Trans>;
                return errors;
            }
        }
        if (values?.comp_start === undefined) {
            errors.comp_start = <Trans>Required field</Trans>;
            return errors;
        }
        if (typeof values?.comp_start === "string") {
            errors.comp_start = <Trans>Invalid format</Trans>;
            return errors;
        }
        if (values?.comp_end === undefined) {
            errors.comp_end = <Trans>Required field</Trans>;
            return errors;
        }
        if (typeof values?.comp_end === "string") {
            errors.comp_end = <Trans>Invalid format</Trans>;
            return errors;
        }

        if (!_.isFinite(values?.mt_type_selected)) {
            errors.mt_type_selected = <Trans>Required field</Trans>;
            return errors;
        }
        return errors;
    };

    return (
        <Modal open centered={false} onClose={(e) => setOpen(false)}>
            <Modal.Header>
                {_.size(selected_equipments_ids) === 1 && <Trans>comparison one feed</Trans>}
                {_.size(selected_equipments_ids) > 1 && <Trans>comparison multi feed</Trans>}
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    keepDirtyOnReinitialize
                    initialValues={initialValues}
                    validate={validate}
                    render={({ form, handleSubmit, submitting, pristine, invalid, values }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid stackable centered>
                                    {_.size(selected_equipments_ids) === 1 && (
                                        <>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Field
                                                        name="days"
                                                        label={i18n._(t`number of days`)}
                                                        placeholder={i18n._(t`set number of days`)}
                                                        component={InputAdapter}
                                                        parse={identityNull}
                                                        inputMode="numeric"
                                                        customAction={(data) => {
                                                            const daysValidate = validateNumber(data, i18n, false, false, true);
                                                            const days = reparseNumber(data);
                                                            if (daysValidate === undefined && days >= 1) {
                                                                form.change("ref_start", values?.ref_end.clone().subtract(data, "d"));
                                                                form.change("comp_start", values?.comp_end.clone().subtract(data, "d"));
                                                            }
                                                        }}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <div className="field required">
                                                        <label>
                                                            <Trans>reference period</Trans>
                                                        </label>
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    {/* DATEPICKER ref start */}
                                                    <Field
                                                        name="ref_start"
                                                        component={DateTimeAdapter}
                                                        locale={current_lng}
                                                        date_limit={now}
                                                        labeled={true}
                                                        label={i18n._(t`from`)}
                                                        labelPosition={"left"}
                                                        isRequired={true}
                                                        dateFormat={true}
                                                        timeFormat={true}
                                                        customAction={(data) => {
                                                            if (moment.isMoment(data)) {
                                                                form.change("ref_end", data.clone().add(values?.days, "d"));
                                                            }
                                                        }}
                                                    />
                                                    {/* END DATEPICKER ref start */}
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    {/* DATEPICKER ref end */}
                                                    <Field
                                                        name="ref_end"
                                                        component={DateTimeAdapter}
                                                        locale={current_lng}
                                                        date_limit={now}
                                                        labeled={true}
                                                        label={i18n._(t`to`)}
                                                        labelPosition={"left"}
                                                        isRequired={true}
                                                        dateFormat={true}
                                                        timeFormat={true}
                                                        disabled={true}
                                                    />
                                                    {/* END DATEPICKER ref end */}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </>
                                    )}
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <div className="field required">
                                                <label>
                                                    <Trans>compare period</Trans>
                                                </label>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            {/* DATEPICKER comp start */}
                                            <Field
                                                name="comp_start"
                                                component={DateTimeAdapter}
                                                locale={current_lng}
                                                date_limit={now}
                                                labeled={true}
                                                label={i18n._(t`from`)}
                                                labelPosition={"left"}
                                                isRequired={true}
                                                dateFormat={true}
                                                timeFormat={true}
                                                customAction={
                                                    // Only change 'comp_end' when only 1 equipment
                                                    _.size(selected_equipments_ids) === 1
                                                        ? (data) => {
                                                              if (moment.isMoment(data)) {
                                                                  form.change("comp_end", data.clone().add(values?.days, "d"));
                                                              }
                                                          }
                                                        : null
                                                }
                                            />
                                            {/* END DATEPICKER comp start */}
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            {/* DATEPICKER comp end */}
                                            <Field
                                                name="comp_end"
                                                component={DateTimeAdapter}
                                                locale={current_lng}
                                                date_limit={now}
                                                labeled={true}
                                                label={i18n._(t`to`)}
                                                labelPosition={"left"}
                                                isRequired={true}
                                                dateFormat={true}
                                                timeFormat={true}
                                                disabled={_.size(selected_equipments_ids) === 1}
                                            />
                                            {/* END DATEPICKER comp end */}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="mt_type_selected"
                                                label={i18n._(t`measurementtype`)}
                                                placeholder={i18n._(t`Select measurementtype`)}
                                                options={_.chain(mtTypesOptions)
                                                    .orderBy((item) => {
                                                        return removeAccents(item.text).toLowerCase();
                                                    }, "asc")
                                                    .value()}
                                                isRequired={true}
                                                component={DropDownAdapter}
                                                noResultsMessage={i18n._(t`Measurement type not found`)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider />
                                <Segment basic textAlign="right">
                                    <Button type="button" negative onClick={() => setOpen(false)}>
                                        <Trans>cancel</Trans>
                                    </Button>
                                    <Button
                                        type="button"
                                        labelPosition="right"
                                        icon
                                        positive
                                        onClick={async (e) => {
                                            form.submit();
                                        }}
                                        disabled={submitting || invalid}
                                    >
                                        <Trans>tocompare</Trans>
                                        <Icon name="trash" inverted />
                                    </Button>
                                </Segment>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

InstantComparison.defaultProps = {
    fromDashboard: false
};

export default InstantComparison;
