import React, { useCallback, useState, useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Dropdown, Icon, Menu, Modal } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { setCurrentOrg } from "../orgSlice";
import { useGetOrganizationsQuery } from "../organizationService";

export const DropdownOrg = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const {
        org: store_org,
        auth: { user }
    } = useSelector((state) => state);

    const organizations = useGetOrganizationsQuery(undefined, { skip: !user });

    const orgOptions = useMemo(() => {
        if (open && organizations.isSuccess) {
            return _.map(organizations.data.organizations, ({ key, text, value }) => {
                return { key, text, value };
            });
        }
        return [];
    }, [open, organizations]);

    const onChangeOrganisation = useCallback(
        (event, data) => {
            const selected = _.find(organizations.data?.organizations, { value: data.value });
            if (selected) {
                setOpen(false);
                localStorage.setItem("currentOrg", JSON.stringify(selected));
                history_app.push("/");
                dispatch(setCurrentOrg(selected));
            }
        },
        [dispatch, organizations]
    );

    return (
        <>
            {organizations.isError && (
                <Menu.Item>
                    <Icon name="warning" color="red" />
                </Menu.Item>
            )}
            {organizations.isFetching && (
                <Menu.Item>
                    <Icon loading name="circle notch" />
                </Menu.Item>
            )}
            {organizations.isSuccess && (
                <>
                    <Menu.Item
                        onClick={_.size(organizations.data.organizations) > 1 ? () => setOpen(true) : null}
                        style={
                            _.get(user, "is_superuser", false) === true
                                ? {
                                      background: `repeating-linear-gradient(
        -55deg,
        #f6ba52,
        #f6ba52 10px,
        #ffd180 10px,
        #ffd180 20px
      )`
                                  }
                                : {}
                        }
                    >
                        <Icon name="industry" />
                        {store_org?.current?.text ?? ""}
                        {_.size(organizations.data.organizations) > 1 && <Icon name="sort" />}
                    </Menu.Item>
                    {open && (
                        <Modal centered={false} onClose={() => setOpen(false)} open>
                            <Modal.Header>
                                <Trans>Select an organization</Trans>
                            </Modal.Header>
                            <Modal.Content>
                                <Dropdown
                                    searchInput={{ autoFocus: true }}
                                    selection
                                    search
                                    fluid
                                    selectOnNavigation={false}
                                    options={orgOptions}
                                    onChange={onChangeOrganisation}
                                    value={store_org?.current?.value ?? null}
                                    noResultsMessage={i18n._(t`no result found`)}
                                />
                            </Modal.Content>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default DropdownOrg;
