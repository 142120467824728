import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { defaultLng } from "modules/i18n/i18nConfig";

export const notificationApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_notification",
    keepUnusedDataFor: 600,
    tagTypes: [],
    endpoints: (build) => ({
        getSrvStatus: build.query({
            query: () => {
                return `srv_status`;
            }
            // Uncomment for testing
            /*transformResponse: (response) => {
                return { ...response, db_status: "r" };
            }*/
        })
    })
});

export const { useGetSrvStatusQuery } = notificationApi;
