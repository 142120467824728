export const defaultBearing = {
    maker: "",
    model: "",
    bearing_type: "",
    description: "",
    by_order: false,
    location: 1,
    dimension: {
        rolElements: null,
        rolDiameter: null,
        pitchDiameter: null,
        contactAngle: null
    },
    order: {
        BPFI: null,
        BPFO: null,
        FTF: null,
        BSF: null
    }
};

export const order_list = ["BPFI", "BPFO", "FTF", "BSF"];
export const dimension_list = ["rolElements", "rolDiameter", "pitchDiameter", "contactAngle"];

export const machineSupplyType = [
    { key: 1, value: "Direct", text: "Direct" },
    { key: 2, value: "Drive", text: "Drive" }
];

export const machineSupplyWiring = [
    { key: 1, value: "Y", text: "Y" },
    { key: 2, value: "delta", text: "Delta" }
];

export const bearingType = [
    { key: 1, value: "DGBB", text: "DGBB" },
    { key: 2, value: "ACBB", text: "ACBB" },
    { key: 3, value: "CRB", text: "CRB" },
    { key: 4, value: "SRB", text: "SRB" },
    { key: 5, value: "SAB", text: "SAB" },
    { key: 6, value: "TRB", text: "TRB" }
];

export const efficiency_class = [
    { key: 1, value: "IE1", text: "IE1" },
    { key: 2, value: "IE2", text: "IE2" },
    { key: 3, value: "IE3", text: "IE3" },
    { key: 4, value: "IE4", text: "IE4" }
];

export const duty_types = [
    { key: 1, value: "S1", text: "S1" },
    { key: 2, value: "S2", text: "S2" },
    { key: 3, value: "S3", text: "S3" },
    { key: 4, value: "S4", text: "S4" },
    { key: 5, value: "S5", text: "S5" },
    { key: 6, value: "S6", text: "S6" },
    { key: 7, value: "S7", text: "S7" },
    { key: 8, value: "S8", text: "S8" },
    { key: 9, value: "S9", text: "S9" },
    { key: 10, value: "S10", text: "S10" }
];

export const frequencyOptions = [
    { key: 1, value: 50, text: 50 },
    { key: 2, value: 60, text: 60 }
];

export const motorTypeOptions = [
    { key: 1, value: "Type 1", text: "Type 1" },
    { key: 2, value: "Type 2", text: "Type 2" },
    { key: 3, value: "Type 3", text: "Type 3" },
    { key: 4, value: "Type 4", text: "Type 4" },
    { key: 5, value: "Type 5", text: "Type 5" },
    { key: 6, value: "Type 6", text: "Type 6" },
    { key: 7, value: "Type 7", text: "Type 7" }
];

export const keyToReparseInMotor = [
    "polePairs",
    "ratedCosphi",
    "ratedCurrent",
    "ratedEfficiency",
    "ratedElec_power",
    "ratedFrequency",
    "ratedMeca_power",
    "ratedSpeed",
    "ratedVoltage",
    "rotorBars",
    "statorSlots"
];
