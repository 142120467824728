import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Form, Field } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import { Button, Modal, Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { identityNull } from "modules/common/utils/form";

import { InputAdapter, ToggleAdapter } from "modules/common/components/form";

export const EditException = (props) => {
    const { idx, item, update } = props;
    const [open, setOpen] = useState(false);

    const onSubmitForm = async (data) => {
        await update("dataflows_exception", idx, {
            ...item,
            override: _.mapValues(data, (item, key) => {
                if (_.includes(keyToReparseInPack, key)) {
                    return reparseNumber(item);
                }
                return item;
            })
        });
        await setOpen(false);
    };

    const initialValues = useMemo(() => {
        return item?.override ?? {};
    }, [item]);

    return (
        <Modal
            open={open}
            trigger={
                <Button
                    type="button"
                    icon="edit"
                    onClick={(event) => {
                        setOpen(true);
                    }}
                />
            }
        >
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>edit exception</Trans>
                                {_.get(item, "name")}
                            </Modal.Header>
                            <Modal.Content scrolling={false}>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid verticalAlign="middle">
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="thd_threshold"
                                                placeholder={i18n._(t`thd_threshold placeholder`)}
                                                label={i18n._(t`thd_threshold`)}
                                                unit={"%"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                isRequired={true}
                                                helperText={i18n._(t`thd_threshold_helper`)}
                                                defaultValue={1}
                                                validate={(value) => {
                                                    return validateNumber(value, i18n, false, false);
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="thd_duration"
                                                placeholder={i18n._(t`thd_duration placeholder`)}
                                                label={i18n._(t`thd_duration`)}
                                                unit={"h"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                isRequired={true}
                                                defaultValue={1}
                                                validate={(value) => {
                                                    const durationValidate = validateNumber(value, i18n, false, false);
                                                    if (durationValidate !== undefined) {
                                                        return durationValidate;
                                                    }
                                                    if (reparseNumber(value) < 1) {
                                                        return <Trans>thd_duration minimum 1h</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="power_imbalance_threshold"
                                                placeholder={i18n._(t`power_imbalance_threshold placeholder`)}
                                                label={i18n._(t`power_imbalance_threshold`)}
                                                unit={"%"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                isRequired={true}
                                                helperText={i18n._(t`power_imbalance_threshold_helper`)}
                                                defaultValue={1}
                                                validate={(value) => {
                                                    return validateNumber(value, i18n, false, false);
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="power_imbalance_duration"
                                                placeholder={i18n._(t`power_imbalance_duration placeholder`)}
                                                label={i18n._(t`power_imbalance_duration`)}
                                                unit={"h"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                isRequired={true}
                                                defaultValue={3}
                                                validate={(value) => {
                                                    const durationValidate = validateNumber(value, i18n, false, false);
                                                    if (durationValidate !== undefined) {
                                                        return durationValidate;
                                                    }
                                                    if (reparseNumber(value) < 1) {
                                                        return <Trans>power_imbalance_duration minimum 1h</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={5} tablet={3} computer={3}>
                                            <Field
                                                name="cycling_active"
                                                label={i18n._(t`cycling active`)}
                                                labelposition="right"
                                                component={ToggleAdapter}
                                                defaultValue={true}
                                                toggle={false}
                                                customAction={(data) => {
                                                    if (data.checked === false) {
                                                        //set cycling to null if cycling active not set
                                                        form.change("cycling", null);
                                                    }
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={11} tablet={5} computer={5}>
                                            <Field
                                                name="cycling"
                                                placeholder={i18n._(t`cycling placeholder`)}
                                                label={i18n._(t`cycling`)}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="numeric"
                                                isRequired={true}
                                                defaultValue={5}
                                                disabled={!values.cycling_active}
                                                helperText={i18n._(t`cycling helper`)}
                                                validate={(value, allValues) => {
                                                    if (allValues.cycling_active === false) {
                                                        return undefined;
                                                    }
                                                    const cyclingValidate = validateNumber(value, i18n, false, false, true);
                                                    if (cyclingValidate !== undefined) {
                                                        return cyclingValidate;
                                                    }
                                                    if (reparseNumber(value) < 1) {
                                                        return <Trans>cycling is invalid</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={(e, data) => {
                                        setOpen(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

export default React.memo(EditException);
