import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { dateRangeOptions, roundedDate } from "modules/time/utils";
import { Tab, Menu, Icon, Popup, Grid } from "semantic-ui-react";

import TimePanel from "modules/common/components/TimePanel";
import { Media } from "App";
import PaneAlerts from "./PaneAlerts";
import PaneEvents from "./PaneEvents";

const AlertEventsTab = (props) => {
    const now = moment();
    const default_time = {
        start: roundedDate(now.clone().subtract(30, "days").startOf("days"), 10),
        end: roundedDate(now.clone().startOf("minute"), 10),
        plage: "30d"
    };
    const [rangeTime, setRangeTime] = useState(default_time);
    const [tabIndex, setTabIndex] = useState(0);
    useSelector((state) => state.i18n.current); //force refresh for lng

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));

    let panes = [];

    panes.push({
        menuItem: (_, props) => {
            const { active } = props;
            return (
                <Menu.Item key="TriggeredAlerts" {...props}>
                    <Media lessThan="computer">
                        <Popup
                            content={i18n._(t`Alerts`)}
                            trigger={
                                <Icon.Group>
                                    <Icon name="hubspot" />
                                    <Icon corner="top right" name="bolt" />
                                </Icon.Group>
                            }
                        />
                    </Media>
                    <Media between={["computer", "bigScreen"]}>
                        {!active && (
                            <Popup
                                content={i18n._(t`Alerts`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="hubspot" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        )}
                        {active && (
                            <>
                                <Icon.Group>
                                    <Icon name="hubspot" />
                                    <Icon corner="top right" name="bolt" />
                                </Icon.Group>
                                <Trans>Alerts</Trans>
                            </>
                        )}
                    </Media>
                    <Media greaterThanOrEqual="bigScreen">
                        <Icon.Group>
                            <Icon name="hubspot" />
                            <Icon corner="top right" name="bolt" />
                        </Icon.Group>
                        <Trans>Alerts</Trans>
                    </Media>
                </Menu.Item>
            );
        },
        render: () => (
            <Tab.Pane>
                <PaneAlerts rangeTime={rangeTime} />
            </Tab.Pane>
        )
    });

    panes.push({
        menuItem: (_, props) => {
            const { active } = props;
            return (
                <Menu.Item key="EventAlerts" {...props}>
                    <Media lessThan="computer">
                        <Popup
                            content={i18n._(t`Events`)}
                            trigger={
                                <Icon.Group>
                                    <Icon name="hubspot" />
                                    <Icon corner="top right" name="bolt" />
                                </Icon.Group>
                            }
                        />
                    </Media>
                    <Media between={["computer", "bigScreen"]}>
                        {!active && (
                            <Popup
                                content={i18n._(t`Events`)}
                                trigger={
                                    <Icon.Group>
                                        <Icon name="hubspot" />
                                        <Icon corner="top right" name="bolt" />
                                    </Icon.Group>
                                }
                            />
                        )}
                        {active && (
                            <>
                                <Icon.Group>
                                    <Icon name="hubspot" />
                                    <Icon corner="top right" name="bolt" />
                                </Icon.Group>
                                <Trans>Events</Trans>
                            </>
                        )}
                    </Media>
                    <Media greaterThanOrEqual="bigScreen">
                        <Icon.Group>
                            <Icon name="hubspot" />
                            <Icon corner="top right" name="bolt" />
                        </Icon.Group>
                        <Trans>Events</Trans>
                    </Media>
                </Menu.Item>
            );
        },
        render: () => (
            <Tab.Pane>
                <PaneEvents rangeTime={rangeTime} />
            </Tab.Pane>
        )
    });

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        minimumDeltaDay={1}
                        action={({ start, end, plage }) => {
                            setRangeTime({ start: moment(start), end: moment(end), plage });
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Tab
                        panes={panes}
                        activeIndex={tabIndex}
                        onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex)}
                        menu={{ attached: true, tabular: true, className: "pwaOverviewTabMenu" }}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default React.memo(AlertEventsTab);
