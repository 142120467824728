import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from "lodash";
import { Grid, Segment, Header } from 'semantic-ui-react';
import { t, Trans } from '@lingui/macro';


import i18n from "modules/i18n/i18nConfig";
import { getMachine } from 'modules/machine/machineSlice';
import { useGetMachinesQuery } from 'modules/machine/machineService';
import { useGetMachinetypesQuery } from 'modules/machine/machinetypeService';
import { useGetComponentsQuery } from 'modules/machine/componentService';

import Back from "modules/common/components/back";
import MessageDisplay from 'modules/common/components/MessageDisplay';
import KinematicForm from './KinematicForm';


const PreloadKinematic = (props) => {
    const { id_machine } = useParams();
    const dispatch = useDispatch();
    const { org, machine } = useSelector((state) => state);
    const [ready, setReady] = useState(false);

    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current || !id_machine });
    const machinetypes = useGetMachinetypesQuery({ org: org.current }, { skip: !org.current || !id_machine });
    const components = useGetComponentsQuery({ org: org.current }, { skip: !org.current || !id_machine });


    useEffect(() => {
        (async () => {
            id_machine && await dispatch(getMachine({ org: org.current, id: id_machine }));
            await setReady(true);
        })();
        return () => {
            //dispatch(resetCurrentKinematic());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, org.current]);

    if (ready) {
        const error_list = [
            machines.isError,
            components.isError,
            machinetypes.isError
        ];

        const status_list = [
            machines.isSuccess,
            components.isSuccess,
            machinetypes.isSuccess
        ];

        if (_.some([
            ...error_list,
            machine.machine.error
        ])) {
            return (
                <Segment attached>
                    <Grid centered verticalAlign="middle">
                        <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                            <Grid.Column width={2}>
                                <Back />
                            </Grid.Column>
                            <Grid.Column width={12} textAlign="center">
                                <Header as="h1"><Trans>kinematic edition</Trans></Header>
                            </Grid.Column>
                            <Grid.Column width={2}></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={15}>
                                <MessageDisplay
                                    message={i18n._(t`error loading data`)}
                                    level="error"
                                    iconName="warning circle"
                                    isLoading={false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            );
        }

        if (id_machine) {
            if (_.every([
                ...status_list,
                machine.machine.status === "succeeded"
            ])) {
                return (
                    <KinematicForm />
                );
            }
        } else {
            if (_.every(status_list)) {
                return (
                    <KinematicForm />
                );
            }
        }
    }
    return (
        <MessageDisplay
            message={i18n._(t`loading data`)}
            level="info"
            iconName="circle notched"
            isLoading={true}
        />
    );
};

export default PreloadKinematic;
