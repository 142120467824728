import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { toast } from "react-toastify";
import { Field, Form } from "react-final-form";
import { Button, Modal, Grid, List } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import { toast_options } from "modules/notification/notificationMiddleware";
import { useGetPasswordHintsQuery, usePasswordChangeMutation } from "../userService";
import i18n from "modules/i18n/i18nConfig";
import { identity } from "modules/common/utils/form";

import { InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";

/**
 * Used for form validation. If errors, you can't submit form
 * @function validate
 * @param {object} values
 */
const validate = (values) => {
    const errors = {};

    if (values.old_password === "") {
        errors.old_password = <Trans>Password is required</Trans>;
    }

    if (values.password1 === "" || values.password2 === "") {
        if (values.password1 === "") {
            errors.password1 = <Trans>Password is required</Trans>;
        }
        if (values.password2 === "") {
            errors.password2 = <Trans>Password is required</Trans>;
        }
    } else {
        if (values.password1 !== values.password2) {
            errors.password1 = <Trans>Passwords are not identical</Trans>;
        }
    }
    return errors;
};

const PasswordChange = (props) => {
    const { setPasswordChange, getSiteRights } = props;
    const { org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);

    const { item } = props;

    const hintsQuery = useGetPasswordHintsQuery(
        { org: org.current, user_id: item.id, lng: current_lng },
        { skip: !org.current || item === undefined }
    );
    const hints = hintsQuery.data?.hints ?? [];

    const [passwordChange, change] = usePasswordChangeMutation();

    let g_error = i18n._(t`cannot change password`);
    const err = change?.error?.data?.password;
    if (err) {
        g_error = (
            <div className="ui list">
                {_.map(err, (e, idx) => {
                    return (
                        <div key={idx} className="item">
                            {e}
                        </div>
                    );
                })}
            </div>
        );
    }

    useEffect(() => {
        (async () => {
            if (change.isLoading) {
                toast.info(i18n._(t`send request to server`), toast_options);
            }
            if (change.isSuccess) {
                await toast.success(i18n._(t`successful change password`), toast_options);
                getSiteRights && getSiteRights({ org: org.current, user_id: item.id });
                setPasswordChange(false);
            }
        })();
    }, [change, setPasswordChange, getSiteRights, item.id, org]);

    const submitForm = async (formData) => {
        const { password1: password, old_password } = formData;
        passwordChange({ org: org.current, user_id: item.id, lng: current_lng, data: { password, old_password } });
    };

    const initialValues = {
        old_password: "",
        password1: "",
        password2: ""
    };

    return (
        <Modal onClose={() => setPasswordChange(false)} open={true} centered={false}>
            <Form
                onSubmit={submitForm}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, invalid }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>Password change</Trans>
                            </Modal.Header>
                            <Modal.Content>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid centered>
                                        <Grid.Row>
                                            <Grid.Column width={16} style={{ color: "#794b02", fontStyle: "italic" }}>
                                                <Trans>
                                                    Please enter your old password, for security’s sake, and then enter your new password twice so we
                                                    can verify you typed it in correctly.
                                                </Trans>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="old_password"
                                                    placeholder={i18n._(t`set old password here`)}
                                                    label={i18n._(t`Old password`)}
                                                    isRequired={true}
                                                    parse={identity}
                                                    type="password"
                                                    component={InputAdapter}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="password1"
                                                    placeholder={i18n._(t`Set new password here`)}
                                                    label={i18n._(t`New Password`)}
                                                    isRequired={true}
                                                    parse={identity}
                                                    type="password"
                                                    component={InputAdapter}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        {_.size(hints) > 0 && (
                                            <Grid.Row style={{ padding: "1em 0em" }}>
                                                <Grid.Column width={16}>
                                                    <List>
                                                        {_.map(hints, (hint, idx) => {
                                                            return <List.Item key={idx}>{hint}</List.Item>;
                                                        })}
                                                    </List>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Field
                                                    name="password2"
                                                    placeholder={i18n._(t`Confirm new password here`)}
                                                    label={i18n._(t`New confirmation password`)}
                                                    isRequired={true}
                                                    parse={identity}
                                                    type="password"
                                                    component={InputAdapter}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    {change.isError && <MessageDisplay message={g_error} level="error" isLoading={false} />}
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={() => {
                                        setPasswordChange(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="button"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

PasswordChange.propTypes = {
    item: PropTypes.object.isRequired
};

export default PasswordChange;
