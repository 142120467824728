import React, { useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Trans } from "@lingui/macro";

import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetUnitsQuery } from "modules/unit/unitService";

import TreeItem from "./TreeItem";

const defaultTree = [{ id: uuidv4(), type: null, children: [] }];

const Tree = (props) => {
    const { form, input } = props;
    const tree = input.value ? input.value : defaultTree;
    const org = useSelector((state) => state.org);

    //const [tree, setTree] = useState(defaultTree);
    const root = _.head(tree);

    const updateFormWithTree = (tree) => {
        form.change("conditions", tree);
    };

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });

    const measurementsObject = useMemo(() => {
        return _.reduce(
            measurements.data,
            (res, measure) => {
                const df = _.find(dataflows.data, { id: measure?.dataflow });
                const mttype = _.find(measurementtypes.data, { id: measure?.measurementtype });
                const eqt = _.find(equipments.data, { id: df?.equipment });
                const representation = df && mttype ? `${df?.name} - ${mttype.repr}` : "-";
                const new_measure = { key: measure?.id, text: representation, value: String(measure?.id) };
                const display_unit = _.find(units.data, { id: measure?.display_unit || mttype?.unit });
                res[measure.id] = { ...measure, ...new_measure, dataflow: df, measurementtype: mttype, equipment: eqt, display_unit };
                return res;
            },
            {}
        );
    }, [measurements, dataflows, measurementtypes, equipments, units]);

    return (
        <>
            <label>
                <b>
                    <Trans>conditions</Trans>
                </b>
            </label>
            <TreeItem
                key={root?.id}
                node={root}
                tree={tree}
                updateTree={updateFormWithTree}
                root={true}
                meta={props.meta}
                form={form}
                measurementsObject={measurementsObject}
            />
        </>
    );
};

export default React.memo(Tree);
