import moment from "moment-timezone";

/** Used for displaying error on datepicker
 * @function dateError
 * @param {any} elem - Moment object or string if invalid
 * @return {boolean}
 */
export const dateError = (elem) => {
    if (typeof elem === "object") {
        return !elem.isValid();
    }
    return true;
};

/**
 * Translate a time picker list for displaying selector
 * @function dateRangeOptions
 * @returns {array} - Time picker Array with semanticUI format (key,text,value)
 */
export const dateRangeOptions = () => [
    {
        key: "1d",
        value: "1d",
        text: `last 24 hours` //mtn
    },
    {
        key: "2d",
        value: "2d",
        text: `last 48 hours` //mtn
    },
    {
        key: "7d",
        value: "7d",
        text: `last 7 days` //mtn
    },
    {
        key: "30d",
        value: "30d",
        text: `last 30 days` //mtn
    },
    {
        key: "90d",
        value: "90d",
        text: `last 90 days` //mtn
    },
    {
        key: "yesterday",
        value: "yesterday", //minuit
        text: `yesterday`
    },
    {
        key: "last_week",
        value: "last_week",
        text: `last week` //minuit
    },
    {
        key: "last_month",
        value: "last_month",
        text: `last month` //minuit
    },
    {
        key: "last_three_month",
        value: "last_three_month",
        text: `last 3 months` //minuit
    },
    {
        key: "24h",
        value: "24h",
        text: `24 hours` //mtn
    },
    {
        key: "48h",
        value: "48h",
        text: `48 hours` //mtn
    },
    {
        key: "72h",
        value: "72h",
        text: `72 hours` //mtn
    }
];

/**
 * Used for processing a time range (start, end) for request
 * @function processTimeRange
 * @param {string} timeRange - type of time range to return
 * @returns {object} - return an object with start and end time
 */
export const processTimeRange = (timeRange, rounded = 10, timeFormat = true) => {
    const now = moment();

    switch (timeRange) {
        case "1d":
            return {
                start: roundedDate(now.clone().subtract(1, "days"), rounded),
                end: roundedDate(now.clone(), rounded)
            };
        case "2d":
            return {
                start: roundedDate(now.clone().subtract(2, "days"), rounded),
                end: roundedDate(now.clone(), rounded)
            };
        case "7d":
            return {
                start: roundedDate(now.clone().subtract(7, "days").startOf("day"), rounded),
                end: roundedDate(now.clone().subtract(timeFormat ? 0 : 1, "day"), rounded)
            };
        case "30d":
            return {
                start: roundedDate(now.clone().subtract(30, "days").startOf("day"), rounded),
                end: roundedDate(now.clone().subtract(timeFormat ? 0 : 1, "day"), rounded)
            };
        case "90d":
            return {
                start: roundedDate(now.clone().subtract(90, "days").startOf("day"), rounded),
                end: roundedDate(now.clone().subtract(timeFormat ? 0 : 1, "day"), rounded)
            };
        case "yesterday":
            return {
                start: roundedDate(now.clone().startOf("day").subtract(1, "days"), rounded),
                end: roundedDate(
                    now
                        .clone()
                        .startOf("day")
                        .subtract(timeFormat ? 0 : 1, "day"),
                    rounded
                )
            };
        case "last_week":
            return {
                start: roundedDate(now.clone().startOf("isoweek").subtract(1, "weeks"), rounded),
                end: roundedDate(
                    now
                        .clone()
                        .startOf("isoweek")
                        .subtract(timeFormat ? 0 : 1, "day"),
                    rounded
                )
            };
        case "last_month":
            return {
                start: roundedDate(now.clone().startOf("month").subtract(1, "month"), rounded),
                end: roundedDate(
                    now
                        .clone()
                        .startOf("month")
                        .subtract(timeFormat ? 0 : 1, "day"),
                    rounded
                )
            };
        case "last_three_month":
            return {
                start: roundedDate(now.clone().startOf("month").subtract(3, "month"), rounded),
                end: roundedDate(
                    now
                        .clone()
                        .startOf("month")
                        .subtract(timeFormat ? 0 : 1, "day"),
                    rounded
                )
            };
        case "24h":
            return {
                start: roundedDate(now.clone().subtract(24, "hours"), rounded),
                end: roundedDate(now.clone(), rounded)
            };
        case "48h":
            return {
                start: roundedDate(now.clone().subtract(48, "hours"), rounded),
                end: roundedDate(now.clone(), rounded)
            };
        case "72h":
            return {
                start: roundedDate(now.clone().subtract(72, "hours"), rounded),
                end: roundedDate(now.clone(), rounded)
            };
        default:
            return {
                start: roundedDate(now.clone().subtract(1, "days"), rounded),
                end: roundedDate(now.clone(), rounded)
            };
    }
};

export const roundedDate = (date, time = 10) => {
    const duration = moment.duration(time, "minutes");
    return moment(Math.floor(+date / +duration) * +duration);
};

export const getTimedelta = (start, end) => {
    const timedelta = moment(end).diff(moment(start), "day");

    if (timedelta <= 2) {
        //2j ou moins
        return { start: moment(end).subtract(7, "day").startOf("day"), end: moment(end).startOf("day"), plage: "7d" };
    } else if (timedelta <= 22) {
        //entre 2 et 22j
        return { start: moment(end).subtract(30, "day").startOf("day"), end: moment(end).startOf("day"), plage: "30d" };
    } else if (timedelta <= 100) {
        //entre 22 et 100j
        return { start: moment(end).subtract(24, "week").startOf("isoweek"), end: moment(end).startOf("isoweek") };
    } else {
        //plus de 100j
        return { start: moment(end).subtract(6, "month").startOf("month"), end: moment(end).startOf("month") };
    }
};

/**
 *
 * @param {Object} date - Date to change based on aggregate
 * @param {Number|String} aggregate_type - Aggregate option. 1:day, 2: week, 3: month. Number in analysis && String in Globalview
 */
export const processAggregateTime = (date, aggregate_type = 1) => {
    if (date === undefined) return date;
    switch (aggregate_type) {
        case 2:
        case "week":
            return date.clone().startOf("isoweek");
        case 3:
        case "month":
            return date.clone().startOf("month");
        default:
            return date;
    }
};
