import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";

export const importedDataSetsApi = defaultApi.injectEndpoints({
    reducerPath: "apiImportedDataSets",
    endpoints: (build) => ({
        getImportedDataSets: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `importeddatasets?org=${current_org}&${refreshTimestampUrlParam()}`;
            },

            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ImportedDataSets", id: arg.org.id }];
                return [];
            }
        }),
        getImportedDataSet: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, dataset_id }) => {
                const current_org = _.get(org, "name", null);
                return `importeddatasets/${dataset_id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ImportedDataSet", id: arg.dataset_id }];
                return [];
            }
        }),
        addImportedDataSet: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importeddatasets?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "ImportedDataSets", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateImportedDataSet: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importeddatasets/${data.id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "ImportedDataSet", id: arg.data.id },
                        { type: "ImportedDataSets", id: arg.org.id }
                    ];
                }
                return [];
            }
        }),
        deleteImportedDataSet: build.mutation({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importeddatasets/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "ImportedDataSets", id: arg.org.id }];
                }
                return [];
            }
        }),
        getProductionDatas: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, id, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `importeddatasets/${id}/importeddata?start=${start}&end=${end}&org=${current_org}&${refreshTimestampUrlParam()}`;
            },

            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ProductionDatas", id: arg.org.id }];
                return [];
            }
        }),
        addProductionData: build.mutation({
            query: ({ org, dataset_id, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importeddatasets/${dataset_id}/importeddata?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "ProductionDatas", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateProductionData: build.mutation({
            query: ({ org, dataset_id, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importeddatasets/${dataset_id}/importeddata/${data.id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "ProductionDatas", id: arg.org.id }];
                }
                return [];
            }
        }),
        deleteProductionData: build.mutation({
            query: ({ org, dataset_id, data_id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importeddatasets/${dataset_id}/importeddata/${data_id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "ProductionDatas", id: arg.org.id }];
                }
                return [];
            }
        }),
        uploadProductionData: build.mutation({
            query: ({ org, dataset_id, file, data }) => {
                const current_org = _.get(org, "name", null);

                const formData = new FormData();
                formData.append("import_file", file);
                formData.append("decimal_separator", data.decimal_separator);
                formData.append("csv_separator", data.csv_separator);

                return {
                    url: `importeddatasets/${dataset_id}/upload-csv?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: formData
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "ProductionDatas", id: arg.org.id }];
                }
                return [];
            }
        })
    })
});

export const {
    useGetImportedDataSetsQuery,
    useGetImportedDataSetQuery,
    useAddImportedDataSetMutation,
    useUpdateImportedDataSetMutation,
    useDeleteImportedDataSetMutation,
    useGetProductionDatasQuery,
    useAddProductionDataMutation,
    useUpdateProductionDataMutation,
    useDeleteProductionDataMutation,
    useUploadProductionDataMutation
} = importedDataSetsApi;
