import React from "react";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import DropdownMeasurement from "./DropdownMeasurement";

const FactorMeasureItem = (props) => {
    const { node, changeForm, formula, idx, input_name } = props;

    const onChangeFactor = (event) => {
        const new_formula = _.map(formula, (item, index) => {
            if (index !== idx) {
                return item;
            }
            return { ...item, factor: event.target.value };
        });
        changeForm(input_name, new_formula);
    };

    return (
        <Grid verticalAlign="middle">
            <Grid.Column width={2}>
                <div className={`ui fluid input ${!_.isUndefined(props.factor_err) ? "field error" : ""}`}>
                    <input type="text" onChange={onChangeFactor} value={node.factor} autoComplete="off" />
                </div>
            </Grid.Column>
            <Grid.Column width={1}>*</Grid.Column>
            <Grid.Column width={13}>
                <DropdownMeasurement {...props} />
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(FactorMeasureItem);
