import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';

export const healthscoretypeApi = defaultApi.injectEndpoints({
    reducerPath: 'apiHealthscoretype',
    endpoints: (build) => ({
        getHealthscoretypes: build.query({
            keepUnusedDataFor: 86400,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `healthscoretypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Healthscoretype"],
            transformResponse: (response) => {
                return _.map(response, (healthscoretype) => ({
                    ...healthscoretype,
                    key: healthscoretype.id,
                    text: healthscoretype.name,
                    value: healthscoretype.id
                }));
            }
        }),
    }),
    overrideExisting: false,
});

export const { useGetHealthscoretypesQuery } = healthscoretypeApi;