import { t } from "@lingui/macro";

export const categoriesOptions = [
    { key: '1', text: t`motor stator`, value: 'motor_stator' },
    { key: '2', text: t`motor brb`, value: 'motor_brb' },
    { key: '3', text: t`motor shaft alignment`, value: 'motor_shaft_alignment' },
    { key: '4', text: t`belt output shaft alignment`, value: 'belt_output_shaft_alignment' },
    { key: '5', text: t`gearbox output shaft alignment`, value: 'gearbox_output_shaft_alignment' },
    { key: '6', text: t`gearbox internal shaft alignment`, value: 'gearbox_internal_shaft_alignment' },
    { key: '7', text: t`motor bearing health`, value: 'motor_bearing_health' },
    { key: '8', text: t`other bearing health`, value: 'other_bearing_health' },
    { key: '9', text: t`belt health`, value: 'belt_health' },
];
