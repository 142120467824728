import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { Grid, Header } from "semantic-ui-react";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { dynamicValueUnit } from "modules/data/utils";
import { useGetMeasurementOverview24hQuery } from "modules/dashboard/dashboardService";
import { useGetUnitsQuery } from "modules/unit/unitService";

import MessageDisplay from "modules/common/components/MessageDisplay";

const pollingInterval = 1000 * 60 * 10;

const MinVal = (props) => {
    const { measure } = props;
    const { org } = useSelector((state) => state);

    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });

    const measurementOverview24h = useGetMeasurementOverview24hQuery(
        { org: org.current, measurement_id: measure?.id },
        { skip: !org.current || !_.isFinite(measure?.id) }
    );

    useEffect(() => {
        const measurementOverview24hTimer = setInterval(() => {
            measurementOverview24h.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(measurementOverview24hTimer);
        };
    }, [measure, measurementOverview24h, org]);

    const { factor, new_unit } = useMemo(() => {
        if (measurementOverview24h.isSuccess && units.isSuccess) {
            //For display unit, main unit from measurement else use unit of measurementtype
            const display_unit = _.find(units.data, { id: measure?.display_unit || measure?.measurementtype?.unit });
            const auto_unit = measure?.display_unit === null;
            const { factor, new_unit } = dynamicValueUnit(measurementOverview24h.data?.min_24h, display_unit?.symbol, auto_unit);
            return { factor, new_unit };
        }
        return { factor: 1, new_unit: "N/C" };
    }, [units, measure, measurementOverview24h]);

    if (measurementOverview24h.isFetching) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }

    if (measurementOverview24h.isFetching === false && measurementOverview24h.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (measurementOverview24h.isFetching === false && measurementOverview24h.isSuccess) {
        if (!_.isFinite(measurementOverview24h.data?.min_24h)) {
            return (
                <MessageDisplay message={i18n._(t`no minimum 24h`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />
            );
        }

        return (
            <Grid stretched centered>
                <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                    <Trans>minimum</Trans>
                </Grid.Column>
                <Grid.Column width={16} textAlign="center">
                    <Header as={"h3"}>
                        {`${i18n.number(measurementOverview24h.data?.min_24h * factor, {
                            maximumFractionDigits: 1
                        })} ${new_unit}`}
                    </Header>
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

MinVal.propTypes = {
    measure: PropTypes.object.isRequired
};

export default MinVal;
