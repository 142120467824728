import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Menu, Icon, Image } from "semantic-ui-react";
import logo from "images/logo-small.png";
import logo_full from "images/logo-full.svg";

import DropDownOrg from "modules/organization/components/DropdownOrg";
import DropDownI18n from "modules/i18n/components/DropdownI18n";
import { Media } from "App";
import { useSelector } from "react-redux";


export const Navbar = (props) => {
    const { path, toggleSidebar } = props;
    const org = useSelector(state => state.org.current);

    return (
        <>
            <Menu attached="top" className="no-print" style={{ height: '60px' }}>
                <Menu.Item
                    onClick={() => {
                        toggleSidebar(true);
                    }}
                >
                    <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Item as={Link} to={`${path}`}>
                    <Media lessThan="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && <Image src={logo} size="mini" />
                        }
                    </Media>
                    <Media greaterThanOrEqual="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && <Image src={logo_full} size="small" style={{ height: '45px', width: 'auto' }} />
                        }
                    </Media>
                </Menu.Item>
                <Menu.Menu position="right">
                    {/*<Notification />*/}
                    <DropDownI18n />
                    {!_.isNull(org) && <DropDownOrg />}
                </Menu.Menu>
            </Menu>
        </>
    );
};

Navbar.propTypes = {
    path: PropTypes.string.isRequired,
    toggleSidebar: PropTypes.func.isRequired
};

export default React.memo(Navbar);
