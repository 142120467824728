import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Load from "./widgets/predict/Load";
import Alerts from "./widgets/predict/Alerts";
import Trend from "./widgets/predict/Trend";
import Val24h from "./widgets/Val24h";
import LastVal from "./widgets/LastVal";
import Val7Day from "./widgets/Val7Day";
import UsagePercent from "./widgets/UsagePercent";

const DashboardPredict = (props) => {
    const { equipment, machine, measurements } = props;
    const org = useSelector((state) => state.org);

    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const { has_power, has_current } = useMemo(() => {
        //In predict widgets panel,use only 'frequency', 'current' or 'active energy index'
        const exclusive_predict_mt_type = ["frequency", "current", "p_act_import"];
        return _.chain(measurements)
            .reduce(
                (res, measure) => {
                    const mt_type = _.find(measurementtypes.data, { id: measure.measurementtype });
                    const mt_type_tech = _.find(measurementtypes.data, { id: measure.measurementtype_tech });
                    if (_.includes(exclusive_predict_mt_type, mt_type?.name)) {
                        const measure_with_mt_type = { ...measure, measurementtype: mt_type, measurementtype_tech: mt_type_tech };
                        if (mt_type?.name === "p_act_import") res.has_power = measure_with_mt_type;
                        if (mt_type?.name === "current") res.has_current = measure_with_mt_type;
                    }
                    return res;
                },
                { has_power: null, has_current: null }
            )
            .value();
    }, [measurements, measurementtypes]);

    if (!has_power && !has_current) {
        return (
            <Grid.Row>
                <Grid.Column width={16}>
                    <MessageDisplay level="warning" message={i18n._(t`no measurements`)} iconName="warning circle" isLoading={false} />;
                </Grid.Column>
            </Grid.Row>
        );
    }
    if (has_power && !has_current) {
        return (
            <>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <LastVal measure={has_power} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Val24h equipment={equipment} measure={has_power} />
                </Grid.Column>
                {has_power?.measurementtype?.datapoint_type === 3 && (
                    <>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <Val7Day measure={has_power} />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <UsagePercent measure={has_power} />
                        </Grid.Column>
                    </>
                )}
            </>
        );
    }

    if (!has_power && has_current) {
        return (
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Load machine={machine} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Val24h equipment={equipment} measure={has_current} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Trend machine={machine} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Alerts machine={machine} />
                </Grid.Column>
            </Grid.Row>
        );
    }
    if (has_power && has_current) {
        return (
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <LastVal measure={has_power} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Val24h equipment={equipment} measure={has_power} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Trend machine={machine} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Alerts machine={machine} />
                </Grid.Column>
            </Grid.Row>
        );
    }

    return null;
};

export default DashboardPredict;
