import React, { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { Popup, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

const TextEnhanced = ({ text, style }) => {
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => setShowMore(!showMore);

    const numberOfAllowedChars = 100;

    useEffect(() => {
        setShowMore(false);
    }, [text]);

    // Fonction pour afficher le bouton et son info-bulle
    const renderToggleIcon = (isOpen) => (
        <Popup
            trigger={
                <Icon
                    style={{ cursor: "pointer" }}
                    size="large"
                    color="grey"
                    name={`arrow alternate circle ${isOpen ? "left" : "right"} outline`}
                    onClick={toggleShowMore}
                    aria-label={isOpen ? i18n._(t`Hide message`) : i18n._(t`View full message`)}
                />
            }
            content={isOpen ? <Trans>Hide message</Trans> : <Trans>View full message</Trans>}
        />
    );

    // Fonction pour tronquer le texte si nécessaire
    const truncateText = (text, limit) => {
        if (text.length <= limit) return { firstPart: text, secondPart: "" };

        return {
            firstPart: text.substring(0, limit),
            secondPart: text.substring(limit)
        };
    };

    // Gestion du texte s'il dépasse la limite de caractères ou contient des sauts de ligne
    const { firstPart, secondPart } = text.includes("\n")
        ? {
              firstPart: text.split("\n")[0],
              secondPart: text.split("\n").slice(1).join("\n")
          }
        : truncateText(text, numberOfAllowedChars);

    return (
        <span style={{ ...style, whiteSpace: "pre-line" }}>
            {firstPart}
            {secondPart && (
                <>
                    {showMore && (
                        <>
                            <br />
                            {secondPart}
                        </>
                    )}
                    {renderToggleIcon(showMore)}
                </>
            )}
        </span>
    );
};

export default React.memo(TextEnhanced);
