import _ from "lodash";

/**
 * Convert current unit to around unit before and after
 * Ex:  kWh => MWh or Wh
 * @function dynamicValueUnit
 * @param {number} value - Current value to convert
 * @param {string} unit - Current unit of value
 * @returns
 */
export const dynamicValueUnit = (value, unit, dynamic = true) => {
    if (!_.isFinite(value)) {
        return { factor: 1, new_unit: unit };
    }

    if (dynamic === false) {
        switch (unit) {
            case "VAr":
            case "VArh":
            case "Wh":
            case "W":
            case "l/h":
            case "l":
            case "mA":
            case "mV":
            case "mbar":
                return { factor: 1e3, new_unit: unit };
            case "MVArh":
            case "MVAr":
            case "MWh":
            case "MW":
            case "kV":
            case "kW/m²":
                return { factor: 1e-3, new_unit: unit };
            default:
                return { factor: 1, new_unit: unit };
        }
    }

    switch (unit) {
        case "kVArh": {
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "VArh" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MVArh" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        }
        case "VArh":
            if (value > 10000) {
                return { factor: 1e-3, new_unit: "kVArh" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "MWh": {
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "kWh" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        }
        case "kWh": {
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "Wh" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MWh" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        }
        case "Wh":
            if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "kW":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "W" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MW" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "MW":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "kW" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "W":
            if (value > 10000) {
                return { factor: 1e-3, new_unit: "kW" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "A":
            if (value >= 0 && value <= 1) {
                return { factor: 1e3, new_unit: "mA" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "kVAr": {
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "VAr" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MVAr" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        }
        case "VAr":
            if (value > 10000) {
                return { factor: 1e-3, new_unit: "kVAr" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "m³": {
            if (value <= 1) {
                return { factor: 1000, new_unit: "l" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        }
        case "m³/h":
            if (value <= 5) {
                return { factor: 1000, new_unit: "l/h" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "l": {
            if (value > 10000) {
                return { factor: 1e-3, new_unit: "m³" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        }
        case "l/h":
            if (value > 10000) {
                return { factor: 1e-3, new_unit: "m³/h" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        // RATIO HERE
        case "Wh/m²":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "mWh/m²" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh/m²" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "kWh/h":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "Wh/h" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MWh/h" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "Wh/h/m²":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "mWh/h/m²" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh/h/m²" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "Wh/utilisateur":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "mWh/utilisateur" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh/utilisateur" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "Wh/person":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "mWh/person" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh/person" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "Wh/h/utilisateur":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "mWh/h/utilisateur" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh/h/utilisateur" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "Wh/h/person":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "mWh/h/person" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh/h/person" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "kWh/DJU":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "Wh/DJU" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MWh/DJU" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "Wh/DJU/m²":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "mWh/DJU/m²" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "kWh/DJU/m²" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "kWh/Tonne":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "Wh/Tonne" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MWh/Tonne" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "kWh/Unité":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "Wh/Unité" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MWh/Unité" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "kWh/Unit":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "Wh/Unit" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "MWh/Unit" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "dm³/m²":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "cm³/m²" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "m³/m²" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "dm³/utilisateur":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "cm³/utilisateur" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "m³/utilisateur" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        case "dm³/person":
            if (value >= 0 && value <= 10) {
                return { factor: 1e3, new_unit: "cm³/person" };
            } else if (value > 10000) {
                return { factor: 1e-3, new_unit: "m³/person" };
            } else {
                return { factor: 1, new_unit: unit };
            }
        default:
            return { factor: 1, new_unit: unit };
    }
};

export const remapIntensive = (name) => {
    switch (name) {
        case "p_act_import":
        case "e_act_counter":
            return "p_act";
        case "e_react-_counter":
        case "p_react_import-":
            return "p_react-";
        case "e_react+_counter":
        case "p_react_import+":
            return "p_react+";
        case "p_react_import":
            return "p_react";
        case "water_import":
            return "water_flow";
        case "gaz_import":
            return "gaz";
        default:
            return name;
    }
};

export const reProcessUnit = (unit, related_unit) => {
    switch (related_unit) {
        case "W":
            return "VAr";
        case "kW":
            return "kVAr";
        case "MW":
            return "MVAr";
        default:
            return unit;
    }
};
