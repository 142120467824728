import React from "react";
import _ from "lodash";
import { Trans, t, number } from "@lingui/macro";
import { Grid, Segment, Header, Table, Message } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";

import { motorTypeOptions, efficiency_class, duty_types, bearingType } from "modules/predict/utils";

const MotorInfo = (props) => {
    const { equipment } = props;

    const motor = _.chain(equipment).get("machine.components").find({ comp_type: 2 }).value();
    const motor_bearings = _.chain(equipment)
        .get("machine.components")
        .filter({ comp_type: 4 })
        .reduce((res, bearing, idx) => {
            //only main motor bearings (id_tech = 1 or 2). For id_tech > 3 additionnal bearings
            if (_.includes([1, 2], bearing.id_tech)) {
                res.push(bearing);
            }
            return res;
        }, [])
        .value();

    const { description } = motor;

    const motorType = _.find(motorTypeOptions, { value: description.motor_type });
    const efficiency_class_motor = _.find(efficiency_class, { value: description.efficiency_class });
    const duty_type_motor = _.find(duty_types, { value: description.duty_types });

    return (
        <Grid divided centered verticalAlign="top">
            <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Header
                        as="h5"
                        block
                        attached="top"
                        textAlign="center"
                        style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                        <Trans>rated info</Trans>
                    </Header>
                    <Table celled striped compact attached>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>voltage_motor</Trans> (V)
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedVoltage ? i18n._(t`${number(description.ratedVoltage, { maximumFractionDigits: 2 })}`) : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>frequency_motor</Trans> (Hz)
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedFrequency
                                        ? i18n._(t`${number(description.ratedFrequency, { maximumFractionDigits: 2 })}`)
                                        : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>current_motor</Trans> (A)
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedCurrent ? i18n._(t`${number(description.ratedCurrent, { maximumFractionDigits: 2 })}`) : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>cosphi_motor</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedCosphi ? i18n._(t`${number(description.ratedCosphi, { maximumFractionDigits: 2 })}`) : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>elec_power_motor</Trans> (kW)
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedElec_power
                                        ? i18n._(t`${number(description.ratedElec_power, { maximumFractionDigits: 2 })}`)
                                        : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>meca_power_motor</Trans> (kW)
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedMeca_power
                                        ? i18n._(t`${number(description.ratedMeca_power, { maximumFractionDigits: 2 })}`)
                                        : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    {" "}
                                    <Header as="h4">
                                        <Trans>efficiency_motor</Trans> (%)
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedEfficiency
                                        ? i18n._(t`${number(description.ratedEfficiency, { maximumFractionDigits: 2 })}`)
                                        : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>speed_motor</Trans> (rpm)
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.ratedSpeed ? i18n._(t`${number(description.ratedSpeed, { maximumFractionDigits: 2 })}`) : "-"}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Header as="h5" block attached textAlign="center" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        <Trans>general info</Trans>
                    </Header>
                    <Table celled striped compact attached>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>name</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{motor.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>motor maker</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{description.maker ? description.maker : "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>motor model</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{description.model ? description.model : "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as="h4">
                                        <Trans>motor type</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell> {motorType ? i18n._(t`${motorType.text}`) : "-"}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Header attached block textAlign="center">
                        <Trans>stator_rotor</Trans>
                    </Header>
                    <Table celled striped compact attached>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>polePairs_motor</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.polePairs ? i18n._(t`${number(description.polePairs, { maximumFractionDigits: 2 })}`) : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>statorSlots_motor</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {" "}
                                    {description.statorSlots ? i18n._(t`${number(description.statorSlots, { maximumFractionDigits: 2 })}`) : "-"}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>rotorBars_motor</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {description.rotorBars ? i18n._(t`${number(description.rotorBars, { maximumFractionDigits: 2 })}`) : "-"}{" "}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Header attached block textAlign="center">
                        <Trans>other</Trans>
                    </Header>
                    <Table celled striped compact attached>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>efficiency_class</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{efficiency_class_motor ? i18n._(t`${efficiency_class_motor.text}`) : "-"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}>
                                    <Header as="h4">
                                        <Trans>duty_types</Trans>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell>{duty_type_motor ? i18n._(t`${duty_type_motor.text}`) : "-"}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Header
                        as="h5"
                        block
                        attached="top"
                        textAlign="center"
                        style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                        <Trans>bearing(s)</Trans>
                    </Header>
                    {_.size(motor_bearings) === 0 && <Message content={i18n._(t`motor has no bearing`)} warning icon="warning circle" attached />}
                    {_.size(motor_bearings) !== 0 && (
                        <Segment attached>
                            {_.map(motor_bearings, (component, idx) => {
                                const { description } = component;
                                const bearing_type = _.find(bearingType, { value: _.get(component, "description.bearing_type", null) });
                                return (
                                    <React.Fragment key={component.id}>
                                        <Header attached={idx === 0 ? "top" : true} block textAlign="center">
                                            {i18n._(component.name)}
                                        </Header>
                                        <Segment attached>
                                            <Header attached="top" block textAlign="center">
                                                <Trans>general</Trans>
                                            </Header>
                                            <Table celled striped compact attached>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <Header as="h4">
                                                                <Trans>bearing maker</Trans>
                                                            </Header>
                                                        </Table.Cell>
                                                        <Table.Cell>{description.maker ? description.maker : "-"}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <Header as="h4">
                                                                <Trans>bearing model</Trans>
                                                            </Header>
                                                        </Table.Cell>
                                                        <Table.Cell>{description.model ? description.model : "-"}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={5}>
                                                            <Header as="h4">
                                                                <Trans>bearing type</Trans>
                                                            </Header>
                                                        </Table.Cell>
                                                        <Table.Cell>{bearing_type ? i18n._(t`${bearing_type.text}`) : "-"}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <Header as="h4">
                                                                <Trans>description</Trans>
                                                            </Header>
                                                        </Table.Cell>
                                                        <Table.Cell>{description.description ? description.description : "-"}</Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <Header attached block textAlign="center">
                                                <Trans>mechanical</Trans>
                                            </Header>
                                            <Table celled striped compact attached>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={5}>
                                                            <Header as="h4">
                                                                <Trans>type of entry</Trans>
                                                            </Header>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {description.by_order ? <Trans>orders entry</Trans> : <Trans>dimensions entry</Trans>}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    {description.by_order === false && (
                                                        <>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>rolElements</Trans>
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.dimension?.rolElements
                                                                        ? i18n._(
                                                                              t`${number(description?.dimension?.rolElements, {
                                                                                  maximumFractionDigits: 2
                                                                              })}`
                                                                          )
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>rolDiameter</Trans> (mm)
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.dimension?.rolDiameter
                                                                        ? i18n._(
                                                                              t`${number(description?.dimension?.rolDiameter, {
                                                                                  maximumFractionDigits: 2
                                                                              })}`
                                                                          )
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>pitchDiameter</Trans> (mm)
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.dimension?.pitchDiameter
                                                                        ? i18n._(
                                                                              t`${number(description?.dimension?.pitchDiameter, {
                                                                                  maximumFractionDigits: 2
                                                                              })}`
                                                                          )
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>contactAngle</Trans> (°)
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.dimension?.contactAngle
                                                                        ? i18n._(
                                                                              t`${number(description?.dimension?.contactAngle, {
                                                                                  maximumFractionDigits: 2
                                                                              })}`
                                                                          )
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </>
                                                    )}
                                                    {description.by_order === true && (
                                                        <>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>bpfi</Trans>
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.order?.BPFI
                                                                        ? i18n._(t`${number(description?.order?.BPFI, { maximumFractionDigits: 2 })}`)
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>bpfo</Trans>
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.order?.BPFO
                                                                        ? i18n._(t`${number(description?.order?.BPFO, { maximumFractionDigits: 2 })}`)
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>ftf</Trans>
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.order?.FTF
                                                                        ? i18n._(t`${number(description?.order?.FTF, { maximumFractionDigits: 2 })}`)
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell width={5}>
                                                                    <Header as="h4">
                                                                        <Trans>bsf</Trans>
                                                                    </Header>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {description?.order?.BSF
                                                                        ? i18n._(t`${number(description?.order?.BSF, { maximumFractionDigits: 2 })}`)
                                                                        : "-"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </>
                                                    )}
                                                </Table.Body>
                                            </Table>
                                        </Segment>
                                    </React.Fragment>
                                );
                            })}
                        </Segment>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default MotorInfo;
