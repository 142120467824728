export const keyToReparseInPack = [
    "elapsed_hour",
    "i_moy_max_threshold",
    "imbalance_threshold",
    "power_imbalance_duration",
    "power_imbalance_threshold",
    "thd_duration",
    "thd_threshold",
    "level_warning",
    "level_critical",
    "duration",
    "cycling"
];
