import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { dataflowApi } from './dataflowService';

const initialState = {
    dataflows: [],
};


const dataflowSlice = createSlice({
    name: "dataflow",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(dataflowApi.endpoints.getDataflows.matchFulfilled, (state, action) => {
                state.dataflows = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default dataflowSlice.reducer;