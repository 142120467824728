import { createSlice } from "@reduxjs/toolkit";
import { notificationApi } from './notificationService';

const initialState = {
    srv_status: {
        db_status: null // 'error'|'r'|'rw'|null
    }
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(notificationApi.endpoints.getSrvStatus.matchFulfilled, (state, action) => {
                state.srv_status = action.payload;
            })
            .addMatcher(notificationApi.endpoints.getSrvStatus.matchRejected, (state, action) => {
                state.srv_status = { ...state.srv_status, db_status: 'error' };
            });
    }
});

export default notificationSlice.reducer;