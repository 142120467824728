import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetTriggeredAlertsQuery } from "../alerteventService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import history_app from "history_app";

import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import { Button, Card, Grid, Icon, Input, Label, Popup, Segment } from "semantic-ui-react";

const customStyle = { cursor: "default", whiteSpace: "nowrap" };

const formatDateItem = (date_str, current_lng) => {
    if (!moment(date_str).isValid()) {
        return "-";
    }
    return `${moment(date_str).locale(current_lng).format("L")} ${moment(date_str).locale(current_lng).format("LTS")}`;
};

const renderDate = (date_str, current_lng, color) => {
    if (date_str === null) return "-";
    if (color === undefined) return formatDateItem(date_str, current_lng);
    return <Label color={color}>{formatDateItem(date_str, current_lng)}</Label>;
};

const PaneAlerts = (props) => {
    const { rangeTime } = props;
    const [localSearchName, setLocalSearchName] = useState("");
    const { org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const triggeredalerts = useGetTriggeredAlertsQuery(
        { org: org.current, start: rangeTime.start.toISOString(), end: rangeTime.end.toISOString() },
        { skip: !org.current }
    );
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "start_change_state", label: i18n._(t`Start of alert`), textAlign: "left" },
        { id: "display_condition_start", label: i18n._(t`triggered_reason`), textAlign: "left" },
        { id: "end_change_state", label: i18n._(t`End of alert`), textAlign: "left" },
        { id: "display_condition_end", label: i18n._(t`Return value to normal`), textAlign: "left" },
        { id: "notified_date_start", label: i18n._(t`Notification date`), textAlign: "left" },
        { id: "notified_date_end", label: i18n._(t`End of alert notification date`), textAlign: "left" }
    ];

    const renderCondition = useCallback(
        (condition, title, triggered_values) => {
            if (equipments.isSuccess && units.isSuccess && dataflows.isSuccess && measurements.isSuccess && measurementtypes.isSuccess) {
                if (condition === null) return "-";
                return (
                    <Popup
                        trigger={
                            <div style={{ zIndex: 10 }}>
                                <span style={customStyle}>{condition || "-"}</span>
                            </div>
                        }
                        style={{ padding: 0 }}
                    >
                        <Popup.Content>
                            <Card style={{ width: "100%" }}>
                                <Card.Content>
                                    <Card.Header>{i18n._(title)}</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    {_.chain(triggered_values)
                                        .map((value, triggered_key) => {
                                            const [measure_id, depth = null] = _.split(triggered_key, "/");

                                            const mes = _.find(measurements.data, { id: Number(measure_id) });
                                            const df = _.find(dataflows.data, { id: mes?.dataflow });
                                            const equipment = _.find(equipments.data, { id: df?.equipment });
                                            const mt_type = _.find(measurementtypes.data, { id: mes?.measurementtype });
                                            const display_unit = _.find(units.data, { id: mes?.display_unit || mt_type?.unit });
                                            const trig_val = _.isFinite(value) ? i18n.number(value, { maximumFractionDigits: 2 }) : "-";
                                            const minutes = Math.floor(parseFloat(depth) / 60);
                                            return (
                                                <Segment
                                                    basic
                                                    key={measure_id}
                                                    style={{ ...customStyle, overflow: "hidden", textOverflow: "ellipsis" }}
                                                >
                                                    <span>"{equipment?.name ?? "-"}"</span>
                                                    {"\u00A0"}={"\u00A0"}
                                                    <span>{trig_val}</span>
                                                    {"\u00A0"}
                                                    <span>{display_unit?.intensive || display_unit?.symbol || "-"}</span>
                                                    {_.isFinite(minutes) && <span>&nbsp;({`${minutes}  ${i18n._(t`min`)}`})</span>}
                                                </Segment>
                                            );
                                        })
                                        .value()}
                                </Card.Content>
                            </Card>
                        </Popup.Content>
                    </Popup>
                );
            }
            return "-";
        },
        // eslint-disable-next-line
        [equipments, units, dataflows, measurements, measurementtypes, current_lng]
    );

    const rows = useMemo(() => {
        const customStyle = { cursor: "default", whiteSpace: "nowrap" };
        if (triggeredalerts.isSuccess && measurements.isSuccess && measurementtypes.isSuccess && dataflows.isSuccess) {
            return _.chain(triggeredalerts.data)
                .reduce((res, alert, idx) => {
                    if (localSearchName !== "" && !_.includes(alert?.[1]?.toLowerCase(), localSearchName.toLowerCase())) return res;

                    const start_change_state = alert?.[2] ?? null;
                    const end_change_state = alert?.[8] ?? null;
                    const notified_date_start = alert?.[3] ?? null;
                    const notified_date_end = alert?.[9] ?? null;

                    const display_condition_start = alert?.[7] ?? null;
                    const display_condition_end = alert?.[13] ?? null;

                    res.push({
                        id: { render: null, value: alert?.[0], textAlign: "left", datatype: "number" },
                        name: {
                            render: <span style={customStyle}>{alert?.[1] ?? "-"}</span>,
                            value: alert?.[1] ?? "-",
                            textAlign: "left",
                            datatype: "string"
                        },
                        start_change_state: {
                            render: renderDate(start_change_state, current_lng, "red"),
                            value: start_change_state === null ? null : moment(start_change_state),
                            textAlign: "right",
                            datatype: "date"
                        },
                        display_condition_start: {
                            render: renderCondition(display_condition_start, t`Data that triggered the alert`, alert?.[4] ?? null), //Add tooltip for measurements detail
                            value: display_condition_start || "-",
                            textAlign: "left",
                            datatype: "string"
                        },
                        end_change_state: {
                            render: renderDate(end_change_state, current_lng, "green"),
                            value: end_change_state === null ? null : moment(end_change_state),
                            textAlign: "right",
                            datatype: "date"
                        },
                        display_condition_end: {
                            render: renderCondition(display_condition_end, t`Data for a return to normal`, alert?.[10] ?? null), //Add tooltip for measurements detail
                            value: display_condition_end || "-",
                            textAlign: "right",
                            datatype: "string"
                        },
                        notified_date_start: {
                            render: renderDate(notified_date_start, current_lng),
                            value: notified_date_start === null ? null : moment(notified_date_start),
                            textAlign: "right",
                            datatype: "date"
                        },
                        notified_date_end: {
                            render: renderDate(notified_date_end, current_lng),
                            value: notified_date_end === null ? null : moment(notified_date_end),
                            textAlign: "right",
                            datatype: "date"
                        }
                    });

                    return res;
                }, [])
                .value();
        }
        return [];
    }, [renderCondition, triggeredalerts, measurements, measurementtypes, dataflows, current_lng, localSearchName]);

    if (triggeredalerts.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }
    if (triggeredalerts.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (triggeredalerts.isSuccess) {
        return (
            <Grid celled>
                <Grid.Column mobile={16} tablet={3} computer={3}>
                    <Input
                        fluid
                        icon="search"
                        placeholder={i18n._(t`search name`)}
                        onChange={(e, { value }) => {
                            setLocalSearchName(value);
                        }}
                        value={localSearchName}
                        disabled={_.size(triggeredalerts.data) === 0}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    <Button
                        icon
                        labelPosition="left"
                        onClick={async (e) => {
                            history_app.push(`/alerts`);
                        }}
                    >
                        <Icon name="bullhorn" />
                        <Trans>alert setup</Trans>
                    </Button>
                </Grid.Column>
                <Grid.Column width={16}>
                    <TableEnhanced
                        headCells={headCells}
                        rows={rows}
                        order="desc"
                        orderBy="start_change_state"
                        textItemPerPages={i18n._(t`items per page`)}
                    />
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

export default React.memo(PaneAlerts);
