import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Modal, Button, Icon, Grid, Divider, Popup } from 'semantic-ui-react';
import { Form, Field } from "react-final-form";


import i18n from 'modules/i18n/i18nConfig';
import { InputAdapter, TextAreaAdapter } from 'modules/common/components/form';
import { useGetUsagesQuery, useAddUsageMutation, useUpdateUsageMutation } from 'modules/usage/usageService';
import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import { identity } from 'modules/common/utils/form';

const UsageModalForm = (props) => {
    const { item, canChange } = props;

    const [open, setOpen] = useState(false);
    const { org } = useSelector(state => state);
    const [createUsage, create] = useAddUsageMutation();
    const [updateUsage, update] = useUpdateUsageMutation();

    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });

    // Create Usage
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`new usage added`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            toast.error(i18n._(t`cannot add new usage`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError]);

    // Update Usage
    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update usage`), toast_options);
            setOpen(false);
        }
        if (update.isError) {
            toast.error(i18n._(t`cannot update usage`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isLoading, update.isSuccess, update.isError]);

    const initialValues = useMemo(() => {
        const defaultValues = { name: "", description: "" };
        return {
            ...defaultValues,
            ...item
        };
    }, [item]);

    const submitForm = async (formData) => {
        const data = _.omit(formData, ["key", "text", "value", "type"]);
        if (canChange) {
            if (data.id) {
                await updateUsage({ org: org.current, data });
            } else {
                await createUsage({ org: org.current, data });
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = i18n._(t`usage is required`);
        }
        const existing_name = _.chain(usages.data)
            .filter((usage) => {
                if (item === undefined) return true;
                return usage.id !== parseInt(item.id);
            })
            .find({ name: values.name })
            .value();
        if (existing_name) {
            errors.name = i18n._(t`this usage already exists`);
        }
        return errors;
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={item?.id ?
                <Popup trigger={
                    <Button
                        type="button"
                        disabled={!canChange}
                        icon="edit"
                        onClick={() => setOpen(true)} />}>
                    <Popup.Content>
                        <Trans>Usage edition</Trans>
                    </Popup.Content>
                </Popup>
                :
                <Button
                    disabled={!canChange}
                    type="button"
                    icon
                    labelPosition="left"
                >
                    <Icon name="add" />
                    <Trans>add a new Usage</Trans>
                </Button>

            }
        >

            <Modal.Header>
                {item?.id !== undefined && <Trans>edit usage</Trans>}
                {item?.id === undefined && <Trans>Add usage</Trans>}
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    validate={validate}
                    render={({
                        handleSubmit,
                        submitting,
                        pristine,
                        invalid,
                        form
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid verticalAlign="top" centered>
                                    <Grid.Column width={15}>
                                        <Field
                                            name="name"
                                            placeholder={i18n._(t`enter new usage`)}
                                            label={i18n._(t`new_usage`)}
                                            isRequired={true}
                                            parse={identity}
                                            component={InputAdapter}
                                        />
                                        <Field
                                            name="description"
                                            placeholder={i18n._(t`enter usage description`)}
                                            label={i18n._(t`description`)}
                                            parse={identity}
                                            component={TextAreaAdapter}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <Divider />
                                    </Grid.Column>
                                    <Grid.Column width={15} textAlign="right">
                                        <Button type="button" negative onClick={() => setOpen(false)}>
                                            <Trans>cancel</Trans>
                                        </Button>
                                        <Button type="submit" positive icon labelPosition='right' disabled={submitting || pristine || invalid}>
                                            <Icon name="check" />
                                            <Trans>validate</Trans>
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default UsageModalForm;
