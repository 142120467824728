import { setCurrentOrg } from "./orgSlice";
import { defaultApi } from "apis/PowerAdapt";
import { reportApi } from "modules/report/reportService";
import { analysisAdvancedApi } from "modules/analysisAdvanced/analysisAdvancedService";
import { otpVerified, setRights } from "modules/auth/authSlice";
import { hierarchyApi } from "modules/hierarchy/hierarchyService";
//import { userApi } from "modules/user/userService";
import { energySavingApi } from "modules/energysaving/energySavingService";
import { dashboardDndApi } from "modules/dashboardDragNDrop/dashboardDndService";

/**
 * Trigger some requests when currentOrg is set
 * @function orgMiddleware
 */
export const orgMiddleware = (store) => (next) => async (action) => {
    if (setCurrentOrg.match(action)) {
        await store.dispatch(otpVerified(false));
        await store.dispatch(setRights(null));
        await store.dispatch(defaultApi.util.resetApiState());
        await store.dispatch(reportApi.util.resetApiState());
        await store.dispatch(analysisAdvancedApi.util.resetApiState());
        await store.dispatch(hierarchyApi.util.resetApiState());
        //await store.dispatch(userApi.util.resetApiState());
        await store.dispatch(energySavingApi.util.resetApiState());
        await store.dispatch(dashboardDndApi.util.resetApiState());
    }
    return next(action);
};
