import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

export const defaultAlert = {
    is_active: true,
    name: "",
    start_date: "00:00:00",
    end_date: "23:59:59",
    mailinglist: [],
    days: [],
    duration_threshold: 0,
    site: null,
    validity: 0,
    alert_type: 0,
    conditions: [{ id: uuidv4(), type: null, children: [] }]
};

const initialState = {
    filter: {
        searchName: "",
        dayFilter: []
    },
    pagination: {
        page: 1,
        itemsPerPage: 10
    }
};

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setDayFilter: (state, action) => {
            state.filter.dayFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const { setSearchNameFilter, setDayFilter, resetFilterWithPage, setItemsPerPage, setPage } = alertSlice.actions;

export default alertSlice.reducer;
