const { t } = require("@lingui/macro");

export const PE6ModeOptions = [
    { key: 0, value: 0, text: t`disabled` },
    { key: 1, value: 1, text: t`1-phase` },
    { key: 2, value: 2, text: t`3-phase w. neutral` },
    { key: 3, value: 3, text: t`balanced 3-phase w. neutral` },
    { key: 4, value: 4, text: t`3-phase without neutral` },
    { key: 5, value: 5, text: t`balanced 3-phase without neutral` },
    { key: 6, value: 6, text: t`3-phase w. power transformer` },
    { key: 7, value: 7, text: t`1-phase or balanced 3-phase` },
    { key: 8, value: 8, text: t`3-phase` },

];

export const PE6TcOptions = [
    { key: 1, value: 1, text: t`TC 5A` },
    { key: 2, value: 2, text: t`TC 30A` },
    { key: 3, value: 3, text: t`TC 70A` },
    { key: 7, value: 7, text: t`TC 100A` },
    { key: 4, value: 4, text: t`TC 200A` },
    { key: 5, value: 5, text: t`TC 400A` },
    { key: 6, value: 6, text: t`Rogowski 1000A` },
];

export const deleteExclusion = [
    "pwa_backend.Channel",
    "pwa_backend.DataFlow",
    "pwa_clients.EquipmentTag"
];

export const modifiedExclusion = [

];