import React from "react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Field } from "react-final-form";
import { Grid, Segment, Header } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { validateNumber } from "modules/common/utils";
import { identityNull } from "modules/common/utils/form";
import { WidgetGaugeOptions } from "modules/dashboardDragNDrop/utils";

import { InputAdapter, DropDownAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";

const GaugeViewChoicesPanel = ({ values, strict_increase, disabled }) => {
    const gaugesOptions = _.chain(WidgetGaugeOptions)
        .map(({ key, value, text }) => ({ key, value, text: i18n._(text) }))
        .orderBy((item) => {
            return removeAccents(item.text).toLowerCase();
        }, "asc")
        .value();

    return (
        <>
            <Header attached block textAlign="center">
                <Trans>Gauge Representation</Trans>
            </Header>
            <Segment attached>
                <Field
                    name="gauge_choices"
                    className="icon"
                    icon="image outline"
                    button
                    labeled
                    search={false}
                    placeholder={i18n._(t`rectilinear_gauge`)}
                    options={gaugesOptions}
                    component={DropDownAdapter}
                    defaultValue={gaugesOptions[0].value}
                    disabled={disabled}
                />
            </Segment>
            {!disabled && strict_increase === false && (
                <MessageDisplay message={i18n._(t`Check your limit definition`)} level="error" iconName="warning circle" isLoading={false} />
            )}
            <Segment attached>
                <Grid>
                    <Grid.Column width={8}>
                        <Field
                            name="low_limit"
                            placeholder={i18n._(t`enter low limit`)}
                            label={i18n._(t`low limit`)}
                            component={InputAdapter}
                            parse={identityNull}
                            inputMode="decimal"
                            defaultValue={0}
                            disabled={disabled}
                            isRequired={true}
                            validate={(value) => {
                                return validateNumber(value, i18n, true, false);
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Field
                            name="high_limit"
                            placeholder={i18n._(t`enter high limit`)}
                            label={i18n._(t`high limit`)}
                            component={InputAdapter}
                            parse={identityNull}
                            inputMode="decimal"
                            defaultValue={150}
                            disabled={disabled}
                            isRequired={true}
                            validate={(value) => {
                                return validateNumber(value, i18n, true, false);
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Field
                            name="low_intermediate_limit"
                            placeholder={i18n._(t`enter low intermediate limit`)}
                            label={i18n._(t`low intermediate limit`)}
                            component={InputAdapter}
                            parse={identityNull}
                            inputMode="decimal"
                            defaultValue={80}
                            disabled={disabled}
                            isRequired={true}
                            validate={(value) => {
                                return validateNumber(value, i18n, true, false);
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Field
                            name="high_intermediate_limit"
                            placeholder={i18n._(t`enter high intermediate limit`)}
                            onKeyDown={(e) => _.includes([69, 190, 188], e.keyCode) && e.preventDefault()}
                            label={i18n._(t`high intermediate limit`)}
                            isRequired={true}
                            component={InputAdapter}
                            parse={identityNull}
                            inputMode="decimal"
                            defaultValue={120}
                            disabled={disabled}
                            validate={(value) => {
                                return validateNumber(value, i18n, true, false);
                            }}
                        />
                    </Grid.Column>
                </Grid>
            </Segment>
        </>
    );
};

export default GaugeViewChoicesPanel;
