import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import { Button, Grid, Header, Icon, Label, Segment } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';
import { getDetail, resetTab, setTime } from 'modules/overview/overviewSlice';
import { dateRangeOptions } from 'modules/time/utils';

import TimePanel from 'modules/common/components/TimePanel';
import MessageDisplay from 'modules/common/components/MessageDisplay';
import HeaderWHelper from 'modules/common/components/HeaderWHelper';
import GraphicDetail from '../graphic/GraphicDetail';
import { CustomSVGSeries, VerticalBarSeries } from 'react-vis';
import EventWidget from 'modules/common/components/EventWidget';
import Voltagedetected from '../Voltagedetected';

const phases = [
    ["phase 1", "brown"],
    ["phase 2", "black"],
    ["phase 3", "grey"]
];



const TabElecDistri = (props) => {
    const tab = 'tabelecdistri';
    const { default_time } = props;
    const measures = _.orderBy(props.measures, "id");
    const dispatch = useDispatch();
    const { org } = useSelector(state => state);
    const { tabelecdistri, last_values, powertime } = useSelector(state => state.overview);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(['24h', '48h', '72h'], item.value));
    const rangeTime = powertime.time ? {
        start: moment(powertime.time.start), end: moment(powertime.time.end), plage: powertime.time.plage
    } : default_time;

    const measures_voltage_detected = _.filter(measures, (measure) => _.get(measure, "measurementtype.name") === "voltage_detected");
    const measures_voltage_imbalance = _.filter(measures, (measure) => _.get(measure, "measurementtype.name") === "voltage_imbalance");
    const measures_sag = _.filter(measures, (measure) => _.includes(["sag", "sag_ph1", "sag_ph2", "sag_ph3"], _.get(measure, "measurementtype.name")));
    const measures_swell = _.filter(measures, (measure) => _.includes(["swell", "swell_ph1", "swell_ph2", "swell_ph3"], _.get(measure, "measurementtype.name")));
    const measures_trip = _.filter(measures, (measure) => _.get(measure, "measurementtype.name") === "trip");
    const measures_i_moy_max = _.filter(measures, (measure) => _.includes(["i_moy_max", "i_moy_max_ph1", "i_moy_max_ph2", "i_moy_max_ph3"], _.get(measure, "measurementtype.name")));

    useEffect(() => {
        (async () => {
            if (powertime.time) {
                const { start, end } = powertime.time;
                _.size(measures_voltage_imbalance) > 0 && dispatch(getDetail({ org: org.current, measures: measures_voltage_imbalance, start, end, tab, type: "voltageimbalance" }));
                _.size(measures_sag) > 0 && dispatch(getDetail({ org: org.current, measures: measures_sag, start, end, tab, type: "sag" }));
                _.size(measures_swell) > 0 && dispatch(getDetail({ org: org.current, measures: measures_swell, start, end, tab, type: "swell" }));
                _.size(measures_trip) > 0 && dispatch(getDetail({ org: org.current, measures: measures_trip, start, end, tab, type: "trip" }));
                _.size(measures_i_moy_max) > 0 && dispatch(getDetail({ org: org.current, measures: measures_i_moy_max, start, end, tab, type: "i_moy_max" }));
            } else {
                _.size(measures_voltage_imbalance) > 0 && dispatch(getDetail({ org: org.current, measures: measures_voltage_imbalance, start: default_time.start.toISOString(), end: default_time.end.toISOString(), tab, type: "voltageimbalance" }));
                _.size(measures_sag) > 0 && dispatch(getDetail({ org: org.current, measures: measures_sag, start: default_time.start.toISOString(), end: default_time.end.toISOString(), tab, type: "sag" }));
                _.size(measures_swell) > 0 && dispatch(getDetail({ org: org.current, measures: measures_swell, start: default_time.start.toISOString(), end: default_time.end.toISOString(), tab, type: "swell" }));
                _.size(measures_trip) > 0 && dispatch(getDetail({ org: org.current, measures: measures_trip, start: default_time.start.toISOString(), end: default_time.end.toISOString(), tab, type: "trip" }));
                _.size(measures_i_moy_max) > 0 && dispatch(getDetail({ org: org.current, measures: measures_i_moy_max, start: default_time.start.toISOString(), end: default_time.end.toISOString(), tab, type: "i_moy_max" }));
            }
        })();
        return () => {
            dispatch(resetTab(tab));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powertime.time]);

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: 'powertime' }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            {_.size(measures_voltage_detected) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={10} computer={14} textAlign="center">
                        {last_values.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                        {last_values.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                        {last_values.status === "succeeded" && (<Voltagedetected measures_voltage_detected={measures_voltage_detected} />)}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_voltage_imbalance) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>voltage imbalance</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            L’équilibre de la tension est une condition fondamentale au
                                            bon fonctionnement des machines tournantes telles que
                                            pompes, ventilateurs, compresseurs ou autres moteurs. Un
                                            déséquilibre, même léger entraine des appels de déséquilibre
                                            de courant induisant des surchauffes potentielles ainsi
                                            qu’un possible déséquilibre mécanique de la machine.
                                        </p>
                                        <p>
                                            La tension est fournie par le gestionnaire de réseau et
                                            respecte des critères d’équilibres très stricts. Néanmoins,
                                            les transformateurs de la distribution interne du site
                                            peuvent subir des défauts engendrant des déséquilibres de la
                                            tension distribuée aux machines.
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_voltage_imbalance) === 0 && (<MessageDisplay message={i18n._(t`no voltage imbalance measure`)} level="info" iconName="info circle" isLoading={false} />)}
                        {tabelecdistri.voltageimbalance.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                        {tabelecdistri.voltageimbalance.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                        {tabelecdistri.voltageimbalance.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecdistri.voltageimbalance.data}
                                SerieType={CustomSVGSeries}
                                customComponent="diamond"
                                markSize={6}
                                csvName={_.get(props, 'equipment.name', 'export')}
                                threshold={2} //default threshold 2% cf norme EDF
                            >
                                <EventWidget
                                    type={<Trans>voltage unbalanced hours</Trans>}
                                    helper={<Trans>voltage unbalanced hours helper</Trans>}
                                    events={
                                        _.chain(tabelecdistri.voltageimbalance.data)
                                            .reduce((res, serie) => {
                                                const { data } = serie;
                                                _.each(data, (record) => {
                                                    //Here threshold = 2
                                                    if (_.chain(record).get('y').value() > 2) { res.push(1); }
                                                });
                                                return res;
                                            }, [])
                                            .sum()
                                            .value()
                                    }
                                    icon="balance"
                                />
                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_sag) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>sag</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            Le système détecte les micro-coupure qui surviennent lorsque
                                            la tension d’une ou plusieurs phases chute en dessous de 70%
                                            de sa valeur nominale pendant 2 périodes consécutives ou
                                            plus (à partir de 40ms sous 50Hz).
                                        </p>
                                        <p>
                                            Ce type d’événement peut perturber des automates
                                            (redémarrage intempestif lors de la chute de tension) ou
                                            générer une panne sur leur alimentation (lors du retour de
                                            tension).
                                        </p>
                                        <p>
                                            De manière générale, les moteurs et les transformateurs
                                            peuvent également être dégradés en cas d’événements trop
                                            fréquents. L’origine du phénomène peut provenir du réseau de
                                            transport ou de distribution (si l’événement se produit
                                            simultanément sur plusieurs transformateurs de la boucle de
                                            livraison par exemple) ou de la distribution interne du site
                                            (si les événements sont localisés).
                                        </p>
                                        <p>
                                            Dans ce cas, la résolution du problème inclut généralement
                                            une vérification des serrages de cable, des séquences de
                                            démarrage des machines (éviter trop de démarrage quasi
                                            simultanés) ou du dimensionnement des postes de
                                            transformation
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_sag) === 0 && (<MessageDisplay message={i18n._(t`no sag measure`)} level="info" iconName="info circle" isLoading={false} />)}
                        {tabelecdistri.sag.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                        {tabelecdistri.sag.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                        {tabelecdistri.sag.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecdistri.sag.data}
                                stackBy="y"
                                SerieType={VerticalBarSeries}
                                event={true}
                                csvName={_.get(props, 'equipment.name', 'export')}
                            >
                                <EventWidget
                                    helper={<Trans>Sag event helper</Trans>}
                                    events={
                                        _.chain(tabelecdistri.sag.data)
                                            .reduce((res, serie) => {
                                                const { data } = serie;
                                                _.each(data, (record) => {
                                                    if (_.chain(record).get('y').value() > 0) { res.push(record.y); }
                                                });
                                                return res;
                                            }, [])
                                            .size()
                                            .value()
                                    }
                                    icon="level down"
                                    inner_icon="bolt"
                                    inner_corner="top left"
                                />
                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_swell) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>swell</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            Le système détecte les surtensions qui surviennent lorsque
                                            la tension d’une ou plusieurs phases dépasse 130% de sa
                                            valeur nominale pendant 2 périodes consécutives ou plus (à
                                            partir de 40ms sous 50Hz).
                                        </p>
                                        <p>
                                            Ce type d’événement peut détériorer les alimentations à
                                            découpage généralement utilisées pour les automates ainsi
                                            que les bobinages des stators de moteurs ou de
                                            transformateurs.
                                        </p>
                                        <p>
                                            L’origine du phénomène peut provenir du réseau de transport
                                            ou de distribution (si l’événement se produit simultanément
                                            sur plusieurs transformateurs de la boucle de livraison par
                                            exemple) ou de la distribution interne du site (si les
                                            événements sont localisés).
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_swell) === 0 && (<MessageDisplay message={i18n._(t`no swell measure`)} level="info" iconName="info circle" isLoading={false} />)}
                        {tabelecdistri.swell.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                        {tabelecdistri.swell.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                        {tabelecdistri.swell.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecdistri.swell.data}
                                stackBy="y"
                                SerieType={VerticalBarSeries}
                                event={true}
                                csvName={_.get(props, 'equipment.name', 'export')}
                            >
                                <EventWidget
                                    helper={<Trans>Swell event helper</Trans>}
                                    events={
                                        _.chain(tabelecdistri.swell.data)
                                            .reduce((res, serie) => {
                                                const { data } = serie;
                                                _.each(data, (record) => {
                                                    if (_.chain(record).get('y').value() > 0) { res.push(record.y); }
                                                });
                                                return res;
                                            }, [])
                                            .size()
                                            .value()
                                    }
                                    icon="level up"
                                    inner_icon="bolt"
                                    inner_corner="bottom left"
                                />
                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_trip) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>trip</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            Les coupures déclenchées par le relai ou disjoncteur de
                                            protection de l’équipement indiquent un défaut électrique au
                                            niveau de l’équipement ou de la distribution entre l’armoire
                                            et l’équipement.
                                        </p>
                                        <p>
                                            Il est nécessaire de mener une analyse des causes à chaque
                                            disjonction.
                                        </p>
                                        <p>
                                            Le compteur de disjonction permet d’identifier les départs
                                            électriques ayant de fréquentes disjonctions, ce qui
                                            impliquent un diagnostic approfondi et peut poser des
                                            risques accrus de sécurité des personnes ou d’incendie.
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_trip) === 0 && (<MessageDisplay message={i18n._(t`no trip measure`)} level="info" iconName="info circle" isLoading={false} />)}
                        {tabelecdistri.trip.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                        {tabelecdistri.trip.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                        {tabelecdistri.trip.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecdistri.trip.data}
                                stackBy="y"
                                SerieType={VerticalBarSeries}
                                event={true}
                                csvName={_.get(props, 'equipment.name', 'export')}
                            >
                                <EventWidget
                                    type={<Trans>number of trip</Trans>}
                                    helper={<Trans>number of trip helper</Trans>}
                                    events={
                                        _.chain(tabelecdistri.trip.data)
                                            .reduce((res, serie) => {
                                                const { data } = serie;
                                                _.each(data, (record) => {
                                                    if (_.chain(record).get('y').value() > 0) { res.push(record.y); }
                                                });
                                                return res;
                                            }, [])
                                            .size()
                                            .value()
                                    }
                                    icon="level down"
                                    inner_icon="bolt"
                                    inner_corner="top left"
                                />
                                {null}
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
            {_.size(measures_i_moy_max) > 0 && (
                <Grid.Row>
                    <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                        <Header as={Segment} attached="top" block textAlign="center">
                            <HeaderWHelper
                                text={<Trans>cable overheating</Trans>}
                                helper={
                                    <Trans>
                                        <p>
                                            Le système peut estimer l’état thermique des cables de
                                            puissance en se basant sur les informations du type de
                                            cable et de la section des cables.
                                        </p>
                                        <p>
                                            Ainsi, si un courant excessif circule pendant une durée
                                            trop importante, entrainant un échauffement excessif, le
                                            système sera en mesure de le détecter.
                                        </p>
                                    </Trans>
                                }
                            />
                        </Header>
                        {_.size(measures_i_moy_max) === 0 && (<MessageDisplay message={i18n._(t`no i_moy_max measure`)} level="info" iconName="info circle" isLoading={false} />)}
                        {tabelecdistri.i_moy_max.status === "loading" && (<MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />)}
                        {tabelecdistri.i_moy_max.status === "failed" && (<MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />)}
                        {tabelecdistri.i_moy_max.status === "succeeded" && (
                            <GraphicDetail
                                time={rangeTime}
                                data={tabelecdistri.i_moy_max.data}
                                csvName={_.get(props, 'equipment.name', 'export')}
                            >
                                {/* Todo events calculation for i moy max widget */}
                                <EventWidget
                                    helper={<Trans>Imoymax event helper</Trans>}
                                    events={0}
                                    icon="hotjar"
                                />
                                <Segment basic>
                                    <Segment tertiary className="pwaSegmentLabel" attached="top">
                                        <Trans>max current reached over a range of 10 min</Trans>
                                    </Segment>
                                    <Segment attached>
                                        {_.chain(tabelecdistri.i_moy_max.data)
                                            .sortBy((item) => item.name)
                                            .map((serie, idx) => {
                                                const current = _.chain(serie.data)
                                                    .sortBy((item) => item.y_real)
                                                    .filter((item) => item.y_real != null)
                                                    .last()
                                                    .get("y_real")
                                                    .value();
                                                const unit = _.get(serie, 'unit', '');
                                                return (
                                                    <Button as='div' labelPosition="right" key={idx}>
                                                        <Button basic> <Icon name="bolt" />{phases[idx][0]}</Button>
                                                        <Label color={phases[idx][1]} pointing="left">
                                                            {!_.isFinite(current) && `- ${unit}`}
                                                            {_.isFinite(current) && `${i18n.number(current, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${unit}`}
                                                        </Label>
                                                    </Button>
                                                );
                                            })
                                            .value()}
                                    </Segment>
                                </Segment>
                            </GraphicDetail>
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};

export default TabElecDistri;
