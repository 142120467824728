import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Segment, Grid, Header, Button, Icon, Dropdown } from 'semantic-ui-react';

import i18n from "modules/i18n/i18nConfig";
import {
    resetFilterWithPage, setSiteFilter, setItemsPerPage,
    setPage, setPackTypeFilter, setStickPage
} from 'modules/alert/alert_pack/alertPackSlice';
import history_app from 'history_app';

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import DeletePack from 'modules/alert/alert_pack/components/DeletePack';
import MessageDisplay from 'modules/common/components/MessageDisplay';
import { checkRights } from 'modules/auth/utils';


const AlertPacks = (props) => {
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const { alertpack, alertpacktype, auth, notification } = useSelector((state) => state);

    const is_admin = auth.rights?.is_admin ?? false;

    useEffect(() => {
        (async () => {
            await dispatch(setSiteFilter(alertpack.filter.siteFilter));
            await dispatch(setPackTypeFilter(alertpack.filter.packTypeFilter));
            //Change stick page after filter application.
            alertpack.pagination.stickPage && await dispatch(setStickPage(false));
            setReady(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!ready) {
        return (
            <MessageDisplay
                message={i18n._(t`loading filter`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }

    const renderIsActive = (item) => {
        return item.active === true ? (
            <Icon name="checkmark" color="green" />
        ) : (
            <Icon name="delete" color="red" />
        );
    };

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "is_active", label: i18n._(t`is_active`), textAlign: "left", collapsing: true },
        { id: "type", label: i18n._(t`packtype`), textAlign: "left", },
        { id: "site", label: i18n._(t`site`), textAlign: "left" },
        { id: "actions", label: i18n._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    const alertpacks_list = _.chain(alertpack.alertpacks)
        .reduce((res, item) => {
            const customStyle = { cursor: "default", "whiteSpace": "nowrap" };
            const alertpack_type = _.chain(alertpacktype.alertpacktypes).find(["value", _.get(item, 'type.id')]).get('text').value();
            const alertpack_site = _.chain(props.sites).find(["value", _.get(item, 'site.id')]).get('text').value();
            const rights = checkRights(item, auth.rights);

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                is_active: {
                    render: <span style={customStyle}>{renderIsActive(item)}</span>,
                    value: +_.get(item, "active", null),
                    textAlign: "left",
                    datatype: "number"
                },
                type: {
                    render: <span style={customStyle}>{alertpack_type ? i18n._(alertpack_type) : "-"}</span>,
                    value: alertpack_type ? i18n._(alertpack_type) : null,
                    textAlign: "left",
                    datatype: "string"
                },
                site: {
                    render: <span style={customStyle}>{alertpack_site ? i18n._(alertpack_site) : "-"}</span>,
                    value: alertpack_site ? i18n._(alertpack_site) : null,
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Button.Group size="small">
                                    {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                        <Button
                                            icon="edit"
                                            onClick={async (e) => {
                                                await dispatch(setStickPage(true));
                                                history_app.push(`alerts_packs/${item.id}`);
                                            }}
                                        />
                                    )}
                                    {notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete") && <DeletePack item={item} />}
                                </Button.Group>
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>alertpacks management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={4} computer={4}>
                                <Dropdown
                                    fluid
                                    options={_.map(alertpacktype.alertpacktypes, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                    placeholder={i18n._(t`select pack types`)}
                                    multiple
                                    selection
                                    onChange={(e, { value }) => { dispatch(setPackTypeFilter(value)); }}
                                    value={alertpack.filter.packTypeFilter}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={4} computer={4}>
                                <Dropdown
                                    fluid
                                    options={_.map(props.sites, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                    placeholder={i18n._(t`select sites`)}
                                    multiple
                                    selection
                                    onChange={(e, { value }) => { dispatch(setSiteFilter(value)); }}
                                    value={alertpack.filter.siteFilter}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                {notification.srv_status.db_status === "rw" && is_admin && <Button
                                    icon
                                    labelPosition="left"
                                    onClick={async (e) => {
                                        await dispatch(resetFilterWithPage());
                                        history_app.push(`alerts_packs/add`);
                                    }}
                                >
                                    <Icon name="add" />
                                    <Trans>add alert pack</Trans>
                                </Button>}
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <TableEnhanced
                                    headCells={headCells}
                                    rows={alertpacks_list}
                                    textItemPerPages={i18n._(t`items per page`)}
                                    order="asc"
                                    page={alertpack.pagination.page}
                                    rowsPerPage={alertpack.pagination.itemsPerPage}
                                    setPage={(page) => { dispatch(setPage(page)); }}
                                    setItemsPerPage={(items) => { dispatch(setItemsPerPage(items)); }}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default AlertPacks;