import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const activityLogApi = defaultApi.injectEndpoints({
    reducerPath: "apiActivityLog",
    endpoints: (build) => ({
        getActivityLog: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, id_type, severity, start, end }) => {
                const current_org = _.get(org, "name", null);
                let query = `jobactivity?org=${current_org}`;
                if (id_type) query += `&content_type=${id_type}`;
                if (severity >= 0) query += `&severity=${severity}`;
                if (start) query += `&start=${start}`;
                if (end) query += `&end=${end}`;
                query += `&${refreshTimestampUrlParam()}`;
                return query;
            },

            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ActivityLog", id: arg.id_type }];
                return [];
            }
        })
    })
});

export const { useGetActivityLogQuery } = activityLogApi;
