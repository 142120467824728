import React, { useState, useEffect } from "react";
import { t, Trans } from "@lingui/macro";
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';
import { Grid, Segment, Header, Input, Button } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';
import { useGetTagsQuery } from 'modules/tag/tagService';
import { useGetUsersQuery } from "modules/user/userService";
import { setSearchNameFilter, setItemsPerPage, setPage, setStickPage } from 'modules/tag/tagSlice';

import Back from 'modules/common/components/back';
import MessageDisplay from 'modules/common/components/MessageDisplay';
import TableEnhanced from 'modules/common/components/TableEnhanced';
import DeleteTag from "./DeleteTag";
import EditTagModalForm from "./EditTagModalForm";

const Tags = () => {
    const dispatch = useDispatch();
    const [localSearchName, setLocalSearchName] = useState("");
    const { org, auth, tag, notification, } = useSelector(state => state);

    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const users = useGetUsersQuery({ org: org.current }, { skip: !org.current });


    const err_list = [
        tags.isError,
        users.isError
    ];

    const status_list = [
        tags.isSuccess,
        users.isSuccess
    ];


    const getUserEmailById = (userId) => {
        const user = users.data?.find(user => user.id === userId);
        // the admin can see all users, 
        // unlike the average user can only see their own tags
        return user ? user.email : '--';
    };

    useEffect(() => {
        (async () => {
            await setLocalSearchName(tag.filter.searchName);
            await dispatch(setSearchNameFilter(tag.filter.searchName));
            tag.pagination.stickPage && await dispatch(setStickPage(false));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "tags", label: i18n._(t`tags`), textAlign: "left" },
        { id: "owner", label: i18n._(t`owner`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    //Usage de tag.tags au lieu de tags.data
    //car via le formulaire d'équipement il est possible d'ajouter un tag
    //sans mettre à jour le cache initial
    const tags_list = _.chain(tag.tags)
        .reduce((res, item) => {
            if (tag.filter.searchName === "") {
                res.push(item);
            } else if (_.includes(item.name.toLowerCase(), tag.filter.searchName.toLowerCase())) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const can_actions = notification.srv_status.db_status === "rw" && (auth.rights?.is_admin || item.owner === auth.rights.user_id);

            res.push({
                ...item,
                id: { render: null, value: item.text, textAlign: "left", datatype: "number" },
                tags: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                owner: {
                    render: <span style={customStyle}>{item.owner ? getUserEmailById(item.owner) : <Trans><i style={{ color: 'grey' }}>There is no longer owner for this tag</i></Trans>}</span>,
                    value: item.owner ? getUserEmailById(item.owner) : "-",
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => {
                        return (
                            <>
                                <Button.Group>
                                    <EditTagModalForm tags={tag.tags} item={item} can_actions={can_actions} users={users.data || []} />
                                    <DeleteTag item={item} can_actions={can_actions} />
                                </Button.Group>
                            </>
                        );
                    })(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                {/* Header  */}
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/manage" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Tags lists</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {(() => {
                    if (_.some(err_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                    else if (_.every(status_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <Grid celled>
                                        {/* Search input  */}
                                        <Grid.Column mobile={16} tablet={3} computer={3}>
                                            <Input
                                                fluid
                                                icon="search"
                                                placeholder={i18n._(t`search tag`)}
                                                onChange={(e, { value }) => {
                                                    setLocalSearchName(value);
                                                    dispatch(setSearchNameFilter(value));
                                                }}
                                                value={localSearchName}
                                            />
                                        </Grid.Column>
                                        {notification.srv_status.db_status === "rw" && (
                                            <Grid.Column width={16} >
                                                <EditTagModalForm tags={tag.tags} can_actions={notification.srv_status.db_status === "rw"} users={users.data || []} />
                                            </Grid.Column>
                                        )}

                                        {/* Table  */}
                                        <Grid.Column width={16}>
                                            <TableEnhanced
                                                headCells={headCells}
                                                rows={tags_list}
                                                order="asc"
                                                orderBy="tags"
                                                page={tag.pagination.page}
                                                rowsPerPage={tag.pagination.itemsPerPage}
                                                setPage={(page) => { dispatch(setPage(page)); }}
                                                setItemsPerPage={(items) => { dispatch(setItemsPerPage(items)); }}
                                                textItemPerPages={i18n._(t`items per page`)}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                    else {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`loading data`)}
                                        level="info"
                                        iconName="circle notched"
                                        isLoading={true}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                })()}

            </Grid>
        </Segment>
    );
};

export default Tags;
