import React, { Fragment } from "react";
import _ from "lodash";
import { Label, Dropdown, Checkbox, Popup, Icon, Segment, Input } from "semantic-ui-react";
import Datetime from "react-datetime";
import i18n from "modules/i18n/i18nConfig";
import moment from "moment";

/**
 * Used for displaying error label above input form
 * @function renderError
 * @param {object} meta - Meta object given by react-final-form
 */
export const renderError = ({ invalid, error, submitError, modified, dirty, touched }, cssOverride) => {
    if ((dirty || touched) && invalid && modified) {
        return (
            <Label basic color="red" pointing={cssOverride ? "right" : "below"}>
                {error || submitError}
            </Label>
        );
    }
};

export const InputAdapter = ({
    helperText,
    input,
    input: { onChange, value },
    meta,
    label,
    isRequired,
    unit,
    customAction = (data) => {},
    cssOverride,
    unitposition = "right",
    unitcolor = "grey",
    ...rest
}) => {
    return (
        <Fragment>
            <div
                className={`field ${isRequired ? "required" : ""} ${(meta.dirty || meta.touched) && meta.invalid && meta.modified ? "error" : ""} ${
                    cssOverride ? "pwaFormField" : ""
                }`}
            >
                {label && <label style={{ display: "inline" }}>{label}</label>}
                {helperText && (
                    <Popup basic trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}>
                        <Segment basic style={{ width: "35vh" }}>
                            {helperText}
                        </Segment>
                    </Popup>
                )}
                {renderError(meta, cssOverride)}
                <div className={unit ? `ui ${unitposition} labeled input` : `ui input`}>
                    {unit && unitposition === "left" && (
                        <div className={`ui ${unitcolor} label`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>{unit}</div>
                        </div>
                    )}
                    <input
                        {...input}
                        autoComplete="off"
                        value={value}
                        onChange={async (event) => {
                            event.persist();
                            await onChange(event.target.value);
                            await customAction(event.target.value);
                        }}
                        {...rest}
                    />
                    {unit && unitposition === "right" && (
                        <div className={`ui ${unitcolor} label`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>{unit}</div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export const DropDownAdapter = ({
    helperText,
    input: { onChange, value },
    meta,
    label,
    isRequired,
    options,
    unit,
    cssOverride,
    customAction = (data) => {},
    ...rest
}) => {
    return (
        <div
            className={`field ${isRequired ? "required" : ""} ${(meta.dirty || meta.touched) && meta.invalid && meta.modified ? "error" : ""} ${
                cssOverride ? "pwaFormField" : ""
            }`}
        >
            {label && <label style={{ display: "inline" }}>{label}</label>} 
            {helperText && (
                <Popup basic trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}>
                    <Segment basic style={{ width: "35vh" }}>
                        {helperText}
                    </Segment>
                </Popup>
            )}
            {renderError(meta, cssOverride)}
            <Dropdown
                multiple={!!rest.multipleselect}
                selection
                search
                fluid
                options={_.map(options, (props) => {
                    const { key, text, value, flag, disabled, description, content } = props;
                    const option = { key, text, value, flag, disabled, description, content };
                    if (!option.flag) {
                        delete option.flag;
                    }
                    if (option.text) {
                        option.text = i18n._(option.text);
                    }
                    if (option.description) {
                        option.description = i18n._(option.description);
                    }
                    return option;
                })}
                value={value}
                onChange={(event, data) => {
                    onChange(data.value);
                    customAction(data.value);
                }}
                name={label}
                {...rest}
            />
        </div>
    );
};

export const TextAreaAdapter = ({ input, meta, label, isRequired, cssOverride, helperText, ...rest }) => {
    return (
        <Fragment>
            <div
                className={`field ${isRequired ? "required" : ""} ${meta.error && meta.touched ? "error" : ""} ${cssOverride ? "pwaFormField" : ""}`}
            >
                {label && <label style={{ display: "inline" }}>{label}</label>} 
                {helperText && (
                    <Popup basic trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}>
                        <Segment basic style={{ width: "35vh" }}>
                            {helperText}
                        </Segment>
                    </Popup>
                )}
                {renderError(meta, cssOverride)}
                <textarea {...input} {...rest} />
            </div>
        </Fragment>
    );
};

export const ToggleAdapter = ({ numbered, input: { onChange, value }, label, toggle = true, customAction = (data) => {}, ...rest }) => {
    return (
        <Checkbox
            toggle={toggle}
            label={label}
            checked={!!value}
            value={+value}
            {...rest}
            onChange={(event, data) => {
                if (numbered) {
                    onChange(+data.checked);
                } else {
                    onChange(data.checked);
                }

                customAction(data);
            }}
        />
    );
};

export const RadioAdapter = ({ input, label, radio = true, customAction = (data) => {}, ...rest }) => {
    return (
        <Checkbox
            name={input.name}
            radio={radio}
            label={label}
            value={input.value}
            checked={input.checked}
            onChange={(event, data) => {
                input.onChange(data.value);
                customAction(data);
            }}
        />
    );
};

export const DateTimeAdapter = ({ customAction = (data) => {}, ...rest }) => {
    const renderInput = (props, openCalendar, closeCalendar) => {
        const { isRequired, meta, labeled, label, labelPosition, disabled, input, customStyle } = props;
        return (
            <div
                className={`field ${isRequired && (labeled || (!labeled && label)) ? "required" : ""} ${
                    (meta.dirty || meta.touched) && meta.invalid && meta.modified ? "error" : ""
                }`}
                style={customStyle ? customStyle : { marginBottom: "1em" }}
            >
                {!labeled && label && <label style={{ display: "inline" }}>{label}</label>}
                {renderError(meta)}
                <Input
                    {...input}
                    fluid
                    disabled={disabled}
                    label={labeled && label}
                    labelPosition={labelPosition || null}
                    autoComplete="off"
                    onClick={openCalendar}
                    onChange={(e, data) => {
                        const now = moment().locale(props.locale);
                        let fmt_date = now._locale._longDateFormat.L; //retrieve default date format from moment
                        let fmt_time = now._locale._longDateFormat.LT; //retrieve default time format from moment

                        let full_fmt = ""; //process generic full datetime format
                        if (props.dateFormat === true) {
                            full_fmt += fmt_date;
                        } else if (typeof props.dateFormat === "string") {
                            full_fmt += props.dateFormat;
                        }
                        if (props.timeFormat === true) {
                            full_fmt += ` ${fmt_time}`;
                        } else if (typeof props.timeFormat === "string") {
                            full_fmt += ` ${props.timeFormat}`;
                        }
                        const d = moment(data.value, full_fmt.trim(), true);
                        input.onChange(d.isValid() ? d.clone() : data.value);
                        customAction(d.isValid() ? d.clone() : data.value);
                    }}
                    value={props.value}
                    icon="calendar"
                />
            </div>
        );
    };

    const comparison = rest?.comparison ?? "isBefore";

    return (
        <Datetime
            customStyle={rest.customStyle}
            className={rest.className}
            locale={rest.locale}
            dateFormat={rest.dateFormat}
            timeFormat={rest.timeFormat}
            renderInput={renderInput}
            inputProps={rest}
            onChange={(moment_obj) => {
                if (!_.isString(moment_obj)) {
                    rest.input.onChange(moment_obj);
                    customAction(moment_obj);
                }
            }}
            isValidDate={
                rest.date_limit
                    ? (current) => {
                          if (comparison === "isDiff") {
                              //custom isDiff method because momentjs has no 'isDiff' method (!isSame)
                              return !current.isSame(rest.date_limit);
                          }
                          return current[comparison](rest.date_limit);
                      }
                    : null
            }
            value={rest.input.value}
        />
    );
};
