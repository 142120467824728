import PowerAdaptAPI, { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";
import { getTimedelta } from "modules/time/utils";

export const dashboardApi = defaultApi.injectEndpoints({
    reducerPath: "apiDashboard",
    endpoints: (build) => ({
        getLastActivity: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `/equipments/last_activity_sensors?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    body: {
                        equipments: _.isEmpty(data) ? "" : data
                    }
                };
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "LastActivity", id: arg.data }];
                return [];
            }
        }),
        createInstantExport: build.mutation({
            queryFn: async ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                const response = await PowerAdaptAPI.post(`/instantexport?org=${current_org}&${refreshTimestampUrlParam()}`, data, {
                    responseType: "blob",
                    timeout: 1000 * 60 * 5,
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                });

                if (response.data !== undefined) {
                    // Passing url reference of blob instead of blob directly because blob object is not serializable for redux-toolkit
                    const url = window.URL.createObjectURL(response.data);
                    return { data: { data: url, params: _.pick(data, ["format"]) } }; //only use format in 'params' in DashboardMiddleware
                } else {
                    return { error: response.error };
                }
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "InstantExport", id: arg.org.id }];
                }
                return [];
            },
            keepUnusedDataFor: 600
        }),
        getDetailComparison: build.query({
            queryFn: async ({ org, selected_equipments_ids, comparison_params }, _queryApi, _extraOptions, baseQuery) => {
                const current_org = _.get(org, "name", null);
                try {
                    if (_.size(selected_equipments_ids) === 1) {
                        const measure = _.head(comparison_params?.m_list);
                        const ref_query = `/measurements/${measure?.id}/detail?org=${current_org}&start=${comparison_params?.ref_start}&end=${
                            comparison_params?.ref_end
                        }&${refreshTimestampUrlParam()}`;
                        const comp_query = `/measurements/${measure?.id}/detail?org=${current_org}&start=${comparison_params?.comp_start}&end=${
                            comparison_params?.comp_end
                        }&${refreshTimestampUrlParam()}`;
                        const ref_response = await baseQuery(ref_query);
                        const comp_response = await baseQuery(comp_query);
                        return {
                            data: [
                                { measure, data: ref_response.data },
                                { measure, data: comp_response.data }
                            ]
                        };
                    } else if (_.size(selected_equipments_ids) > 1) {
                        const response_measurements = await Promise.all(
                            _.map(comparison_params?.m_list, async (measure) => {
                                try {
                                    const comp_query = `/measurements/${measure?.id}/detail?org=${current_org}&start=${
                                        comparison_params?.comp_start
                                    }&end=${comparison_params?.comp_end}&${refreshTimestampUrlParam()}`;
                                    const comp_response = await baseQuery(comp_query);
                                    return { data: comp_response.data, measure };
                                } catch (error) {
                                    return { error };
                                }
                            })
                        );
                        return { data: response_measurements };
                    }
                    return { data: [] };
                } catch (error) {
                    return { data: [] };
                }
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ComparisonDetail", id: arg.comparison_params }];
                return [];
            }
        }),
        getConsoComparison: build.query({
            queryFn: async ({ org, selected_equipments_ids, comparison_params }, _queryApi, _extraOptions, baseQuery) => {
                const current_org = _.get(org, "name", null);
                try {
                    if (_.size(selected_equipments_ids) === 1) {
                        const measure = _.head(comparison_params?.m_list);
                        const { start: ref_start, end: ref_end } = getTimedelta(comparison_params?.ref_start, comparison_params?.ref_end);
                        const { start: comp_start, end: comp_end } = getTimedelta(comparison_params?.comp_start, comparison_params?.comp_end);
                        const ref_query = `/measurements/${measure?.id}/consumptions?org=${current_org}&start=${ref_start.format(
                            "YYYY-MM-DD"
                        )}&end=${ref_end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const comp_query = `/measurements/${measure?.id}/consumptions?org=${current_org}&start=${comp_start.format(
                            "YYYY-MM-DD"
                        )}&end=${comp_end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const ref_response = await baseQuery(ref_query);
                        const comp_response = await baseQuery(comp_query);
                        return {
                            data: [
                                { measure, data: ref_response.data },
                                { measure, data: comp_response.data }
                            ]
                        };
                    } else if (_.size(selected_equipments_ids) > 1) {
                        const response_measurements = await Promise.all(
                            _.map(comparison_params?.m_list, async (measure) => {
                                try {
                                    const { start: comp_start, end: comp_end } = getTimedelta(
                                        comparison_params?.comp_start,
                                        comparison_params?.comp_end
                                    );
                                    const comp_query = `/measurements/${measure?.id}/consumptions?org=${current_org}&start=${comp_start.format(
                                        "YYYY-MM-DD"
                                    )}&end=${comp_end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                                    const comp_response = await baseQuery(comp_query);
                                    return { data: comp_response.data, measure };
                                } catch (error) {
                                    return { error };
                                }
                            })
                        );
                        return { data: response_measurements };
                    }
                    return { data: [] };
                } catch (error) {
                    return { data: [] };
                }
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ComparisonConso", id: arg.comparison_params }];
                return [];
            }
        }),
        getSummaryComparison: build.query({
            queryFn: async ({ org, selected_equipments_ids, comparison_params }, _queryApi, _extraOptions, baseQuery) => {
                const current_org = _.get(org, "name", null);
                try {
                    if (_.size(selected_equipments_ids) === 1) {
                        const measure = _.head(comparison_params?.m_list);
                        const { start: ref_start, end: ref_end } = getTimedelta(comparison_params?.ref_start, comparison_params?.ref_end);
                        const { start: comp_start, end: comp_end } = getTimedelta(comparison_params?.comp_start, comparison_params?.comp_end);
                        const ref_query = `/measurements/${measure?.id}/summary?org=${current_org}&start=${ref_start.format(
                            "YYYY-MM-DD"
                        )}&end=${ref_end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const comp_query = `/measurements/${measure?.id}/summary?org=${current_org}&start=${comp_start.format(
                            "YYYY-MM-DD"
                        )}&end=${comp_end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                        const ref_response = await baseQuery(ref_query);
                        const comp_response = await baseQuery(comp_query);
                        return {
                            data: [
                                { measure, data: ref_response.data },
                                { measure, data: comp_response.data }
                            ]
                        };
                    } else if (_.size(selected_equipments_ids) > 1) {
                        const response_measurements = await Promise.all(
                            _.map(comparison_params?.m_list, async (measure) => {
                                try {
                                    const { start: comp_start, end: comp_end } = getTimedelta(
                                        comparison_params?.comp_start,
                                        comparison_params?.comp_end
                                    );
                                    const comp_query = `/measurements/${measure?.id}/summary?org=${current_org}&start=${comp_start.format(
                                        "YYYY-MM-DD"
                                    )}&end=${comp_end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`;
                                    const comp_response = await baseQuery(comp_query);
                                    return { data: comp_response.data, measure };
                                } catch (error) {
                                    return { error };
                                }
                            })
                        );
                        return { data: response_measurements };
                    }
                    return { data: [] };
                } catch (error) {
                    return { data: [] };
                }
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "ComparisonSummary", id: arg.comparison_params }];
                return [];
            }
        }),
        getSortedEquipments: build.query({
            query: ({ org, sorting, sites = [] }) => {
                const current_org = _.get(org, "name", null);
                let query = `equipmentsorting?org=${current_org}`;
                if (!_.isEmpty(sites)) {
                    query += `&sites=${_.join(sites, ",")}`;
                }
                if (sorting?.unit) {
                    query += `&unit=${sorting?.unit}`;
                }
                query += `&category=${sorting?.type}`;
                query += `&asc=${sorting?.order}`;
                query += `&${refreshTimestampUrlParam()}`;
                return query;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "SortedDashboards", id: arg.org.id }];
                return [];
            }
        }),
        getDataflowsLastValues: build.query({
            query: ({ org, dataflows_list }) => {
                const current_org = _.get(org, "name", null);
                let query = `/dataflows/last_values?org=${current_org}`;
                if (dataflows_list !== "") {
                    query += `&pk_list=${dataflows_list}`;
                }
                query += `&${refreshTimestampUrlParam()}`;
                return query;
            },
            keepUnusedDataFor: 540,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "DataflowsLastValues", id: arg.dataflows_list }];
                return [];
            }
        }),
        getMachinesLastValues: build.query({
            query: ({ org, machines_list }) => {
                const current_org = _.get(org, "name", null);
                let query = `/machines/last_values?org=${current_org}`;
                if (machines_list !== "") {
                    query += `&pk_list=${machines_list}`;
                }
                query += `&${refreshTimestampUrlParam()}`;
                return query;
            },
            keepUnusedDataFor: 540,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "MachinesLastValues", id: arg.machines_list }];
                return [];
            }
        }),
        getMachineOverview24h: build.query({
            query: ({ org, machine_id }) => {
                const current_org = _.get(org, "name", null);
                return `/machines/${machine_id}/overview24h?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 540,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "MachineOverview24h", id: arg.machine_id }];
                return [];
            }
        }),
        getMeasurementOverview24h: build.query({
            query: ({ org, measurement_id }) => {
                const current_org = _.get(org, "name", null);
                return `/measurements/${measurement_id}/overview24h?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 540,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "MeasurementOverview24h", id: arg.measurement_id }];
                return [];
            }
        }),
        getMeasurementLastValues: build.query({
            query: ({ org, measurement_id }) => {
                const current_org = _.get(org, "name", null);
                return `/measurements/${measurement_id}/last_values?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 540,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "MeasurementLastValues", id: arg.measurement_id }];
                return [];
            }
        }),
        getMachineLastValues: build.query({
            query: ({ org, id_machine }) => {
                const current_org = _.get(org, "name", null);
                return `/machines/${id_machine}/last_values?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 540,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "MachinesLastValues", id: arg.id_machine }];
                return [];
            }
        })
    })
});

export const {
    useGetLastActivityQuery,
    useCreateInstantExportMutation,
    useGetSortedEquipmentsQuery,
    useGetDataflowsLastValuesQuery,
    useGetMachinesLastValuesQuery,
    useGetMachineLastValuesQuery,
    useGetMachineOverview24hQuery,
    useGetMeasurementOverview24hQuery,
    useGetMeasurementLastValuesQuery,
    useGetDetailComparisonQuery,
    useGetConsoComparisonQuery,
    useGetSummaryComparisonQuery
} = dashboardApi;
