import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Grid, Popup, Label, Menu, Icon } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { clearSelectedEquipments, setAllSelectedEquipments } from "../dashboardSlice";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";

import { Media } from "App";
import { equipmentsMatcher } from "../utils";
import InstantExport from "./actions/InstantExport";
import InstantComparison from "./actions/InstantComparison";

const DashboardActions = (props) => {
    const dispatch = useDispatch();
    const [openExport, setOpenExport] = useState(false);
    const [openComparison, setOpenComparison] = useState(false);
    const org = useSelector((state) => state.org);
    const selected_equipments_ids = useSelector((state) => state.dash.selected_equipments);
    const filtered_equipments = useSelector((state) => state.dash.filtered_equipments);

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });

    const equipmentsMatching = useMemo(() => {
        if (equipments.isSuccess) {
            return equipmentsMatcher(equipments.data, selected_equipments_ids);
        }
        return true;
    }, [equipments, selected_equipments_ids]);

    const handleSelectAll = (e) => {
        const equipments_id = _.map(filtered_equipments, (item) => item.id);
        dispatch(setAllSelectedEquipments(equipments_id));
    };
    const handleClearAll = (e) => dispatch(clearSelectedEquipments());

    if (_.size(selected_equipments_ids) > 0) {
        return (
            <>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {/* COMPUTER DISPLAY */}
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Menu compact>
                                        <Popup
                                            content={i18n._(t`Select all`)}
                                            trigger={
                                                <Menu.Item active onClick={handleSelectAll}>
                                                    <Icon name="tasks" />
                                                    <Trans>Select all</Trans>
                                                </Menu.Item>
                                            }
                                        />
                                        <Popup
                                            content={i18n._(t`Reset all`)}
                                            trigger={
                                                <Menu.Item active onClick={handleClearAll}>
                                                    <Icon name="undo" />
                                                    <Trans>Reset all</Trans>
                                                </Menu.Item>
                                            }
                                        />
                                        <Menu.Item active>
                                            <Icon name="hdd" />
                                            <Trans>Selected</Trans>
                                            <Label>{_.size(selected_equipments_ids)}</Label>
                                        </Menu.Item>
                                        {/* Add spacing */}
                                        <Menu.Item active />
                                        <Menu.Item
                                            as="a"
                                            active
                                            disabled={_.size(selected_equipments_ids) >= 20 || equipmentsMatching === false}
                                            onClick={(e) => setOpenComparison(true)}
                                        >
                                            <Icon
                                                name="balance scale"
                                                disabled={_.size(selected_equipments_ids) >= 20}
                                                color={_.size(selected_equipments_ids) >= 20 ? "red" : null}
                                            />
                                            {_.size(selected_equipments_ids) === 1 && <Trans>Comparison on 2 periods</Trans>}
                                            {_.inRange(_.size(selected_equipments_ids), 2, 20) && <Trans>Comparison</Trans>}
                                            {_.size(selected_equipments_ids) >= 20 && (
                                                <span style={{ color: "rgba(255, 0, 0, 0.3)" }}>
                                                    <Trans>too much equipments, max 20</Trans>
                                                </span>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item active as="a" onClick={(e) => setOpenExport(true)}>
                                            <Icon name="send" />
                                            <Trans>toexport</Trans>
                                        </Menu.Item>
                                    </Menu>
                                )
                            }
                        </Media>
                        {/* OTHER DISPLAY */}
                        <Media lessThan="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <Menu compact stackable fluid>
                                        <Popup
                                            content={i18n._(t`Select all`)}
                                            trigger={
                                                <Menu.Item active onClick={handleSelectAll}>
                                                    <Icon name="tasks" />
                                                    <Trans>Select all</Trans>
                                                </Menu.Item>
                                            }
                                        />
                                        <Popup
                                            content={i18n._(t`Reset all`)}
                                            trigger={
                                                <Menu.Item active onClick={handleClearAll}>
                                                    <Icon name="undo" />
                                                    <Trans>Reset all</Trans>
                                                </Menu.Item>
                                            }
                                        />
                                        <Menu.Item active>
                                            <Icon name="hdd" />
                                            <Trans>Selected</Trans>
                                            <Label>{_.size(selected_equipments_ids)}</Label>
                                        </Menu.Item>
                                        {/* Add spacing */}
                                        <Menu.Item active />
                                        <Menu.Item
                                            active
                                            style={{ flexGrow: 2 }}
                                            disabled={_.size(selected_equipments_ids) >= 20 || equipmentsMatching === false}
                                            as="a"
                                            onClick={(e) => setOpenComparison(true)}
                                        >
                                            <Icon name="balance scale" />
                                            {_.size(selected_equipments_ids) === 1 && <Trans>Comparison on 2 periods</Trans>}
                                            {_.size(selected_equipments_ids) > 1 && <Trans>Comparison</Trans>}
                                        </Menu.Item>
                                        <Menu.Item as="a" active style={{ flexGrow: 2 }} onClick={(e) => setOpenExport(true)}>
                                            <Icon name="send" />
                                            <Trans>toexport</Trans>
                                        </Menu.Item>
                                    </Menu>
                                )
                            }
                        </Media>
                    </Grid.Column>
                </Grid.Row>
                {openExport && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <InstantExport setOpen={setOpenExport} />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {openComparison && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <InstantComparison setOpen={setOpenComparison} fromDashboard={true} />
                        </Grid.Column>
                    </Grid.Row>
                )}
            </>
        );
    }
    return null;
};

export default DashboardActions;
