import React from 'react';
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Segment, Grid, Header } from 'semantic-ui-react';

import i18n from "modules/i18n/i18nConfig";

import { useGetSitesQuery } from 'modules/site/siteService';
import { useGetEquipmentsQuery } from 'modules/equipment/equipmentService';
import { useGetZonesQuery } from 'modules/area/areaService';
import { useGetEquipmenttypesQuery } from 'modules/equipment/equipmenttypeService';
import { useGetSensorsQuery } from 'modules/sensor/sensorService';
import { useGetMachinesQuery } from 'modules/machine/machineService';
import { useGetUsagesQuery } from 'modules/usage/usageService';

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Equipments from 'modules/equipment/components/Equipments';



const PreloadEquipments = (props) => {
    const { org } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const sensors = useGetSensorsQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const equipmenttypes = useGetEquipmenttypesQuery({ org: org.current }, { skip: !org.current });

    if (_.some([equipments.isError, equipmenttypes.isError, sites.isError, usages.isError, zones.isError, sensors.isError, machines.isError])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>equipments management</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

    else if (_.every([
        sites.isSuccess,
        usages.isSuccess,
        zones.isSuccess,
        sensors.isSuccess,
        equipments.isSuccess,
        equipmenttypes.isSuccess,
        machines.isSuccess
    ])) {
        return (
            <Equipments sites={sites.data} usages={usages.data} equipments={equipments.data} />
        );
    } else {
        return (
            <MessageDisplay
                message={i18n._(t`loading data`)}
                level="info"
                iconName="circle notched"
                isLoading={true}
            />
        );
    }


};

export default PreloadEquipments;