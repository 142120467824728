import { t } from "@lingui/macro";
import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";

export const siteApi = defaultApi.injectEndpoints({
    reducerPath: "apiSite",
    endpoints: (build) => ({
        getSites: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `sites?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                return _.map(response, (site) => {
                    return {
                        ...site,
                        key: site.id,
                        text: site.name,
                        value: site.id,
                        type: "site"
                    };
                });
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Sites", id: arg.org.id }];
                return [];
            }
        }),
        getSite: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return `sites/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "Site", id: arg.data }];
            }
        }),
        createSite: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `sites?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Sites", id: arg.org.id },
                        { type: "Zones", id: arg.org.id }
                    ];
                }
                return [];
            }
        }),
        updateSite: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `sites/${data.id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Sites", id: arg.org.id },
                        { type: "Site", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteSite: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, "name", null);
                const url = `sites/${data}?org=${current_org}${confirm ? "&confirm=yes" : ""}&${refreshTimestampUrlParam()}`;
                return {
                    url,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error && arg.confirm) {
                    return [
                        { type: "Sites", id: arg.org.id },
                        { type: "Site", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        // Stations
        getStations: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);

                return `stations?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                const stations = _.reduce(
                    response,
                    (result, station) => {
                        result.push({
                            ...station,
                            key: station.id,
                            text: `${station.name}-${station.administrative_area} (${station.accuweather_id})`,
                            value: station.id,
                            type: "station"
                        });
                        return result;
                    },
                    []
                );

                return [{ key: 99999, value: -1, text: t`No station` }, ...stations];
            },
            providesTags: ["Station"]
        })
    }),
    overrideExisting: false
});

export const { useGetSitesQuery, useGetSiteQuery, useCreateSiteMutation, useUpdateSiteMutation, useDeleteSiteMutation, useGetStationsQuery } =
    siteApi;
