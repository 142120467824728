import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    filter: {
        searchName: ""
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        stickPage: false
    },
    activePanes: 0 // 0: file, 1: api ==> needed for good routing after create or update
};

const apiImportSlice = createSlice({
    name: "apiImports",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.apiImports = initialState.imports;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        setPanes: (state, action) => {
            state.activePanes = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const { setSearchNameFilter, setPage, setItemsPerPage, setStickPage, resetFilterWithPage, setPanes } = apiImportSlice.actions;

export default apiImportSlice.reducer;
