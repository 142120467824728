import React from "react";
import { Trans } from "@lingui/macro";
import { Menu, Tab, Icon } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import ImportsSource from "./ImportsSource";
import ApiImports from "./ApiImports";
import { setPanes } from "../apiImportSlice";

const TabMenu = (props) => {
    const dispatch = useDispatch();
    const { activePanes } = useSelector((state) => state.apiImports);

    const handleTabChange = (index) => {
        dispatch(setPanes(index));
    };

    const panes = [
        {
            menuItem: (_, menuProps) => {
                return (
                    <Menu.Item key="0" {...menuProps} onClick={() => handleTabChange(0)}>
                        <Icon style={{ marginRight: ".5rem" }} name="file alternate outline" />
                        <Trans>From files</Trans>
                    </Menu.Item>
                );
            },
            render: () => {
                return (
                    <Tab.Pane>
                        <ImportsSource {...props} />
                    </Tab.Pane>
                );
            }
        },
        {
            menuItem: (_, menuProps) => {
                return (
                    <Menu.Item key="1" {...menuProps} onClick={() => handleTabChange(1)}>
                        <Icon style={{ marginRight: ".5rem" }} name="code" />
                        <Trans>From Api</Trans>
                    </Menu.Item>
                );
            },
            render: () => {
                return (
                    <Tab.Pane>
                        <ApiImports {...props} />
                    </Tab.Pane>
                );
            }
        }
    ];

    return <Tab activeIndex={activePanes} style={{ marginTop: "1rem" }} panes={panes} />;
};

export default TabMenu;
