import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    step: 0,
    kinematic: {
        supply: null,
        motor: null,
        motor_bearings: null,
        transmission: null,
        additonnal_bearings: null,
        pump: null
    }
};

const kinematicSlice = createSlice({
    name: "kinematic",
    initialState,
    reducers: {
        resetKinematicForm: (state, action) => {
            return initialState;
        },
        previousStep: (state, action) => {
            state.step = state.step - 1;
        },
        nextStep: (state, action) => {
            state.step = state.step + 1;
        },
        setSupply: (state, action) => {
            state.kinematic.supply = action.payload;
        },
        setMotorInfo: (state, action) => {
            state.kinematic.motor = action.payload.motor;
            state.kinematic.motor_bearings = action.payload.motor_bearings;
        },
        setTransmission: (state, action) => {
            state.kinematic.transmission = action.payload;
        },
        setPumpInfo: (state, action) => {
            state.kinematic.pump = action.payload;
        },
        setAdditionnalBearings: (state, action) => {
            state.kinematic.additonnal_bearings = action.payload.additonnal_bearings;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const { resetKinematicForm, previousStep, nextStep, setSupply, setMotorInfo, setTransmission, setAdditionnalBearings, setPumpInfo } =
    kinematicSlice.actions;

export default kinematicSlice.reducer;
