import React from "react";
import { Divider, Grid, Segment } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { Field } from "react-final-form";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";

import { InputAdapter, ToggleAdapter } from "modules/common/components/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

export const ElecDistribution = () => {
    return (
        <>
            <Divider horizontal>
                <Trans>elec distribution</Trans>
            </Divider>
            <Grid verticalAlign="top">
                <Grid.Column width={16}>
                    <Field
                        name="configuration.i_moy_max_threshold"
                        placeholder={i18n._(t`i_moy_max_threshold placeholder`)}
                        label={i18n._(t`i_moy_max_threshold`)}
                        unit={"A"}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        helperText={
                            <Trans>
                                <p>
                                    Le système peut estimer l’état thermique des cables de puissance en se basant sur les informations du type de
                                    cable et de la section des cables.
                                </p>
                                <p>
                                    Ainsi, si un courant excessif circule pendant une durée trop importante, entrainant un échauffement excessif, le
                                    système sera en mesure de le détecter.
                                </p>
                            </Trans>
                        }
                        defaultValue={100}
                        validate={(value) => {
                            return validateNumber(value, i18n, false, false);
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name="configuration.imbalance_threshold"
                        placeholder={i18n._(t`voltage_imbalance_threshold placeholder`)}
                        label={i18n._(t`voltage_imbalance_threshold`)}
                        unit={"%"}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        helperText={i18n._(t`voltage_imbalance_helper`)}
                        defaultValue={2}
                        validate={(value) => {
                            return validateNumber(value, i18n, false, false);
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name="configuration.elapsed_hour"
                        placeholder={i18n._(t`voltage_elapsed_hour placeholder`)}
                        label={i18n._(t`voltage_imbalance_duration`)}
                        unit={"h"}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        defaultValue={3}
                        validate={(value) => {
                            const elapsedValidate = validateNumber(value, i18n, false, false);
                            if (elapsedValidate !== undefined) {
                                return elapsedValidate;
                            }
                            if (reparseNumber(value) < 1) {
                                return <Trans>alert time minimum 1h</Trans>;
                            }
                            return undefined;
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={5} computer={5}>
                    <Segment.Group horizontal style={{ boxShadow: "None", border: "None" }}>
                        <Segment basic>
                            <Field
                                name="configuration.auto_sag"
                                label={i18n._(t`auto_sag`)}
                                labelposition="right"
                                component={ToggleAdapter}
                                defaultValue={true}
                                toggle={false}
                            />
                        </Segment>
                        <Segment basic>
                            <Field
                                name="configuration.auto_swell"
                                label={i18n._(t`auto_swell`)}
                                labelposition="right"
                                component={ToggleAdapter}
                                defaultValue={true}
                                toggle={false}
                            />
                        </Segment>
                    </Segment.Group>
                </Grid.Column>
            </Grid>
        </>
    );
};

export default React.memo(ElecDistribution);
