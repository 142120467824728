import React from "react";
import { Field } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { daysOptions } from "modules/export/utils";

import { Icon, Grid, Header, Segment } from "semantic-ui-react";

import { InputAdapter, DropDownAdapter } from "modules/common/components/form";

const ConfigApiFrequencyForm = (props) => {
    const { form, values, api_frequency_list, can_change } = props;
    return (
        <>
            <Header style={{ marginTop: "1rem" }} as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                <Trans>Api transfer schedule</Trans>
            </Header>
            <Segment attached>
                <Grid stackable verticalAlign="top">
                    <Grid.Column width={16}>
                        <Field
                            helperText={i18n._(t`Allows you to define how often the data is transferred to the server`)}
                            label={i18n._(t`frequency`)}
                            component={DropDownAdapter}
                            name="frequency"
                            placeholder={i18n._(t`select a frequency`)}
                            search={false}
                            compact
                            fluid
                            selection
                            options={api_frequency_list}
                            isRequired={true}
                            disabled={!can_change}
                            customAction={(value) => {
                                if (value === 0) {
                                    form.change("dayofmonth", null);
                                    form.change("dayofweek", null);
                                }
                                if (value === 1) {
                                    form.change("dayofmonth", null);
                                }
                                if (value === 2) {
                                    form.change("dayofweek", null);
                                }
                                if (value === 3) {
                                    form.change("dayofmonth", null);
                                    form.change("hour", null);
                                    form.change("dayofweek", null);
                                }
                                if (value === 4) {
                                    form.change("dayofmonth", null);
                                    form.change("dayofweek", null);
                                }
                            }}
                        />
                    </Grid.Column>
                    {values.frequency !== 3 && (
                        <Grid.Column width={16}>
                            <Field
                                name="hour"
                                placeholder={i18n._(t`Put hour value here`)}
                                label={`${values.frequency === 4 ? i18n._(t`hour (2nd reading 12 hours later)`) : i18n._(t`hour`)} (UTC)`}
                                isRequired={true}
                                parse={identityNull}
                                inputMode="numeric"
                                component={InputAdapter}
                                unitposition="right"
                                unit="h"
                                unitcolor="secondary"
                                disabled={!can_change}
                                validate={(value) => {
                                    const hourValidate = validateNumber(value, i18n, false, false, true);
                                    if (hourValidate !== undefined) {
                                        return hourValidate;
                                    }
                                    const hour = reparseNumber(value);
                                    if (hour > 23) {
                                        return <Trans>Value must be between 0 to 23</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                    <Grid.Column width={16}>
                        <Field
                            name="minute"
                            placeholder={i18n._(t`Put minute value here`)}
                            label={i18n._(t`Minute`)}
                            isRequired={true}
                            parse={identityNull}
                            inputMode="numeric"
                            component={InputAdapter}
                            unitposition="right"
                            unit="min"
                            unitcolor="secondary"
                            disabled={!can_change}
                            validate={(value) => {
                                const minuteValidate = validateNumber(value, i18n, false, false, true);
                                if (minuteValidate !== undefined) {
                                    return minuteValidate;
                                }
                                const min = reparseNumber(value);
                                if (min > 59) {
                                    return <Trans>Value must be between 0 to 59</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    {values.frequency === 2 && (
                        <Grid.Column width={16}>
                            <Field
                                name="dayofmonth"
                                placeholder={i18n._(t`dayofmonth`)}
                                label={i18n._(t`dayofmonth`)}
                                isRequired={true}
                                parse={identityNull}
                                inputMode="numeric"
                                component={InputAdapter}
                                unitposition="right"
                                unit={<Icon name="calendar alternate" />}
                                unitcolor="secondary"
                                disabled={!can_change}
                                validate={(value) => {
                                    const monthValidate = validateNumber(value, i18n, false, false, true);
                                    if (monthValidate !== undefined) {
                                        return monthValidate;
                                    }
                                    const month = reparseNumber(value);
                                    if (month < 1 || month > 31) {
                                        return <Trans>Value must be between 1 to 31</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                    {values.frequency === 1 && (
                        <Grid.Column width={16}>
                            <Field
                                name="dayofweek"
                                label={i18n._(t`dayofweek`)}
                                placeholder={i18n._(t`choose the day of the week`)}
                                options={daysOptions}
                                component={DropDownAdapter}
                                disabled={!can_change}
                                validate={(value) => {
                                    const weekValidate = validateNumber(value, i18n, false, false, true);
                                    if (weekValidate !== undefined) {
                                        return weekValidate;
                                    }
                                    const week = reparseNumber(value);
                                    if (week < 0 || week > 6) {
                                        return <Trans>Value must be between 0 to 6</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                    <Grid.Column width={16}>
                        <Field
                            name="depth"
                            placeholder={i18n._(t`Depth (hour) placeholder`)}
                            helperText={i18n._(t`Historical depth of data to retrieve from the triggering of the import task`)}
                            label={i18n._(t`Depth (hour)`)}
                            isRequired={true}
                            parse={identityNull}
                            inputMode="numeric"
                            component={InputAdapter}
                            unitposition="right"
                            unit="h"
                            unitcolor="secondary"
                            disabled={!can_change}
                            validate={(value) => {
                                const depthValidate = validateNumber(value, i18n, false, false, true);
                                if (depthValidate !== undefined) {
                                    return depthValidate;
                                }
                                const depthValue = reparseNumber(value);
                                if (depthValue > 168) {
                                    return <Trans>Depth Value must be between 0 to 168 hours</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                </Grid>
            </Segment>
        </>
    );
};

export default ConfigApiFrequencyForm;
