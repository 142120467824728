import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import Val24h from "./widgets/Val24h";
import LastVal from "./widgets/LastVal";
import Val7Day from "./widgets/Val7Day";
import UsagePercent from "./widgets/UsagePercent";
import DigitalInput from "./widgets/DigitalInput";
import MinVal from "./widgets/MinVal";
import MaxVal from "./widgets/MaxVal";

const DashboardPower = (props) => {
    const { equipment, measurements } = props;
    const org = useSelector((state) => state.org);
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const { measurements_with_mt_type, has_e_act_counter, digital_measure } = useMemo(() => {
        return (
            _.chain(measurements)
                .reduce(
                    (res, measure) => {
                        const mt_type = _.find(measurementtypes.data, { id: measure.measurementtype });
                        const mt_type_tech = _.find(measurementtypes.data, { id: measure.measurementtype_tech });
                        if (mt_type_tech?.name === "e_act_counter") res.has_e_act_counter = true;
                        if (mt_type_tech?.name === "di")
                            res.digital_measure = { ...measure, measurementtype: mt_type, measurementtype_tech: mt_type_tech };
                        res.measurements_with_mt_type.push({ ...measure, measurementtype: mt_type, measurementtype_tech: mt_type_tech });
                        return res;
                    },
                    { measurements_with_mt_type: [], has_e_act_counter: false, digital_measure: null }
                )
                /*.thru((value) => {
                return { ...value, measurements_with_mt_type: _.orderBy(value.measurements_with_mt_type, "measurementtype.name", "desc") };
            })*/
                .value()
        );
    }, [measurements, measurementtypes]);

    if (digital_measure) {
        return (
            <Grid.Row>
                <DigitalInput measure={digital_measure} />
            </Grid.Row>
        );
    }

    //Define criteria to exclude p_act_import if e_act_counter exists (PE6 case expert VS no expert)
    const has_measure_counter = _.size(measurements_with_mt_type) >= 2 && has_e_act_counter;

    return (
        <Grid.Row>
            {_.map(measurements_with_mt_type, (measure, idx) => {
                // Prevent display more than 4 widgets in dashboard
                if (idx >= 3) return null;
                //Don't display non expert if expert exists
                if (has_measure_counter && measure?.measurementtype_tech?.name === "p_act_import") return null;
                return (
                    <React.Fragment key={idx}>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <LastVal measure={measure} />
                        </Grid.Column>
                        {/* Criteria to display 3 instant gauge + 1 graphic */}
                        {(_.size(measurements_with_mt_type) < 3 || idx === 0) && (
                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                <Val24h equipment={equipment} measure={measure} />
                            </Grid.Column>
                        )}
                        {measure?.measurementtype?.datapoint_type === 3 && (
                            <>
                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                    <Val7Day measure={measure} />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                    <UsagePercent measure={measure} />
                                </Grid.Column>
                            </>
                        )}
                        {/* If only one measurment display Min and Max panel */}
                        {_.size(measurements_with_mt_type) === 1 && measure?.measurementtype?.datapoint_type !== 3 && (
                            <>
                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                    {<MinVal measure={measure} />}
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                    {<MaxVal measure={measure} />}
                                </Grid.Column>
                            </>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid.Row>
    );
};

export default DashboardPower;
