import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { refreshTimestampUrlParam } from "apis/PowerAdapt";
import cookie from "react-cookies";
import _ from "lodash";
import { remapForReactFlow } from "./utils";
import { defaultLng } from "modules/i18n/i18nConfig";

export const hierarchyApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_hierarchy",
    keepUnusedDataFor: 600,
    tagTypes: ["Diagrams", "DiagramNodes"],
    endpoints: (build) => ({
        getDiagrams: build.query({
            query: (data) => {
                const current_org = _.get(data, "org.name", "");
                return `diagrams?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Diagrams", id: arg.org.id }];
                return [];
            },
            transformResponse: (response) => {
                const diagrams_wrap = _.chain(response)
                    .map((diagram) => {
                        return { ...diagram, key: diagram.id, value: diagram.id, text: diagram.name };
                    })
                    .orderBy("name");
                return diagrams_wrap.value();
            }
        }),
        createDiagram: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `diagrams?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Diagrams", id: arg.org.id }];
                }
                return [];
            }
        }),
        updateDiagram: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `diagrams/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Diagrams", id: arg.org.id }];
                }
                return [];
            }
        }),
        patchDiagram: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `diagrams/${data.id}?org=${current_org}`,
                    method: "PATCH",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Diagrams", id: arg.org.id }];
                }
                return [];
            }
        }),
        deleteDiagram: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `diagrams/${data.id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Diagrams", id: arg.org.id }];
                }
                return [];
            }
        }),
        getNodesFromDiagram: build.query({
            keepUnusedDataFor: 0,
            query: ({ org, diagram_id, start, end }) => {
                const current_org = _.get(org, "name", "");
                return `diagrams/${diagram_id}/nodes?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "DiagramNodes", id: arg.diagram_id }];
                return [];
            },
            transformResponse: (response, meta, arg) => {
                const nodes_edges = _.reduce(
                    response,
                    (res, node) => {
                        const { node: remapNode, edge } = remapForReactFlow(node);
                        if (edge) {
                            res.edges.push(edge);
                        }
                        res.nodes.push(remapNode);

                        return res;
                    },
                    { nodes: [], edges: [] }
                );
                return nodes_edges;
            }
        }),
        addNode: build.mutation({
            query: ({ org, diagram_id, start, end, data }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `diagrams/${diagram_id}/add_node?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            }
        }),
        updateNode: build.mutation({
            query: ({ diagram_id, node_id, data, org, start, end }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `diagrams/${diagram_id}/update_node?org=${current_org}&node=${node_id}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            }
        }),
        patchNode: build.mutation({
            query: ({ node_id, data, org }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `nodes/${node_id}?org=${current_org}`,
                    method: "PATCH",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            }
        }),
        deleteNode: build.mutation({
            query: ({ diagram_id, org, start, end, node_id }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `diagrams/${diagram_id}/delete_node?org=${current_org}&node=${node_id}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            }
        })
    })
});

export const {
    useGetDiagramsQuery,
    useCreateDiagramMutation,
    useUpdateDiagramMutation,
    usePatchDiagramMutation,
    useDeleteDiagramMutation,
    useGetNodesFromDiagramQuery,
    useAddNodeMutation,
    usePatchNodeMutation,
    useUpdateNodeMutation,
    useDeleteNodeMutation
} = hierarchyApi;
