import { t } from "@lingui/macro";
import _ from "lodash";

export const mt_type_blacklist = [
    "battery",
    "battery_status",
    "current_imbalance",
    "cycles",
    "debit_nm3",
    "debit_tv",
    "e_react+_counter",
    "e_react-_counter",
    "i0_ratio",
    "i_moy_max",
    "i_moy_max_ph1",
    "i_moy_max_ph2",
    "i_moy_max_ph3",
    "i_peak",
    "operating_hours",
    "p_act",
    "p_react",
    "p_react+",
    "p_react-",
    "p_react_import",
    "p_react_import+",
    "p_react_import-",
    "power_imbalance",
    "pulse",
    "rotation_speed",
    "sag",
    "sag_ph1",
    "sag_ph2",
    "sag_ph3",
    "swell",
    "swell_ph1",
    "swell_ph2",
    "swell_ph3",
    "thd",
    "thd_ph1",
    "thd_ph2",
    "thd_ph3",
    "trip",
    "voltage_detected",
    "voltage_imbalance",
    "water"
];

export const sort_list = [
    { key: 8, text: t`Alphabetical`, value: 8 },
    { key: 1, text: t`Alerts (health scores)`, value: 1 },
    { key: 2, text: t`Last value`, value: 2 },
    { key: 3, text: t`Index`, value: 3 },
    { key: 4, text: t`Conso 24h`, value: 4 },
    { key: 5, text: t`Cost 24h`, value: 5 },
    { key: 6, text: t`Emissions 24h`, value: 6 },
    { key: 7, text: t`Last sensor activity`, value: 7 }
];

export const sortingType = {
    ALPHABETICAL: 8,
    ALERTHEALTHSCORE: 1,
    LASTVALUE: 2,
    INDEX: 3,
    CONSO24H: 4,
    COST24H: 5,
    EMISSIONS24H: 6,
    LASTACTIVITYSENSOR: 7
};

export const sortingUnitsForType = {
    // <sorting_type>: []<units>
    2: [5, 8, 11, 42, 6, 7, 18, 16, 48, 666],
    3: [11, 16, 42, 48],
    4: [11, 16, 42, 48]
};

export const categories_with_units = [
    { category: 2, units: [5, 8, 11, 42, 6, 7, 18, 16, 48, 666] },
    { category: 3, units: [11, 16, 42, 48] },
    { category: 4, units: [11, 16, 42, 48] }
];

export const processFilter = (equipments, filter) => {
    return _.chain(equipments)
        .reduce((res, eqt) => {
            /* name filter */
            if (filter.searchName === "") {
                res.push(eqt);
            } else if (_.includes(eqt.name.toLowerCase(), filter.searchName.toLowerCase())) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* site filter */
            if (_.size(filter.sites) === 0) {
                res.push(eqt);
            } else if (_.includes(filter.sites, eqt.site)) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* zone filter */
            if (_.size(filter.zones) === 0) {
                res.push(eqt);
            } else if (_.includes(filter.zones, eqt.zone)) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* usage filter */
            if (_.size(filter.usages) === 0) {
                res.push(eqt);
            } else if (_.includes(filter.usages, eqt.usage)) {
                res.push(eqt);
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            if (_.includes(eqt?.dataflowspec_set ?? [], 8)) {
                //HARDCODED PULSE EXCLUSION
                return res;
            }
            /* category filter */
            if (_.size(filter.categories) === 0) {
                res.push(eqt);
                return res;
            }
            const same_categories = _.intersection(eqt?.dataflowspec_set ?? [], filter.categories);
            if (_.size(same_categories) > 0) {
                res.push(eqt);
                return res;
            }
            return res;
        }, [])
        .reduce((res, eqt) => {
            /* Tags filter */
            if (_.size(filter.tags) === 0) {
                res.push(eqt);
                return res;
            }
            const same_tags = _.intersection(eqt?.tag_set ?? [], filter.tags);
            if (_.size(same_tags) > 0) {
                res.push(eqt);
                return res;
            }
            return res;
        }, [])
        .value();
};

export const equipmentsMatcher = (equipments_list, checked_equipments_ids) => {
    if (_.size(checked_equipments_ids) === 0) return true;
    const first_equipment = _.find(equipments_list, { id: _.head(checked_equipments_ids) });
    if (first_equipment === undefined) return false;
    //extract categories of first equipment
    const categories_of_first = first_equipment?.dataflowspec_set ?? [];
    if (_.size(categories_of_first) === 0) return false; // No categories => error ?

    const all_matching = _.every(checked_equipments_ids, (equipment_id) => {
        const equipment = _.find(equipments_list, { id: equipment_id });
        if (equipment === undefined) return false;
        const categories_of_equipment = equipment?.dataflowspec_set ?? [];
        if (_.size(categories_of_equipment) === 0) return false;
        return _.chain(categories_of_first).intersection(categories_of_equipment).size().value() > 0;
    });
    return all_matching;
};
