import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Dropdown, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useViewport } from "reactflow";

const NodeContent = (props) => {
    const { zoom } = useViewport();

    const { id, selected, dataWithPosition, unit, disabled_diagram, setOpenModalAdd, setOpenModalUpdate, setOpenModalDelete } = props;
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        if (selected === false) {
            setOpenMenu(false);
        }
    }, [selected]);

    const onHandleClickMenu = useCallback(
        (event) => {
            event.preventDefault();
            if (selected) setOpenMenu(true);
        },
        [selected]
    );

    useEffect(() => {
        const container = document.querySelector(`#node-container-${id}`);
        container.addEventListener("contextmenu", onHandleClickMenu);

        return () => {
            container.removeEventListener("contextmenu", onHandleClickMenu);
        };
    }, [onHandleClickMenu, id]);

    const dropdownStyle = {
        transform: `scale(${(1 / zoom) * 2})`, // Ajustez la taille en fonction du niveau de zoom
        transformOrigin: "top left" // Assurez-vous que le dropdown est correctement positionné
    };

    return (
        <>
            <div style={{ flex: 1 }}>
                <h5>
                    {dataWithPosition.node_db.equipment === null && dataWithPosition.node_db.name}
                    {dataWithPosition.node_db.equipment !== null && (
                        <div>{dataWithPosition.node_db.name || dataWithPosition.node_db.equipment_name}</div>
                    )}
                </h5>
                {!disabled_diagram && openMenu && (
                    <Dropdown
                        item
                        icon={null}
                        open={true}
                        onClick={() =>
                            setOpenMenu((menu) => {
                                return !menu;
                            })
                        }
                        className="pwaNodeContextMenuDropdown"
                        style={dropdownStyle}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                className="pwaNodeContextMenuDropdownItem"
                                onClick={() => {
                                    setOpenModalAdd(true);
                                }}
                            >
                                <Icon name="add" />
                                <Trans>Add node</Trans>
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="pwaNodeContextMenuDropdownItem"
                                onClick={() => {
                                    setOpenModalUpdate(true);
                                }}
                            >
                                <Icon name="edit" />
                                <Trans>Edit node</Trans>
                            </Dropdown.Item>
                            <Dropdown.Item
                                className="pwaNodeContextMenuDropdownItem"
                                onClick={() => {
                                    setOpenModalDelete(true);
                                }}
                            >
                                <Icon name="trash" />
                                <Trans>Delete node</Trans>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            <div style={{ flex: 1, display: "flex", fontStyle: "italic", color: "rgba(0,0,0,.5)" }}>
                {_.isFinite(dataWithPosition.node_db.consumption) && (
                    <>
                        {_.isFinite(dataWithPosition.node_db.percentage) && dataWithPosition.node_db.consumption !== 0 && (
                            <div style={{ flex: 1, textAlign: "right" }}>
                                <b>{`(${i18n.number(dataWithPosition.node_db.percentage, {
                                    maximumFractionDigits: 1
                                })} %)`}</b>
                            </div>
                        )}
                        <div style={{ flex: 3, textAlign: "right" }}>{`${i18n.number(dataWithPosition.node_db.consumption, {
                            maximumFractionDigits: 2
                        })} ${unit}`}</div>
                    </>
                )}
            </div>
        </>
    );
};

export default NodeContent;
