import React, { useEffect, useMemo } from 'react';
import _ from "lodash";
import { toast } from 'react-toastify';
import { t, Trans } from '@lingui/macro';
import { Form } from 'react-final-form';
import { Icon, Button, Modal, Divider, Segment } from 'semantic-ui-react';

import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import { useDeleteNodeMutation } from 'modules/hierarchy/hierarchyService';
import { NodeActionType, remapForReactFlow } from 'modules/hierarchy/utils';

const DeleteNode = (props) => {
    const { openModal, setOpenModal, i18n, data } = props;

    const { org, rangeTime: { start, end } } = data.extra;
    const { onNodeAction } = data.actions;
    const current_node = data.node_db;

    const disabled = false;

    const [removeNode, remove] = useDeleteNodeMutation();

    useEffect(() => {
        if (remove.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (remove.isSuccess) {
            toast.success(i18n._(t`successful remove node`), toast_options);
            setOpenModal(false);
        }
        if (remove.isError) {
            toast.error(i18n._(t`cannot remove node`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remove]);

    const onSubmitForm = async (formData) => {
        const action = await removeNode({
            org,
            node_id: formData.id,
            diagram_id: current_node.diagram,
            start: start.format("YYYY-MM-DD"),
            end: end.format("YYYY-MM-DD")
        });
        const error = _.get(action, 'error', null);
        if (!error) {
            const { deleted_nodes, updated_nodes } = action.data;

            const nodes_edges_del = _.reduce(deleted_nodes, (res, node) => {
                const { node: remapNode, edge } = remapForReactFlow(node);
                if (edge) {
                    res.edges.push(edge);
                }
                res.nodes.push(remapNode);

                return res;
            }, { nodes: [], edges: [] });
            const nodes_edges_upt = _.reduce(updated_nodes, (res, node) => {
                const { node: remapNode, edge } = remapForReactFlow(node);
                if (edge) {
                    res.edges.push(edge);
                }
                res.nodes.push(remapNode);

                return res;
            }, { nodes: [], edges: [] });
            await setOpenModal(false);
            onNodeAction && await onNodeAction(nodes_edges_upt, NodeActionType.Update, data.extra);
            onNodeAction && await onNodeAction(nodes_edges_del, NodeActionType.Delete, data.extra);


        }
    };

    const initialValues = useMemo(() => {
        return current_node;
    }, [current_node]);

    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, submitting, pristine, invalid, form, values }) => {
                return (
                    <Modal open={openModal}
                        centered={false}
                        closeOnDimmerClick
                        closeOnEscape
                        onClose={(e) => {
                            setOpenModal(false);
                        }}
                    >
                        <Modal.Header>
                            <Trans>Delete node</Trans>
                        </Modal.Header>
                        <Modal.Content>
                            <Trans>want delete node {initialValues.name}</Trans>
                            <Modal.Actions>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Divider />
                                    <Segment attached basic textAlign='right'>
                                        <Button
                                            type="button"
                                            negative
                                            icon
                                            labelPosition="left"
                                            onClick={(e) => {
                                                setOpenModal(false);
                                            }}
                                        >
                                            <Icon name="arrow left" />
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {!disabled && (
                                            <Button
                                                positive
                                                icon
                                                labelPosition="right"
                                                type="submit"
                                            >
                                                <Icon name="trash" inverted />
                                                <Trans>validate</Trans>
                                            </Button>
                                        )}
                                    </Segment>
                                </form>
                            </Modal.Actions>
                        </Modal.Content>
                    </Modal>

                );
            }} />
    );
};

export default React.memo(DeleteNode);