import React from "react";
import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { Message } from "semantic-ui-react";

const Maintenance = () => {
    const { srv_status } = useSelector((state) => state.notification);

    switch (srv_status.db_status) {
        case "error":
            return (
                <Message error attached className="no-print">
                    <Trans>Server unreachable.Please try again later</Trans>
                </Message>
            );
        case "r":
            return (
                <Message warning attached className="no-print">
                    <Trans>Server maintenance in progress. Modifications are temporarily disabled</Trans>
                </Message>
            );
        default:
            return null;
    }
};

export default Maintenance;
