/**
 * ALL GENERAL TRANSLATION HERE + INIT CONFIG
 */
import _ from "lodash";
import { setupI18n } from "@lingui/core";
import catalogEn from "locales/en/messages.js";
import catalogFr from "locales/fr/messages.js";
import catalogDe from "locales/de/messages.js";
import catalogFi from "locales/fi/messages.js";
import catalogIt from "locales/it/messages.js";

import { t } from "@lingui/macro";

import { timeFormat, timeSecond, timeMinute, timeDay, timeHour, timeMonth, timeWeek, timeYear, timeFormatDefaultLocale as tfdl } from "d3";

const catalogs = {
    fr: catalogFr,
    en: catalogEn,
    de: catalogDe,
    it: catalogIt,
    fi: catalogFi
};

const i18n = setupI18n();
i18n.load(catalogs);

//Options for react-d3 graphic
export const options = {
    en: {
        dateTime: "%a %e %b %X %Y",
        date: "%d/%m/%Y",
        time: "%H:%M:%S",
        periods: ["AM", "PM"],
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        shortDays: ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."],
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        shortMonths: ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."]
    },
    de: {
        dateTime: "%a %e %b %X %Y",
        date: "%d/%m/%Y",
        time: "%H:%M:%S",
        periods: ["", ""],
        days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        shortDays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        shortMonths: ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
    },
    fi: {
        dateTime: "%a %e %b %X %Y",
        date: "%d/%m/%Y",
        time: "%H:%M:%S",
        periods: ["ap.", "ip."],
        days: ["sunnuntai", "maanantai", "tiistai", "keskiviikko", "torstai", "perjantai", "lauantai"],
        shortDays: ["su", "ma", "ti", "ke", "to", "pe", "la"],
        months: [
            "tammikuu",
            "helmikuu",
            "maaliskuu",
            "huhtikuu",
            "toukokuu",
            "kesäkuu",
            "heinäkuu",
            "elokuu",
            "syyskuu",
            "lokakuu",
            "marraskuu",
            "joulukuu"
        ],
        shortMonths: ["tammi", "helmi", "maalis", "huhti", "touko", "kesä", "heinä", "elo", "syys", "loka", "marras", "joulu"]
    },
    it: {
        dateTime: "%a %e %b %X %Y",
        date: "%d/%m/%Y",
        time: "%H:%M:%S",
        periods: ["", ""],
        days: ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"],
        shortDays: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
        months: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
        shortMonths: ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"]
    },
    fr: {
        dateTime: "%A, le %e %B %Y, %X",
        date: "%d/%m/%Y",
        time: "%H:%M:%S",
        periods: ["", ""],
        days: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        shortDays: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
        months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        shortMonths: ["Janv.", "Févr.", "Mars", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."]
    }
};

/**
 * Specific Rewrite react-vis tickformat for timeseries
 * See node_modules/react-vis/node_modules/d3-scale/src/time.js
 * See https://github.com/d3/d3-time-format/blob/master/README.md
 * See https://github.com/uber/react-vis/blob/master/docs/scales-and-data.md
 * @function multiI18nFormat
 * @param {object} date - date object
 * @param {string} lng - current language used
 */
export const multiI18nFormat = (date, lng) => {
    tfdl(options[lng]);
    const formatMillisecond = timeFormat(".%L"),
        formatSecond = timeFormat(":%S"),
        formatDay = timeFormat("%a %d"),
        formatWeek = timeFormat("%b %d"),
        formatMonth = timeFormat("%B"),
        formatYear = timeFormat("%Y");

    let formatMinute = timeFormat("%H:%M"),
        formatHour = timeFormat("%H:%M");

    /*switch (lng) {
        case "en":
            formatMinute = timeFormat("%I:%M");
            formatHour = timeFormat("%I:%M %p");
            break;
        default:
            break;
    }*/

    return (
        timeSecond(date) < date
            ? formatMillisecond
            : timeMinute(date) < date
            ? formatSecond
            : timeHour(date) < date
            ? formatMinute
            : timeDay(date) < date
            ? formatHour
            : timeMonth(date) < date
            ? timeWeek(date) < date
                ? formatDay
                : formatWeek
            : timeYear(date) < date
            ? formatMonth
            : formatYear
    )(date);
};

//write all django server text here + custom translation
//Used to generate occurences in .po file
export const serverAndcustomText = [
    t`elec`,
    t`co2`,
    t`gas`,
    t`hygrometry`,
    t`pm25`,
    t`temperature`,
    t`water`,
    t`pulse`,
    t`globalview_p_act_import`,
    t`globalview_water_import`,
    t`other`,
    t`industrial_process`,
    t`computer_and_server`,
    t`lift`,
    t`electrical_outlet`,
    t`HVAC`,
    t`lighting`,
    t`incorrect login or password`,
    t`Account locked: too many login attempts. Please try again later`,
    t`undefined`,
    t`no station found for site`,
    t`calories`,
    t`frigories`,
    t`No ImportedDataset found for this analysis.`,
    t`unable to add export`,
    t`unable to update export`,
    t`battery`,
    t`current`,
    t`cycles`,
    t`e_act_counter`,
    t`e_react+_counter`,
    t`e_react-_counter`,
    t`i0_ratio`,
    t`i_moy_max`,
    t`i_moy_max_ph1`,
    t`i_moy_max_ph2`,
    t`i_moy_max_ph3`,
    t`operating_hours`,
    t`p_act`,
    t`p_act_import`,
    t`p_react_import`,
    t`p_react_import+`,
    t`p_react_import-`,
    t`power_imbalance`,
    t`sag_ph1`,
    t`sag_ph2`,
    t`sag_ph3`,
    t`swell_ph1`,
    t`swell_ph2`,
    t`swell_ph3`,
    t`thd_ph1`,
    t`thd_ph2`,
    t`thd_ph3`,
    t`voltage`,
    t`voltage_imbalance`,
    t`water_import`,
    t`water_flow`,
    t`p_react+`,
    t`p_react-`,
    t`p_react`,
    t`min_day`,
    t`max_day`,
    t`di`,
    t`historic_data`,
    t`voltage_detected`,
    t`i_peak`,
    t`current_imbalance`,
    t`dende_bearing`,
    t`robot kinematic chain`,
    t`conveyor, treadmill`,
    t`winch, lift`,
    t`bridge crane`,
    t`marine propulsion`,
    t`traction for vehicle`,
    t`industrial saw, cutter`,
    t`loom`,
    t`machine tool`,
    t`cabling machine`,
    t`extruder, conditioning`,
    t`rolling mill`,
    t`centrifuge`,
    t`crusher`,
    t`blender, mixer, agitator`,
    t`rotodynamic compressor`,
    t`rotodynamic pump`,
    t`volumetric compressor`,
    t`centrifugal pump`,
    t`volumetric pump`,
    t`diesel genset`,
    t`wind generator`,
    t`gas turbine generator`,
    t`hydro turbine generator`,
    t`steam turbine generator`,
    t`transformer station`,
    t`LV switchboard`,
    t`canalis`,
    t`building main feed`,
    t`workshop main feed`,
    t`rotating machine`,
    t`other machine type`,
    t`yesterday`,
    t`last 24 hours`,
    t`last 48 hours`,
    t`24 hours`,
    t`48 hours`,
    t`72 hours`,
    t`analog`,
    t`solar_irradiance`,
    t`fan`,
    t`fans`,
    t`elec_predict`,
    t`alignment`,
    t`BRB`,
    t`stator`,
    t`health`,
    t`bearing`,
    t`motor`,
    t`shaft`,
    t`belt`,
    t`gearbox`,
    t`pump`,
    t`motor shaft`,
    t`sensor_alerts`,
    t`elec_distribution_alerts`,
    t`elec_dysfunction_alerts`,
    t`predict_score_alerts`,
    t`acquisitions`,
    t`datapoints`,
    t`de_bearing`,
    t`nde_bearing`,
    t`dende_bearing`,
    t`gearbox output`,
    t`belt output`,
    t`drive`,
    t`intermediate`,
    t`auto unit`,
    t`p_hydro`,
    t`intermediate 1`,
    t`intermediate 2`,
    t`intermediate 3`,
    t`bearing 3`,
    t`bearing 4`,
    t`bearing 5`,
    t`light_maintenance`,
    t`shaft_realignment`,
    t`shaft_replacement`,
    t`shaft_light_maintenance`,
    t`motor_replacement`,
    t`stator_rewiring`,
    t`motor_supply_change`,
    t`gearbox_maintenance`,
    t`gearbox_replacement`,
    t`bearing_light_maintenance`,
    t`bearing_replacement`,
    t`bearing_maintenance`,
    t`belt_maintenance`,
    t`belt_replacement`,
    t`drive_change`,
    t`machine_reassembly`,
    t`predict_replacement`,
    t`predict_reset`,
    t`A kinematics already exists for the machine, you must provide a machine_event to submit a kinematics modification`,
    t`motor shaft`,
    t`belt output`,
    t`An Equipment with the same external ID already exists.`,
    t`Data must belong to the same time zone`,
    t`Denominator measurement does not belong to site`,
    t`No ImportedDataset found.`,
    t`login`,
    t`This site has sensor(s)`,
    t`kinematic`,
    t`machines`,
    t`Machine must be a centrifugal pump`,
    t`Cannot delete a machine`,
    t`Equipment is not a rotating machine`,
    t`alerts_packs`,
    t`pwa_clients.AdvancedReport_mailinglist`,
    t`pwa_clients.ScheduleExport_mailinglist`,
    t`pwa_clients.AlertPack_mailinglist`,
    t`pwa_backend.Equipment`,
    t`pwa_clients.Usage`,
    t`pwa_clients.AnalysisSection_usages`,
    t`pwa_clients.EquipmentTag`,
    t`pwa_clients.Tag`,
    t`pwa_clients.AnalysisSection_tags`,
    t`pwa_clients.Zone`,
    t`pwa_backend.Measurement`,
    t`pwa_clients.Alert`,
    t`pwa_clients.ScheduleExport`,
    t`pwa_clients.EnergySavingActions`,
    t`pwa_backend.SynDataFlow`,
    t`pwa_clients.DiagramNode`,
    t`pwa_clients.MailingList`,
    t`pwa_clients.Diagram`,
    t`organization.Site`,
    t`pwa_clients.AdvancedAnalysis`,
    t`pwa_clients.AdvancedReport`,
    t`pwa_clients.ImportedDataset`,
    t`predict.MachineEvent`,
    t`pwa_clients.Widget_measurements`,
    t`pwa_clients.AnalysisSection`,
    t`pwa_clients.Widget`,
    t`mailing`,
    t`Incorrect token`,
    t`A Zone with equipments(s) cannot be deleted.`,
    t`The last zone of a site cannot be deleted`,
    t`Missing site in AnalysisSection.`,
    t`Currency must be the same for all sites`,
    t`C0002`,
    t`C0003`,
    t`B0001`,
    t`B0002`,
    t`C0503`,
    t`C0400`,
    t`C0504`,
    t`Cannot access this site`,
    t`Date`,
    t`file`,
    t`last 90 days`,
    t`healthscore`,
    t`debit_tv`,
    t`activitylogs`,
    t`avg`,
    t`importeddatasets`
];

export const defaultLng = _.includes(["fr", "fr-FR"], navigator.language) ? "fr" : "en";
export default i18n;
