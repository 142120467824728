import _ from "lodash";
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import axios from "axios";

import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { defaultLng } from "modules/i18n/i18nConfig";


const initialState = {
    status: 'idle',
    init_alertpacks: [],
    alertpacks: [],
    error: null,
    filter: {
        siteFilter: [],
        packTypeFilter: [],
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
    alertpack: {
        status: 'idle',
        error: null,
        current: null,
        default: {
            active: true,
            site: null,
            type: null,
            language: localStorage.getItem('lng', defaultLng),
            configuration: {},
            mailinglist: [],
        },
        override: {
            status: 'idle',
            overrides: [],
            error: null
        }
    }
};

/**
 * Retrieve all alerPacks from server for current organization
 * @function getAlertPacks
 */
export const getAlertPacks = createAsyncThunk('alert/getAlertPacks', async ({ org }, thunkAPI) => {
    const current_org = _.get(org, 'name', null);
    try {
        const response = await PowerAdaptAPI.get(`/alertpacks?org=${current_org}&${refreshTimestampUrlParam()}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve specific alertpack from server for current organization
 * @function getAlertPack
 */
export const getAlertPack = createAsyncThunk('alert/getAlertPack', async ({ org, id }, thunkAPI) => {
    const current_org = _.get(org, 'name', null);
    try {
        const response = await PowerAdaptAPI.get(`/alertpacks/${id}?org=${current_org}&${refreshTimestampUrlParam()}`);

        const { type } = response.data;
        if (type === 4) {
            //Convert duration 'minutes' => 'hours' for predict_alert
            return {
                ...response.data,
                configuration: {
                    ...response.data.configuration,
                    duration: response.data.configuration.duration /= 60
                }
            };
        }
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});


/**
 * Create an alertPack
 * @function createAlertPack
 */
export const createAlertPack = createAsyncThunk('alert/createAlertPack', async ({ formData }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, 'name', null);
    try {

        const response = await PowerAdaptAPI.post(`/alertpacks?org=${current_org}&${refreshTimestampUrlParam()}`, formData);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Update an alertPack
 * @function updateAlertPack
 */
export const updateAlertPack = createAsyncThunk('alert/updateAlertPack', async ({ formData }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, 'name', null);
    try {
        const response = await PowerAdaptAPI.put(`/alertpacks/${formData.id}?org=${current_org}&${refreshTimestampUrlParam()}`, formData);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve specific alertpackoverride from server for current organization
 * @function getAlertPackOverride
 */
export const getAlertPackOverride = createAsyncThunk('alert/getAlertPackOverride', async ({ org, id }, thunkAPI) => {
    const current_org = _.get(org, 'name', null);
    try {
        const response = await PowerAdaptAPI.get(`/alertpackoverrides?pack=${id}&org=${current_org}&${refreshTimestampUrlParam()}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve specific alertpackoverride from server for current organization
 * @function setAlertPackOverride
 */
export const setAlertPackOverride = createAsyncThunk('alert/setAlertPackOverride', async ({ formdata, pack_id, type }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, 'name', null);
    try {
        const response = await PowerAdaptAPI.post(`/alertpackoverrides?org=${current_org}&${refreshTimestampUrlParam()}`, { ...formdata, pack_id });
        return { data: response.data, type };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});


/**
 * Delete alertPack from server for current organization
 * @function deleteAlertPack
 */
export const deletePack = createAsyncThunk('alert/deleteAlertPack', async (id, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, 'name', null);
    try {
        /* eslint-disable no-unused-vars */
        const response = await PowerAdaptAPI.delete(`/alertpacks/${id}?org=${current_org}&${refreshTimestampUrlParam()}`);
        return id;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});



const alertPackSlice = createSlice({
    name: "alertpack",
    initialState,
    reducers: {
        setSiteFilter: (state, action) => {
            state.filter.siteFilter = action.payload;
        },
        setPackTypeFilter: (state, action) => {
            state.filter.packTypeFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            state.alertpacks = state.init_alertpacks;
            state.filter = initialState.filter;
            state.pagination = initialState.pagination;
        },
        resetCurrentAlertPack: (state, action) => {
            state.alertpack = initialState.alertpack;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAlertPacks.pending, (state, action) => {
                state.status = 'loading';
                state.alertpacks = [];
                state.init_alertpacks = [];
                state.error = null;
            })
            .addCase(getAlertPacks.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.alertpacks = action.payload;
                state.init_alertpacks = action.payload;
                state.error = null;
            })
            .addCase(getAlertPacks.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.alertpacks = [];
                state.init_alertpacks = [];
            })
            .addCase(getAlertPack.pending, (state, action) => {
                state.alertpack.status = 'loading';
                state.alertpack.current = null;
                state.alertpack.error = null;
            })
            .addCase(getAlertPack.fulfilled, (state, action) => {
                state.alertpack.status = 'succeeded';
                state.alertpack.current = action.payload;
                state.alertpack.error = null;
            })
            .addCase(getAlertPack.rejected, (state, action) => {
                state.alertpack.status = 'failed';
                state.alertpack.error = action.payload;
                state.alertpack.current = null;
            })
            .addCase(getAlertPackOverride.pending, (state, action) => {
                state.alertpack.override.status = 'loading';
                state.alertpack.override.overrides = [];
                state.alertpack.override.error = null;
            })
            .addCase(getAlertPackOverride.fulfilled, (state, action) => {
                state.alertpack.override.status = 'succeeded';
                state.alertpack.override.overrides = action.payload;
                state.alertpack.override.error = null;
            })
            .addCase(getAlertPackOverride.rejected, (state, action) => {
                state.alertpack.override.status = 'failed';
                state.alertpack.override.error = action.payload;
                state.alertpack.override.overrides = [];
            })
            .addCase(setAlertPackOverride.fulfilled, (state, action) => {
                const { data, type } = action.payload;
                state.alertpack.override.overrides = _.reduce(type, (res, item) => {
                    const record = _.get(data, item);
                    if (record) {
                        res.push(...record);
                    }
                    return res;
                }, []);
            })
            .addCase(deletePack.fulfilled, (state, action) => {
                state.init_alertpacks = _.filter(state.init_alertpacks, (item) => item.id !== action.payload);
                state.alertpacks = _.filter(state.alertpacks, (item) => item.id !== action.payload);
            })
            .addMatcher(isAnyOf(setSiteFilter, setPackTypeFilter), (state, action) => {
                const filtered_alertpack = _.chain(state.init_alertpacks)
                    .reduce((res, alertpack) => {
                        if (_.size(state.filter.siteFilter) === 0) {
                            res.push(alertpack);
                        } else if (
                            _.includes(state.filter.siteFilter, _.get(alertpack, 'site.id', null))
                        ) {
                            res.push(alertpack);
                        }
                        return res;
                    }, [])
                    .reduce((res, alertpack) => {
                        if (_.size(state.filter.packTypeFilter) === 0) {
                            res.push(alertpack);
                        } else if (
                            _.includes(state.filter.packTypeFilter, _.get(alertpack, 'type.id', null))
                        ) {
                            res.push(alertpack);
                        }
                        return res;
                    }, [])
                    .value();

                state.alertpacks = filtered_alertpack;
                if (!state.pagination.stickPage) {
                    state.pagination.page = 1;
                }
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setSiteFilter, setPackTypeFilter,
    setPage, setItemsPerPage, setStickPage, resetFilterWithPage,
    resetCurrentAlertPack
} = alertPackSlice.actions;

export default alertPackSlice.reducer;