import moment from "moment";
import jwt_decode from "jwt-decode";
import { login, logout } from "modules/auth/authSlice";
import PowerAdaptAPI, { defaultApi } from "apis/PowerAdapt";
import history_app from "history_app";
import { analysisAdvancedApi } from "modules/analysisAdvanced/analysisAdvancedService";
import { reportApi } from "modules/report/reportService";
import { orgApi } from "modules/organization/organizationService";
import { authApi } from "./authService";
import { hierarchyApi } from "modules/hierarchy/hierarchyService";
import { userApi } from "modules/user/userService";
import { energySavingApi } from "modules/energysaving/energySavingService";
import { dashboardDndApi } from "modules/dashboardDragNDrop/dashboardDndService";

export let tokenTimeout = null;

export const resetTokenTimeout = () => {
    tokenTimeout = null;
};

/**
 * Check expiracy of token each 30sec. If expired then auto-disconnect
 * @function checkToken
 * @param {Object} decoded_token - decoded JWT Token
 */
export const checkToken = (listenerApi, decoded_token) => {
    const currentTime = Math.floor(moment.now() / 1000);
    if (decoded_token.exp < currentTime) {
        //logout user if token expired
        clearTimeout(tokenTimeout);
        return listenerApi.dispatch(authApi.endpoints.logoutUser.initiate());
    }
    tokenTimeout = setTimeout(() => {
        checkToken(listenerApi, decoded_token);
    }, 1000 * 60 * 30);
};

/**
 * Used to add token in request Header;Set localstorage; check token expiracy
 * @function loginMiddleware
 */
export const authMiddleware = (store) => (next) => async (action) => {
    if (login.match(action)) {
        clearTimeout(tokenTimeout);
        tokenTimeout = null;
        const decoded_token = jwt_decode(action.payload);
        //First init check
        const currentTime = Math.floor(moment.now() / 1000);
        if (decoded_token.exp < currentTime) {
            //logout user if token expired
            return store.dispatch(authApi.endpoints.logoutUser.initiate());
        }
        PowerAdaptAPI.defaults.headers.common["Authorization"] = `Bearer ${action.payload}`;
        localStorage.setItem("accessToken", action.payload);
        checkToken(store, decoded_token);
    }
    if (authApi.endpoints.logoutUser.matchFulfilled(action) || authApi.endpoints.logoutUser.matchRejected(action)) {
        localStorage.removeItem("accessToken");
        delete PowerAdaptAPI.defaults.headers.common["Authorization"];
        clearTimeout(tokenTimeout);
        tokenTimeout = null;
        localStorage.removeItem("currentOrg");
        //Here we reset all API
        await store.dispatch(logout());
        await store.dispatch(orgApi.util.resetApiState());
        await store.dispatch(authApi.util.resetApiState());
        await store.dispatch(defaultApi.util.resetApiState());
        await store.dispatch(reportApi.util.resetApiState());
        await store.dispatch(analysisAdvancedApi.util.resetApiState());
        await store.dispatch(hierarchyApi.util.resetApiState());
        await store.dispatch(userApi.util.resetApiState());
        await store.dispatch(energySavingApi.util.resetApiState());
        await store.dispatch(dashboardDndApi.util.resetApiState());
        history_app.push("/login");
    }

    return next(action);
};
