import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Card, Grid, Icon, Label } from "semantic-ui-react";
import moment from "moment-timezone";

import i18n from "modules/i18n/i18nConfig";
import HeaderWHelper from "modules/common/components/HeaderWHelper";
import MessageDisplay from "modules/common/components/MessageDisplay";

const Voltagedetected = ({ measures_voltage_detected }) => {
    const current_lng = useSelector((state) => state.i18n.current);
    const { last_values } = useSelector((state) => state.overview);

    const last = _.chain(last_values.data)
        .reduce((res, item) => {
            const measurements = _.get(item, "measurements");
            if (measurements) {
                //get Voltage detected for first voltage_detected_measurement
                const data = _.chain(measurements).find({ id: _.chain(measures_voltage_detected).head().get('id').value() }).value();
                if (data) res = { ...data };
            }
            return res;
        }, {})
        .value();

    if (_.size(last) === 0) {
        return (<MessageDisplay level="warning" message={i18n._(t`unable to get voltage detected`)} iconName="warning circle" isLoading={false} />);
    }

    const intensive = _.get(last, "last_intensive");
    const date = _.get(last, "last_state_change");

    return (
        <>
            <Grid verticalAlign="middle" centered>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={10} computer={6}>
                        <Card centered color={intensive === 1 ? "green" : "red"} fluid>
                            <Card.Content>
                                <Icon name="bolt" color="yellow" className="right floated big" />
                                <Card.Header>
                                    <HeaderWHelper
                                        text={<Trans>voltage detected</Trans>}
                                        helper={<Trans>voltage detected helper</Trans>}
                                    />
                                </Card.Header>
                                <Card.Description>
                                    <Label color={intensive === 1 ? "green" : "red"}>
                                        {intensive === 1 ? i18n._(t`power on`) : i18n._(t`power off`)}
                                    </Label>
                                </Card.Description>
                            </Card.Content>
                            {date !== undefined && moment(date).isValid() && (
                                <Card.Content extra>
                                    <Card.Description>
                                        <Trans>your device has been{" "}<strong>{intensive === 1 ? i18n._(t`power on`) : i18n._(t`power off`)}</strong>{" "}since</Trans>
                                        {` ${moment(date).locale(current_lng).format("LLL")}`}
                                    </Card.Description>
                                </Card.Content>
                            )}
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
};

export default Voltagedetected;
