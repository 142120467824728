export const mt_type_elec_distri = [
    "voltage_detected",
    "voltage_imbalance",
    "sag",
    "sag_ph1",
    "sag_ph2",
    "sag_ph3",
    "swell",
    "swell_ph1",
    "swell_ph2",
    "swell_ph3",
    "trip",
    "i_moy_max",
    "i_moy_max_ph1",
    "i_moy_max_ph2",
    "i_moy_max_ph3"
];
export const mt_type_elec_state = ["thd", "thd_ph1", "thd_ph2", "thd_ph3", "current_imbalance", "i0_ratio", "power_imbalance", "i_peak"];

export const mt_type_elec_operationnal = ["p_act_import", "operating_hours", "cycles"];

export const mt_type_elec_efficiency = ["p_act_import", "p_react_import", "p_react_import+", "p_react_import-"];

export const mt_type_blacklist = ["battery", "battery_status"];
