import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { measurementtypeApi } from "./measurementtypeService";

const initialState = {
    measurementtypes: [],
};

const measurementTypeSlice = createSlice({
    name: "measurementtype",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(measurementtypeApi.endpoints.getMeasurementtypes.matchFulfilled, (state, action) => {
                state.measurementtypes = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default measurementTypeSlice.reducer;