import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Header } from "semantic-ui-react";

import { getDetail, getSummary, getConsumption, setTime, getTotalConsumption, resetTab } from "modules/overview/overviewSlice";
import { dateRangeOptions, getTimedelta } from "modules/time/utils";
import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import i18n from "modules/i18n/i18nConfig";
import GraphicDetail from "../graphic/GraphicDetail";
import GraphicSummary from "../graphic/GraphicSummary";
import GraphicConsumption from "../graphic/GraphicConsumption";
import EnergyHeaderText from "../EnergyHeaderText";
import { useGetStandbyThresholdQuery } from "modules/measurement/measurementService";

const TabEnergy = (props) => {
    const tab = "tabenergy";
    const { default_time } = props;
    const measures = _.orderBy(props.measures, "id");
    const main_measure = _.find(measures, (item) =>
        _.includes(["p_act_import", "water_import", "index_nm3", "index_tv"], _.get(item, "measurementtype.name"))
    );
    const dispatch = useDispatch();
    const { org } = useSelector((state) => state);
    const { tabenergy, powertime } = useSelector((state) => state.overview);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));
    const rangeTime = powertime.time
        ? {
              start: moment(powertime.time.start),
              end: moment(powertime.time.end),
              plage: powertime.time.plage
          }
        : default_time;

    const standby_threshold = _.get(main_measure, "full_df.standby_threshold");
    const standby_threshold_query = useGetStandbyThresholdQuery(
        {
            org: org.current,
            measure: main_measure,
            start: powertime.time ? powertime.time.start : default_time.start.toISOString(),
            end: powertime.time ? powertime.time.end : default_time.end.toISOString()
        },
        { skip: !_.isFinite(standby_threshold) || _.isUndefined(main_measure) }
    );

    useEffect(() => {
        (async () => {
            if (powertime.time) {
                const { start, end } = powertime.time;
                !_.isUndefined(main_measure) && dispatch(getDetail({ org: org.current, measures, start, end, tab, type: "detail" }));
                !_.isUndefined(main_measure) && dispatch(getTotalConsumption({ org: org.current, measure: main_measure, start, end, tab }));
                const new_range_time = getTimedelta(start, end);
                !_.isUndefined(main_measure) &&
                    dispatch(
                        getSummary({
                            org: org.current,
                            measure: main_measure,
                            start: new_range_time.start.format("YYYY-MM-DD"),
                            end: new_range_time.end.format("YYYY-MM-DD"),
                            tab
                        })
                    );
                !_.isUndefined(main_measure) &&
                    dispatch(
                        getConsumption({
                            org: org.current,
                            measure: main_measure,
                            start: new_range_time.start.format("YYYY-MM-DD"),
                            end: new_range_time.end.format("YYYY-MM-DD"),
                            tab
                        })
                    );
            } else {
                !_.isUndefined(main_measure) &&
                    dispatch(
                        getDetail({
                            org: org.current,
                            measures,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab,
                            type: "detail"
                        })
                    );
                !_.isUndefined(main_measure) &&
                    dispatch(
                        getTotalConsumption({
                            org: org.current,
                            measure: main_measure,
                            start: default_time.start.toISOString(),
                            end: default_time.end.toISOString(),
                            tab
                        })
                    );

                const new_range_time = getTimedelta(default_time.start.toISOString(), default_time.end.toISOString());
                !_.isUndefined(main_measure) &&
                    dispatch(
                        getSummary({
                            org: org.current,
                            measure: main_measure,
                            start: new_range_time.start.format("YYYY-MM-DD"),
                            end: new_range_time.end.format("YYYY-MM-DD"),
                            tab
                        })
                    );
                !_.isUndefined(main_measure) &&
                    dispatch(
                        getConsumption({
                            org: org.current,
                            measure: main_measure,
                            start: new_range_time.start.format("YYYY-MM-DD"),
                            end: new_range_time.end.format("YYYY-MM-DD"),
                            tab
                        })
                    );
            }
        })();
        return () => {
            dispatch(resetTab(tab));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powertime.time]);

    return (
        <Grid id="pwaMain" verticalAlign="top" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "powertime" }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center" className="pwaGraph">
                    <Header as="h3" block textAlign="center" attached="top">
                        {tabenergy.total_consumption.status !== "succeeded" && <Trans>detail</Trans>}
                        {tabenergy.total_consumption.status === "succeeded" && (
                            <EnergyHeaderText
                                total_consumption={tabenergy.total_consumption}
                                standby_threshold={standby_threshold_query.data}
                                measure={main_measure}
                            />
                        )}
                    </Header>
                    {_.isUndefined(main_measure) && (
                        <MessageDisplay message={i18n._(t`unknown measurement`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                    {tabenergy.detail.status === "loading" && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {tabenergy.detail.status === "failed" && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {tabenergy.detail.status === "succeeded" && (
                        <GraphicDetail
                            time={rangeTime}
                            data={tabenergy.detail.data}
                            csvName={_.get(props, "equipment.name", "export")}
                            threshold={_.isFinite(standby_threshold) ? standby_threshold : undefined}
                        />
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={7} computer={7} textAlign="center" className="pwaGraph">
                    <Header as="h3" block textAlign="center" attached="top">
                        <Trans>consumptions historic</Trans>
                    </Header>
                    {_.isUndefined(main_measure) && (
                        <MessageDisplay message={i18n._(t`unknown measurement`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                    {tabenergy.consumption.status === "loading" && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {tabenergy.consumption.status === "failed" && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {tabenergy.consumption.status === "succeeded" && (
                        <GraphicConsumption
                            time={rangeTime}
                            consumption={tabenergy.consumption}
                            csvName={_.get(props, "equipment.name", "export")}
                            measure={main_measure}
                        />
                    )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={7} computer={7} textAlign="center" className="pwaGraph">
                    <Header as="h3" block textAlign="center" attached="top">
                        <Trans>long term trend</Trans>
                    </Header>
                    {_.isUndefined(main_measure) && (
                        <MessageDisplay message={i18n._(t`unknown measurement`)} level="warning" iconName="warning circle" isLoading={false} />
                    )}
                    {tabenergy.summary.status === "loading" && (
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                    )}
                    {tabenergy.summary.status === "failed" && (
                        <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                    )}
                    {tabenergy.summary.status === "succeeded" && (
                        <GraphicSummary time={rangeTime} data={tabenergy.summary.data} csvName={_.get(props, "equipment.name", "export")} />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TabEnergy;
