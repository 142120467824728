import React, { useState } from "react";
import { useSelector } from "react-redux";
import { t } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";
import { Card, Grid, Segment } from "semantic-ui-react";
import { FlexibleXYPlot, HeatmapSeries, Hint, HorizontalGridLines, LineSeries, XAxis, YAxis } from "react-vis";

import i18n, { multiI18nFormat } from "modules/i18n/i18nConfig";

const GraphicOperatingMap = (props) => {
    const { clr_min, clr_max, type, time, threshold, data } = props;
    const current_lng = useSelector((state) => state.i18n.current);
    const [hintValue, setHintValue] = useState(null);
    const unit = type === "frequency" ? "Hz" : "A";
    const timezone = moment.tz.guess();

    const x_domain_min = 0;
    const x_domain_max = time.end.diff(time.start, "day");
    const y_domain_min = _.get(data, "min") || 0;
    const y_domain_max = _.get(data, "max") || threshold || 10;

    const tooltip = () => {
        return (
            <Hint value={hintValue}>
                <Card className="pwaOverviewTooltip">
                    <Card.Content>
                        <Card.Header textAlign="center">{moment.tz(hintValue.date, timezone).locale(current_lng).format("L")}</Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Grid centered verticalAlign="middle">
                            <Grid.Row>
                                <Grid.Column width={10} textAlign="left">
                                    <span style={{ color: "black" }}>{i18n._(type)}</span>
                                </Grid.Column>
                                <Grid.Column width={6} textAlign="right">
                                    <span style={{ color: "black" }}>
                                        {hintValue.y !== null ? `${i18n.number(hintValue.y, { maximumFractionDigits: 2 })} ${unit}` : "-"}
                                    </span>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={10} textAlign="left">
                                    <span style={{ color: "black" }}>{i18n._(t`quantity point`)}</span>
                                </Grid.Column>
                                <Grid.Column width={6} textAlign="right">
                                    <span style={{ color: "black" }}>{`${i18n.number(hintValue.color, { maximumFractionDigits: 2 })}`}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Card>
            </Hint>
        );
    };

    return (
        <>
            <Segment attached>
                <FlexibleXYPlot height={500} xDomain={[x_domain_min, x_domain_max]} yDomain={[y_domain_min, y_domain_max]}>
                    <HorizontalGridLines />
                    <XAxis
                        title={i18n._(t`time`)}
                        tickLabelAngle={-20}
                        tickFormat={(value, index, scale, tickTotal) => {
                            try {
                                const val = time.start.clone().add(value, "day").startOf("day").toDate();
                                return multiI18nFormat(val, current_lng);
                            } catch (error) {
                                return "";
                            }
                        }}
                    />
                    <HeatmapSeries
                        colorRange={["#FFFFFF00", clr_min, clr_max]}
                        colorDomain={[-1, 0, 288]}
                        style={{
                            stroke: "white",
                            strokeWidth: "0.5px"
                        }}
                        data={_.chain(data)
                            .get("data")
                            .reduce((res, item) => {
                                const date = moment(item[0]);
                                if (date.diff(time.start, "day") >= 0) {
                                    res.push({ x: date.diff(time.start, "day"), date: date.toISOString(), y: item[1], color: item[2] });
                                }
                                return res;
                            }, [])
                            .value()}
                        onValueMouseOver={(v) => {
                            if (v.color !== -1) setHintValue(v);
                        }}
                        onValueMouseOut={(v) => setHintValue(null)}
                    />
                    {threshold !== null && (
                        <LineSeries
                            style={{ zIndex: 100 }}
                            color={"green"}
                            data={[
                                { x: x_domain_min - 0.5, y: threshold },
                                { x: x_domain_max + 0.5, y: threshold }
                            ]}
                        />
                    )}
                    {hintValue ? tooltip() : null}
                    <YAxis
                        title={props.titleY}
                        tickFormat={(value, index, scale, tickTotal) => {
                            const format = scale.tickFormat(tickTotal)(value);
                            if (typeof value === "number") {
                                return `${i18n.number(value, { maximumFractionDigits: 1 })}`;
                            }
                            return format;
                        }}
                    />
                </FlexibleXYPlot>
            </Segment>
        </>
    );
};

export default GraphicOperatingMap;
