import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import _ from "lodash";

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { sortingType } from "./utils";

const initialState = {
    filter: {
        searchName: "",
        sites: [],
        zones: [],
        usages: [],
        tags: [],
        categories: []
    },
    sorting: {
        type: sortingType.ALPHABETICAL,
        unit: "",
        order: true
    },
    pagination: {
        page: 1,
        itemsPerPage: 10
    },
    filtered_equipments: [],
    selected_equipments: [],
    comparison_params: null
};

const dashboardSlice = createSlice({
    name: "dash",
    initialState,
    reducers: {
        setFilter: (state, action) => {
            const { field, value } = action.payload;
            if (field === "sites") {
                //reset zones filter when sites change
                state.filter.zones = [];
            }
            state.filter[field] = value;
            //Go to page 1 when filter change
            state.pagination.page = 1;
        },
        setSorting: (state, action) => {
            const { field, value } = action.payload;
            if (field === "type") {
                //Auto select first unit for specific type
                switch (value) {
                    case sortingType.LASTVALUE:
                        state.sorting.unit = "kWh";
                        break;
                    case sortingType.INDEX:
                        state.sorting.unit = "kWh";
                        break;
                    case sortingType.CONSO24H:
                        state.sorting.unit = "kWh";
                        break;
                    default:
                        state.sorting.unit = "";
                        break;
                }
            }
            state.sorting[field] = value;
        },
        setRowsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = parseInt(action.payload) || 10;
        },
        setPage: (state, action) => {
            state.pagination.page = parseInt(action.payload) || 1;
        },
        setFilteredEquipments: (state, action) => {
            state.filtered_equipments = action.payload;
        },
        setAllSelectedEquipments: (state, action) => {
            /* CASE 1 */
            _.each(action.payload, (equipment_id) => {
                if (_.includes(state.selected_equipments, equipment_id) === false) {
                    state.selected_equipments = [...state.selected_equipments, equipment_id];
                }
            });
            /* CASE 2 */
            //state.selected_equipments = action.payload;
        },
        setSelectedEquipment: (state, action) => {
            const equipment_id = action.payload;
            if (_.includes(state.selected_equipments, equipment_id)) {
                state.selected_equipments = _.filter(state.selected_equipments, (id) => id !== equipment_id);
            } else {
                state.selected_equipments.push(equipment_id);
            }
        },
        clearSelectedEquipments: (state, action) => {
            state.selected_equipments = initialState.selected_equipments;
        },
        setComparisonParams: (state, action) => {
            state.comparison_params = action.payload;
        },
        clearComparisonParams: (state, action) => {
            state.comparison_params = initialState.comparison_params;
        }
    },
    extraReducers(builder) {
        builder.addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
            return initialState;
        });
    }
});

export const {
    setFilter,
    setSorting,
    setRowsPerPage,
    setPage,
    setReadyPagination,
    setSelectedEquipment,
    clearSelectedEquipments,
    setFilteredEquipments,
    setAllSelectedEquipments,
    setComparisonParams,
    clearComparisonParams
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
