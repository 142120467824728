import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import _ from "lodash";
import { Modal, Button, Icon, Grid, Divider, Popup, Table, Segment, Message, Checkbox } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { InputAdapter, DateTimeAdapter, ToggleAdapter } from "modules/common/components/form";
import { dayMapping } from "../utils/dayMapping";
import { useGetCalendarQuery, useUpdateCalendarMutation } from "../calendarService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { roundedDate } from "modules/time/utils";

const UpdateCalendarModalForm = (props) => {
    const today = moment().startOf("day");
    const { id_site, calendars, org, item } = props;
    const [open, setOpen] = useState(false);

    //check if schedule is writable
    const can_change = props.can_change; //default is site's rights
    const past_schedule =
        item.validity_end !== null && item.validity_start <= today.format("YYYY-MM-DD") && item.validity_end <= today.format("YYYY-MM-DD");

    const current_lng = useSelector((state) => state.i18n.current);
    const defaultSchedule = _.find(calendars, (item) => item.validity_end === null);

    const current_calendar = useGetCalendarQuery(
        { org: org.current, id_site, id_calendar: item.id },
        { skip: !org.current || id_site === undefined || open === false || item?.id === undefined }
    );

    const [updateCalendar, update] = useUpdateCalendarMutation();

    // Update Calendar
    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`Opening schedule updated`), toast_options);
            setOpen(false);
        }
        if (update.isError) {
            toast.error(i18n._(t`Can't update opening schedule`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isSuccess, update.isError]);

    const initialValues = useMemo(() => {
        if (current_calendar.isSuccess) {
            const calendar = current_calendar.data;
            return {
                calendar: {
                    ...calendar,
                    validity_start: moment(calendar.validity_start, "YYYY-MM-DD", true),
                    validity_end: calendar.validity_end === null ? null : moment(calendar.validity_end, "YYYY-MM-DD", true)
                },
                has_end: calendar.validity_end !== null,
                confirmCalendar: calendar.validity_end ? true : false
            };
        }
        return {
            calendar: null,
            has_end: true
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_calendar]);

    const validate = (values) => {
        const { has_end, calendar: validated_calendar } = values;
        const errors = {};

        let calendar = {};
        if (validated_calendar.validity_start === undefined || validated_calendar.validity_start === null) {
            calendar.validity_start = <Trans>The start date cannot be empty</Trans>;
            errors.calendar = calendar;
            return errors;
        }
        if (typeof validated_calendar.validity_start === "string") {
            calendar.validity_start = <Trans>Invalid format</Trans>;
            errors.calendar = calendar;
            return errors;
        }
        if (has_end && (validated_calendar.validity_end === null || validated_calendar.validity_end === undefined)) {
            calendar.validity_end = <Trans>End date cannot be empty</Trans>;
            errors.calendar = calendar;
            return errors;
        }
        if (has_end && (typeof validated_calendar.validity_end === "string" || validated_calendar.validity_end === null)) {
            calendar.validity_end = <Trans>Invalid format</Trans>;
            errors.calendar = calendar;
            return errors;
        }
        if (has_end && validated_calendar.validity_start && validated_calendar.validity_start.isSameOrAfter(validated_calendar.validity_end)) {
            calendar.validity_start = <Trans>Start date must be before end date</Trans>;
            calendar.validity_end = <Trans>End date must be after start date</Trans>;
            errors.calendar = calendar;
            return errors;
        }

        //check server calendars for overlap
        _.each(calendars, (cal) => {
            if (cal.id === item.id) {
                return;
            }
            const cal_start = moment(cal.validity_start, "YYYY-MM-DD", true);
            const cal_end = cal.validity_end === null ? null : moment(cal.validity_end, "YYYY-MM-DD", true);

            if (validated_calendar.validity_start < cal_end && cal_start < validated_calendar.validity_end) {
                calendar.validity_start = <Trans>2 calendars cannot have their period overlapping</Trans>;
                errors.calendar = calendar;
                return false;
            }
        });

        //check all days time ranges
        _.each(_.range(7), (day) => {
            const day_values = validated_calendar?.[`day${day + 1}`] ?? [];
            calendar[`day${day + 1}`] = [];
            _.each(day_values, (time_range, idx, all_values) => {
                calendar[`day${day + 1}`][idx] = {};
                if (time_range.start === undefined) {
                    calendar[`day${day + 1}`][idx].start = <Trans>Invalid format</Trans>;
                    errors.calendar = calendar;
                    return false;
                }
                if (time_range.end === undefined) {
                    calendar[`day${day + 1}`][idx].end = <Trans>Invalid format</Trans>;
                    errors.calendar = calendar;
                    return false;
                } else if (time_range.end === "00:00" && idx !== _.size(day_values) - 1) {
                    calendar[`day${day + 1}`][idx].end = <Trans>End time can't be 00:00</Trans>;
                    errors.calendar = calendar;
                    return false;
                }
                if (time_range.start >= time_range.end && time_range.end !== "00:00") {
                    calendar[`day${day + 1}`][idx].start = <Trans>Start time must be before end time</Trans>;
                    errors.calendar = calendar;
                    return false;
                }

                if (idx > 0) {
                    const prev_range = day_values[idx - 1];
                    if (time_range.start === prev_range.end) {
                        calendar[`day${day + 1}`][idx].start = <Trans>A start time must be different from an end time</Trans>;
                        errors.calendar = calendar;
                        return false;
                    } else if (time_range.start < prev_range.end) {
                        calendar[`day${day + 1}`][idx].start = <Trans>2 opening time ranges can't overlap</Trans>;
                        errors.calendar = calendar;
                        return false;
                    }
                }
            });
        });
        return errors;
    };

    const submitForm = async (formData) => {
        if (can_change) {
            const remap_days = {
                ..._.reduce(
                    _.range(7),
                    (res, day) => {
                        res[`day${day + 1}`] = [];
                        const day_values = formData.calendar?.[`day${day + 1}`] ?? [];
                        if (_.size(day_values) === 0) {
                            return res;
                        }
                        _.each(day_values, (time_range, idx) => {
                            const { start, end } = time_range;
                            res[`day${day + 1}`].push(start);
                            if (idx === _.size(day_values) - 1 && end === "00:00") {
                                res[`day${day + 1}`].push(null);
                            } else {
                                res[`day${day + 1}`].push(end);
                            }
                        });
                        return res;
                    },
                    {}
                )
            };
            const data = {
                ...formData.calendar,
                validity_start: formData.calendar.validity_start.format("YYYY-MM-DD"),
                validity_end: formData.has_end ? formData.calendar.validity_end.clone().add(1, "d").format("YYYY-MM-DD") : null,
                site: id_site,
                ...remap_days
            };
            await updateCalendar({ org: org.current, id_site, data });
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Popup
                    trigger={
                        <Button
                            type="button"
                            icon={can_change ? "edit" : "eye"}
                            onClick={async (e) => {
                                setOpen(true);
                            }}
                        />
                    }
                >
                    <Popup.Content>
                        {can_change && <Trans>Opening calendar edition</Trans>}
                        {!can_change && <Trans>View opening calendar</Trans>}
                    </Popup.Content>
                </Popup>
            }
        >
            <Modal.Header>
                {can_change && <Trans>Update opening calendar</Trans>}
                {!can_change && <Trans>View opening calendar</Trans>}
            </Modal.Header>
            <Modal.Content>
                {(current_calendar.isFetching || current_calendar.isLoading) && (
                    <Grid.Column width={16}>
                        <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
                    </Grid.Column>
                )}
                {current_calendar.isError && (
                    <MessageDisplay
                        message={i18n._(t`error loading data`)}
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                        attache={false}
                    />
                )}
                {current_calendar.isSuccess && (
                    <Form
                        onSubmit={submitForm}
                        initialValues={initialValues}
                        mutators={{ ...arrayMutators }}
                        validate={validate}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            pristine,
                            invalid,
                            form: {
                                mutators: { push, pop }
                            },
                            values
                        }) => {
                            const hasAlreadyDefaultSchedule = !values.has_end && !_.isUndefined(defaultSchedule) && defaultSchedule?.id !== item?.id;
                            return (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid>
                                        <Grid.Column width={16}>
                                            <Field
                                                name="calendar.name"
                                                placeholder={i18n._(t`Enter opening calendar name`)}
                                                label={i18n._(t`name`)}
                                                isRequired={true}
                                                component={InputAdapter}
                                                validate={(value) => {
                                                    const existing_name = _.chain(calendars)
                                                        .filter((cal) => {
                                                            return cal.id !== item.id;
                                                        })
                                                        .find({ name: value })
                                                        .value();

                                                    if (existing_name) {
                                                        return <Trans>An opening calendar with this name already exists</Trans>;
                                                    }
                                                    if (!value) {
                                                        return <Trans>Name is required</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                                disabled={!can_change}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Divider horizontal>
                                                <Trans>Opening dates</Trans>
                                            </Divider>
                                            <Grid columns={values.has_end ? 3 : 2} verticalAlign="middle">
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Field
                                                            name="calendar.validity_start"
                                                            component={DateTimeAdapter}
                                                            locale={current_lng}
                                                            date_limit={null}
                                                            labeled={true}
                                                            label={i18n._(t`from`)}
                                                            labelPosition={"left"}
                                                            isRequired={true}
                                                            dateFormat={true}
                                                            timeFormat={false}
                                                            disabled={past_schedule || !can_change}
                                                        />
                                                    </Grid.Column>
                                                    {values.has_end && (
                                                        <Grid.Column>
                                                            <Field
                                                                name="calendar.validity_end"
                                                                component={DateTimeAdapter}
                                                                locale={current_lng}
                                                                labeled={true}
                                                                label={i18n._(t`to`)}
                                                                labelPosition={"left"}
                                                                isRequired={true}
                                                                date_limit={null}
                                                                dateFormat={true}
                                                                timeFormat={false}
                                                                disabled={past_schedule || !can_change}
                                                            />
                                                        </Grid.Column>
                                                    )}
                                                    <Grid.Column>
                                                        <Field
                                                            name="has_end"
                                                            label={i18n._(t`Has end date ?`)}
                                                            component={ToggleAdapter}
                                                            disabled={past_schedule || !can_change}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Grid>
                                                {values.has_end && !past_schedule && (
                                                    <Grid.Column style={{ padding: "9px" }}>
                                                        <Message
                                                            style={{ backgroundColor: "#FFFAF3", color: "#573A08" }}
                                                            icon="warning sign"
                                                            content={
                                                                <>
                                                                    <Trans>
                                                                        A calendar with an end date earlier than the current date cannot be modified
                                                                        subsequently.
                                                                    </Trans>
                                                                    <Checkbox
                                                                        style={{ marginTop: ".5rem" }}
                                                                        label={
                                                                            <label>
                                                                                <Trans>I confirm the creation of this calendar</Trans>
                                                                            </label>
                                                                        }
                                                                        onChange={(e, data) => {
                                                                            if (data) {
                                                                                form.change("confirmCalendar", !values.confirmCalendar);
                                                                            }
                                                                        }}
                                                                        checked={values.confirmCalendar}
                                                                    />
                                                                </>
                                                            }
                                                        />
                                                    </Grid.Column>
                                                )}
                                            </Grid>
                                        </Grid.Column>
                                    </Grid>
                                    {hasAlreadyDefaultSchedule && (
                                        <MessageDisplay
                                            message={
                                                <Trans>
                                                    You have already an opening calendar with no end date named '
                                                    <strong>{defaultSchedule.name}</strong>'. If you want to create a new one, you must modify or
                                                    delete the old one.
                                                </Trans>
                                            }
                                            level="warning"
                                            iconName="warning circle"
                                            isLoading={false}
                                        />
                                    )}
                                    {past_schedule && (
                                        <MessageDisplay
                                            message={<Trans>Opening Dates in the past cannot be changed</Trans>}
                                            level="warning"
                                            iconName="warning circle"
                                            isLoading={false}
                                        />
                                    )}

                                    {(!values.has_end || values.confirmCalendar) && (
                                        <Segment basic attached style={{ overflowX: "auto" }}>
                                            <Table unstackable striped celled attached>
                                                <Table.Header fullWidth>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            <Trans>day</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <Trans>Opening hours</Trans>
                                                        </Table.HeaderCell>
                                                        {!past_schedule && can_change && (
                                                            <Table.HeaderCell>
                                                                <Trans>actions</Trans>
                                                            </Table.HeaderCell>
                                                        )}
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {/* DAYS  */}
                                                    {_.map(dayMapping, (day, key) => {
                                                        return (
                                                            <Table.Row key={key}>
                                                                <FieldArray name={`calendar.${key}`}>
                                                                    {(c_field) => {
                                                                        return (
                                                                            <>
                                                                                <Table.Cell collapsing>{i18n._(day)}</Table.Cell>
                                                                                <Table.Cell>
                                                                                    {c_field.fields.length === 0 && (
                                                                                        <MessageDisplay
                                                                                            message={i18n._(t`Site is closed this day`)}
                                                                                            level="info"
                                                                                            iconName="info circle"
                                                                                            isLoading={false}
                                                                                            attached={false}
                                                                                        />
                                                                                    )}
                                                                                    {c_field.fields.length > 0 && (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                alignContent: "center",
                                                                                                justifyContent: "start"
                                                                                            }}
                                                                                        >
                                                                                            {c_field.fields.map((c_name, c_index) => {
                                                                                                return (
                                                                                                    <React.Fragment key={c_name}>
                                                                                                        <div key={`${c_index}.start`}>
                                                                                                            <Field
                                                                                                                name={`${c_name}.start`}
                                                                                                                component={InputAdapter}
                                                                                                                type="time"
                                                                                                                style={{
                                                                                                                    marginRight: "2px"
                                                                                                                }}
                                                                                                                disabled={
                                                                                                                    past_schedule || !can_change
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div key={`${c_index}.end`}>
                                                                                                            <Field
                                                                                                                name={`${c_name}.end`}
                                                                                                                component={InputAdapter}
                                                                                                                type="time"
                                                                                                                style={{
                                                                                                                    marginRight: "20px"
                                                                                                                }}
                                                                                                                disabled={
                                                                                                                    past_schedule || !can_change
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    )}
                                                                                </Table.Cell>
                                                                                {!past_schedule && can_change && (
                                                                                    <Table.Cell collapsing>
                                                                                        <Button.Group>
                                                                                            <Popup
                                                                                                on={"hover"}
                                                                                                content={i18n._(t`Add a new opening time range`)}
                                                                                                trigger={
                                                                                                    <Button
                                                                                                        type="button"
                                                                                                        icon="add"
                                                                                                        onClick={(e) => {
                                                                                                            push(`calendar.${key}`, {
                                                                                                                start: roundedDate(
                                                                                                                    moment().startOf("d")
                                                                                                                ).format("HH:mm"),
                                                                                                                end: roundedDate(
                                                                                                                    moment().startOf("d")
                                                                                                                ).format("HH:mm")
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                            <Popup
                                                                                                on={"hover"}
                                                                                                content={i18n._(t`Clear the last opening time range`)}
                                                                                                trigger={
                                                                                                    <Button
                                                                                                        disabled={c_field.fields.length === 0}
                                                                                                        negative
                                                                                                        type="button"
                                                                                                        icon="trash"
                                                                                                        onClick={(e) => {
                                                                                                            pop(`calendar.${key}`);
                                                                                                        }}
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                        </Button.Group>
                                                                                    </Table.Cell>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }}
                                                                </FieldArray>
                                                            </Table.Row>
                                                        );
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </Segment>
                                    )}

                                    <Grid.Column>
                                        <Divider />
                                    </Grid.Column>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column floated="right" textAlign="right" computer={5} tablet={16}>
                                                <Button
                                                    type="button"
                                                    negative
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    <Trans>cancel</Trans>
                                                </Button>
                                                {can_change && (
                                                    <Button
                                                        type="submit"
                                                        positive
                                                        icon
                                                        labelPosition="right"
                                                        disabled={submitting || pristine || invalid || hasAlreadyDefaultSchedule}
                                                    >
                                                        <Icon name="check" />
                                                        <Trans>validate</Trans>
                                                    </Button>
                                                )}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                )}
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(UpdateCalendarModalForm);
