export const dropdownOptions = [
    {
        key: "€",
        text: "€",
        value: "€"
    },
    {
        key: "$",
        text: "$",
        value: "$"
    },
    {
        key: "£",
        text: "£",
        value: "£"
    },
    {
        key: "¥",
        text: "¥",
        value: "¥"
    },
    {
        key: "CHF",
        text: "CHF",
        value: "CHF"
    },
    {
        key: "DA",
        text: "DA",
        value: "DA"
    },
    {
        key: "DH",
        text: "DH",
        value: "DH"
    },
    {
        key: "DT",
        text: "DT",
        value: "DT"
    },
    {
        key: "Fr",
        text: "Fr",
        value: "Fr"
    },
    {
        key: "ft",
        text: "ft",
        value: "ft"
    },
    {
        key: "Kč",
        text: "Kč",
        value: "Kč"
    },
    {
        key: "kr",
        text: "kr",
        value: "kr"
    },
    {
        key: "lei",
        text: "lei",
        value: "lei"
    },
    {
        key: "zł",
        text: "zł",
        value: "zł"
    }
];

export const deleteExclusion = [
    "organization.Site",
    "organization.SiteRight",
    "pwa_clients.CalendarWeek",
    "pwa_clients.SiteStation",
    "pwa_clients.AnalysisSection_sites",
    "pwa_clients.Widget_sites"
];
export const modifiedExclusion = [];

export const priceCurrency = ["price_gas", "price_gas_m3", "price_elec", "price_water"];
