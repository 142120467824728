import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Segment, Header, Button, Table } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { setAlertPackOverride } from "modules/alert/alert_pack/alertPackSlice";
import { categoriesOptions } from "./utils/index";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";
import { reparseNumber } from "modules/common/utils";

import MessageDisplay from "modules/common/components/MessageDisplay";
import AlertPredictModal from "./AlertPredictModal";
import EditException from "./EditException";
import AlertPredictModalGlobal from "./AlertPredictModalGlobal";
import EditExceptionGlobal from "./EditExceptionGlobal";

const AlertPredictOverride = (props) => {
    const { rights, notification, equipments, pack, overrides } = props;
    const dispatch = useDispatch();

    const onSubmitForm = async (formdata) => {
        if (notification.srv_status.db_status === "rw") {
            const filtered_override_form = {
                ...formdata,
                pack_exception: _.reduce(
                    formdata.pack_exception,
                    (res, exception) => {
                        if (_.isEmpty(exception.override)) {
                            return res;
                        }
                        res.push({
                            ...exception,
                            override: _.mapValues(exception.override, (component_values) => {
                                return _.mapValues(component_values, (item, key) => {
                                    if (_.includes(keyToReparseInPack, key)) {
                                        return reparseNumber(item);
                                    }
                                    return item;
                                });
                            })
                        });
                        return res;
                    },
                    []
                ),
                equipments_exception: _.reduce(
                    formdata.equipments_exception,
                    (res, exception) => {
                        if (_.isEmpty(exception.override)) {
                            return res;
                        }
                        res.push({
                            ...exception,
                            override: _.mapValues(exception.override, (component_values) => {
                                return _.mapValues(component_values, (item, key) => {
                                    if (_.includes(keyToReparseInPack, key)) {
                                        return reparseNumber(item);
                                    }
                                    return item;
                                });
                            })
                        });
                        return res;
                    },
                    []
                )
            };

            await dispatch(
                setAlertPackOverride({ formdata: filtered_override_form, pack_id: pack.id, type: ["equipments_exception", "pack_exception"] })
            );
        }
    };

    const initial = useMemo(() => {
        return _.reduce(
            overrides,
            (res, item) => {
                if (item.object_type === 5) {
                    const equipment = _.find(equipments, { id: item.object_id });
                    if (equipment) {
                        res.equipments_exception.push({ ...item, name: equipment?.name });
                    }
                } else if (item.object_type === 6) {
                    if (item.object_id === pack.id) {
                        res.pack_exception.push(item);
                    }
                }
                return res;
            },
            { pack_exception: [], equipments_exception: [] }
        );
    }, [overrides, equipments, pack]);

    return (
        <Segment.Group>
            <Form
                onSubmit={onSubmitForm}
                mutators={{ ...arrayMutators }}
                initialValues={initial}
                render={({
                    handleSubmit,
                    form: {
                        mutators: { push, update }
                    },
                    submitting,
                    values,
                    invalid
                }) => {
                    const exceptions_equipments = _.chain(values)
                        .get("equipments_exception", [])
                        .filter((equipment) => {
                            return !_.isEmpty(equipment.override);
                        })
                        .value();
                    const pack_exception = _.chain(values)
                        .get("pack_exception", [])
                        .filter((item) => {
                            return !_.isEmpty(item.override);
                        })
                        .value();

                    return (
                        <form onSubmit={handleSubmit} className="ui form">
                            <Header as="h4" attached="top" block textAlign="center">
                                <Trans>Global exceptions</Trans>
                            </Header>
                            <Segment attached>
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                    <Segment basic>
                                        <AlertPredictModalGlobal
                                            pack_exception={_.get(values, "pack_exception")}
                                            push={push}
                                            update={update}
                                            pack={pack}
                                        />
                                    </Segment>
                                )}
                                {_.size(pack_exception) === 0 && (
                                    <MessageDisplay
                                        message={i18n._(t`No global exceptions`)}
                                        level="info"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                )}
                                {_.size(pack_exception) > 0 && (
                                    <Segment basic style={{ overflowX: "auto" }}>
                                        <Table attached striped celled unstackable compact structured>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        <Trans>Health score category</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>First threshold</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>Second threshold</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>Duration</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <FieldArray name="pack_exception">
                                                    {(myFields) => {
                                                        return myFields.fields.map((name, index) => {
                                                            const pa_exception = myFields.fields.value[index];
                                                            return _.map(pa_exception.override, (override_value, categorie) => {
                                                                const categorieText = _.chain(categoriesOptions)
                                                                    .find({ value: categorie })
                                                                    .get("text", t`health score categorie error`)
                                                                    .value();
                                                                return (
                                                                    <Table.Row key={categorie}>
                                                                        <Table.Cell>{i18n._(categorieText)}</Table.Cell>
                                                                        <Table.Cell textAlign="center">{override_value.level_warning}</Table.Cell>
                                                                        <Table.Cell textAlign="center">{override_value.level_critical}</Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            {/* Convert duration "minutes" => "hours" for UI */}
                                                                            {override_value.duration / 60}
                                                                        </Table.Cell>
                                                                        {notification.srv_status.db_status === "rw" && (
                                                                            <Table.Cell>
                                                                                <Button.Group size="small">
                                                                                    {_.includes(rights, "can_change") && (
                                                                                        <EditExceptionGlobal
                                                                                            idx={0}
                                                                                            item={pa_exception}
                                                                                            categorie={categorie}
                                                                                            update={update}
                                                                                        />
                                                                                    )}
                                                                                    {_.includes(rights, "can_delete") && (
                                                                                        <Button
                                                                                            type="button"
                                                                                            icon="trash"
                                                                                            color="red"
                                                                                            onClick={() =>
                                                                                                update("pack_exception", 0, {
                                                                                                    ...pa_exception,
                                                                                                    override: _.omit(pa_exception.override, categorie)
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Button.Group>
                                                                            </Table.Cell>
                                                                        )}
                                                                    </Table.Row>
                                                                );
                                                            });
                                                        });
                                                    }}
                                                </FieldArray>
                                            </Table.Body>
                                        </Table>
                                    </Segment>
                                )}
                            </Segment>
                            <Header as="h4" attached block textAlign="center">
                                <Trans>Equipment exceptions</Trans>
                            </Header>
                            <Segment attached>
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                    <Segment basic>
                                        <AlertPredictModal
                                            equipments={equipments}
                                            equipments_exception={_.get(values, "equipments_exception")}
                                            push={push}
                                            update={update}
                                            pack={pack}
                                        />
                                    </Segment>
                                )}
                                {_.size(exceptions_equipments) === 0 && (
                                    <MessageDisplay
                                        message={i18n._(t`No equipment exceptions`)}
                                        level="info"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                )}
                                {_.size(exceptions_equipments) > 0 && (
                                    <Segment basic style={{ overflowX: "auto" }}>
                                        <Table attached striped celled unstackable compact structured>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell rowSpan="2">
                                                        <Trans>Name</Trans>
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        <Trans>Health score category</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>First threshold</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>Second threshold</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        <Trans>Duration</Trans>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <FieldArray name="equipments_exception">
                                                    {(myFields) => {
                                                        return myFields.fields.map((eqt_name, eqt_index) => {
                                                            const pa_exception = myFields.fields.value[eqt_index];
                                                            const override_size = _.size(pa_exception?.override) || 1;
                                                            let override_index = 0;
                                                            return _.map(pa_exception.override, (override_value, categorie) => {
                                                                const categorieText = _.chain(categoriesOptions)
                                                                    .find({ value: categorie })
                                                                    .get("text", t`health score categorie error`)
                                                                    .value();
                                                                const renderTable = (
                                                                    <Table.Row key={`${eqt_index}_${categorie}`}>
                                                                        {override_index === 0 && (
                                                                            <Table.Cell
                                                                                rowSpan={override_size}
                                                                                verticalAlign={override_size > 1 ? "top" : "middle"}
                                                                            >
                                                                                {_.get(pa_exception, "name", "-")}
                                                                            </Table.Cell>
                                                                        )}
                                                                        <Table.Cell>{i18n._(categorieText)}</Table.Cell>
                                                                        <Table.Cell textAlign="center">{override_value.level_warning}</Table.Cell>
                                                                        <Table.Cell textAlign="center">{override_value.level_critical}</Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            {/* Convert duration "minutes" => "hours" for UI */}
                                                                            {override_value.duration / 60}
                                                                        </Table.Cell>
                                                                        {notification.srv_status.db_status === "rw" && (
                                                                            <Table.Cell>
                                                                                <Button.Group size="small">
                                                                                    {_.includes(rights, "can_change") && (
                                                                                        <EditException
                                                                                            idx={eqt_index}
                                                                                            item={pa_exception}
                                                                                            categorie={categorie}
                                                                                            update={update}
                                                                                        />
                                                                                    )}
                                                                                    {_.includes(rights, "can_delete") && (
                                                                                        <Button
                                                                                            type="button"
                                                                                            icon="trash"
                                                                                            color="red"
                                                                                            onClick={() =>
                                                                                                update("equipments_exception", eqt_index, {
                                                                                                    ...pa_exception,
                                                                                                    override: _.omit(pa_exception.override, categorie)
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </Button.Group>
                                                                            </Table.Cell>
                                                                        )}
                                                                    </Table.Row>
                                                                );
                                                                override_index++;
                                                                return renderTable;
                                                            });
                                                        });
                                                    }}
                                                </FieldArray>
                                            </Table.Body>
                                        </Table>
                                    </Segment>
                                )}
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                    <Segment basic>
                                        <Button type="submit" content={i18n._(t`validate`)} positive disabled={submitting || invalid} />
                                    </Segment>
                                )}
                            </Segment>
                        </form>
                    );
                }}
            />
        </Segment.Group>
    );
};

export default React.memo(AlertPredictOverride);
