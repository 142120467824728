import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Header, Label, Segment, Table } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { Trans, t } from '@lingui/macro';

import googleplay from "images/googleplay.png";
import appstore from "images/appstore.png";

import i18n from 'modules/i18n/i18nConfig';
import * as extern from "extern";
import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import { useOtpGenerateQuery, useOtpQrCodeQuery, useOtpSetMutation } from 'modules/user/userService';

import { InputAdapter } from 'modules/common/components/form';
import history_app from 'history_app';


const OtpSetup = (props) => {
    const { org, auth } = useSelector((state) => state);
    const otpGenerate = useOtpGenerateQuery({ org: org.current, user_id: auth.user.user_id }, { skip: !auth.isAuthenticated || auth.isVerified || auth.rights === null });
    const otpQrCode = useOtpQrCodeQuery({ org: org.current, user_id: auth.user.user_id, secret: otpGenerate.data?.secret }, { skip: !auth.isAuthenticated || auth.isVerified || otpGenerate.isSuccess !== true || auth.rights === null });

    const [setOtp, set] = useOtpSetMutation();

    useEffect(() => {
        if (set.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (set.isSuccess) {
            toast.success(i18n._(t`successful set otp`), toast_options);
            history_app.push('/otp/validate');
        }
        if (set.isError) {
            let msg = t`cannot set otp`;
            if (set.error?.data?.error) {
                msg = set.error?.data?.error;
            }
            toast.error(i18n._(msg), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [set.isSuccess, set.isError, set.isLoading]);

    useEffect(() => {
        if (auth.rights?.has_otp === true) {
            if (auth.rights?.two_factor === true) {
                if (auth.rights?.verified === false) {
                    history_app.push("/otp/validate");
                } else {
                    history_app.push("/");
                }
            } else {
                history_app.push("/");
            }
        }
    }, [auth]);

    const submitForm = async (formData) => {
        setOtp({ org: org.current, user_id: auth.user.user_id, data: formData });
    };

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader no-print">
                    <Grid.Column width={2} />
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Two-Factor authentication (2FA)</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {otpQrCode.isSuccess && (
                    <>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={8} computer={6}>
                                <Segment padded attached="top">
                                    <Label attached='top left' color='blue'><Trans>Scan QrCode</Trans></Label>
                                    <Header as="h3"><Trans>Install one of the following applications on your mobile</Trans></Header>
                                    <Table definition compact>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell><Trans>Microsoft Authenticator</Trans></Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    <a href={extern.microsoftAuthGooglePlay} target="_blank" rel="noopener noreferrer">
                                                        <img src={googleplay} alt="" />
                                                    </a>
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    <a href={extern.microsoftAuthAppStore} target="_blank" rel="noopener noreferrer">
                                                        <img src={appstore} alt="" />
                                                    </a>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell><Trans>Google Authenticator</Trans></Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    <a href={extern.googleAuthGoogleplay} target="_blank" rel="noopener noreferrer">
                                                        <img src={googleplay} alt="" />
                                                    </a>
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    <a href={extern.googleAuthAppStore} target="_blank" rel="noopener noreferrer">
                                                        <img src={appstore} alt="" />
                                                    </a>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <Header as="h3"><Trans>Open the application and scan the barcode</Trans></Header>
                                    <Segment basic textAlign='center'>
                                        <img src={otpQrCode.data} alt="QR Code" />
                                    </Segment>
                                </Segment>
                                <Segment padded attached>
                                    <Label attached='top left' color='blue'><Trans>Or enter code in your app</Trans></Label>
                                    <Segment basic textAlign='center'>
                                        <Trans>Secret</Trans>: {otpGenerate.data.secret}
                                    </Segment>
                                </Segment>
                                <Segment padded attached>
                                    <Label attached='top left' color='blue'><Trans>Verify Code</Trans></Label>
                                    <Segment basic>
                                        <p><Trans>Please set the authentication code</Trans></p>
                                        <Segment basic textAlign='center'>
                                            <Form
                                                onSubmit={submitForm}
                                                initialValues={{ secret: otpGenerate.data.secret, token: "" }}
                                                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                                    return (
                                                        <form onSubmit={handleSubmit} className="ui form">
                                                            <Field
                                                                name="token"
                                                                placeholder={i18n._(t`Authentication code`)}
                                                                onKeyDown={(e) => (e.keyCode === 69) && e.preventDefault()}
                                                                component={InputAdapter}
                                                                isRequired={true}
                                                                validate={(value) => {
                                                                    if (!(/^[0-9]{6}$/.test(value))) {
                                                                        return <Trans>invalid token</Trans>;
                                                                    }
                                                                    return undefined;
                                                                }}
                                                            />
                                                            <Button
                                                                type="submit"
                                                                content={i18n._(t`verify & validate`)}
                                                                disabled={submitting || pristine || invalid}
                                                            />
                                                        </form>
                                                    );
                                                }}
                                            />
                                        </Segment>
                                    </Segment>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(OtpSetup);