import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Menu, Icon } from "semantic-ui-react";

//import { djangoAdmin } from "apis/PowerAdapt";
import { useLogoutUserMutation } from "modules/auth/authService";

const navItemsGenerator = (path, org, user, current_lng) => {
    return [
        {
            icon: "home",
            title: <Trans>home</Trans>,
            onClick: null,
            path: `${path}`,
            display: true
        },
        {
            icon: "dashboard",
            title: <Trans>dashboard</Trans>,
            onClick: null,
            path: `${path}dashboard`,
            display: true
        },
        {
            icon: "chart pie",
            title: <Trans>globalview</Trans>,
            onClick: null,
            path: `${path}globalview`,
            display: true
        },
        {
            icon: "grid layout",
            title: <Trans>customdashboard</Trans>,
            onClick: null,
            path: `${path}customdashboard`,
            display: true
        },
        {
            icon: "sitemap",
            title: <Trans>hierarchy</Trans>,
            onClick: null,
            path: `${path}hierarchy`,
            display: true
        },
        {
            icon: "chart bar",
            title: <Trans>advancedanalytics</Trans>,
            onClick: null,
            path: `${path}advancedanalytics`,
            display: true
        },
        {
            icon: "file",
            title: <Trans>reports</Trans>,
            onClick: null,
            path: `${path}reports`,
            display: true
        },
        {
            icon: "chart line",
            title: <Trans>energysavings</Trans>,
            onClick: null,
            path: `${path}energysavings`,
            display: true
        },
        {
            icon: "envelope open",
            title: <Trans>exports</Trans>,
            onClick: null,
            path: `${path}exports`,
            display: true
        },
        {
            icon: "bullhorn",
            title: <Trans>alerts_activity</Trans>,
            onClick: null,
            path: `${path}alerts_activity`,
            display: true
        },
        {
            icon: "alarm",
            title: <Trans>alerts_packs</Trans>,
            onClick: null,
            path: `${path}alerts_packs`,
            display: true
        },
        {
            icon: "whmcs",
            title: <Trans>equipments</Trans>,
            onClick: null,
            path: `${path}equipments`,
            display: true
        },
        {
            icon: "hdd",
            title: <Trans>sensors</Trans>,
            onClick: null,
            path: `${path}sensors`,
            display: true
        },
        {
            icon: "building",
            title: <Trans>manage</Trans>,
            onClick: null,
            path: `${path}manage`,
            display: true
        },
        {
            icon: "user",
            title: <Trans>account</Trans>,
            path: `${path}users/${user.user_id}/change`,
            display: true
        }
    ];
};

export const SideBarItems = (props) => {
    const { path } = props;
    const { auth } = useSelector((state) => state);
    const { user, rights } = auth;
    const org = useSelector((state) => state.org.current);
    const current_lng = useSelector((state) => state.i18n.current);

    const [logoutUser] = useLogoutUserMutation();

    return (
        <>
            {!_.isNull(org) &&
                auth.isVerified &&
                rights?.pw_expired === false &&
                _.chain(navItemsGenerator(path, org, user, current_lng))
                    .filter((item) => item.display === true)
                    .map((item, idx) => {
                        return (
                            <Menu.Item key={idx} as={item.path ? Link : null} to={item.path} onClick={item.onClick}>
                                <Icon name={item.icon} flipped={item?.flipped} />
                                {item.title}
                            </Menu.Item>
                        );
                    })
                    .value()}
            <Menu.Item
                onClick={() => {
                    logoutUser();
                }}
            >
                <Icon name="log out" />
                <Trans>log out</Trans>
            </Menu.Item>
        </>
    );
};

SideBarItems.propTypes = {
    path: PropTypes.string.isRequired
};

export default SideBarItems;
