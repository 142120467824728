import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';
import cookie from "react-cookies";

export const mailingApi = defaultApi.injectEndpoints({
    reducerPath: 'apiMailing',
    endpoints: (build) => ({
        getMailings: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `mailinglists?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                return _.map(response, (mailing => ({
                    ...mailing,
                    key: mailing.id,
                    text: mailing.name,
                    value: mailing.id
                })));
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Mailings", id: arg.org.id }];
                return [];
            },
        }),
        getMailing: build.query({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return `mailinglists/${data}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                if (result) return [{ type: 'Mailing', id: arg.data }];
                return [];
            },
        }),
        addMailing: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `mailinglists?org=${current_org}`,
                    method: 'POST',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    if (arg.local === undefined) {
                        return [
                            { type: "Mailings", id: arg.org.id }
                        ];
                    }
                }
                return [];
            }
        }),
        updateMailing: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, 'name', null);
                return {
                    url: `mailinglists/${data.id}?org=${current_org}`,
                    method: 'PUT',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Mailings", id: arg.org.id },
                        { type: "Mailing", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteMailing: build.mutation({
            query: ({ org, data, confirm }) => {
                const current_org = _.get(org, 'name', null);
                const url = `mailinglists/${data}?org=${current_org}${confirm ? '&confirm=yes' : ''}&${refreshTimestampUrlParam()}`;

                return {
                    url,
                    method: 'DELETE',
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    //refresh cache only if confirm true
                    const { confirm } = arg;
                    if (confirm) {
                        return [
                            { type: "Mailings", id: arg.org.id },
                            { type: "Mailing", id: arg.data.id }
                        ];
                    }
                }
                return [];

            }
        }),
    }),
    overrideExisting: false,

});

export const { useGetMailingsQuery, useGetMailingQuery, useAddMailingMutation, useUpdateMailingMutation, useDeleteMailingMutation } = mailingApi;