import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from "modules/auth/authSlice";
import { zoneApi } from './areaService';

const initialState = {
    zones: [],
    filter: {
        searchName: ""
    },
    pagination: {
        page: 1,
        itemsPerPage: 10,
        // Used to get lastPage read
        stickPage: false
    },
};

const zoneSlice = createSlice({
    name: "zone",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        setStickPage: (state, action) => {
            state.pagination.stickPage = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(zoneApi.endpoints.getZones.matchFulfilled, (state, action) => {
                state.zones = action.payload;
            })
            .addMatcher(isAnyOf(logout), (state, action) => {
                return initialState;
            });
    }
});

export const { setSearchNameFilter, setPage, setItemsPerPage, setStickPage } = zoneSlice.actions;
export default zoneSlice.reducer;