import React, { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Checkbox, Segment, Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { useDeleteProductionDataMutation } from "../importedDataSetsService";

const DeleteProductionData = (props) => {
    const { item, dataset_id, setOpen } = props;

    const [confirm, setConfirm] = useState(false);
    const { org } = useSelector((state) => state);

    const [deleteProductionData, deleted] = useDeleteProductionDataMutation();

    useEffect(() => {
        if (deleted.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`Successful delete imported data`), toast_options);
            setOpen(false);
        }
        if (deleted.isError) {
            toast.error(i18n._(t`Cannot delete imported data`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted]);

    const handleDelete = async (e) => {
        await deleteProductionData({ org: org.current, dataset_id: dataset_id, data_id: item.id });
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => {
                setOpen(false);
            }}
            open={true}
        >
            <Modal.Header>
                <Trans>Want delete imported data from</Trans> {item.day}
            </Modal.Header>
            <Modal.Content>
                <Segment basic>
                    <Grid>
                        <Grid.Column style={{ marginTop: ".5rem" }} width={16}>
                            <Checkbox
                                label={
                                    <label>
                                        <Trans>yes, i confirm deletion</Trans>
                                    </label>
                                }
                                onChange={(e, data) => setConfirm(data.checked)}
                                checked={confirm}
                            />
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>

                <Button disabled={!confirm} labelPosition="right" icon positive onClick={handleDelete}>
                    <Icon name="check" />
                    <Trans>validate</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteProductionData;
