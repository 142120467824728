import React from "react";
import { Trans } from "@lingui/macro";
import { Menu, Tab } from "semantic-ui-react";

import SiteCalendarExceptions from "./SiteCalendarExceptions";
import SiteCalendarWeeks from "./SiteCalendarWeeks";

const SiteCalendar = (props) => {
    const panes = [
        {
            menuItem: (_, menuProps) => {
                return (
                    <Menu.Item key="list weeks" {...menuProps}>
                        <Trans>Opening calendars</Trans>
                    </Menu.Item>
                );
            },
            render: () => {
                return (
                    <Tab.Pane style={{ padding: 0 }}>
                        <SiteCalendarWeeks {...props} />
                    </Tab.Pane>
                );
            }
        },
        {
            menuItem: (_, menuProps) => {
                return (
                    <Menu.Item key="exceptions" {...menuProps}>
                        <Trans>Exceptions</Trans>
                    </Menu.Item>
                );
            },
            render: () => {
                return (
                    <Tab.Pane style={{ padding: 0 }}>
                        <SiteCalendarExceptions {...props} />
                    </Tab.Pane>
                );
            }
        },
    ];

    return (
        <Tab panes={panes} />
    );
};

export default SiteCalendar;
