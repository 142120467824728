import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Icon, Grid, Divider, Card, Segment, Dimmer } from "semantic-ui-react";

export const Landing = (props) => {
    const {
        match: { path }
    } = props;

    const org = useSelector((state) => state.org.current);

    const navItemsGenerator = [
        {
            icon: "dashboard",
            title: <Trans>dashboard</Trans>,
            onClick: null,
            path: `${path}dashboard`,
            color: "red",
            display: true
        },
        {
            icon: "chart pie",
            title: <Trans>globalview</Trans>,
            onClick: null,
            path: `${path}globalview`,
            color: "yellow",
            display: true
        },
        {
            icon: "grid layout",
            title: <Trans>customdashboard</Trans>,
            onClick: null,
            path: `${path}customdashboard`,
            color: "blue",
            display: true
        },
        {
            icon: "sitemap",
            title: <Trans>hierarchy</Trans>,
            onClick: null,
            path: `${path}hierarchy`,
            color: "grey",
            display: true
        },
        {
            icon: "chart bar",
            title: <Trans>advancedanalytics</Trans>,
            onClick: null,
            path: `${path}advancedanalytics`,
            color: "violet",
            display: true
        },
        {
            icon: "file",
            title: <Trans>reports</Trans>,
            onClick: null,
            path: `${path}reports`,
            color: "purple",
            display: true
        },
        {
            icon: "chart line",
            title: <Trans>energysavings</Trans>,
            onClick: null,
            path: `${path}energysavings`,
            color: "yellow",
            display: true
        },
        {
            icon: "envelope open",
            title: <Trans>exports</Trans>,
            onClick: null,
            path: `${path}exports`,
            color: "brown",
            display: true
        },
        {
            icon: "bullhorn",
            title: <Trans>alerts_activity</Trans>,
            onClick: null,
            path: `${path}alerts_activity`,
            color: "green",
            display: true
        },
        {
            icon: "alarm",
            title: <Trans>alerts_packs</Trans>,
            onClick: null,
            path: `${path}alerts_packs`,
            color: "red",
            display: true
        },
        {
            icon: "building",
            title: <Trans>manage</Trans>,
            onClick: null,
            path: `${path}manage`,
            color: "teal",
            display: true
        }
    ];

    const renderMenuItem = (item) => {
        const { icon, color, title, flipped } = item;
        return (
            <Card style={{ backgroundColor: "rgba(0, 0, 0, 0)", boxShadow: "none" }}>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    <Icon name={icon} circular color={color} inverted size="huge" flipped={flipped} />
                </Card.Content>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    <Card.Header>{title}</Card.Header>
                </Card.Content>
            </Card>
        );
    };

    return (
        <>
            <Dimmer.Dimmable as={Segment} basic dimmed={org === null}>
                <Dimmer active={org === null} inverted />
                <Divider hidden section />
                <Grid container textAlign="left">
                    {_.chain(navItemsGenerator)
                        .filter((item) => item.display === true)
                        .map((item, idx) => {
                            return item.onClick ? (
                                <Grid.Column
                                    key={idx}
                                    mobile="8"
                                    computer="4"
                                    textAlign="center"
                                    onClick={item.onClick}
                                    style={{ cursor: "pointer" }}
                                >
                                    {renderMenuItem(item)}
                                </Grid.Column>
                            ) : (
                                <Grid.Column key={idx} mobile="8" computer="4" textAlign="center">
                                    {item.path && <Link to={item.path}>{renderMenuItem(item)}</Link>}
                                </Grid.Column>
                            );
                        })
                        .value()}
                </Grid>
                <Divider hidden section />
            </Dimmer.Dimmable>
        </>
    );
};

export default Landing;
