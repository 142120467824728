import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t, Trans } from "@lingui/macro";
import { Button, Grid, Icon, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetMeasurementLastValuesQuery } from "modules/dashboard/dashboardService";

import MessageDisplay from "modules/common/components/MessageDisplay";

const pollingInterval = 1000 * 60 * 10;

const DigitalInput = (props) => {
    const { measure } = props;
    const org = useSelector((state) => state.org);
    const current_lng = useSelector((state) => state.i18n.current);

    const measurementLastValues = useGetMeasurementLastValuesQuery(
        { org: org.current, measurement_id: measure?.id },
        { skip: !org.current || !_.isFinite(measure?.id) }
    );

    useEffect(() => {
        const measurementTimer = setInterval(() => {
            measurementLastValues.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(measurementTimer);
        };
    }, [measure, measurementLastValues, org]);

    if (measurementLastValues.isFetching) {
        return (
            <Grid.Column width={16}>
                <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />
            </Grid.Column>
        );
    }
    if (measurementLastValues.isFetching === false && (measurementLastValues.isError || _.isEmpty(measurementLastValues.data))) {
        return (
            <Grid.Column width={16}>
                <MessageDisplay
                    message={i18n._(t`unable to get last value`)}
                    level="warning"
                    iconName="warning circle"
                    isLoading={false}
                    attached={false}
                />
            </Grid.Column>
        );
    }
    if (measurementLastValues.isFetching === false && measurementLastValues.isSuccess) {
        const { intensive = null, state_change = null } = measurementLastValues.data;
        return (
            <>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Grid centered verticalAlign="middle">
                        <Grid.Column width={16} className="unpadded" textAlign="center">
                            <Button as="div" labelPosition="right">
                                <Button color={intensive === 1 ? "green" : "red"}>
                                    <Icon name="bolt" />
                                    <Trans>state</Trans>
                                </Button>
                                <Label basic pointing="left" color={intensive === 1 ? "green" : "red"}>
                                    {intensive === 1 ? i18n._(t`up`) : i18n._(t`down`)}
                                </Label>
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                {state_change !== null && moment(state_change).isValid() && (
                    <Grid.Column mobile={16} tablet={8} computer={12}>
                        <Grid centered stretched verticalAlign="middle">
                            <Grid.Column width={16} textAlign="center">
                                <span>
                                    <Trans>
                                        your device has been <strong>{intensive === 1 ? i18n._(t`up`) : i18n._(t`down`)}</strong> since
                                    </Trans>
                                    :<br />
                                    <strong>{` ${moment(state_change).locale(current_lng).format("LLL")}`}</strong>
                                </span>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                )}
            </>
        );
    }
    return null;
};

DigitalInput.propTypes = {
    measure: PropTypes.object.isRequired
};

export default DigitalInput;
