import React from "react";
import { Trans, t } from "@lingui/macro";
import { Field } from "react-final-form";
import { Divider } from "semantic-ui-react";

import { identityNull } from "modules/common/utils/form";
import i18n from "modules/i18n/i18nConfig";

import { InputAdapter } from "modules/common/components/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

export const AlertSensor = (props) => {
    return (
        <>
            <Divider horizontal>
                <Trans>alert sensor</Trans>
            </Divider>
            <Field
                name="configuration.elapsed_hour"
                placeholder={i18n._(t`enter time elapsed between 2 data`)}
                label={i18n._(t`time`)}
                unit={"h"}
                component={InputAdapter}
                parse={identityNull}
                inputMode="decimal"
                isRequired={true}
                helperText={
                    <Trans>
                        <p>alert sensor time helper</p>
                        <p>alert sensor time helper2</p>
                    </Trans>
                }
                validate={(value) => {
                    const elapsedValidate = validateNumber(value, i18n, false, false);
                    if (elapsedValidate !== undefined) {
                        return elapsedValidate;
                    }
                    const num = reparseNumber(value);
                    if (num < 6 || num > 720) {
                        return <Trans>sensor alert time minimum 6h maximum 30d</Trans>;
                    }
                    return undefined;
                }}
                defaultValue={6}
            />
        </>
    );
};

export default React.memo(AlertSensor);
