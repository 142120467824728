import { createListenerMiddleware } from "@reduxjs/toolkit";
import { timeFormatDefaultLocale } from "d3-time-format";
import { setLng } from "./i18nSlice";
import i18n from "./i18nConfig";
import { defaultLng, options } from "./i18nConfig";

export const i18nMiddleware = createListenerMiddleware();
i18nMiddleware.startListening({
    actionCreator: setLng,
    effect: async (action, listenerApi) => {
        let lng = defaultLng;
        if (action.payload) {
            lng = action.payload;
        }
        //Define default lng here. Now it's 'en'
        i18n.activate(lng);
        timeFormatDefaultLocale(options[lng]);
        localStorage.setItem("lng", lng);
    }
});
