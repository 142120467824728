import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const dataflowApi = defaultApi.injectEndpoints({
    reducerPath: "apiDataflow",
    endpoints: (build) => ({
        getDataflows: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `dataflows?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Dataflows"]
        }),
        getLastValues: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, pk_list }) => {
                const current_org = _.get(org, "name", null);
                return `dataflows/last_values?org=${current_org}&pk_list=${pk_list}&${refreshTimestampUrlParam()}`;
            }
        })
    }),
    overrideExisting: false
});

export const { useGetDataflowsQuery, useGetLastValuesQuery } = dataflowApi;
