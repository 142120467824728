import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Field, Form } from "react-final-form";
import { Grid, Segment, Header, Container, Button, Divider, Input } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import moment from "moment";
import Datetime from "react-datetime";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { checkRights } from "modules/auth/utils";
import { setEventForm } from "modules/machine/machineSlice";
import { useGetMachineEventQuery, useGetMachineEventsQuery, useGetMachinesQuery, useGetMaintenanceActionTypesQuery } from "../../machineService";
import { useGetComponenttypesQuery } from "modules/machine/componenttypeService";
import { useGetComponentsQuery } from "modules/machine/componentService";
import { eventTypeOptions } from "../../utils";
import { identity, identityNull } from "modules/common/utils/form";
import { toast_options_err } from "modules/notification/notificationMiddleware";
import history_app from "history_app";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { DropDownAdapter, InputAdapter, TextAreaAdapter, ToggleAdapter, renderError } from "modules/common/components/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

const TimeAdapter = (props) => {
    const now = moment();
    return (
        <div className={`field ${props.isRequired ? "required" : ""} ${props.meta.error ? "error" : ""}`}>
            <label>{props.label}</label>
            {renderError(props.meta)}
            <Datetime
                locale={props.locale}
                value={props.input.value}
                timeFormat="HH:mm"
                renderInput={({ ...rest }, openCalendar, closeCalendar) => {
                    return (
                        <div>
                            <Input labelPosition="left" {...rest} error />
                        </div>
                    );
                }}
                inputProps={{
                    error: !!props.meta.err,
                    fluid: true,
                    icon: "calendar",
                    disabled: props.disabled
                }}
                onChange={(moment_obj) => {
                    props.input.onChange(moment_obj);
                }}
                isValidDate={(current) => {
                    return current.isBefore(now);
                }}
            />
        </div>
    );
};

export const MachineEvent = (props) => {
    const dispatch = useDispatch();
    const { id_machine, id_event } = useParams();
    const { org, auth, machine, notification } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const machine_event = useGetMachineEventQuery({ org: org.current, data: id_event }, { skip: !org.current || id_event === undefined });
    const machine_events = useGetMachineEventsQuery({ org: org.current, id_machine }, { skip: !org.current || id_machine === undefined });
    const m_action_types = useGetMaintenanceActionTypesQuery({ org: org.current }, { skip: !org.current });
    const components = useGetComponentsQuery({ org: org.current }, { skip: !org.current });
    const componenttypes = useGetComponenttypesQuery({ org: org.current }, { skip: !org.current });

    const event_form = machine.event.form;
    const current_machine = _.find(machines.data, { id: parseInt(id_machine) });
    const rights = checkRights(current_machine, auth.rights);
    const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");

    const now = moment();
    const default_event = {
        title: "",
        description: "",
        event_date: now,
        event_type: 1,
        delay_training: 5,
        components_changed: false
    };

    const error_list = [machines.isError, machine_events.isError, m_action_types.isError, components.isError, componenttypes.isError];
    const loading_list = [machines.isLoading, machine_events.isLoading, m_action_types.isLoading, components.isLoading, componenttypes.isLoading];
    const status_list = [machines.isSuccess, machine_events.isSuccess, m_action_types.isSuccess, components.isSuccess, componenttypes.isSuccess];

    const submitForm = async (formData, form) => {
        try {
            const components_changed = _.get(formData, "components_changed", false);
            dispatch(
                setEventForm({
                    ...formData,
                    event_date: moment(formData.event_date).toISOString(),
                    machine: parseInt(id_machine),
                    delay_training: reparseNumber(formData.delay_training)
                })
            );
            form.getRegisteredFields().forEach((field) => {
                form.resetFieldState(field);
            });
            form.reset();
            if (components_changed) {
                history_app.push(`/kinematic/${id_machine}/change`);
            } else {
                history_app.push(`/machines/${id_machine}/maintenanceactions/declaration`);
            }
        } catch (error) {
            toast.error(i18n._(t`error during machine event submit`), toast_options_err);
        }
    };

    const global_load = _.some([...loading_list, machine_event.isLoading]);
    const global_err = _.some([...error_list, machine_event.isError]);
    const global_success = _.every([...status_list, id_event === undefined ? machine_event.isUninitialized : machine_event.isSuccess]);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "component", label: i18n._(t`name`), textAlign: "left" },
        { id: "maintenance_type", label: i18n._(t`maintenance_type`), textAlign: "left" }
    ];

    const maintenance_actions = _.chain(machine_event)
        .get("data.maintenanceaction_set", [])
        .reduce((res, item, idx) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const component = _.find(components.data, { id: item.component });
            const maintenance_type = _.find(m_action_types.data, { id: item.maintenance_type });
            const comp_type = _.find(componenttypes.data, { id: _.get(component, "comp_type") });
            if (component && comp_type && maintenance_type) {
                const comp_name = `${i18n._(_.get(component, "name", "-"))} (${i18n._(_.get(comp_type, "text", "-"))})`;
                const m_type_name = _.get(maintenance_type, "name", "-");
                const m_type_desc = _.get(maintenance_type, "description", "");
                const fmt_m_type_name = `${i18n._(m_type_name)} ${m_type_desc ? `(${i18n._(m_type_desc)})` : ""}`;

                res.push({
                    id: { render: null, value: idx + 1, textAlign: "left", datatype: "number" },
                    component: {
                        render: <span style={customStyle}>{comp_name}</span>,
                        value: comp_name,
                        textAlign: "left",
                        datatype: "string"
                    },
                    maintenance_type: {
                        render: <span style={customStyle}>{fmt_m_type_name}</span>,
                        value: fmt_m_type_name,
                        textAlign: "left",
                        datatype: "string"
                    }
                });
            }
            return res;
        }, [])
        .value();

    const initialValues = useMemo(() => {
        if (global_success) {
            return id_event
                ? { ...machine_event.data, event_date: moment(machine_event.data.event_date) }
                : event_form
                ? { ...event_form, event_date: moment(event_form.event_date) }
                : default_event;
        }
        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [global_success]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_event === undefined && <Trans>add machine event</Trans>}
                            {id_event !== undefined && <Trans>view machine event</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {global_load && <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />}
                        {!global_load && global_err && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {global_success && (
                            <Container>
                                <Form
                                    onSubmit={submitForm}
                                    initialValues={initialValues}
                                    render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                        return (
                                            <form onSubmit={handleSubmit} className="ui form">
                                                <Field
                                                    name="title"
                                                    placeholder={i18n._(t`enter title of machine event`)}
                                                    label={i18n._(t`title`)}
                                                    isRequired={true}
                                                    component={InputAdapter}
                                                    parse={identity}
                                                    defaultValue={""}
                                                    validate={(value) => {
                                                        if (!value) {
                                                            return <Trans>title is required</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                    disabled={id_event !== undefined}
                                                />
                                                <Field
                                                    name="description"
                                                    placeholder={i18n._(t`enter description of your machine event`)}
                                                    label={i18n._(t`description`)}
                                                    component={TextAreaAdapter}
                                                    defaultValue={""}
                                                    parse={identity}
                                                    disabled={id_event !== undefined}
                                                />
                                                <Field
                                                    name="event_date"
                                                    label={i18n._(t`event_date`)}
                                                    isRequired={true}
                                                    locale={current_lng}
                                                    component={TimeAdapter}
                                                    defaultValue={now}
                                                    validate={(value) => {
                                                        if (_.isString(value)) {
                                                            return <Trans>Invalide date</Trans>;
                                                        } else if (value > now) {
                                                            return <Trans>Invalide date</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                    disabled={id_event !== undefined}
                                                />
                                                <Field
                                                    name="event_type"
                                                    label={i18n._(t`event_type`)}
                                                    options={_.chain(eventTypeOptions)
                                                        .reduce((res, evt_type) => {
                                                            if (id_event === undefined && evt_type.key === 6) {
                                                                //exclude 'initial declaration event_type during add'
                                                                return res;
                                                            }
                                                            res.push({ ...evt_type, text: i18n._(evt_type.text) });
                                                            return res;
                                                        }, [])
                                                        .orderBy("text")
                                                        .value()}
                                                    component={DropDownAdapter}
                                                    isRequired={true}
                                                    defaultValue={1}
                                                    validate={(value) => {
                                                        if (!value) return <Trans>event type is required</Trans>;
                                                        return undefined;
                                                    }}
                                                    customAction={(data) => {
                                                        //force toggle btn to true if kinematic correction or initial kinematic
                                                        if (_.includes([6, 7], data)) {
                                                            form.change("components_changed", true);
                                                        }
                                                    }}
                                                    disabled={id_event !== undefined}
                                                />
                                                <Field
                                                    name="delay_training"
                                                    placeholder={i18n._(t`enter delay training`)}
                                                    label={i18n._(t`delay_training`)}
                                                    unit={"j"}
                                                    component={InputAdapter}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    isRequired={true}
                                                    helperText={
                                                        <Trans>
                                                            <p>delay training helper</p>
                                                        </Trans>
                                                    }
                                                    validate={(value) => {
                                                        return validateNumber(value, i18n, false, false);
                                                    }}
                                                    defaultValue={5}
                                                    disabled={id_event !== undefined}
                                                />
                                                <Field
                                                    name="components_changed"
                                                    label={i18n._(t`components_changed`)}
                                                    labelposition="right"
                                                    component={ToggleAdapter}
                                                    //force toggle btn disabled if kinematic correction or initial kinematic
                                                    //prevent user to change toggle
                                                    disabled={_.includes([6, 7], values.event_type) || id_event !== undefined}
                                                />
                                                {id_event === undefined && can_change && (
                                                    <>
                                                        <Divider />
                                                        <Button type="submit" content={i18n._(t`next`)} disabled={submitting || invalid} />
                                                    </>
                                                )}
                                            </form>
                                        );
                                    }}
                                />
                                {id_event !== undefined && (
                                    <>
                                        <Header as="h4" attached="top" block textAlign="center">
                                            <Trans>your maintenanceactions</Trans>
                                        </Header>
                                        <Segment attached>
                                            <TableEnhanced
                                                headCells={headCells}
                                                rows={maintenance_actions}
                                                textItemPerPages={i18n._(t`items per page`)}
                                                page={page}
                                                rowsPerPage={itemsPerPage}
                                                setPage={(page) => {
                                                    setPage(page);
                                                }}
                                                setItemsPerPage={(items) => {
                                                    setItemsPerPage(items);
                                                }}
                                                emptyMsg={i18n._(t`no maintenance actions`)}
                                            />
                                        </Segment>
                                    </>
                                )}
                            </Container>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default MachineEvent;
