import React from "react";
import PropTypes from "prop-types";
import { Message, Icon } from "semantic-ui-react";

export default function MessageDisplay(props) {
    const { level, message, iconName, isLoading, attached, customStyle } = props;

    return (
        <Message
            error={level === "error"}
            warning={level === "warning"}
            info={level === "info"}
            success={level === "success"}
            attached={attached}
            style={{ display: "block", ...customStyle }}
        >
            {iconName && (<Icon name={iconName} loading={isLoading} />)}
            {message}
        </Message>
    );
}

MessageDisplay.defaultProps = {
    attached: true,
    customStyle: null
};

MessageDisplay.propTypes = {
    level: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired
};
