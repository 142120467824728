import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const measurementApi = defaultApi.injectEndpoints({
    reducerPath: "apiMeasurement",
    endpoints: (build) => ({
        getConsumptions: build.query({
            keepUnusedDataFor: 120,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `consumptions?org=${current_org}&${refreshTimestampUrlParam()}`;
            }
        }),
        getStandbyThreshold: build.query({
            keepUnusedDataFor: 0,
            query: ({ org, measure, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `/measurements/${measure.id}/standby_consumption?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            }
        }),
        getMeasurements: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `measurements?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            transformResponse: (response, meta, arg) => {
                return response;
            },
            providesTags: ["Measurements"]
        })
    }),
    overrideExisting: false
});

export const { useGetMeasurementsQuery, useGetConsumptionsQuery, useGetStandbyThresholdQuery } = measurementApi;
