import React, { useState } from "react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Button, Modal, Dropdown, Input, Grid, Popup } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { status_list } from "../utils";

import LoginHistoryTable from "./LoginHistoryTable";

const LoginHistoryModal = (props) => {
    const { user_id } = props; //only when user check his profile. Else undefined
    const [open, setOpen] = useState(false);
    const [localSearchName, setLocalSearchName] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(status_list[0].value);

    const closeModal = () => {
        setLocalSearchName("");
        setSelectedStatus(status_list[0].value);
    };

    return (
        <Modal
            onClose={() => {
                setOpen(false);
                closeModal();
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                user_id === undefined ? (
                    <Button type="button" content={i18n._(t`Login history`)} icon="history" labelPosition="left" />
                ) : (
                    <Popup
                        trigger={
                            <Button
                                type="button"
                                icon="history"
                                onClick={() => {
                                    setOpen(true);
                                }}
                            ></Button>
                        }
                    >
                        <Popup.Content>
                            <Trans>Login history</Trans>
                        </Popup.Content>
                    </Popup>
                )
            }
        >
            <Modal.Header>
                <Trans>Login history</Trans>
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row style={{ marginLeft: ".2rem" }}>
                        {user_id === undefined && (
                            <Grid.Column style={{ marginBottom: ".2rem" }} mobile={16} computer={6}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search by email`)}
                                    onChange={(e, { value }) => {
                                        setLocalSearchName(value);
                                    }}
                                    value={localSearchName}
                                />
                            </Grid.Column>
                        )}
                        <Grid.Column mobile={16} computer={5}>
                            <Dropdown
                                fluid
                                button
                                className="icon"
                                labeled
                                icon="newspaper outline"
                                options={_.map(status_list, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                placeholder={i18n._(t`select status`)}
                                selection
                                onChange={(e, { value }) => {
                                    setSelectedStatus(value);
                                }}
                                value={selectedStatus}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {open && <LoginHistoryTable searchEmail={localSearchName} selectedStatus={selectedStatus} user_id={user_id} />}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        closeModal();
                    }}
                >
                    <Trans>Close</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default LoginHistoryModal;
