import { t } from "@lingui/macro";

export const typeImportOptions = [
    { key: 0, text: t`Undefined`, value: 0 },
    { key: 1, text: t`users`, value: 1 },
    { key: 2, text: t`ton`, value: 2 },
    { key: 3, text: t`unit`, value: 3 },
    { key: 4, text: t`hour`, value: 4 },
    { key: 5, text: t`Cost`, value: 5 }
];
