import React, { useMemo, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import _ from "lodash";
import { Dropdown, Icon, Menu, Modal } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';

export const DiagramsChoice = (props) => {
    const { diagrams, id, setSelectedDiagram, current_diagram, user_email } = props;
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const items = useMemo(() => {
        if (_.size(diagrams) === 0) {
            return [];
        }
        const group_by_user = _.chain(diagrams).groupBy('owner_email').value();
        const owner_diagram = _.pick(group_by_user, user_email);
        const other_diagram = _.omit(group_by_user, user_email);

        return _.reduce({ ...owner_diagram, ...other_diagram }, (res, diagram, email) => {
            if (_.size(diagram) > 0) {
                res.push(<Dropdown.Header key={email} icon="user" content={email} />);
                const remap_diagram = _.chain(diagram)
                    .orderBy('default_date', 'desc')
                    .reduce((res, item, idx) => {
                        const { key, text, value, description } = item;
                        if (search === "" || text.toLowerCase().includes(search.toLowerCase())) {
                            res.push(<Dropdown.Item
                                key={key}
                                text={text}
                                value={value}
                                description={description}
                                icon={idx === 0 && user_email === email ? { name: "home" } : null}
                                onClick={(e) => {
                                    setSelectedDiagram(item.id);
                                    setSearch("");
                                    setOpen(false);
                                }}
                            />);
                        }
                        return res;
                    }, [])
                    .value();
                if (_.size(remap_diagram) === 0) {
                    res.push(<Dropdown.Item key={`${email}_null`} text={i18n._(t`no result found`)} />);
                } else {
                    res.push(...remap_diagram);
                }

            }
            return res;
        }, []);
    }, [diagrams, search, user_email, setSelectedDiagram]);

    return (
        <Modal
            centered={false}
            onClose={() => {
                setOpen(false);
                setSearch("");
            }}
            open={open}
            trigger={
                <Menu.Item onClick={(e) => setOpen(true)} disabled={id !== undefined || _.size(diagrams) === 0}>
                    {_.size(diagrams) > 0 && current_diagram && (
                        <>{current_diagram?.name ?? '-'}<Icon name="sort" /></>
                    )}
                    {_.size(diagrams) === 0 && <Trans>You have no diagram</Trans>}
                    {(_.size(diagrams) > 0 && current_diagram === undefined) && (<><Trans>Select a diagram</Trans><Icon name="sort" /></>)}
                </Menu.Item>
            }
        >
            <Modal.Header><Trans>Select a diagram</Trans></Modal.Header>
            <Modal.Content>
                <Dropdown
                    className="selection"
                    searchInput={{ autoFocus: true }}
                    search
                    fluid
                    onSearchChange={(e, data) => {
                        setSearch(data.searchQuery);
                    }}
                >
                    <Dropdown.Menu>
                        {items}
                    </Dropdown.Menu>
                </Dropdown>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(DiagramsChoice);