import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Grid, Segment, Header, Button } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";
import { setAlertPackOverride } from "modules/alert/alert_pack/alertPackSlice";

import { InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";
import AlertSensorModal from "./AlertSensorModal";

export const AlertSensorOverride = (props) => {
    const dispatch = useDispatch();
    const { pack, rights, sensors, overrides, notification } = props;

    const onSubmitForm = async (data) => {
        if (notification.srv_status.db_status === "rw") {
            let formdata = data;
            if (_.isEmpty(data)) {
                //trick to prevent empty formData cause by fields.remove(index) in FieldArray
                formdata = { sensors_exception: [] };
            }

            formdata = {
                ...formdata,
                sensors_exception: _.map(formdata.sensors_exception, (exception) => {
                    return {
                        ...exception,
                        override: _.mapValues(exception?.override, (item, key) => {
                            if (_.includes(keyToReparseInPack, key)) {
                                return reparseNumber(item);
                            }
                            return item;
                        })
                    };
                })
            };

            await dispatch(setAlertPackOverride({ formdata, pack_id: pack.id, type: ["sensors_exception"] }));
        }
    };

    const initial = useMemo(() => {
        return {
            sensors_exception: _.reduce(
                overrides,
                (res, item) => {
                    const sensor = _.find(sensors, { id: item.object_id });
                    if (_.get(sensor, "identifier")) {
                        res.push({ ...item, identifier: _.get(sensor, "identifier") });
                    }
                    return res;
                },
                []
            )
        };
    }, [overrides, sensors]);

    return (
        <Segment.Group>
            <Header as="h4" attached="top" block textAlign="center">
                <Trans>sensors exception</Trans>
            </Header>
            <Segment attached>
                <Form
                    onSubmit={onSubmitForm}
                    mutators={{ ...arrayMutators }}
                    initialValues={initial}
                    render={({
                        handleSubmit,
                        form: {
                            mutators: { push }
                        },
                        pristine,
                        submitting,
                        values,
                        invalid
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                    <Segment basic>
                                        <AlertSensorModal
                                            push={push}
                                            pack={pack}
                                            sensors={sensors}
                                            sensors_exception={_.get(values, "sensors_exception", [])}
                                        />
                                    </Segment>
                                )}
                                {_.size(values.sensors_exception) === 0 && (
                                    <MessageDisplay
                                        message={i18n._(t`no sensors exception`)}
                                        level="info"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                <FieldArray name="sensors_exception">
                                    {({ fields }) =>
                                        fields.map((name, index) => {
                                            return (
                                                <Segment key={name} basic>
                                                    <Grid verticalAlign="bottom">
                                                        <Grid.Row>
                                                            <Grid.Column mobile={16} tablet={5} computer={5}>
                                                                <Field
                                                                    name={`${name}.identifier`}
                                                                    label={i18n._(t`sensor id`)}
                                                                    component={InputAdapter}
                                                                    readOnly
                                                                    validate={(value) => (!value ? <Trans>identifier is required</Trans> : undefined)}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column mobile={16} tablet={5} computer={5}>
                                                                <Field
                                                                    name={`${name}.override.elapsed_hour`}
                                                                    label={i18n._(t`time`)}
                                                                    component={InputAdapter}
                                                                    isRequired={true}
                                                                    parse={identityNull}
                                                                    inputMode="decimal"
                                                                    unit={"h"}
                                                                    validate={(value) => {
                                                                        const elapsedValidate = validateNumber(value, i18n, false, false);
                                                                        if (elapsedValidate !== undefined) {
                                                                            return elapsedValidate;
                                                                        }
                                                                        const num = reparseNumber(value);
                                                                        if (num < 6 || num > 720) {
                                                                            return <Trans>sensor alert time minimum 6h maximum 30d</Trans>;
                                                                        }
                                                                        return undefined;
                                                                    }}
                                                                />
                                                            </Grid.Column>
                                                            {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                                                <Grid.Column mobile={16} tablet={1} computer={1}>
                                                                    <Button
                                                                        type="button"
                                                                        icon="trash"
                                                                        color="red"
                                                                        onClick={() => {
                                                                            fields.remove(index);
                                                                        }}
                                                                    />
                                                                </Grid.Column>
                                                            )}
                                                        </Grid.Row>
                                                    </Grid>
                                                </Segment>
                                            );
                                        })
                                    }
                                </FieldArray>
                                <Segment basic>
                                    {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                        <Button type="submit" content={i18n._(t`validate`)} positive disabled={submitting || pristine || invalid} />
                                    )}
                                </Segment>
                            </form>
                        );
                    }}
                />
            </Segment>
        </Segment.Group>
    );
};

AlertSensorOverride.propTypes = {
    sensors: PropTypes.array.isRequired,
    pack: PropTypes.object.isRequired,
    rights: PropTypes.array.isRequired
};

export default AlertSensorOverride;
