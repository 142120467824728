import { t } from "@lingui/macro";

export const eventTypeOptions = [
    { key: 1, value: 1, text: t`preventive maintenance`, icon: "hand paper" },
    { key: 2, value: 2, text: t`machine failure`, icon: "warning sign" },
    { key: 3, value: 3, text: t`correction measure`, icon: "edit" },
    { key: 4, value: 4, text: t`configuration change`, icon: "exchange" },
    { key: 5, value: 5, text: t`component failure`, icon: "warning circle" },
    { key: 6, value: 6, text: t`initial declaration`, icon: "file alternate outline" },
    { key: 7, value: 7, text: t`kinematic correction`, icon: "gg circle" }
];

export const maintenanceactiontypes_description = {
    light_maintenance: t`description_light_maintenance`,
    shaft_realignment: "",
    shaft_replacement: "",
    shaft_light_maintenance: t`description_shaft_light_maintenance`,
    motor_replacement: "",
    stator_rewiring: "",
    motor_supply_change: t`description_motor_supply_change`,
    gearbox_maintenance: "",
    gearbox_replacement: "",
    bearing_light_maintenance: t`description_bearing_light_maintenance`,
    bearing_replacement: "",
    bearing_maintenance: t`description_bearing_maintenance`,
    belt_maintenance: t`description_belt_maintenance`,
    belt_replacement: "",
    machine_reassembly: t`description_machine_reassembly`,
    predict_replacement: t`description_predict_replacement`,
    predict_reset: t`description_predict_reset`
};
