import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import tinycolor from "tinycolor2";
import { t, Trans } from "@lingui/macro";
import { Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Palette from "modules/common/components/graphic/Colors";
import { rewriteCalendarType } from "modules/globalview/globalviewMiddleware";

const Synthesis = (props) => {
    const { default_global_filter } = props;
    const { globalview, unit } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const state = _.get(globalview, "synthesis", null);
    const site_id = _.get(globalview, "global_filter.site", default_global_filter.site);
    const unit_id = _.get(globalview, "global_filter.unit", default_global_filter.unit);

    let current_unit = "";

    if (unit_id > 0) {
        current_unit = _.chain(unit.units).find({ id: unit_id }).get("symbol").value();
    } else if (unit_id === "kgCo2e") {
        current_unit = "kgCo2e";
    } else {
        //Get first site from site filter (Here all sites return by request have same currency: Filtering before request)
        const first_full_site = _.chain(site_id)
            .reduce((res, item) => {
                const full_site = _.find(props.sites, { id: item });
                if (full_site && _.get(full_site, "conversions.currency", null) === unit_id) {
                    res.push(full_site);
                }
                return res;
            }, [])
            .head()
            .value();
        current_unit = _.get(first_full_site, "conversions.currency", "-");
    }

    const todo_compare = _.get(globalview, "global_filter.todo_compare", default_global_filter.todo_compare);
    const calendar_type = _.get(globalview, "global_filter.calendar_type", default_global_filter.calendar_type);

    const renderDataflowSvg = (idx, value) => {
        const color =
            idx === null
                ? tinycolor(Palette.circles[Palette.circles.length - 4])
                      .setAlpha(0.6)
                      .toString()
                : tinycolor(Palette.circles[(idx - 1) % Palette.circles.length])
                      .setAlpha(0.6)
                      .toString();
        return (
            <div style={{ display: "flex" }}>
                <span style={{ flex: 0 }}>
                    <svg height="10" width="14" style={{ marginRight: "5px" }}>
                        <rect height="10" width="14" style={{ fill: color }} />
                    </svg>
                </span>
                <span style={{ flex: 1, whiteSpace: "nowrap" }}>{value}</span>
            </div>
        );
    };

    const renderLabelTime = (translation, time_type) => {
        const time = _.get(globalview, `global_filter.${time_type}`, null);
        if (time) {
            return (
                <span style={{ whiteSpace: "nowrap" }}>{`${i18n._(translation)} ${_.lowerCase(i18n._(t`from`))} ${moment(time.start)
                    .locale(current_lng)
                    .format("L")} ${_.lowerCase(i18n._(t`to`))} ${moment(time.end).locale(current_lng).format("L")}`}</span>
            );
        }
        return (
            <span style={{ whiteSpace: "nowrap" }}>{`${i18n._(translation)} ${_.lowerCase(i18n._(t`from`))} ${default_global_filter?.[
                time_type
            ]?.start
                .locale(current_lng)
                .format("L")} ${_.lowerCase(i18n._(t`to`))} ${default_global_filter?.[time_type]?.end.locale(current_lng).format("L")}`}</span>
        );
    };

    let headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "total", label: !todo_compare ? i18n._(t`total`) : renderLabelTime(t`Period total consumption`, "time"), textAlign: "right" },
        {
            id: "distribution",
            label: !todo_compare ? i18n._(t`global_distribution`) : renderLabelTime(t`global_distribution`, "time"),
            textAlign: "right"
        },
        { id: "minimum", label: i18n._(t`minimum`), textAlign: "right" },
        { id: "average", label: i18n._(t`average`), textAlign: "right" },
        { id: "maximum", label: i18n._(t`maximum`), textAlign: "right" },
        { id: "min_day", label: i18n._(t`minimum day`), textAlign: "right" },
        { id: "max_day", label: i18n._(t`maximum day`), textAlign: "right" }
    ];

    switch (rewriteCalendarType(calendar_type)) {
        case 1:
        case 2:
            if (todo_compare) {
                headCells.splice(4, 0, { id: "total_comp", label: renderLabelTime(t`Period total consumption`, "compare_time"), textAlign: "right" });
                headCells.splice(5, 0, {
                    id: "distribution_comp",
                    label: renderLabelTime(t`global_distribution`, "compare_time"),
                    textAlign: "right"
                });
            }
            break;
        default:
            if (todo_compare) {
                headCells.splice(4, 0, { id: "total_opening", label: renderLabelTime(t`Total opening`, "time"), textAlign: "right" });
                headCells.splice(5, 0, { id: "total_closing", label: renderLabelTime(t`Total closing`, "time"), textAlign: "right" });
                headCells.splice(6, 0, { id: "total_comp", label: renderLabelTime(t`Period total consumption`, "compare_time"), textAlign: "right" });
                headCells.splice(7, 0, {
                    id: "distribution_comp",
                    label: renderLabelTime(t`global_distribution`, "compare_time"),
                    textAlign: "right"
                });
                headCells.splice(8, 0, { id: "total_opening_comp", label: renderLabelTime(t`Total opening`, "compare_time"), textAlign: "right" });
                headCells.splice(9, 0, { id: "total_closing_comp", label: renderLabelTime(t`Total closing`, "compare_time"), textAlign: "right" });
            } else {
                headCells.splice(4, 0, { id: "total_opening", label: i18n._(t`Total opening`), textAlign: "right" });
                headCells.splice(5, 0, { id: "total_closing", label: i18n._(t`Total closing`), textAlign: "right" });
            }
            break;
    }

    const { ref_total, comp_total } = _.chain(state)
        .get("data", [])
        .reduce(
            (res, serie) => {
                if (res.ref_total === null) {
                    res.ref_total = _.isFinite(serie.total) ? serie.total : null;
                } else {
                    if (_.isFinite(serie.total)) {
                        res.ref_total = res.ref_total + serie.total;
                    }
                }
                if (res.comp_total === null) {
                    res.comp_total = _.isFinite(serie.total_comp) ? serie.total_comp : null;
                } else {
                    if (_.isFinite(serie.total_comp)) {
                        res.comp_total = res.comp_total + serie.total_comp;
                    }
                }

                return res;
            },
            { ref_total: null, comp_total: null }
        )
        .value();

    const series = _.map(state.data, (record, idx) => {
        let ref_distri = null;
        let comp_distri = null;
        if (_.isFinite(record.total) && _.isFinite(ref_total)) {
            ref_distri = (record.total / Math.abs(ref_total)) * 100;
        }
        if (_.isFinite(record.total_comp) && _.isFinite(comp_total)) {
            comp_distri = (record.total_comp / Math.abs(comp_total)) * 100;
        }

        return {
            id: { render: null, value: record.id, textAlign: "left", datatype: "number" },
            name: { render: renderDataflowSvg(record.id, i18n._(record.name)), value: i18n._(record.name), textAlign: "left", datatype: "string" },
            total: {
                render: !_.isFinite(record.total) ? "-" : i18n.number(record.total, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.total) ? null : record.total,
                textAlign: "right",
                datatype: "number"
            },
            distribution: {
                render: !_.isFinite(ref_distri) ? "-" : i18n.number(ref_distri, { maximumFractionDigits: 2 }),
                value: !_.isFinite(ref_distri) ? null : ref_distri,
                textAlign: "right",
                datatype: "number"
            },
            total_comp: {
                render: !_.isFinite(record.total_comp) ? "-" : i18n.number(record.total_comp, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.total_comp) ? null : record.total_comp,
                textAlign: "right",
                datatype: "number"
            },
            distribution_comp: {
                render: !_.isFinite(comp_distri) ? "-" : i18n.number(comp_distri, { maximumFractionDigits: 2 }),
                value: !_.isFinite(comp_distri) ? null : comp_distri,
                textAlign: "right",
                datatype: "number"
            },
            total_opening: {
                render: !_.isFinite(record.total_opening) ? "-" : i18n.number(record.total_opening, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.total_opening) ? null : record.total_opening,
                textAlign: "right",
                datatype: "number"
            },
            total_closing: {
                render: !_.isFinite(record.total_closing) ? "-" : i18n.number(record.total_closing, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.total_closing) ? null : record.total_closing,
                textAlign: "right",
                datatype: "number"
            },
            total_opening_comp: {
                render: !_.isFinite(record.total_opening_comp) ? "-" : i18n.number(record.total_opening_comp, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.total_opening_comp) ? null : record.total_opening_comp,
                textAlign: "right",
                datatype: "number"
            },
            total_closing_comp: {
                render: !_.isFinite(record.total_closing_comp) ? "-" : i18n.number(record.total_closing_comp, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.total_closing_comp) ? null : record.total_closing_comp,
                textAlign: "right",
                datatype: "number"
            },
            minimum: {
                render: !_.isFinite(record.minimum) ? "-" : i18n.number(record.minimum, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.minimum) ? null : record.minimum,
                textAlign: "right",
                datatype: "number"
            },
            average: {
                render: !_.isFinite(record.average) ? "-" : i18n.number(record.average, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.average) ? null : record.average,
                textAlign: "right",
                datatype: "number"
            },
            maximum: {
                render: !_.isFinite(record.maximum) ? "-" : i18n.number(record.maximum, { maximumFractionDigits: 2 }),
                value: !_.isFinite(record.maximum) ? null : record.maximum,
                textAlign: "right",
                datatype: "number"
            },
            min_day: {
                render: record.min_day ? moment(record.min_day).locale(current_lng).format("L") : "-",
                value: record.min_day ? moment(record.min_day) : null,
                textAlign: "right",
                datatype: "date"
            },
            max_day: {
                render: record.max_day ? moment(record.max_day).locale(current_lng).format("L") : "-",
                value: record.max_day ? moment(record.max_day) : null,
                textAlign: "right",
                datatype: "date"
            }
        };
    });

    return (
        <>
            <Header as="h3" block textAlign="center" attached="top">
                <Trans>synthesis table</Trans>
                {` (${current_unit})`}
            </Header>
            {_.get(state, "status") === "loading" && (
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            )}
            {_.get(state, "status") === "failed" && (
                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
            )}
            {_.get(state, "status") === "succeeded" && (
                <Segment attached>
                    <TableEnhanced
                        headCells={headCells}
                        rows={series}
                        textItemPerPages={i18n._(t`items per page`)}
                        orderBy="total"
                        order="desc"
                        attached
                    />
                </Segment>
            )}
        </>
    );
};

export default Synthesis;
