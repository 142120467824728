import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const unitApi = defaultApi.injectEndpoints({
    reducerPath: "apiUnit",
    endpoints: (build) => ({
        getUnits: build.query({
            keepUnusedDataFor: 86400,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `units?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                //Map data for semantic usage
                return [
                    ..._.map(response, (unit) => {
                        return {
                            ...unit,
                            key: unit.id,
                            text: unit.symbol,
                            value: unit.id,
                            base: unit.base_unit === null ? "true" : "false",
                            type: "unit"
                        };
                    }),
                    { key: -1, text: "auto unit", value: -1, base: null, type: "unit" }
                ];
            },
            providesTags: ["Unit"]
        })
    }),
    overrideExisting: false
});

export const { useGetUnitsQuery } = unitApi;
