import { t } from '@lingui/macro';
import { toast } from 'react-toastify';
import { updateEquipmentImage } from 'modules/equipment/equipmentSlice';

import i18n from "modules/i18n/i18nConfig";
import { createAlertPack, deletePack, setAlertPackOverride } from 'modules/alert/alert_pack/alertPackSlice';

export const toast_options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
};

export const toast_options_err = {
    ...toast_options,
    autoClose: false
};

export const notificationMiddleware = (store) => (next) => (action) => {
    if (updateEquipmentImage.pending.match(action)) {
        toast.info(i18n._(t`send image to server`), toast_options);
    }
    if (updateEquipmentImage.rejected.match(action)) {
        toast.error(i18n._(t`cannot update image of equipment`), toast_options_err);
    }
    if (updateEquipmentImage.fulfilled.match(action)) {
        toast.success(i18n._(t`successful update image`), toast_options);
    }
    if (deletePack.fulfilled.match(action)) {
        toast.success(i18n._(t`successful delete alertpack`), toast_options);
    }
    if (deletePack.rejected.match(action)) {
        toast.error(i18n._(t`cannot delete alertpack`), toast_options_err);
    }
    if (setAlertPackOverride.fulfilled.match(action)) {
        toast.success(i18n._(t`successful update alertpack override`), toast_options);
    }
    if (createAlertPack.rejected.match(action)) {
        toast.error(i18n._(t`error add pack`), toast_options_err);
    }

    return next(action);
};
