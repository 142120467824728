import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import moment from "moment";
import _ from "lodash";
import { Form, Grid, Button } from "semantic-ui-react";
import { Media } from "App";

import i18n from "modules/i18n/i18nConfig";
import { getClusters, resetTab, setTime } from "modules/overview/overviewSlice";
import { dateRangeOptions } from "modules/time/utils";
import { useGetMachineEventsQuery } from "modules/machine/machineService";

import TimePanel from "modules/common/components/TimePanel";
import MessageDisplay from "modules/common/components/MessageDisplay";
import HealthScoreRow from "../healthscore/HealthScoreRow";
import ClusterPanel from "../healthscore/ClusterPanel";

const TabPredictHealthScore = (props) => {
    const { equipment, default_time } = props;
    const machine = _.get(equipment, "machine") || undefined;

    const components = _.chain(machine).get("components").orderBy(["comp_type", "id"]).value();

    const tab = "tabpredicthealthscore";
    const dispatch = useDispatch();
    const { org, healthscoretype } = useSelector((state) => state);
    const { tabpredicthealthscore, predicttime } = useSelector((state) => state.overview);
    const clustersOptions = _.get(tabpredicthealthscore, "clusters.data", []);

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["24h", "48h", "72h"], item.value));
    const rangeTime = predicttime.time
        ? {
              start: moment(predicttime.time.start),
              end: moment(predicttime.time.end),
              plage: predicttime.time.plage
          }
        : default_time;

    useEffect(() => {
        !_.isUndefined(machine) && dispatch(getClusters({ org: org.current, machine, tab, rangeTime, type: "clusters" }));
        return () => {
            dispatch(resetTab(tab));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, predicttime.time]);

    const machine_events = useGetMachineEventsQuery({ org: org.current, id_machine: machine?.id }, { skip: !org.current || machine === undefined });

    return (
        <Grid id="pwaMain" verticalAlign="middle" centered>
            <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                    <TimePanel
                        time={rangeTime}
                        rangeOptions={rangeOptions}
                        accordion={true}
                        rounded={60}
                        action={({ start, end, plage }) => {
                            dispatch(setTime({ start, end, plage, tab: "predicttime" }));
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} computer={14} textAlign="center">
                    <Form.Field className="pwaClusters">
                        {_.isUndefined(machine) && (
                            <MessageDisplay
                                message={i18n._(t`machine unknown`)}
                                level="warning"
                                iconName="info circle"
                                isLoading={false}
                                attached={false}
                            />
                        )}
                        {_.includes(["idle", "loading"], tabpredicthealthscore.clusters.status) && (
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                attached={false}
                                iconName="circle notched"
                                isLoading={true}
                            />
                        )}
                        {tabpredicthealthscore.clusters.status === "failed" && (
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        )}
                        {tabpredicthealthscore.clusters.status === "succeeded" && (
                            <>
                                <Media lessThan="computer">
                                    <Button attached="top">
                                        <Trans>selection of your clusters</Trans>
                                    </Button>
                                </Media>
                                <Media greaterThanOrEqual="computer">
                                    <Button attached="left">
                                        <Trans>selection of your clusters</Trans>
                                    </Button>
                                </Media>
                                <ClusterPanel />
                            </>
                        )}
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            {_.size(clustersOptions) > 0 && (
                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        {_.size(components) === 0 && (
                            <MessageDisplay message={i18n._(t`machine has no components`)} level="warning" iconName="info circle" isLoading={false} />
                        )}
                        {_.chain(components)
                            .reduce((res, component, idx_comp) => {
                                const my_hsts = _.filter(healthscoretype.healthscoretypes, { component_type: component.comp_type });
                                if (_.size(my_hsts) > 0) {
                                    _.each(my_hsts, (hst, idx_hst) => {
                                        res.push(
                                            <React.Fragment key={`${idx_comp}_${idx_hst}`}>
                                                <HealthScoreRow
                                                    index={idx_comp}
                                                    component={component}
                                                    hst={hst}
                                                    time={rangeTime}
                                                    clusters={clustersOptions}
                                                    events={machine_events.data}
                                                />
                                            </React.Fragment>
                                        );
                                    });
                                }
                                return res;
                            }, [])
                            .value()}
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};

export default TabPredictHealthScore;
