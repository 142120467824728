import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Modal, Button, Icon, Grid, Divider, Popup } from 'semantic-ui-react';
import { Form, Field } from "react-final-form";


import i18n from 'modules/i18n/i18nConfig';
import { InputAdapter, TextAreaAdapter } from 'modules/common/components/form';
import { useAddZoneMutation, useGetZonesQuery, useUpdateZoneMutation } from '../areaService';

import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import { identity } from 'modules/common/utils/form';


const AreaModalForm = (props) => {
    const { item, canChange, id_site } = props;

    const [open, setOpen] = useState(false);
    const { org } = useSelector(state => state);
    const [createArea, create] = useAddZoneMutation();
    const [updateArea, update] = useUpdateZoneMutation();

    const areas = useGetZonesQuery({ org: org.current }, { skip: !org.current });

    // Create Usage
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`new area added`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            toast.error(i18n._(t`cannot add new area`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError]);

    // Update Usage
    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update area`), toast_options);
            setOpen(false);
        }
        if (update.isError) {
            toast.error(i18n._(t`cannot update area`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isLoading, update.isSuccess, update.isError]);

    const initialValues = useMemo(() => {
        const defaultValues = { name: "", description: "" };
        return {
            ...defaultValues,
            site_id: id_site,
            ...item
        };
    }, [item, id_site]);

    const submitForm = async (formData) => {
        const { id, name, description, site_id } = formData;
        if (canChange) {
            if (formData.id) {
                await updateArea({ org: org.current, data: { id, name, description, site_id } });
            } else {
                await createArea({ org: org.current, data: { name, description, site_id } });
            }
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = i18n._(t`area is required`);
        }
        const existing_name = _.chain(areas.data)
            .reduce((res, area) => {
                if (area?.site_id === id_site) {
                    res.push(area);
                }
                return res;
            }, [])
            .filter((area) => {
                if (item === undefined) return true;
                return area.id !== parseInt(item.id);
            })
            .find({ name: values.name })
            .value();
        if (existing_name) {
            errors.name = i18n._(t`this area already exists`);
        }
        return errors;
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={item?.id ?
                <Popup trigger={
                    <Button
                        type="button"
                        icon={canChange ? "edit" : "eye"}
                        onClick={() => setOpen(true)} />}>
                    <Popup.Content>
                        {canChange && <Trans>Area edition</Trans>}
                        {!canChange && <Trans>Area view</Trans>}
                    </Popup.Content>
                </Popup>
                :
                <Button
                    disabled={!canChange}
                    type="button"
                    icon
                    labelPosition="left"
                >
                    <Icon name="add" />
                    <Trans>Add an area</Trans>
                </Button>

            }
        >

            <Modal.Header>
                {item?.id !== undefined && <Trans>Edit an area</Trans>}
                {item?.id === undefined && <Trans>Add an area</Trans>}
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    validate={validate}
                    render={({
                        handleSubmit,
                        submitting,
                        pristine,
                        invalid,
                        form
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid verticalAlign="top" centered>
                                    <Grid.Column width={15}>
                                        <Field
                                            name="name"
                                            placeholder={i18n._(t`enter new area`)}
                                            label={i18n._(t`Name`)}
                                            isRequired={true}
                                            parse={identity}
                                            component={InputAdapter}
                                            disabled={!canChange}
                                        />
                                        <Field
                                            name="description"
                                            placeholder={i18n._(t`enter area description`)}
                                            label={i18n._(t`description`)}
                                            parse={identity}
                                            component={TextAreaAdapter}
                                            disabled={!canChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <Divider />
                                    </Grid.Column>
                                    <Grid.Column width={15} textAlign="right">
                                        <Button type="button" negative onClick={() => setOpen(false)}>
                                            <Trans>cancel</Trans>
                                        </Button>
                                        {canChange && (
                                            <Button type="submit" positive icon labelPosition='right' disabled={submitting || pristine || invalid}>
                                                <Icon name="check" />
                                                <Trans>validate</Trans>
                                            </Button>
                                        )}
                                    </Grid.Column>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default AreaModalForm;
