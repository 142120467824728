import React from "react";
import { t, Trans } from "@lingui/macro";
import { FieldArray } from "react-final-form-arrays";
import _ from "lodash";
import { Field } from "react-final-form";

import { Button, Icon, Grid, Segment, Header, Label, Table } from "semantic-ui-react";

import { startsWith98, isTwelveDigits } from "modules/import/utils";
import { identity } from "modules/common/utils/form";
import i18n from "modules/i18n/i18nConfig";

import { InputAdapter, DropDownAdapter } from "modules/common/components/form";

const ConfigApiFileFieldsForm = (props) => {
    const { form, zones, sites, id_file, can_change, default_first_zone, default_first_site, apiImport } = props;

    return (
        <Grid.Column width={16} style={{ marginTop: "1rem" }}>
            <Header as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                <Trans>Api information</Trans>
            </Header>
            <Segment attached>
                <Grid verticalAlign="top">
                    {id_file === undefined && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Icon color="grey" name="info circle" />
                                <Trans>
                                    Configure the measurements to be imported below. Please note that after validation, only the Identifier field will
                                    be editable.
                                </Trans>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <FieldArray name="api_fields">
                        {(myFields) => {
                            return (
                                myFields.fields.length > 0 && (
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Table striped celled unstackable compact="very" structured>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            <Trans>Site</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <Trans>Name</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <Trans>Identifier</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <Trans>Measurement</Trans>
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {myFields.fields.map((item, index) => {
                                                        return (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>
                                                                    {
                                                                        sites?.data?.find(
                                                                            (site) => site.value === apiImport?.data?.api_fields[index].site
                                                                        )?.text
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>{apiImport?.data?.api_fields[index].name}</Table.Cell>
                                                                <Table.Cell verticalAlign="top">
                                                                    <Field
                                                                        name={`${item}.identifier`}
                                                                        isRequired={true}
                                                                        parse={identity}
                                                                        component={InputAdapter}
                                                                        unitposition="left"
                                                                        unit={<Icon name="certificate" />}
                                                                        unitcolor="secondary"
                                                                        disabled={!can_change}
                                                                        validate={(value) => {
                                                                            if (!value) {
                                                                                return i18n._(t`Required field`);
                                                                            }
                                                                            if (!startsWith98(value)) {
                                                                                return <Trans>Identifier must start with 98</Trans>;
                                                                            }
                                                                            if (!isTwelveDigits(value)) {
                                                                                return <Trans>Identifier must be 12 digits</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Table.Cell>
                                                                {/* Water & water_import default value for Suez  */}
                                                                <Table.Cell>
                                                                    <Trans>water</Trans> / <Trans>water_import</Trans>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            );
                        }}
                    </FieldArray>
                    <FieldArray name="new_fields">
                        {(myNewFields) => {
                            return (
                                <>
                                    {myNewFields.fields.map((name, index) => {
                                        // transform zones_options choice by site choice
                                        const site = myNewFields.fields.value[index]?.site;
                                        const zone_options_filtered = site ? zones?.data?.filter((zone) => zone.site === site) : zones.data;

                                        return (
                                            <Grid.Row key={index}>
                                                <Grid.Column width={16}>
                                                    {myNewFields.fields.length >= 1 && (
                                                        <>
                                                            <Segment attached secondary>
                                                                <Grid stackable>
                                                                    <Grid.Row verticalAlign="bottom">
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                helperText={i18n._(t`Equipment name`)}
                                                                                name={`${name}.name`}
                                                                                placeholder={i18n._(t`placeholder file_fields name`)}
                                                                                label={i18n._(t`file_fields name`)}
                                                                                isRequired={true}
                                                                                parse={identity}
                                                                                component={InputAdapter}
                                                                                unitposition="left"
                                                                                unit={<Icon name="file text" />}
                                                                                unitcolor="secondary"
                                                                                disabled={!can_change}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return i18n._(t`Required field`);
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                helperText={i18n._(
                                                                                    t`For Suez, the identifier of the measurement to import is the point of service (PDS). It corresponds to 12 digits which starts with 98`
                                                                                )}
                                                                                name={`${name}.identifier`}
                                                                                placeholder={i18n._(t`identifier`)}
                                                                                label={i18n._(t`Identifier`)}
                                                                                isRequired={true}
                                                                                parse={identity}
                                                                                component={InputAdapter}
                                                                                unitposition="left"
                                                                                unit={<Icon name="certificate" />}
                                                                                unitcolor="secondary"
                                                                                disabled={!can_change}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return i18n._(t`Required field`);
                                                                                    }
                                                                                    if (!startsWith98(value)) {
                                                                                        return <Trans>Identifier must start with 98</Trans>;
                                                                                    }
                                                                                    if (!isTwelveDigits(value)) {
                                                                                        return <Trans>Identifier must be 12 digits</Trans>;
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row verticalAlign="bottom">
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                name={`${name}.site`}
                                                                                label={i18n._(t`select site`)}
                                                                                placeholder={i18n._(t`select site`)}
                                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                                isRequired={true}
                                                                                options={_.map(sites.data, ({ key, text, value }) => ({
                                                                                    key,
                                                                                    text,
                                                                                    value,
                                                                                    content: <Label color="blue">{i18n._(text)}</Label>
                                                                                }))}
                                                                                component={DropDownAdapter}
                                                                                customAction={(value) => {
                                                                                    const only_one_zone = _.chain(zones.data)
                                                                                        .filter((zone) => {
                                                                                            return zone.site_id === value;
                                                                                        })
                                                                                        .thru((data) => {
                                                                                            if (_.size(data) === 1) {
                                                                                                return data[0].id;
                                                                                            } else {
                                                                                                return null;
                                                                                            }
                                                                                        })
                                                                                        .value();
                                                                                    form.change(`${name}.zone`, only_one_zone);
                                                                                }}
                                                                                disabled={!can_change}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return <Trans>Required field</Trans>;
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                name={`${name}.zone`}
                                                                                placeholder={i18n._(t`select zone`)}
                                                                                label={i18n._(t`select zone`)}
                                                                                isRequired={true}
                                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                                options={_.map(zone_options_filtered, ({ key, text, value }) => ({
                                                                                    key,
                                                                                    text,
                                                                                    value,
                                                                                    content: <Label color="teal">{i18n._(text)}</Label>
                                                                                }))}
                                                                                disabled={!can_change || !myNewFields.fields.value[index]?.site}
                                                                                component={DropDownAdapter}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return <Trans>Required field</Trans>;
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Segment>
                                                        </>
                                                    )}
                                                    {(id_file !== undefined || myNewFields.fields.length > 1) && (
                                                        <>
                                                            {/* Display delete button more 1 element && during add mode */}
                                                            <Segment attached secondary textAlign="right">
                                                                <Button
                                                                    type="button"
                                                                    icon
                                                                    color="red"
                                                                    labelPosition="left"
                                                                    onClick={() => myNewFields.fields.remove(index)}
                                                                    disabled={!can_change}
                                                                >
                                                                    <Icon name="trash" />
                                                                    <Trans>delete</Trans>
                                                                </Button>
                                                            </Segment>
                                                        </>
                                                    )}
                                                </Grid.Column>
                                            </Grid.Row>
                                        );
                                    })}
                                    <Grid.Row textAlign="right">
                                        <Grid.Column width={16}>
                                            <Button
                                                type="button"
                                                style={{ margin: "0em" }}
                                                icon
                                                labelPosition="left"
                                                onClick={() => {
                                                    const default_obj = {
                                                        name: "",
                                                        //Auto-select site && zone if only one
                                                        site: default_first_site || null,
                                                        zone: default_first_zone || null,
                                                        // Special default values for Suez (0)
                                                        dataflowspec: 2,
                                                        measurementtype: 15
                                                    };
                                                    myNewFields.fields.push(default_obj);
                                                }}
                                            >
                                                <Icon name="add" />
                                                <Trans>Add new column</Trans>
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </>
                            );
                        }}
                    </FieldArray>
                </Grid>
            </Segment>
        </Grid.Column>
    );
};

export default ConfigApiFileFieldsForm;
