import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import moment from "moment";
import tinycolor from "tinycolor2";
import { CustomSVGSeries, FlexibleWidthXYPlot, XAxis, YAxis } from "react-vis";
import { Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetMachineOverview24hQuery } from "modules/dashboard/dashboardService";

import Palette from "modules/common/components/graphic/Colors";
import MessageDisplay from "modules/common/components/MessageDisplay";

const pollingInterval = 1000 * 60 * 10;

export const Trend = (props) => {
    const { machine } = props;
    const org = useSelector((state) => state.org);

    //retrieve overview24h for machine if exists
    const machineOverview24h = useGetMachineOverview24hQuery(
        { org: org.current, machine_id: machine?.id },
        { skip: !org.current || machine?.id === undefined }
    );

    useEffect(() => {
        const machineOverview24hTimer = setInterval(() => {
            machineOverview24h.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(machineOverview24hTimer);
        };
    }, [machine, machineOverview24h, org]);

    if (machineOverview24h.isFetching) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }

    if (machineOverview24h.isFetching === false && machineOverview24h.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (machineOverview24h.isFetching === false && machineOverview24h.isSuccess) {
        const healthscores = machineOverview24h.data?.healthscores ?? [];

        if (_.size(healthscores) === 0) {
            return <MessageDisplay level="warning" message={i18n._(t`no score 24h`)} iconName="warning circle" isLoading={false} />;
        }

        const now = moment();
        const defaultColor = tinycolor(Palette.named.yellow).toString();
        const red = tinycolor(Palette.named["red"]).toString();
        const orange = tinycolor(Palette.named["orange"]).toString();
        const green = tinycolor(Palette.named["green"]).toString();

        const renderPointColor = (value) => {
            if (value > 9) {
                return { stroke: red, fill: red };
            } else if (value > 7) {
                return { stroke: orange, fill: orange };
            } else if (value >= 0) {
                return { stroke: green, fill: green };
            }
            return null;
        };

        const fmt_data = _.chain(healthscores).map((point) => ({
            x: moment(point[0]).unix() * 1000,
            y: point[1] !== null ? point[1] : null,
            style: renderPointColor(point[1])
        }));

        return (
            <Grid stretched centered>
                <Grid.Column width={16} textAlign="center" className="pwaPanelTitle">
                    <Trans>trend score</Trans>
                </Grid.Column>
                <Grid.Column width={16}>
                    {/* Graphic for last 24h datapoints */}
                    <FlexibleWidthXYPlot
                        xType="time"
                        height={80}
                        xDomain={[now.clone().subtract(1, "day"), now.clone()]}
                        yDomain={[0, 10]}
                        margin={{ left: 1, right: 1, top: 1, bottom: 1 }}
                        className="pwaVal24hPlot"
                    >
                        <XAxis hideTicks />
                        <YAxis hideTicks />
                        <CustomSVGSeries
                            style={{
                                stroke: defaultColor,
                                fill: defaultColor,
                                pointerEvents: "auto"
                            }}
                            size={6}
                            opacity={0.4}
                            customComponent="diamond"
                            getNull={(d) => {
                                return d.y !== null;
                            }}
                            data={fmt_data.value()}
                        />
                    </FlexibleWidthXYPlot>
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

export default Trend;
