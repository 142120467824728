import React from "react";
import { Header, Grid, Segment } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import Back from "modules/common/components/back";

const Page404 = () => {
    return (
        <Grid verticalAlign="middle" centered style={{ minHeight: "60%" }}>
            <Grid.Row>
                <Grid.Column width={4} textAlign="center">
                    <Header as={"h1"}>
                        <Trans>not found</Trans>
                    </Header>
                    <Segment basic>
                        <Trans>page not exist</Trans>
                    </Segment>
                    <Back />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Page404;
