import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Form, Field } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import { Button, Modal, Grid, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";

import { InputAdapter, ToggleAdapter } from "modules/common/components/form";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";

export const EditException = (props) => {
    const { idx, item, update } = props;
    const [open, setOpen] = useState(false);

    const onSubmitForm = async (data) => {
        await update("dataflows_exception", idx, {
            ...item,
            override: _.mapValues(data, (item, key) => {
                if (_.includes(keyToReparseInPack, key)) {
                    return reparseNumber(item);
                }
                return item;
            })
        });
        await setOpen(false);
    };

    const initialValues = useMemo(() => {
        return item?.override ?? {};
    }, [item]);

    return (
        <Modal
            open={open}
            trigger={
                <Button
                    type="button"
                    icon="edit"
                    onClick={(event) => {
                        setOpen(true);
                    }}
                />
            }
        >
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>edit exception</Trans>
                                {_.get(item, "name")}
                            </Modal.Header>
                            <Modal.Content scrolling={false}>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid verticalAlign="top">
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Field
                                                name="i_moy_max_threshold"
                                                placeholder={i18n._(t`i_moy_max_threshold placeholder`)}
                                                label={i18n._(t`i_moy_max_threshold`)}
                                                unit={"A"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                helperText={
                                                    <Trans>
                                                        <p>
                                                            Le système peut estimer l’état thermique des cables de puissance en se basant sur les
                                                            informations du type de cable et de la section des cables.
                                                        </p>
                                                        <p>
                                                            Ainsi, si un courant excessif circule pendant une durée trop importante, entrainant un
                                                            échauffement excessif, le système sera en mesure de le détecter.
                                                        </p>
                                                    </Trans>
                                                }
                                                isRequired={true}
                                                validate={(value) => {
                                                    return validateNumber(value, i18n, false, false);
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="imbalance_threshold"
                                                placeholder={i18n._(t`voltage_imbalance_threshold placeholder`)}
                                                label={i18n._(t`voltage_imbalance_threshold`)}
                                                unit={"%"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                helperText={i18n._(t`voltage_imbalance_helper`)}
                                                isRequired={true}
                                                validate={(value) => {
                                                    return validateNumber(value, i18n, false, false);
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="elapsed_hour"
                                                placeholder={i18n._(t`voltage_elapsed_hour placeholder`)}
                                                label={i18n._(t`voltage_imbalance_duration`)}
                                                unit={"h"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                isRequired={true}
                                                validate={(value) => {
                                                    const elapsedValidate = validateNumber(value, i18n, false, false);
                                                    if (elapsedValidate !== undefined) {
                                                        return elapsedValidate;
                                                    }
                                                    if (reparseNumber(value) < 1) {
                                                        return <Trans>period time minimum 1h</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Segment.Group horizontal style={{ border: "None", boxShadow: "None" }}>
                                                <Segment basic compact>
                                                    <Field
                                                        name="auto_sag"
                                                        label={i18n._(t`auto_sag`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        defaultValue={true}
                                                        toggle={false}
                                                    />
                                                </Segment>
                                                <Segment basic compact>
                                                    <Field
                                                        name="auto_swell"
                                                        label={i18n._(t`auto_swell`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        defaultValue={true}
                                                        toggle={false}
                                                    />
                                                </Segment>
                                            </Segment.Group>
                                        </Grid.Column>
                                    </Grid>
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={(e, data) => {
                                        setOpen(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

export default React.memo(EditException);
