import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import history_app from "history_app";

export const OtpRoute = ({
    component: Component,
    ...rest
}) => {
    const { auth } = useSelector(state => state);

    useEffect(() => {
        if (auth.rights && auth.isAuthenticated && auth.isVerified === false) {
            if (auth.rights?.two_factor === true && auth.rights?.has_otp === false) {
                history_app.push('/otp/setup');
            } else if (auth.rights?.two_factor === true && auth.rights?.has_otp === true) {
                history_app.push('/otp/validate');
            }
        }
    }, [auth]);



    return (
        <Route
            {...rest}
            render={(props) => {
                if (auth.isAuthenticated && auth.isVerified) {
                    return <Component {...props} />;
                }
                return null;
            }}
        />

    );
};

OtpRoute.propTypes = {
    component: PropTypes.elementType.isRequired
};

export default OtpRoute;
