import { t } from "@lingui/macro";
import { Media } from "App";
import _ from "lodash";
import MessageDisplay from "modules/common/components/MessageDisplay";
import i18n from "modules/i18n/i18nConfig";
import moment from "moment";
import React from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { useSelector } from "react-redux";
import { Header, Segment } from "semantic-ui-react";

const Gauge = (props) => {
    const current_lng = useSelector((state) => state.i18n.current);
    const { tabpredicthealthscore } = useSelector((state) => state.overview);
    const selected_clusters = _.get(tabpredicthealthscore, "clusters.selected_clusters", []);

    const { data } = props;

    const max_all_clusters = _.chain(data)
        //filter via dropdown cluster
        .filter((cluster) => _.includes(selected_clusters, cluster.index))
        //remap cluster => all data from cluster
        .reduce((res, cluster) => {
            _.each(cluster.data, (item) => {
                if (item?.validity === 1) {
                    res.push(item);
                }
            });
            return res;
        }, [])
        // get max of all data
        .maxBy("x")
        .value();

    if (_.isUndefined(max_all_clusters)) {
        return <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="info circle" isLoading={false} />;
    }

    const gauge_params = {
        value: _.get(max_all_clusters, "y_real", 0),
        currentValueText: i18n.number(_.get(max_all_clusters, "y_real", 0), { maximumFractionDigits: 2 }),
        ringWidth: 25,
        customSegmentStops: [0, 5, 7, 9, 10],
        segmentColors: ["limegreen", "limegreen", "orange", "red"],
        paddingHorizontal: 0,
        paddingVertical: 0,
        needleHeightRatio: 0.8,
        needleColor: "gray",
        minValue: 0,
        maxValue: 10
    };

    return (
        <>
            <Header attached as={"h4"} textAlign="center" style={{ color: _.get(max_all_clusters, "color") }}>
                <em>{_.get(max_all_clusters, "title")}</em>
            </Header>
            <Segment attached style={{ flexGrow: 1 }}>
                <Media lessThan="computer">
                    {(mediaClassNames, renderChildren) => renderChildren && <ReactSpeedometer {...gauge_params} width={200} height={140} />}
                </Media>
                <Media between={["computer", "largeScreen"]}>
                    {(mediaClassNames, renderChildren) => renderChildren && <ReactSpeedometer {...gauge_params} width={150} height={140} />}
                </Media>
                <Media greaterThanOrEqual="largeScreen">
                    {(mediaClassNames, renderChildren) => renderChildren && <ReactSpeedometer {...gauge_params} width={190} height={140} />}
                </Media>
            </Segment>
            <Header attached="bottom" block textAlign="right">
                <em>{moment(_.get(max_all_clusters, "x")).locale(current_lng).format("LLL")}</em>
            </Header>
        </>
    );
};

export default Gauge;
