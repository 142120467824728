import React, { Fragment } from "react";
import { Button, Icon } from "semantic-ui-react";
import history from "history_app";
import { Trans } from "@lingui/macro";
import { Media } from "App";

/**
 * Used for checking if you need to go back or target specific URL
 * @function backLocation
 * @param {String} target - URL used for redirection
 */
const backLocation = (target) => {
    if (target === undefined) {
        history.goBack();
    } else {
        history.push(target);
    }
};

const back = ({ target, action }) => {
    const handleClick = async (e) => {
        action && action();
        backLocation(target);
    };

    return (
        <Fragment>
            <Media greaterThanOrEqual="tablet">
                <Button type="button" icon labelPosition="left" onClick={handleClick}>
                    <Icon name="arrow left" />
                    <Trans>Back</Trans>
                </Button>
            </Media>
            <Media lessThan="tablet">
                <Button type="button" icon compact onClick={handleClick}>
                    <Icon name="arrow left" />
                </Button>
            </Media>
        </Fragment>
    );
};

export default back;
