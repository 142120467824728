import React from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Divider, Grid, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetMachinesQuery } from "modules/machine/machineService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetTagsQuery } from "modules/tag/tagService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetUsagesQuery } from "modules/usage/usageService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DashboardFilter from "./DashboardFilter";
import DashboardPanel from "./DashboardPanel";
import DashboardActions from "./DashboardActions";

const Dashboard = (props) => {
    const { org } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });
    const tags = useGetTagsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });

    const success_list = [
        sites.isSuccess,
        categories.isSuccess,
        zones.isSuccess,
        usages.isSuccess,
        tags.isSuccess,
        units.isSuccess,
        measurementtypes.isSuccess,
        measurements.isSuccess,
        equipments.isSuccess,
        dataflows.isSuccess,
        machines.isSuccess
    ];

    const fetching_list = [
        sites.isFetching,
        categories.isFetching,
        zones.isFetching,
        usages.isFetching,
        tags.isFetching,
        units.isFetching,
        measurementtypes.isFetching,
        measurements.isFetching,
        equipments.isFetching,
        dataflows.isFetching,
        machines.isFetching
    ];

    const error_list = [
        sites.isError,
        categories.isError,
        zones.isError,
        usages.isError,
        tags.isError,
        units.isError,
        measurementtypes.isError,
        measurements.isError,
        equipments.isError,
        dataflows.isError,
        machines.isError
    ];

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Dashboard</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                {/* DISPLAY LOADER */}
                {_.some(fetching_list) && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {/* DISPLAY ERROR MESSAGES */}
                {_.some(error_list) && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {_.every(success_list) && (
                    <>
                        <DashboardFilter />
                        <DashboardActions />
                        <Divider />
                        <DashboardPanel />
                    </>
                )}
            </Grid>
        </Segment>
    );
};

export default React.memo(Dashboard);
