import React from 'react';
import _ from "lodash";
import { t } from "@lingui/macro";
import { List, Icon, Message } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetExternalApiKeysQuery } from '../organizationService';

import TableEnhanced from 'modules/common/components/TableEnhanced';
import MessageDisplay from 'modules/common/components/MessageDisplay';
import AddExternalApiKey from './AddExternalApiKey';
import DeleteExternalApiKey from './DeleteExternalApiKey';

const ExternalApi = (props) => {
    const { can_change, active, org, } = props;
    const externalApiKeys = useGetExternalApiKeysQuery({ org: org.current }, { skip: !org.current || !active });

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "identifier", label: i18n._(t`identifier`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const extApi_list = _.chain(externalApiKeys)
        .get('data.list', [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                identifier: {
                    render: <span style={customStyle}>{_.get(item, "identifier", "-")}</span>,
                    value: _.get(item, "identifier", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <DeleteExternalApiKey item={item} can_change={can_change} />
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    const urls_list_render = _.chain(externalApiKeys)
        .get('data.urls', [])
        .map((item, index) => {
            return (
                <List.Item key={index} href={item} target="_blank" as="a" style={{ wordWrap: "break-word" }}>
                    <Icon name="linkify" />
                    {item}
                </List.Item>
            );
        })
        .value();

    return (
        <>
            {externalApiKeys.isError &&
                <MessageDisplay
                    message={i18n._(t`error loading data`)}
                    level="error"
                    iconName="warning circle"
                    isLoading={false}
                    attached={false}
                />
            }
            {externalApiKeys.isFetching &&
                <MessageDisplay
                    message={i18n._(t`loading data`)}
                    level="info"
                    iconName="circle notched"
                    isLoading={true}
                    attached={false}
                />
            }
            {externalApiKeys.isSuccess && (
                <>
                    {can_change && (<AddExternalApiKey can_change={can_change} />)}
                    {_.size(urls_list_render) > 0 &&
                        <Message>
                            <List link>
                                <List.Header as='h5' >{i18n._(t`OpenAPI Documentation`)}</List.Header>
                                {urls_list_render}
                            </List>
                        </Message>
                    }
                    <TableEnhanced
                        headCells={headCells}
                        rows={extApi_list}
                        order="asc"
                        orderBy="identifier"
                        page={1}
                        rowsPerPage={10}
                        textItemPerPages={i18n._(t`items per page`)}
                    />
                </>
            )}
        </>
    );
};

export default ExternalApi;
