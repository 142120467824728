import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';

export const measurementtypeApi = defaultApi.injectEndpoints({
    reducerPath: 'apiMeasurementtype',
    endpoints: (build) => ({
        getMeasurementtypes: build.query({
            keepUnusedDataFor: 86400,
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `measurementtypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: ["Measurementtype"],
            transformResponse: (response) => {
                return _.chain(response)
                    .map((measurementtype) => {
                        return {
                            ...measurementtype,
                            key: measurementtype.id,
                            value: measurementtype.id,
                            text: measurementtype.name,
                            type: "measurementtype",
                        };
                    }
                    )
                    .value();
            }
        }),
    }),
    overrideExisting: false,
});

export const { useGetMeasurementtypesQuery } = measurementtypeApi;