import React, { useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import _ from "lodash";
import { Icon, Grid, Segment, Header, Button, Label, Divider, Table } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { InputAdapter, DropDownAdapter } from "modules/common/components/form";
import { identity, identityNull } from "modules/common/utils/form";
import { my_measurementtypes, my_dataflowspec } from "modules/import/utils";
import { removeAccents, reparseNumber, validateNumber } from "modules/common/utils";

const ConfigFileFieldsForm = (props) => {
    const { can_change, measurementtypes, dataflowspec, values, sites, id_file, units, file, form, zones, default_first_site, default_first_zone } =
        props;

    //limit measurement type
    const measurement_types_options = useMemo(() => {
        if (measurementtypes.isSuccess) {
            return _.chain(measurementtypes.data)
                .reduce((res, mttype) => {
                    if (_.includes(my_measurementtypes, mttype.name)) {
                        if (values.import_type === 2 && mttype.name === "water_import") {
                            return res;
                        }
                        const { text } = mttype;
                        res.push({ ...mttype, text: i18n._(text), content: <Label color="yellow">{i18n._(text)}</Label> });
                    }
                    return res;
                }, [])
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [measurementtypes, values.import_type]);

    //   limit categories type
    const dataflowspec_options = useMemo(() => {
        if (dataflowspec.isSuccess) {
            return _.chain(dataflowspec.data)
                .reduce((res, cat) => {
                    if (_.includes(my_dataflowspec, cat.name)) {
                        const { key, text, value } = cat;
                        res.push({
                            key,
                            text: i18n._(text),
                            value,
                            content: <Label color="purple">{i18n._(text)}</Label>
                        });
                    }
                    return res;
                }, [])
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [dataflowspec]);

    return (
        <>
            <Header as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                <Trans>Import information</Trans>
            </Header>
            <Segment attached>
                <Grid verticalAlign="top">
                    {id_file === undefined && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Icon color="grey" name="info circle" />

                                <Trans>
                                    The description of the measures to be imported from the file. Please note that once saved, you will not be able to
                                    modify this information except for the column.
                                </Trans>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <FieldArray name="file_fields">
                        {(myFields) => {
                            return (
                                myFields.fields.length > 0 && (
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Table striped celled unstackable compact="very" structured>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            <Trans>site</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <Trans>name</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <Trans>measurementtype</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <Trans>unit</Trans>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {values.field_identification === 1 ? (
                                                                <Trans>Column (identified by position)</Trans>
                                                            ) : (
                                                                <Trans>Column (identified by header)</Trans>
                                                            )}
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {myFields.fields.map((name, index) => {
                                                        return (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>
                                                                    {
                                                                        sites?.data?.find(
                                                                            (site) => site.value === file?.data?.file_fields[index].site
                                                                        )?.text
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>{file?.data?.file_fields[index].name}</Table.Cell>
                                                                <Table.Cell>
                                                                    {
                                                                        measurement_types_options.find(
                                                                            (type) => type.key === file?.data?.file_fields[index]?.measurementtype
                                                                        )?.text
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {
                                                                        units?.data?.find(
                                                                            (unite) => unite.value === file?.data?.file_fields[index].unit
                                                                        )?.text
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Field
                                                                        name={`${name}.column`}
                                                                        placeholder={
                                                                            values.field_identification === 1
                                                                                ? i18n._(t`column number`)
                                                                                : i18n._(t`Enter name of column`)
                                                                        }
                                                                        isRequired={true}
                                                                        parse={values.field_identification === 1 ? identityNull : identity}
                                                                        inputMode={values.field_identification === 1 ? "numeric" : "text"}
                                                                        component={InputAdapter}
                                                                        unitposition="left"
                                                                        unit={<Icon name="columns" />}
                                                                        unitcolor="secondary"
                                                                        disabled={!can_change}
                                                                        validate={(value, allValues) => {
                                                                            if (allValues.field_identification === 1) {
                                                                                const colValidate = validateNumber(value, i18n, false, false, true);
                                                                                if (colValidate !== undefined) {
                                                                                    return colValidate;
                                                                                }
                                                                                if (reparseNumber(value) < 1) {
                                                                                    return <Trans>Minimum value is 1</Trans>;
                                                                                }
                                                                            }
                                                                            if (!value) {
                                                                                return <Trans>Required field</Trans>;
                                                                            }
                                                                            return undefined;
                                                                        }}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            );
                        }}
                    </FieldArray>
                    <FieldArray name="new_fields">
                        {(myNewFields) => {
                            return (
                                <>
                                    {myNewFields.fields.map((name, index) => {
                                        // transform zones_options choice by site choice
                                        const site = myNewFields.fields.value[index]?.site;
                                        const category = myNewFields.fields.value[index]?.dataflowspec;
                                        const zone_options_filtered = site ? zones?.data?.filter((zone) => zone.site === site) : zones.data;
                                        const mttype_filtered = _.filter(measurement_types_options, (item) => {
                                            return _.includes(item.dataflowspec_set, category);
                                        });

                                        return (
                                            <Grid.Row key={index}>
                                                <Grid.Column width={16}>
                                                    {myNewFields.fields.length >= 1 && (
                                                        <>
                                                            <Segment attached secondary>
                                                                <Grid stackable>
                                                                    <Grid.Row verticalAlign="bottom">
                                                                        <Grid.Column width={10}>
                                                                            <Field
                                                                                helperText={i18n._(t`Equipment name`)}
                                                                                name={`${name}.name`}
                                                                                placeholder={i18n._(t`placeholder file_fields name`)}
                                                                                label={i18n._(t`file_fields name`)}
                                                                                isRequired={true}
                                                                                parse={identity}
                                                                                component={InputAdapter}
                                                                                unitposition="left"
                                                                                unit={<Icon name="file text" />}
                                                                                unitcolor="secondary"
                                                                                disabled={!can_change}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return i18n._(t`Required field`);
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            <Field
                                                                                name={`${name}.column`}
                                                                                placeholder={
                                                                                    values.field_identification === 1
                                                                                        ? i18n._(t`column number`)
                                                                                        : i18n._(t`Enter name of column`)
                                                                                }
                                                                                label={
                                                                                    values.field_identification === 1
                                                                                        ? i18n._(t`Column (identified by position)`)
                                                                                        : i18n._(t`Column (identified by header)`)
                                                                                }
                                                                                isRequired={true}
                                                                                parse={values.field_identification === 1 ? identityNull : identity}
                                                                                inputMode={values.field_identification === 1 ? "numeric" : "text"}
                                                                                component={InputAdapter}
                                                                                unitposition="left"
                                                                                unit={<Icon name="columns" />}
                                                                                unitcolor="secondary"
                                                                                disabled={!can_change}
                                                                                validate={(value, allValues) => {
                                                                                    if (allValues.field_identification === 1) {
                                                                                        const colValidate = validateNumber(
                                                                                            value,
                                                                                            i18n,
                                                                                            false,
                                                                                            false,
                                                                                            true
                                                                                        );
                                                                                        if (colValidate !== undefined) {
                                                                                            return colValidate;
                                                                                        }
                                                                                        if (reparseNumber(value) < 1) {
                                                                                            return <Trans>Minimum value is 1</Trans>;
                                                                                        }
                                                                                    }
                                                                                    if (!value) {
                                                                                        return <Trans>Required field</Trans>;
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                    <Grid.Row verticalAlign="bottom">
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                name={`${name}.site`}
                                                                                label={i18n._(t`select site`)}
                                                                                placeholder={i18n._(t`select site`)}
                                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                                isRequired={true}
                                                                                options={_.map(sites.data, ({ key, text, value }) => ({
                                                                                    key,
                                                                                    text,
                                                                                    value,
                                                                                    content: <Label color="blue">{i18n._(text)}</Label>
                                                                                }))}
                                                                                component={DropDownAdapter}
                                                                                customAction={(value) => {
                                                                                    const only_one_zone = _.chain(zones.data)
                                                                                        .filter((zone) => {
                                                                                            return zone.site_id === value;
                                                                                        })
                                                                                        .thru((data) => {
                                                                                            if (_.size(data) === 1) {
                                                                                                return data[0].id;
                                                                                            } else {
                                                                                                return null;
                                                                                            }
                                                                                        })
                                                                                        .value();
                                                                                    form.change(`${name}.zone`, only_one_zone);
                                                                                }}
                                                                                disabled={!can_change}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return <Trans>Required field</Trans>;
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                name={`${name}.zone`}
                                                                                placeholder={i18n._(t`select zone`)}
                                                                                label={i18n._(t`select zone`)}
                                                                                isRequired={true}
                                                                                noResultsMessage={i18n._(t`no result found`)}
                                                                                options={_.map(zone_options_filtered, ({ key, text, value }) => ({
                                                                                    key,
                                                                                    text,
                                                                                    value,
                                                                                    content: <Label color="teal">{i18n._(text)}</Label>
                                                                                }))}
                                                                                disabled={!can_change || !myNewFields.fields.value[index]?.site}
                                                                                component={DropDownAdapter}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return <Trans>Required field</Trans>;
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                name={`${name}.dataflowspec`}
                                                                                placeholder={i18n._(t`select dataflowspec`)}
                                                                                label={i18n._(t`dataflowspec`)}
                                                                                isRequired={true}
                                                                                component={DropDownAdapter}
                                                                                search={false}
                                                                                options={dataflowspec_options}
                                                                                disabled={!can_change || [1, 2].includes(values.import_type)}
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return i18n._(t`Required field`);
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                                customAction={(value) => {
                                                                                    const mttype_filtered = _.filter(
                                                                                        measurement_types_options,
                                                                                        (item) => {
                                                                                            return _.includes(item.dataflowspec_set, value);
                                                                                        }
                                                                                    );
                                                                                    if (_.size(mttype_filtered) === 1) {
                                                                                        form.change(
                                                                                            `${name}.measurementtype`,
                                                                                            mttype_filtered[0]?.id ?? null
                                                                                        );
                                                                                    } else {
                                                                                        form.change(`${name}.measurementtype`, null);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <Field
                                                                                name={`${name}.measurementtype`}
                                                                                placeholder={i18n._(t`measurement type`)}
                                                                                label={i18n._(t`measurement type`)}
                                                                                isRequired={true}
                                                                                component={DropDownAdapter}
                                                                                search={false}
                                                                                options={mttype_filtered}
                                                                                disabled={
                                                                                    !can_change ||
                                                                                    values.import_type === 1 ||
                                                                                    !myNewFields.fields.value[index]?.dataflowspec
                                                                                }
                                                                                validate={(value) => {
                                                                                    if (!value) {
                                                                                        return i18n._(t`Required field`);
                                                                                    }
                                                                                    return undefined;
                                                                                }}
                                                                            />
                                                                        </Grid.Column>
                                                                        {myNewFields.fields.length > 1 && (
                                                                            <Grid.Column width={16}>
                                                                                <Divider />
                                                                            </Grid.Column>
                                                                        )}
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Segment>
                                                        </>
                                                    )}
                                                    {(id_file !== undefined || myNewFields.fields.length > 1) && (
                                                        <>
                                                            {/* Display delete button more 1 element && during add mode */}
                                                            <Segment attached secondary textAlign="right">
                                                                <Button
                                                                    type="button"
                                                                    icon
                                                                    color="red"
                                                                    labelPosition="left"
                                                                    onClick={() => myNewFields.fields.remove(index)}
                                                                    disabled={!can_change}
                                                                >
                                                                    <Icon name="trash" />
                                                                    <Trans>delete</Trans>
                                                                </Button>
                                                            </Segment>
                                                        </>
                                                    )}
                                                </Grid.Column>
                                            </Grid.Row>
                                        );
                                    })}
                                    <Grid.Row textAlign="right">
                                        <Grid.Column width={16}>
                                            <Button
                                                type="button"
                                                style={{ margin: "0em" }}
                                                icon
                                                labelPosition="left"
                                                onClick={() => {
                                                    const default_obj = {
                                                        name: "",
                                                        //Auto-select site && zone if only one
                                                        site: default_first_site || null,
                                                        zone: default_first_zone || null,
                                                        // Special default values for Enedis (1) and GRTgaz (2)
                                                        dataflowspec: { 1: 1, 2: 3 }[values.import_type] || null,
                                                        measurementtype: { 1: 9, 2: 9 }[values.import_type] || null
                                                    };
                                                    myNewFields.fields.push(default_obj);
                                                }}
                                            >
                                                <Icon name="add" />
                                                <Trans>Add new column</Trans>
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </>
                            );
                        }}
                    </FieldArray>
                </Grid>
            </Segment>
        </>
    );
};

export default React.memo(ConfigFileFieldsForm);
