import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { t, Trans } from '@lingui/macro';
import _ from 'lodash';
import { Modal, Button, Icon, Grid, Divider, Popup } from 'semantic-ui-react';
import { Form, Field } from "react-final-form";

import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import i18n from 'modules/i18n/i18nConfig';
import { useUpdateTagMutation, useAddTagMutation } from 'modules/tag/tagService';

import { InputAdapter, DropDownAdapter } from 'modules/common/components/form';

const EditTagModalForm = (props) => {
    const { tags, item, can_actions, users } = props;

    const [open, setOpen] = useState(false);
    const { org, auth } = useSelector(state => state);

    const is_admin = auth.rights?.is_admin;

    const [updateTag, update] = useUpdateTagMutation();
    const [createTag, create] = useAddTagMutation();

    // Update Tag 
    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update tag`), toast_options);
            setOpen(false);;
        }
        if (update.isError) {
            toast.error(i18n._(t`cannot update tag`), toast_options_err);
            setOpen(false);
        }
    }, [update.isLoading, update.isSuccess, update.isError]);

    // Create Tag
    useEffect(() => {
        if (create.isSuccess) {
            toast.success(i18n._(t`new tag added`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            toast.error(i18n._(t`cannot add new tag`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError]);

    const initialValues = useMemo(() => {
        return { word: "", ...item };
    }, [item]);


    const validate = (values) => {
        const errors = {};
        if (!values.word) {
            errors.word = i18n._(t`tag is required`);
        }
        const existing_word = _.chain(tags)
            .filter((tag) => {
                if (item === undefined) return true;
                return tag.id !== parseInt(item.id);
            })
            .find({ word: values.word })
            .value();
        if (existing_word && item?.owner !== null) {
            errors.word = i18n._(t`This tag already exists`);
        }

        return errors;
    };

    const submitForm = async (formData) => {
        const data = _.omit(formData, ["key", "text", "value", "name", "type"]);

        if (can_actions) {
            if (data.id) {
                await updateTag({ org: org.current, data });
            } else {
                await createTag({ org: org.current, data });
            }
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => { setOpen(false); }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={item?.id ?
                <Popup trigger={
                    <Button
                        type="button"
                        disabled={!can_actions}
                        icon="edit"
                        onClick={() => setOpen(true)}
                    />
                }>
                    <Popup.Content>
                        <Trans>Edit tag</Trans>
                    </Popup.Content>
                </Popup>
                :
                <Button
                    disabled={!can_actions}
                    type="button"
                    icon
                    labelPosition="left"
                >
                    <Icon name="add" />
                    <Trans>Add new tag</Trans>
                </Button>

            }
        >
            <Modal.Header>
                {item?.id !== undefined && <Trans>Edit tag</Trans>}
                {item?.id === undefined && <Trans>Add new tag</Trans>}
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    validate={validate}
                    render={({
                        handleSubmit,
                        submitting,
                        pristine,
                        invalid
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid verticalAlign="top" centered>
                                    <Grid.Column width={15}>
                                        <Field
                                            name="word"
                                            placeholder={i18n._(t`Enter name of tag`)}
                                            label={i18n._(t`Tag`)}
                                            isRequired={true}
                                            component={InputAdapter}
                                        />
                                        {/* Add new owner (by admin only) if owner is null  */}
                                        {is_admin && item !== undefined && (
                                            <Field
                                                name="owner"
                                                placeholder={i18n._(t`Select owner of tag`)}
                                                label={i18n._(t`owner`)}
                                                component={DropDownAdapter}
                                                options={users}
                                            />
                                        )}
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <Divider />
                                    </Grid.Column>
                                    <Grid.Column width={15} textAlign="right">
                                        <Button type="button" negative onClick={() => setOpen(false)}>
                                            <Trans>cancel</Trans>
                                        </Button>
                                        <Button type="submit" positive icon labelPosition='right' disabled={submitting || pristine || invalid}>
                                            <Icon name="check" />
                                            <Trans>validate</Trans>
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal >
    );
};

export default EditTagModalForm;

