import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { Grid, Icon, Message, Popup } from "semantic-ui-react";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { machine_status } from "./utils";
import { useGetMachineLastValuesQuery } from "modules/dashboard/dashboardService";

import BarGauge from "modules/common/components/graphic/BarGauge";
import MessageDisplay from "modules/common/components/MessageDisplay";

const pollingInterval = 1000 * 60 * 10;

export const Load = (props) => {
    const { machine } = props;
    const org = useSelector((state) => state.org);

    const machineLastValues = useGetMachineLastValuesQuery(
        { org: org.current, id_machine: machine?.id },
        { skip: !org.current || machine?.id === undefined }
    );

    useEffect(() => {
        const machineLVTimer = setInterval(() => {
            if (machineLastValues.isUninitialized === false) {
                machineLastValues.refetch();
            }
        }, pollingInterval);

        return () => {
            clearInterval(machineLVTimer);
        };
    }, [machine, machineLastValues, org]);

    const { status, max_data, m_status } = useMemo(() => {
        if (machineLastValues.isSuccess) {
            const status = machine?.status ?? null;
            const max_data = _.chain(machineLastValues.data).maxBy("value").value();
            const m_status = machine_status[status];
            return { status, max_data, m_status };
        }
        return { status: null, max_data: null, m_status: null };
    }, [machineLastValues, machine]);

    if (machineLastValues.isFetching) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (machineLastValues.isFetching === false && machineLastValues.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }

    if (machineLastValues.isFetching === false && machineLastValues.isSuccess) {
        if (max_data === undefined && m_status) {
            return (
                <Message
                    error={m_status?.level === "error"}
                    warning={m_status?.level === "warning"}
                    info={m_status?.level === "info"}
                    style={{ display: "block" }}
                >
                    {i18n._(m_status?.message)}&nbsp;
                    <Popup position="top center" trigger={<Icon name={"question circle"} />}>
                        <Popup.Content>
                            {_.map(m_status?.popup_content, (content, idx) => {
                                return (
                                    <p key={idx} style={content?.style}>
                                        {i18n._(content?.text)}
                                    </p>
                                );
                            })}
                        </Popup.Content>
                    </Popup>
                </Message>
            );
        }

        if (_.isUndefined(max_data)) {
            return (
                <MessageDisplay
                    level="warning"
                    message={i18n._(t`unable to get score`)}
                    iconName="warning circle"
                    isLoading={false}
                    attached={false}
                />
            );
        }

        return (
            <Grid stretched centered>
                <Grid.Column width={16} className="unpadded">
                    <BarGauge
                        value={{
                            text: _.isFinite(max_data?.value)
                                ? `${i18n.number(max_data?.value, {
                                      maximumFractionDigits: 1
                                  })}`
                                : "N/A",
                            numeric: _.isFinite(max_data?.value) ? max_data.value : null
                        }}
                        minValue={0}
                        maxValue={10}
                        thresholds={[
                            { value: 0, color: "green" },
                            { value: 7, color: "orange" },
                            { value: 9, color: "red" }
                        ]}
                        height={40}
                        width={200}
                        itemSpacing={2}
                        popupTitle={<Trans>Global score</Trans>}
                    />
                </Grid.Column>
                {status === 4 && (
                    <Grid.Column width={16} textAlign="center" className="unpadded">
                        <span style={{ color: "#276F86" }}>
                            {i18n._(m_status?.message)}&nbsp;
                            <Popup position="top center" trigger={<Icon name={status === 2 ? "warning circle" : "question circle"} />}>
                                <Popup.Content>{i18n._(m_status?.popup_content)}</Popup.Content>
                            </Popup>
                        </span>
                    </Grid.Column>
                )}
            </Grid>
        );
    }
    return null;
};

export default Load;
