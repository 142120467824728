import { t } from "@lingui/macro";

export const validityOptions = [
    { key: "0", value: 0, text: t`Fixed Period` },
    { key: "1", value: 1, text: t`Opening calendar` },
    { key: "2", value: 2, text: t`Closing calendar` }
];

export const alertTypeOptions = [
    { key: "0", value: 0, text: t`Instant value alert` },
    { key: "1", value: 1, text: t`Consumption over fixed range` },
    { key: "2", value: 2, text: t`Consumption over sliding range` }
];

export const daysOptions = [
    { key: "1", value: 1, text: t`monday` },
    { key: "2", value: 2, text: t`tuesday` },
    { key: "3", value: 3, text: t`wednesday` },
    { key: "4", value: 4, text: t`thursday` },
    { key: "5", value: 5, text: t`friday` },
    { key: "6", value: 6, text: t`saturday` },
    { key: "7", value: 7, text: t`sunday` }
];
