import jwt_decode from "jwt-decode";
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    isVerified: false,
    user: null,
    token: null,
    rights: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.user = jwt_decode(action.payload);
            state.token = action.payload;
        },
        logout: (state, action) => {
            return initialState;
        },
        refreshToken: (state, action) => {
            state.user = jwt_decode(action.payload);
            state.token = action.payload;
        },
        setRights: (state, action) => {
            state.rights = action.payload;
        },
        otpVerified: (state, action) => {
            state.isVerified = action.payload;
        }
    },
    extraReducers(builder) { }
});

export const { login, logout, refreshToken, setRights, otpVerified } = authSlice.actions;

export default authSlice.reducer;