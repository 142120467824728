import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Button, Dimmer, Divider, Dropdown, Grid, Icon, Label, Message, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetUsagesQuery } from "modules/usage/usageService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import MeasureItem from "./MeasureItem";
import FactorMeasureItem from "./FactorMeasureItem";

const defaultTree = [{ operator: "+", type: "measure", measure: null }];

const operatorList = [
    { key: "+", value: "+", text: <Trans>+</Trans>, icone: <Trans>+</Trans> },
    { key: "-", value: "-", text: <Trans>-</Trans>, icone: <Trans>-</Trans> }
];

const processList = [
    { key: "measure", value: "measure", text: <Trans>measure</Trans>, icone: null },
    { key: "factor", value: "factor", text: <Trans>factor</Trans>, icone: null }
];

const Tree = (props) => {
    const { input, meta, site, output_cat, output_mtType, changeForm, measurementsObject } = props;
    const { org } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const usages = useGetUsagesQuery({ org: org.current }, { skip: !org.current });

    const [visited, setVisited] = useState(false);

    const formulaList = input.value || defaultTree;

    const changeProcess = (idx, data) => {
        const new_formula = _.map(formulaList, (item, index) => {
            if (index !== idx) {
                return item;
            }
            switch (data.value) {
                case "measure":
                    return { operator: item.operator, type: data.value, measure: null };
                case "factor":
                    return { operator: item.operator, type: data.value, measure: null, factor: 1 };
                default:
                    return item;
            }
        });
        changeForm(input.name, new_formula);
        setVisited(true);
    };

    const changeOperator = (idx, data) => {
        const new_formula = _.map(formulaList, (item, index) => {
            if (index !== idx) {
                return item;
            }
            return { ...item, operator: data.value };
        });
        changeForm(input.name, new_formula);
        setVisited(true);
    };

    const removeOperation = (idx) => {
        const new_formula = _.filter(formulaList, (item, index) => index !== idx);
        changeForm(input.name, new_formula);
        setVisited(true);
    };

    const renderFormula = useMemo(() => {
        return _.chain(formulaList)
            .map((node, idx) => {
                const wrapper_err = _.chain(meta.error).get(idx);
                const process_err = wrapper_err.get("process_type").value();
                const meas_err = wrapper_err.get("measure").value();
                const factor_err = wrapper_err.get("factor").value();
                return (
                    <React.Fragment key={idx}>
                        {visited && wrapper_err.value() && (
                            <Grid.Row>
                                {node.type === null && process_err && (
                                    <Grid.Column width={4} textAlign="center">
                                        <Label basic color="red" pointing="below">
                                            {process_err}
                                        </Label>
                                    </Grid.Column>
                                )}
                                {node.type === "measure" && meas_err && (
                                    <Grid.Column width={16} textAlign="center">
                                        <Label basic color="red" pointing="below">
                                            {meas_err}
                                        </Label>
                                    </Grid.Column>
                                )}
                                {node.type === "factor" && (
                                    <>
                                        <Grid.Column width={4} />
                                        <Grid.Column width={2}>
                                            {factor_err && (
                                                <Label basic color="red" pointing="below">
                                                    {factor_err}
                                                </Label>
                                            )}
                                        </Grid.Column>
                                        <Grid.Column width={1} />
                                        <Grid.Column width={8}>
                                            {meas_err && (
                                                <Label basic color="red" pointing="below">
                                                    {meas_err}
                                                </Label>
                                            )}
                                        </Grid.Column>
                                    </>
                                )}
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            {idx === 0 && <Grid.Column width={1} />}
                            {idx !== 0 && (
                                <Grid.Column width={1}>
                                    <Dropdown
                                        fluid
                                        options={operatorList}
                                        selection
                                        onChange={(e, data) => {
                                            changeOperator(idx, data);
                                        }}
                                        value={node.operator}
                                        upward
                                    />
                                </Grid.Column>
                            )}
                            {node.type === "measure" && (
                                <>
                                    <Grid.Column width={3}>
                                        <Dropdown
                                            fluid
                                            options={processList}
                                            selection
                                            onChange={(e, data) => changeProcess(idx, data)}
                                            value={node.type}
                                            upward
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={10}>
                                        <MeasureItem
                                            idx={idx}
                                            node={node}
                                            changeForm={changeForm}
                                            formula={formulaList}
                                            input_name={input.name}
                                            meas_err={meas_err}
                                            sites={sites.data}
                                            usages={usages.data}
                                            site={site}
                                            output_cat={output_cat}
                                            output_mtType={output_mtType}
                                            measurementsObject={measurementsObject}
                                        />
                                    </Grid.Column>
                                </>
                            )}
                            {node.type === "factor" && (
                                <>
                                    <Grid.Column width={3}>
                                        <Dropdown
                                            fluid
                                            options={processList}
                                            selection
                                            onChange={(e, data) => changeProcess(idx, data)}
                                            value={node.type}
                                            upward
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={10}>
                                        <FactorMeasureItem
                                            idx={idx}
                                            node={node}
                                            changeForm={changeForm}
                                            formula={formulaList}
                                            input_name={input.name}
                                            meas_err={meas_err}
                                            factor_err={factor_err}
                                            sites={sites.data}
                                            usages={usages.data}
                                            site={site}
                                            output_cat={output_cat}
                                            measurementsObject={measurementsObject}
                                        />
                                    </Grid.Column>
                                </>
                            )}
                            {node.type === null && (
                                <>
                                    <Grid.Column width={3}>
                                        <Dropdown
                                            fluid
                                            options={processList}
                                            selection
                                            onChange={(e, data) => changeProcess(idx, data)}
                                            value={node.type}
                                            upward
                                            error={!_.isUndefined(process_err)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={10} />
                                </>
                            )}
                            {idx !== 0 && (
                                <Grid.Column width={1}>
                                    <Button
                                        type="button"
                                        icon="delete"
                                        negative
                                        onClick={(e) => {
                                            removeOperation(idx);
                                        }}
                                    />
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </React.Fragment>
                );
            })
            .value();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formulaList, meta.error, visited, sites.data, usages.data, site, output_cat, output_mtType]);

    return (
        <>
            <label>
                <b>
                    <Trans>formula</Trans>
                </b>
            </label>
            <Dimmer.Dimmable dimmed={true} style={{ zIndex: 0, overflow: "visible" }}>
                <Dimmer inverted active={site !== null && output_cat === null}>
                    <MessageDisplay
                        message={i18n._(t`select dataflowspec`)}
                        level="warning"
                        iconName="warning circle"
                        isLoading={false}
                        attached={false}
                    />
                </Dimmer>

                {visited && meta.error && (
                    <Message warning attached="top" style={{ display: "block" }}>
                        <Icon name="warning" />
                        {`${_.size(formulaList) === 1 && visited ? i18n._(t`formula needs one more operand`) : i18n._(t`formula error`)}`}
                    </Message>
                )}
                <Segment attached style={{ background: "rgba(0, 151, 19, 0.1)" }}>
                    <Grid>
                        {renderFormula}
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Divider />
                            </Grid.Column>
                            <Grid.Column mobile={2} tablet={2} computer={1}>
                                {
                                    <Button.Group>
                                        <Button
                                            type="button"
                                            icon="add"
                                            positive
                                            onClick={(e) => {
                                                const new_nodes = { operator: "+", type: null };
                                                changeForm(input.name, [...formulaList, new_nodes]);
                                                setVisited(true);
                                            }}
                                        />
                                        <Button
                                            type="button"
                                            icon="delete"
                                            negative
                                            disabled={_.size(formulaList) <= 1}
                                            onClick={(e) => {
                                                if (_.size(formulaList) > 1) {
                                                    const new_formula = _.dropRight(formulaList, 1);
                                                    changeForm(input.name, new_formula);
                                                    setVisited(true);
                                                }
                                            }}
                                        />
                                    </Button.Group>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Dimmer.Dimmable>
        </>
    );
};

export default React.memo(Tree);
