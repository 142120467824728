import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Icon, Grid, Divider, Card, Segment } from "semantic-ui-react";

export const LandingAdmin = (props) => {
    const auth = useSelector((state) => state.auth);

    const navItemsGenerator = [
        {
            icon: "home",
            title: <Trans>home</Trans>,
            onClick: null,
            path: `/`,
            color: "red",
            display: true
        },
        {
            icon: "building",
            title: <Trans>organization</Trans>,
            onClick: null,
            path: `/organization`,
            color: "teal",
            display: auth?.rights?.is_admin === true
        },
        {
            icon: "users",
            title: <Trans>users</Trans>,
            onClick: null,
            path: `/users`,
            color: "blue",
            display: auth?.rights?.is_admin === true
        },
        {
            icon: "map marker alternate",
            title: <Trans>sites</Trans>,
            onClick: null,
            path: `/sites`,
            color: "grey",
            display: true
        },
        {
            icon: "list",
            title: <Trans>mailings</Trans>,
            onClick: null,
            path: `/mailings`,
            color: "pink",
            display: true
        },
        {
            icon: "plug",
            title: <Trans>usages</Trans>,
            onClick: null,
            path: `/usages`,
            color: "violet",
            display: true
        },
        {
            icon: "bolt",
            title: <Trans>imports</Trans>,
            onClick: null,
            path: `/imports`,
            color: "red",
            display: true
        },
        {
            icon: "upload",
            title: <Trans>importeddataset</Trans>,
            path: `/importeddatasets`,
            color: "yellow",
            display: true
        },
        {
            icon: "tags",
            title: <Trans>tags</Trans>,
            onClick: null,
            path: `/tags`,
            color: "orange",
            display: true
        },
        {
            icon: "whmcs",
            title: <Trans>equipments</Trans>,
            onClick: null,
            path: `/equipments`,
            color: "brown",
            display: true
        },
        {
            icon: "hdd",
            title: <Trans>sensors</Trans>,
            onClick: null,
            path: `/sensors`,
            color: "blue",
            display: true
        }
    ];

    const renderMenuItem = (item) => {
        const { icon, color, title, flipped } = item;
        return (
            <Card style={{ backgroundColor: "rgba(0, 0, 0, 0)", boxShadow: "none" }}>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    <Icon name={icon} circular color={color} inverted size="huge" flipped={flipped} />
                </Card.Content>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    <Card.Header>{title}</Card.Header>
                </Card.Content>
            </Card>
        );
    };

    return (
        <>
            <Segment attached basic>
                <Divider hidden section />
                <Grid container textAlign="left">
                    {_.chain(navItemsGenerator)
                        .filter((item) => item.display === true)
                        .map((item, idx) => {
                            return item.onClick ? (
                                <Grid.Column
                                    key={idx}
                                    mobile="8"
                                    computer="4"
                                    textAlign="center"
                                    onClick={item.onClick}
                                    style={{ cursor: "pointer" }}
                                >
                                    {renderMenuItem(item)}
                                </Grid.Column>
                            ) : (
                                <Grid.Column key={idx} mobile="8" computer="4" textAlign="center">
                                    {item.path && <Link to={item.path}>{renderMenuItem(item)}</Link>}
                                </Grid.Column>
                            );
                        })
                        .value()}
                </Grid>
                <Divider hidden section />
            </Segment>
        </>
    );
};

export default LandingAdmin;
