import React from "react";
import { Trans } from "@lingui/macro";
import { Grid, Segment, Header, Message, Icon } from "semantic-ui-react";

import Back from "modules/common/components/back";
import TabMenu from "./TabMenu";

export const Imports = () => {
    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/manage" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Imports management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Message icon>
                            <Icon name="info circle" />
                            <Trans>In this section, you will find the list of your data imports from files or APIs.</Trans>
                            <br />
                            <Trans>For file imports, define a source, including a server (FTP, FTPS, or SFTP) and a base directory.</Trans>
                            <br />
                            <Trans>
                                Specify how to locate and identify the file on the source server, which columns to import, what to do with the file
                                once it's read, and the import frequency.
                            </Trans>
                            <br />
                            <Trans>
                                For API imports, define the API URL and the necessary parameters to access the data. Specify the information required
                                to authenticate the request (API keys, endpoints, etc.).
                            </Trans>
                            <br />
                            <Trans>A task is then launched periodically to import the data.</Trans>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <TabMenu />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default React.memo(Imports);
