import _ from "lodash";
import axios from "axios";
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    status: 'idle',
    alertpacktypes: [],
    error: null
};

export const getAlertPackTypes = createAsyncThunk('alert/getAlertPackTypes', async ({ org }, thunkAPI) => {
    const current_org = _.get(org, 'name', null);
    try {
        const response = await PowerAdaptAPI.get(`/alertpacktypes?org=${current_org}&${refreshTimestampUrlParam()}`);
        return _.map(response.data, (alertpacktype) => {
            return {
                ...alertpacktype,
                key: alertpacktype.id,
                value: alertpacktype.id,
                text: alertpacktype.name,
                type: "packType"
            };
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

const alertPackTypeSlice = createSlice({
    name: "alertpacktype",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAlertPackTypes.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
                state.alertpacktypes = [];
            })
            .addCase(getAlertPackTypes.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Add any fetched posts to the array
                state.alertpacktypes = action.payload;
                state.error = null;
            })
            .addCase(getAlertPackTypes.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.alertpacktypes = [];
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default alertPackTypeSlice.reducer;