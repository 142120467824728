import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Modal, Button } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

export const AlertSensorModal = (props) => {
    const { sensors, sensors_exception, pack, push } = props;
    const [open, setOpen] = useState(false);

    const onSubmitForm = async (data) => {
        _.each(data.sensors, (sensor_id) => {
            const sensor = _.find(sensors, { id: sensor_id });
            const item = {
                object_type: 1, //cf. django alert override object_type
                object_id: sensor_id,
                identifier: sensor.text,
                override: {
                    elapsed_hour: reparseNumber(data.elapsed_hour)
                },
                pack: pack.id
            };
            push("sensors_exception", item);
        });
        setOpen(false);
    };

    const sensorOptionsFilter = _.chain(sensors)
        .filter((item) => _.chain(item).get("site").isEqual(pack.site).value())
        .filter((item) => _.chain(sensors_exception).find({ object_id: item.value }).isUndefined().value())
        .value();

    const initial = useMemo(() => {
        return {
            sensors: [],
            elapsed_hour: 6
        };
    }, []);

    return (
        <Modal
            open={open}
            trigger={
                <Button
                    icon="add"
                    type="button"
                    basic
                    onClick={(event) => {
                        setOpen(true);
                    }}
                />
            }
        >
            <Form
                onSubmit={onSubmitForm}
                initialValues={initial}
                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>Add sensor exception</Trans>
                            </Modal.Header>
                            <Modal.Content scrolling={false}>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Field
                                        name="sensors"
                                        label={i18n._(t`sensors`)}
                                        placeholder={i18n._(t`select sensors`)}
                                        options={sensorOptionsFilter}
                                        component={DropDownAdapter}
                                        multipleselect={1}
                                        isRequired={true}
                                        validate={(value) => (_.size(value) === 0 ? <Trans>sensors is required</Trans> : undefined)}
                                    />
                                    <Field
                                        name="elapsed_hour"
                                        placeholder={i18n._(t`enter time elapsed between 2 data`)}
                                        label={i18n._(t`time`)}
                                        unit={"h"}
                                        component={InputAdapter}
                                        parse={identityNull}
                                        inputMode="decimal"
                                        isRequired={true}
                                        validate={(value) => {
                                            const elapsedValidate = validateNumber(value, i18n, false, false);
                                            if (elapsedValidate !== undefined) {
                                                return elapsedValidate;
                                            }
                                            const num = reparseNumber(value);
                                            if (num < 6 || num > 720) {
                                                return <Trans>sensor alert time minimum 6h maximum 30d</Trans>;
                                            }
                                            return undefined;
                                        }}
                                    />
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={(e, data) => {
                                        setOpen(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

AlertSensorModal.propTypes = {
    sensors: PropTypes.array.isRequired,
    pack: PropTypes.object.isRequired,
    sensors_exception: PropTypes.array.isRequired,
    push: PropTypes.func.isRequired
};

export default React.memo(AlertSensorModal);
