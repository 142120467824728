import _ from "lodash";
import { t } from "@lingui/macro";

export const syndataflowtypeOptions = [
    { key: 3, value: 3, text: t`derivative must be >= 0` },
    { key: 1, value: 1, text: t`values and derivative can be < 0` },

];

export const equipmenttypeOptions = [
    { key: 1, value: 1, text: t`other` },
    { key: 2, value: 2, text: t`transformer station` },
    { key: 3, value: 3, text: t`LV switchboard` },
    { key: 4, value: 4, text: t`canalis` },
    { key: 5, value: 5, text: t`building main feed` },
    { key: 6, value: 6, text: t`workshop main feed` },
    { key: 7, value: 7, text: t`rotating machine` },
    { key: 8, value: 8, text: t`other machine type` }
];

/**
 * Use this function for display_unit transformation in equipment null => -1 / -1 => null
 * semantic UI use -1 && database needs null
 * @function process_auto_unit
 * @param {objet} equipement
 * @returns
 */
export const process_auto_unit = (equipment, server = false) => {
    return _.reduce(equipment, (res, data, field) => {
        if (field === "dataflow_set") {
            res[field] = _.chain(data)
                .map(dataflow => {
                    //iteration on dataflows
                    return _.reduce(dataflow, (df_res, df_data, df_field) => {
                        if (df_field === "measurement_set") {
                            df_res[df_field] = _.chain(df_data)
                                .map(measure => {
                                    //iteration on measurements
                                    return _.reduce(measure, (m_res, m_data, m_field) => {
                                        //check display_unit data if -1, null, or id_unit
                                        if (m_field === "display_unit" && m_data === -1 && server === true) {
                                            m_res[m_field] = null; //null for server
                                        } else if (m_field === "display_unit" && m_data === null && server === false) {
                                            m_res[m_field] = -1; //-1 for semantic and form display
                                        }
                                        else {
                                            m_res[m_field] = m_data;
                                        }
                                        return m_res;
                                    }, {});
                                })
                                .value();
                        } else {
                            df_res[df_field] = df_data;
                        }
                        return df_res;
                    }, {});
                })
                .value();
        } else {
            res[field] = data;
        }
        return res;
    }, {});
};


export const standbyThresholdUnit = (unit) => {
    switch (unit) {
        case 'kWh':
            return 'kW';
        case 'm³':
            return 'm³';
        case 'Nm³':
            return 'Nm³';
        default:
            return unit;
    }
};

export const deleteExclusion = [
    "pwa_backend.SynDataFlow_measurements",
    "pwa_backend.DataFlow",
    "pwa_clients.EnergySavingActions_numerator_measurements",
    "pwa_clients.AlertMeasurement",
    "pwa_clients.EquipmentTag",
    "pwa_clients.DiagramNode",
    "pwa_clients.ScheduleExport_measurements"
];

export const modifiedExclusion = [];