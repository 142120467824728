import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { Form, Field } from "react-final-form";
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from "final-form-arrays";
import _ from "lodash";
import { Modal, Button, Icon, Grid, Divider, Popup, Segment, Table } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';
import { roundedDate } from 'modules/time/utils';
import { toast_options, toast_options_err } from 'modules/notification/notificationMiddleware';
import { useGetCalendarExceptionQuery, useUpdateCalendarExceptionMutation } from '../calendarService';

import { InputAdapter, DateTimeAdapter, ToggleAdapter } from 'modules/common/components/form';
import MessageDisplay from 'modules/common/components/MessageDisplay';

const UpdateCalendarExceptionModalForm = (props) => {
    const today = moment().startOf("day");
    const { id_site, exceptions, item } = props;
    const [open, setOpen] = useState(false);
    const { org } = useSelector(state => state);
    const current_lng = useSelector((state) => state.i18n.current);

    //check if schedule is writable
    const can_change = props.can_change; //default is site's rights
    const past_exception = item.day !== null && item.day < today.format('YYYY-MM-DD');


    const current_exception = useGetCalendarExceptionQuery(
        { org: org.current, id_site, id_exception: item.id },
        { skip: !org.current || id_site === undefined || open === false || item?.id === undefined }
    );


    const [updateException, update] = useUpdateCalendarExceptionMutation();

    // Create Calendar
    useEffect(() => {
        if (update.isSuccess) {
            toast.success(i18n._(t`Exception updated`), toast_options);
            setOpen(false);
        }
        if (update.isError) {
            toast.error(i18n._(t`Can't update exception`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isSuccess, update.isError]);


    const initialValues = useMemo(() => {
        if (current_exception.isSuccess) {
            const exception = current_exception.data;
            return {
                exception: {
                    ...exception,
                    day: moment(exception.day, 'YYYY-MM-DD', true),
                },
                isClosed: _.size(exception.hours) === 0
            };
        }
        return {
            exception: null,
            isClosed: true
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_exception]);

    const validate = (values) => {
        const errors = {};

        let exception = {};
        if (typeof values.exception?.day === "string") {
            exception.day = <Trans>Invalid format</Trans>;
            errors.exception = exception;
        } else {
            const existing_day = _.chain(exceptions)
                .filter((excpt) => {
                    return excpt.id !== item.id;
                })
                .find({ day: values.exception?.day.format('YYYY-MM-DD') })
                .value();
            if (existing_day) {
                exception.day = <Trans>An exception with this day already exists</Trans>;
                errors.exception = exception;

            }
        }
        if (!values.isClosed) {
            if (_.size(values.exception.hours) === 0) {
                errors.global = <Trans>You need to set time ranges for a day open</Trans>;
            } else {
                exception['hours'] = [];
                _.each(values.exception.hours, (time_range, idx, all_values) => {
                    exception['hours'][idx] = {};
                    if (time_range.start === undefined) {
                        exception['hours'][idx].start = <Trans>Invalid format</Trans>;
                        errors.exception = exception;
                        return false;
                    }
                    if (time_range.end === undefined) {
                        exception['hours'][idx].end = <Trans>Invalid format</Trans>;
                        errors.exception = exception;
                        return false;
                    } else if (time_range.end === "00:00" && idx !== _.size(all_values) - 1) {
                        exception['hours'][idx].end = <Trans>End time can't be 00:00</Trans>;
                        errors.exception = exception;
                        return false;
                    }
                    if (time_range.start >= time_range.end && time_range.end !== "00:00") {
                        exception['hours'][idx].start = <Trans>Start time must be before end time</Trans>;
                        errors.exception = exception;
                        return false;
                    }


                    if (idx > 0) {
                        const prev_range = all_values[idx - 1];
                        if (time_range.start === prev_range.end) {
                            exception['hours'][idx].start = <Trans>A start time must be different from an end time</Trans>;
                            errors.exception = exception;
                            return false;
                        }
                        else if (time_range.start < prev_range.end) {
                            exception['hours'][idx].start = <Trans>2 opening time ranges can't overlap</Trans>;
                            errors.exception = exception;
                            return false;
                        }
                    }
                });
            }
        }
        return errors;
    };

    const submitForm = async (formData) => {
        if (can_change && !past_exception) {
            const { exception, isClosed } = formData;
            const remap_hours = isClosed ? [] : _.reduce(exception.hours, (res, range, idx) => {
                res.push(range.start);
                if (idx === _.size(exception.hours) - 1 && range.end === "00:00") {
                    res.push(null);
                } else {
                    res.push(range.end);
                }
                return res;
            }, []);

            const data = {
                ...exception,
                day: exception.day.format('YYYY-MM-DD'),
                site: id_site,
                hours: remap_hours
            };
            await updateException({ org: org.current, id_site, data });
        }
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Popup
                    trigger={
                        <Button
                            type="button"
                            icon={can_change ? "edit" : "eye"}
                            onClick={async (e) => {
                                setOpen(true);
                            }}
                        />
                    }
                >
                    <Popup.Content>
                        {can_change && (<Trans>Opening day exception edition</Trans>)}
                        {!can_change && (<Trans>View opening day exception</Trans>)}
                    </Popup.Content>
                </Popup>
            }
        >
            <Modal.Header>
                <Trans>Update opening day exception</Trans>
            </Modal.Header>
            {past_exception && (
                <Modal.Content>
                    <MessageDisplay
                        message={
                            <Trans>Exception in the past can't be changed</Trans>
                        }
                        level="warning"
                        iconName="warning circle"
                        isLoading={false}
                        attached={false}
                    />
                </Modal.Content>
            )}
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    mutators={{ ...arrayMutators }}
                    validate={validate}
                    render={({
                        handleSubmit,
                        form: { mutators: { push, pop } },
                        submitting,
                        pristine,
                        invalid,
                        values,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid >
                                    <Grid.Column width={16} >
                                        <Field
                                            name="exception.name"
                                            placeholder={i18n._(t`enter an exception name`)}
                                            label={i18n._(t`name`)}
                                            isRequired={true}
                                            component={InputAdapter}
                                            validate={(value) => {
                                                const existing_name = _.chain(exceptions)
                                                    .filter((excpt) => {
                                                        return excpt.id !== item.id;
                                                    })
                                                    .find({ name: value })
                                                    .value();

                                                if (existing_name) {
                                                    return <Trans>An exception with this name already exists</Trans>;
                                                }
                                                if (!value) {
                                                    return <Trans>Name is required</Trans>;
                                                }
                                                return undefined;
                                            }}
                                            disabled={!can_change || past_exception}
                                        />
                                    </Grid.Column>
                                    <Grid.Column mobile={16} computer={6}>
                                        <Field
                                            name="exception.day"
                                            component={DateTimeAdapter}
                                            locale={current_lng}
                                            date_limit={null}
                                            labeled={false}
                                            label={i18n._(t`day`)}
                                            labelPosition={'left'}
                                            isRequired={true}
                                            dateFormat={true}
                                            timeFormat={false}
                                            disabled={true} // can't change day
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Field
                                            name="isClosed"
                                            label={i18n._(t`site closed today ?`)}
                                            component={ToggleAdapter}
                                            disabled={!can_change || past_exception}
                                        />
                                    </Grid.Column>
                                    {!values.isClosed && (
                                        <Segment basic attached style={{ overflowX: "auto" }}>
                                            <Table
                                                unstackable
                                                striped
                                                celled
                                                attached
                                            >
                                                <Table.Header fullWidth>
                                                    <Table.Row>
                                                        <Table.HeaderCell><Trans>Opening hours</Trans></Table.HeaderCell>
                                                        {can_change && !past_exception && (<Table.HeaderCell collapsing><Trans>actions</Trans></Table.HeaderCell>)}
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <FieldArray name="exception.hours">
                                                            {(c_field) => {
                                                                return (
                                                                    <>
                                                                        <Table.Cell>
                                                                            {c_field.fields.length === 0 && (
                                                                                <MessageDisplay
                                                                                    message={i18n._(t`You need to add time ranges for this day`)}
                                                                                    level="info"
                                                                                    iconName="info circle"
                                                                                    isLoading={false}
                                                                                    attached={false}
                                                                                />
                                                                            )}
                                                                            {
                                                                                c_field.fields.length > 0 && (
                                                                                    <div style={{ display: "flex", alignItems: 'center', alignContent: 'center', justifyContent: "start" }}>
                                                                                        {c_field.fields.map((c_name, c_index) => {
                                                                                            return (
                                                                                                <React.Fragment key={c_name}>
                                                                                                    <div key={`${c_index}.start`}>
                                                                                                        <Field
                                                                                                            name={`${c_name}.start`}
                                                                                                            component={InputAdapter}
                                                                                                            type="time"
                                                                                                            style={{
                                                                                                                marginRight: '2px',
                                                                                                            }}
                                                                                                            disabled={!can_change || past_exception}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div key={`${c_index}.end`}>
                                                                                                        <Field
                                                                                                            name={`${c_name}.end`}
                                                                                                            component={InputAdapter}
                                                                                                            type="time"
                                                                                                            style={{
                                                                                                                marginRight: '20px',
                                                                                                            }}
                                                                                                            disabled={!can_change || past_exception}
                                                                                                        />
                                                                                                    </div>

                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </Table.Cell>
                                                                        {can_change && !past_exception && (<Table.Cell collapsing>
                                                                            <Button.Group>
                                                                                <Popup
                                                                                    on={"hover"}
                                                                                    content={i18n._(t`Add a new time range`)}
                                                                                    trigger={
                                                                                        <Button
                                                                                            disabled={!can_change}
                                                                                            type="button"
                                                                                            icon="add"
                                                                                            onClick={(e) => {
                                                                                                push(`exception.hours`, { start: roundedDate(moment().startOf('d')).format("HH:mm"), end: roundedDate(moment().startOf('d')).format("HH:mm") });
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                />
                                                                                <Popup
                                                                                    on={"hover"}
                                                                                    content={i18n._(t`Clear last time range`)}
                                                                                    trigger={
                                                                                        <Button
                                                                                            disabled={c_field.fields.length === 0 || !can_change}
                                                                                            negative
                                                                                            type="button"
                                                                                            icon="trash"
                                                                                            onClick={(e) => {
                                                                                                pop(`exception.hours`);
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            </Button.Group>
                                                                        </Table.Cell>
                                                                        )}
                                                                    </>
                                                                );
                                                            }}
                                                        </FieldArray>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Segment>
                                    )}
                                </Grid>
                                <Grid.Column width={16}>
                                    <Divider />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Grid>
                                        <Grid.Column textAlign="right">
                                            <Button type="button" negative onClick={() => { setOpen(false); }}>
                                                <Trans>cancel</Trans>
                                            </Button>
                                            {can_change && !past_exception && (
                                                <Button type="submit" positive icon labelPosition='right' disabled={submitting || pristine || invalid}>
                                                    <Icon name="check" />
                                                    <Trans>validate</Trans>
                                                </Button>
                                            )}
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default UpdateCalendarExceptionModalForm;
