import React from "react";
import _ from "lodash";
import ReactSpeedometer from "react-d3-speedometer";

const GaugeRounded = (props) => {
    const { width, height, className, ...rest } = props;

    const ratio = 4 / 3;
    const gaugeHeight = height * 0.8;
    const gaugeWidth = Math.min(gaugeHeight * ratio, width);

    return (
        <div
            className={className}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width,
                height,
                filter: _.isFinite(rest.raw_value) ? "none" : "grayscale(100%)"
            }}
        >
            <ReactSpeedometer {...rest} width={gaugeWidth} height={gaugeHeight} />
        </div>
    );
};

GaugeRounded.defaultProps = {
    paddingHorizontal: 5,
    paddingVertical: 5,
    forceRender: true,
    needleHeightRatio: 0.7,
    needleColor: "gray",
    ringWidth: 20,
    value: 0
};

export default React.memo(GaugeRounded, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
