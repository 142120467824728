import React from "react";
import { t, Trans } from "@lingui/macro";
import { Field } from "react-final-form";
import i18n from "modules/i18n/i18nConfig";
import _ from "lodash";
import { identity } from "modules/common/utils/form";
import { uuidvalidate } from "modules/common/utils";

import { Icon, Grid, Header, Segment } from "semantic-ui-react";

import { startsWith98, isTwelveDigits } from "modules/import/utils";

import { InputAdapter, DropDownAdapter } from "modules/common/components/form";

const ConfigApiParamsForm = (props) => {
    const { can_change, id_file, apiImportTypes, form } = props;

    return (
        <>
            <Header style={{ marginTop: "1rem" }} as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                <Trans>Api parameters</Trans>
            </Header>
            <Segment attached>
                <Grid stackable verticalAlign="top">
                    <Grid.Column width={16}>
                        <Field
                            name="import_type"
                            placeholder={i18n._(t`import_type`)}
                            label={i18n._(t`import_type`)}
                            isRequired={true}
                            component={DropDownAdapter}
                            search={false}
                            options={apiImportTypes}
                            disabled={!can_change || id_file !== undefined}
                            validate={(value) => {
                                if (!_.isFinite(value)) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>

                    {/* Import type 0 => default forms fields for Suez API  */}
                    {form.getState().values.import_type === 0 && (
                        <>
                            <Grid.Column width={16}>
                                <Field
                                    name="endpoint"
                                    placeholder={i18n._(t`Enter endpoint of API import`)}
                                    label={i18n._(t`Endpoint`)}
                                    helperText={i18n._(t`URL of the « ConsumptionDetail (WaterData) » API provided by SUEZ`)}
                                    isRequired={true}
                                    parse={identity}
                                    component={InputAdapter}
                                    unitposition="left"
                                    unit={<Icon name="at" />}
                                    unitcolor="secondary"
                                    disabled={!can_change}
                                    validate={(value) => {
                                        if (!value) {
                                            return <Trans>Required field</Trans>;
                                        }
                                        return undefined;
                                    }}
                                />
                            </Grid.Column>

                            <Grid.Column width={16}>
                                <Field
                                    name="parameters.api_key"
                                    placeholder={i18n._(t`Enter an Api key`)}
                                    label={i18n._(t`Api key`)}
                                    helperText={i18n._(t`The API KEY field of the connection identifiers provided by SUEZ`)}
                                    isRequired={true}
                                    parse={identity}
                                    component={InputAdapter}
                                    unitposition="left"
                                    unit={<Icon name="key" />}
                                    unitcolor="secondary"
                                    disabled={!can_change}
                                    validate={(value) => {
                                        if (!value) {
                                            return <Trans>Required field</Trans>;
                                        }

                                        if (!uuidvalidate(value)) {
                                            return <Trans>invalid format</Trans>;
                                        }
                                        return undefined;
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Field
                                    name="parameters.organization_id"
                                    placeholder={i18n._(t`Enter an organization id`)}
                                    label={i18n._(t`Organization id`)}
                                    helperText={i18n._(t`The OrganizationID field of the connection identifiers provided by SUEZ`)}
                                    isRequired={true}
                                    parse={identity}
                                    component={InputAdapter}
                                    unitposition="left"
                                    unit={<Icon name="building" />}
                                    unitcolor="secondary"
                                    disabled={!can_change}
                                    validate={(value) => {
                                        if (!value) {
                                            return <Trans>Required field</Trans>;
                                        }

                                        if (!uuidvalidate(value)) {
                                            return <Trans>invalid format</Trans>;
                                        }
                                        return undefined;
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Field
                                    name="parameters.client_ref"
                                    placeholder={i18n._(t`Enter an client ref`)}
                                    label={i18n._(t`Client ref`)}
                                    helperText={i18n._(t`The 12 digits of the customer reference, starting with 98, without the « - » character`)}
                                    isRequired={true}
                                    parse={identity}
                                    component={InputAdapter}
                                    unitposition="left"
                                    unit={<Icon name="user" />}
                                    unitcolor="secondary"
                                    disabled={!can_change}
                                    validate={(value) => {
                                        if (!value) {
                                            return <Trans>Required field</Trans>;
                                        }

                                        if (!startsWith98(value)) {
                                            return <Trans>Client ref must start with 98</Trans>;
                                        }
                                        if (!isTwelveDigits(value)) {
                                            return <Trans>Client ref must have 12 digits</Trans>;
                                        }
                                        return undefined;
                                    }}
                                />
                            </Grid.Column>
                        </>
                    )}
                </Grid>
            </Segment>
        </>
    );
};

export default ConfigApiParamsForm;
