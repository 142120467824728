import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetMachinesQuery } from "modules/machine/machineService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import DashboardPower from "./DashboardPower";
import DashboardPredict from "./DashboardPredict";

const DashboarWidgets = (props) => {
    const { equipment, measurements } = props;
    const org = useSelector((state) => state.org);

    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const machine = _.find(machines.data, { id: equipment?.machine });

    const hasDigitalInput = _.includes(equipment?.dataflowspec_set, 16); //id:16 => categorie digital input

    if (_.isEmpty(measurements)) {
        return <MessageDisplay message={i18n._(t`no measurements`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />;
    }

    return (
        <Grid celled stretched centered className={hasDigitalInput ? "pwaPanelDi" : "pwaPanel"}>
            {machine?.is_predict === true && <DashboardPredict equipment={equipment} machine={machine} measurements={measurements} />}
            {machine?.is_predict !== true && <DashboardPower equipment={equipment} measurements={measurements} />}
        </Grid>
    );
};

export default React.memo(DashboarWidgets);
