import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Segment, Header, Button } from "semantic-ui-react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";

import MessageDisplay from "modules/common/components/MessageDisplay";
import Back from "modules/common/components/back";
import DetailGraphic from "./DetailGraphic";
import MinAvgMaxGraphic from "./MinAvgMaxGraphic";
import ConsumptionGraphic from "./ConsumptionGraphic";
import InstantComparison from "../actions/InstantComparison";

const Comparison = (props) => {
    const [openComparison, setOpenComparison] = useState(false);
    const selected_equipments_ids = useSelector((state) => state.dash.selected_equipments);
    const comparison_params = useSelector((state) => state.dash.comparison_params);

    return (
        <Segment>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {_.size(selected_equipments_ids) === 0 && <Trans>comparison</Trans>}
                            {_.size(selected_equipments_ids) === 1 && <Trans>comparison one feed</Trans>}
                            {_.size(selected_equipments_ids) > 1 && <Trans>comparison multi feed</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                {_.size(selected_equipments_ids) === 0 && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <MessageDisplay
                                message={i18n._(t`You need to select equipments in dashboard first`)}
                                level="warning"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {_.size(selected_equipments_ids) > 0 && (
                    <>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                <Button
                                    fluid
                                    icon="balance"
                                    content={i18n._(t`New comparison`)}
                                    labelPosition="left"
                                    onClick={(e) => setOpenComparison(true)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={14} computer={14} textAlign="center" className="pwaGraph">
                                <Header as="h3" block textAlign="center" attached="top">
                                    <Trans>detail</Trans>
                                </Header>
                                <DetailGraphic />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            {comparison_params?.hasTimeIntegral && (
                                <Grid.Column mobile={16} tablet={14} computer={7} textAlign="center" className="pwaGraph">
                                    <Header as="h3" block textAlign="center" attached="top">
                                        <Trans>consumptions historic</Trans>
                                    </Header>
                                    {<ConsumptionGraphic />}
                                </Grid.Column>
                            )}
                            <Grid.Column
                                mobile={16}
                                tablet={14}
                                computer={comparison_params?.hasTimeIntegral ? 7 : 14}
                                textAlign="center"
                                className="pwaGraph"
                            >
                                <Header as="h3" block textAlign="center" attached="top">
                                    <Trans>long term trend</Trans>
                                </Header>
                                {<MinAvgMaxGraphic />}
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )}
                {openComparison && (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <InstantComparison setOpen={setOpenComparison} />
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default Comparison;
