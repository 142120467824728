import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Dropdown, Icon, List, Pagination } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { setPage, setRowsPerPage } from "../dashboardSlice";

import { Media } from "App";

const DashboardPagination = (props) => {
    const { paginate_all } = props;
    const dispatch = useDispatch();
    const pagination = useSelector((state) => state.dash.pagination);
    useSelector((state) => state.i18n.current);

    const rowsPerPageOptions = useMemo(() => {
        const defaultRowsPerPages = [
            { key: "10", value: 10, text: 10 },
            { key: "20", value: 20, text: 20 },
            { key: "50", value: 50, text: 50 },
            { key: "100", value: 100, text: 100 }
        ];
        if (_.isFinite(paginate_all)) {
            return [...defaultRowsPerPages, { key: "all", value: paginate_all, text: i18n._(t`all`) }];
        }
        return defaultRowsPerPages;

        // eslint-disable-next-line
    }, [i18n, paginate_all]);

    const totalPages = Math.ceil(paginate_all / pagination.itemsPerPage);

    return (
        <List horizontal size="mini">
            <Media greaterThanOrEqual="computer">
                {(mediaClassNames, renderChildren) =>
                    renderChildren && (
                        <List.Item>
                            <Trans>item per page</Trans>
                        </List.Item>
                    )
                }
            </Media>
            <List.Item>
                <Dropdown
                    selection
                    compact
                    onChange={async (e, { value }) => {
                        await dispatch(setRowsPerPage(value));
                    }}
                    value={pagination?.itemsPerPage ?? 10}
                    options={rowsPerPageOptions}
                    disabled={false}
                    upward
                />
            </List.Item>
            <List.Item>
                <Pagination
                    size="mini"
                    siblingRange={0}
                    boundaryRange={1}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true
                    }}
                    nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true
                    }}
                    onPageChange={async (e, { activePage }) => {
                        await dispatch(setPage(activePage));
                    }}
                    totalPages={totalPages}
                    activePage={pagination.page}
                    disabled={false}
                />
            </List.Item>
        </List>
    );
};

export default React.memo(DashboardPagination);
