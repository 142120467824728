import React, { useCallback, useMemo } from "react";
import { Field } from "react-final-form";
import _ from "lodash";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { Grid, Dimmer, Header, Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { validateNumber } from "modules/common/utils";
import { syndataflowtypeOptions } from "../utils";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetEquipmentsQuery } from "../equipmentService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import { DropDownAdapter } from "modules/common/components/form";
import Tree from "./advanced/Tree";

const SyntheticFormula = (props) => {
    const { df_name, df, id_eqpmt, changeForm, site, output_cat, output_measurementtype, categoryOptions, sameFormulaWithInit } = props;
    const org = useSelector((state) => state.org);

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });

    const measurementsObject = useMemo(() => {
        return _.reduce(
            measurements.data,
            (res, measure) => {
                const df = _.find(dataflows.data, { id: measure?.dataflow });
                const mttype = _.find(measurementtypes.data, { id: measure?.measurementtype });
                const eqt = _.find(equipments.data, { id: df?.equipment });
                const representation = df && mttype ? `${df?.name} - ${mttype.repr}` : "-";
                const new_measure = { key: measure?.id, text: representation, value: String(measure?.id) };
                res[measure.id] = { ...measure, ...new_measure, dataflow: df, measurementtype: mttype, equipment: eqt };
                return res;
            },
            {}
        );
    }, [measurements, dataflows, measurementtypes, equipments]);

    const formulaValidator = useCallback(
        (err, allValues) => {
            const err_list = _.chain(err)
                .map((line_formula) => {
                    switch (line_formula.type) {
                        case null:
                            return {
                                process_type: <Trans>you need to choose type item</Trans>
                            };
                        case "factor":
                            let factor_err = {};
                            const factorValidate = validateNumber(line_formula.factor, i18n, false, false);
                            if (factorValidate !== undefined) {
                                factor_err["factor"] = factorValidate;
                            }
                            if (!line_formula.measure) {
                                factor_err["measure"] = <Trans>measurement is required</Trans>;
                            }
                            return _.isEmpty(factor_err) ? undefined : factor_err;
                        case "measure": {
                            if (!line_formula.measure)
                                return {
                                    measure: <Trans>measurement is required</Trans>
                                };

                            //Retrieve measurement based on measurement id
                            const measurement = measurementsObject?.[line_formula.measure];

                            const mt_m = measurement?.measurementtype?.id;
                            //Retrive mt from form in the current state not previous
                            const mt_form = allValues?.equipment?.dataflow_set?.[0]?.measurement_set?.[0].measurementtype ?? null;
                            if (mt_form === null) {
                                return true; //silent error because output_mt_type need to be seleted
                            }
                            if (mt_m !== mt_form) {
                                return {
                                    measure: <Trans>Unit incompatible with output measurement type</Trans>
                                };
                            }
                            return undefined;
                        }
                        default:
                            return undefined;
                    }
                })
                .value();
            if (!_.every(err_list, _.isUndefined)) {
                return err_list;
            }
            if (_.size(err) < 2) {
                return <Trans>formula needs one more operand</Trans>;
            }
            return undefined;
        },
        [measurementsObject]
    );

    return (
        <Grid.Column width={16}>
            <Header attached="top" block as="h3" textAlign="center">
                <Trans>Syndataflow definition</Trans>
            </Header>
            {id_eqpmt !== undefined && sameFormulaWithInit === false && (
                <MessageDisplay
                    message={i18n._(
                        t`In case the measurement is used in an alert, if you modify the measurement, it will be necessary to update the associated alerts`
                    )}
                    level="info"
                    iconName="info circle"
                    isLoading={false}
                />
            )}
            <Segment attached>
                <Grid verticalAlign="top" centered>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Field
                                name={`${df_name}.dataflowspec`}
                                label={i18n._(t`output category`)}
                                placeholder={i18n._(t`select category`)}
                                options={categoryOptions}
                                isRequired={true}
                                component={DropDownAdapter}
                                disabled={id_eqpmt !== undefined}
                                customAction={(data) => {
                                    //set dataflowspec_tech when dataflowspec change
                                    changeForm(`${df_name}.dataflowspec_tech`, data);
                                    //reset associated measurement_type
                                    changeForm(`${df_name}.measurement_set[0].measurementtype`, null);
                                    changeForm(`${df_name}.measurement_set[0].measurementtype_tech`, null);
                                    changeForm(`${df_name}.measurement_set[0].display_unit`, null);
                                    /*changeForm(
                                `${df_name}.syndataflow.formula`,
                                null
                            );*/
                                }}
                                validate={(value) => {
                                    if (!value) return <Trans>output category is required</Trans>;
                                    if (value === 8) return <Trans>can't use pulse category</Trans>;
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Field
                                name={`${df_name}.syndataflow.syndataflowtype`}
                                label={i18n._(t`syndataflowtype`)}
                                placeholder={i18n._(t`select syndataflowtype`)}
                                options={syndataflowtypeOptions}
                                isRequired={true}
                                component={DropDownAdapter}
                                validate={(value) => (!value ? <Trans>syndataflowtype is required</Trans> : undefined)}
                                defaultValue={3}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Dimmer.Dimmable
                                dimmed={true}
                                style={{
                                    zIndex: 0,
                                    overflow: "visible"
                                }}
                            >
                                <Dimmer inverted active={id_eqpmt === undefined && output_measurementtype === null}>
                                    <MessageDisplay
                                        message={i18n._(t`select output category then output measurementtype first`)}
                                        level="warning"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Dimmer>
                                {
                                    <Field
                                        name={`${df_name}.syndataflow.formula`}
                                        label={i18n._(t`formula`)}
                                        component={Tree}
                                        site={site}
                                        output_cat={output_cat}
                                        output_mtType={output_measurementtype}
                                        changeForm={changeForm}
                                        measurementsObject={measurementsObject}
                                        value={df?.formula}
                                        isAdd={id_eqpmt === undefined}
                                        validate={formulaValidator}
                                    />
                                }
                            </Dimmer.Dimmable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Grid.Column>
    );
};

export default React.memo(SyntheticFormula);
