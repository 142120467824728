import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Button, Modal, Icon } from "semantic-ui-react";
import { Trans } from "@lingui/macro";
import { deletePack } from "modules/alert/alert_pack/alertPackSlice";


const DeletePack = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const { item } = props;

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon="delete" />}
        >
            <Modal.Header>
                <Trans>delete</Trans>
            </Modal.Header>
            <Modal.Content>
                <Trans>want delete alertpack</Trans>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => setOpen(false)}>
                    <Trans>cancel</Trans>
                </Button>
                <Button
                    labelPosition="right"
                    icon
                    positive
                    onClick={async (e) => {
                        await setOpen(false);
                        await dispatch(deletePack(item.id));
                    }}
                >
                    <Trans>validate</Trans>
                    <Icon name="trash" inverted />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

DeletePack.propTypes = {
    item: PropTypes.object.isRequired,
};

export default DeletePack;
