import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';

export const sensortypeApi = defaultApi.injectEndpoints({
    reducerPath: 'apiSensortype',
    endpoints: (build) => ({
        getSensortypes: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `sensortypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 86400,
            providesTags: ["Sensortype"],
            transformResponse: (response) => {
                return _.chain(response)
                    .map((sensortype) => {
                        return {
                            ...sensortype,
                            key: sensortype.id,
                            value: sensortype.id,
                            text: sensortype.name
                        };
                    })
                    .value();
            }
        }),
    }),
    overrideExisting: false,
});


export const { useGetSensortypesQuery } = sensortypeApi;