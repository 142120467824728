import React from "react";
import { Field } from "react-final-form";
import { t, Trans } from "@lingui/macro";
import i18n from "modules/i18n/i18nConfig";
import _ from "lodash";
import { identity } from "modules/common/utils/form";

import { Icon, Grid } from "semantic-ui-react";

import { ToggleAdapter, InputAdapter, TextAreaAdapter } from "modules/common/components/form";

const ConfigApiDetailForm = (props) => {
    const { can_change, id_file, apiImports } = props;
    return (
        <>
            <Grid.Column width={16}>
                <Field
                    name="is_active"
                    label={i18n._(t`is_active`)}
                    labelposition="right"
                    parse={identity}
                    component={ToggleAdapter}
                    disabled={!can_change}
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <Field
                    name="name"
                    placeholder={i18n._(t`Enter name of API import`)}
                    label={i18n._(t`name`)}
                    isRequired={true}
                    parse={identity}
                    component={InputAdapter}
                    unitposition="left"
                    unit={<Icon name="file text" />}
                    unitcolor="secondary"
                    disabled={!can_change}
                    validate={(value) => {
                        if (!value) {
                            return <Trans>Required field</Trans>;
                        }

                        const existing_name = _.chain(apiImports.data)
                            .filter((item) => {
                                return item.id !== parseInt(id_file);
                            })
                            .find({ name: value })
                            .value();

                        if (existing_name) {
                            return <Trans>An api source with this name already exists</Trans>;
                        }
                        return undefined;
                    }}
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <Field
                    name="description"
                    placeholder={i18n._(t`Enter description`)}
                    label={i18n._(t`description`)}
                    parse={identity}
                    rows={4}
                    component={TextAreaAdapter}
                    disabled={!can_change}
                />
            </Grid.Column>
        </>
    );
};

export default ConfigApiDetailForm;
