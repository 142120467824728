import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Button, Modal, Segment, Checkbox, Icon, Popup, Grid, Divider } from "semantic-ui-react";
import { toast } from 'react-toastify';
import { t, Trans } from "@lingui/macro";
import i18n from "modules/i18n/i18nConfig";

import { useDeleteTagMutation } from "modules/tag/tagService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { deleteExclusion } from "../utils";

const DeleteTags = (props) => {
    const { item, can_actions } = props;

    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { org } = useSelector((state) => state);

    const [deleteTag, deleted] = useDeleteTagMutation();
    const [waitingDelete, wDeleted] = useDeleteTagMutation();

    useEffect(() => {
        if (deleted.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (deleted.isSuccess) {
            toast.success(i18n._(t`successful delete tag`), toast_options);
            setConfirm(false);
        }
        if (deleted.isError) {
            toast.error(i18n._(t`cannot delete tag`), toast_options_err);
        }
    }, [deleted]);

    const handleClick = async (e) => {
        setOpen(true);
        await waitingDelete({ org: org.current, data: item.id });
    };

    const delete_objects = useMemo(() => {
        if (wDeleted.isSuccess) {
            return _.chain(wDeleted.data)
                .get('objects', {})
                .reduce((res, item, key) => {
                    if (!_.includes(deleteExclusion, key)) {
                        res.push(
                            <tr key={key}>
                                <td>{i18n._(key)}:</td>
                                <td><strong>{item}</strong></td>
                            </tr>
                        );
                    }
                    return res;
                }, [])
                .value();
        }
        return [];
    }, [wDeleted]);


    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            trigger={
                <Popup trigger={
                    <Button
                        disabled={!can_actions}
                        type="button"
                        icon="delete"
                        onClick={handleClick}
                    />
                }>
                    <Popup.Content>
                        <Trans >Delete tag</Trans>
                    </Popup.Content>
                </Popup>
            }
            onClose={() => {
                setOpen(false);
                setConfirm(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>
                <Trans>want delete tag {item.name}</Trans>
            </Modal.Header>

            <Modal.Content>
                {wDeleted.isError && (
                    <MessageDisplay
                        message={
                            <>
                                {/* check 404 not found to prevent incorrect message display */}
                                <Trans>You can't delete this tag</Trans>
                                <Divider />
                                {wDeleted.error?.originalStatus !== 404 && wDeleted?.error?.data?.error !== undefined && (<p>{i18n._(wDeleted?.error?.data?.error)}</p>)}
                            </>
                        }
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                    />
                )}
                {wDeleted.isLoading && (
                    <MessageDisplay
                        message={i18n._(t`send request to server`)}
                        level="info"
                        iconName="info circle"
                        isLoading={false}
                    />
                )}
                {wDeleted.isSuccess && (
                    <>
                        <Segment basic>
                            <div style={{ color: "#794b02", fontStyle: "italic" }}>
                                {!_.isUndefined(wDeleted.data?.objects) && (
                                    <Grid centered>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Trans>Deleting this tag may lead to the deletion of several elements related to this tag.</Trans>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {!_.isEmpty(delete_objects) && (
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Trans>Related data that will be deleted:</Trans>
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <table>
                                                        <tbody>
                                                            {delete_objects}
                                                        </tbody>
                                                    </table>
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                )}
                            </div>
                        </Segment>
                        <Segment basic>
                            <Checkbox
                                label={
                                    <label>
                                        <Trans>yes, i confirm deletion</Trans>
                                    </label>
                                }
                                onChange={(e, data) => setConfirm(data.checked)}
                                checked={confirm}
                            />
                        </Segment>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setOpen(false);
                        setConfirm(false);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                {wDeleted.data?.objects &&
                    <Button
                        type="button"
                        labelPosition="right"
                        icon
                        positive
                        onClick={(e) => {
                            setOpen(false);
                            deleteTag({ org: org.current, data: item.id, confirm: true });
                        }}
                        disabled={!confirm}
                    >
                        <Icon name="check" />
                        <Trans>validate</Trans>
                    </Button>
                }
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteTags;
