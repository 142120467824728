import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { componenttypeApi } from './componenttypeService';

const initialState = {
    componenttypes: [],
};

const componenttypeSlice = createSlice({
    name: "componenttype",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(componenttypeApi.endpoints.getComponenttypes.matchFulfilled, (state, action) => {
                state.componenttypes = action.payload;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export default componenttypeSlice.reducer;