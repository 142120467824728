import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Button, Modal, Icon, Grid, Divider, Segment, Label } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import i18n from "modules/i18n/i18nConfig";
import { toast_options } from "modules/notification/notificationMiddleware";
import { regexEmail } from "modules/common/utils";
import { useAddMailingMutation } from "../mainlingService";
import { addMailingLocal } from "../mailingSlice";

import { InputAdapter } from "modules/common/components/form";
import MessageDisplay from "modules/common/components/MessageDisplay";

const MailingAddModalForm = (props) => {
    const dispatch = useDispatch();
    const { mailings } = props;
    const current_mailing_form = _.get(props.form.getState(), "values.mailinglist", []);
    const [open, setOpen] = useState(false);
    const [initial, setInitial] = useState({
        name: "",
        mailinglistmember_set: [{ email: "" }]
    });
    const { org } = useSelector((state) => state);

    const [addMailing, { isSuccess, isError, isLoading, data, error }] = useAddMailingMutation();
    let err = t`cannot add new mailing list`;
    if (error?.data?.code) {
        err = error?.data?.code;
    } else if (error?.data?.name) {
        err = error?.data?.name;
    }

    useEffect(() => {
        (async () => {
            if (isSuccess) {
                await dispatch(
                    addMailingLocal({
                        ...data,
                        key: data.id,
                        text: data.name,
                        value: data.id
                    })
                );
                props.form.change("mailinglist", [...current_mailing_form, data.id]);
                toast.success(i18n._(t`new mailing added`), toast_options);
                onClose();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const onClose = async () => {
        await setOpen(false);
        await setInitial({ name: "", mailinglistmember_set: [{ email: "" }] });
    };

    const submitForm = async (data) => {
        await addMailing({ org: org.current, data, local: true });
    };

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = i18n._(t`name is required`);
        }
        const existing_name = _.find(mailings, { name: values.name });
        if (existing_name) {
            errors.name = i18n._(t`this name of mailinglist already exists`);
        }

        if (values.mailinglistmember_set.length > 0) {
            errors.mailinglistmember_set = _.map(values.mailinglistmember_set, (item, index) => {
                const mail = _.get(item, "email", null);
                const err = {};
                if (_.includes([undefined, null, ""], mail)) {
                    err.email = <Trans>email is required</Trans>;
                }
                if (!regexEmail.test(mail)) {
                    err.email = <Trans>invalid email format</Trans>;
                }
                const existing = _.findIndex(values.mailinglistmember_set, { email: item.email });
                if (existing >= 0 && existing !== index) {
                    err.email = <Trans>email already exists in this mailing list</Trans>;
                }
                return err;
            });
        }

        return errors;
    };

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={onClose}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button icon="add" type="button" basic />}
        >
            <Modal.Header>
                <Trans>add mailinglist</Trans>
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={initial}
                    validate={validate}
                    mutators={{
                        ...arrayMutators
                    }}
                    render={({
                        handleSubmit,
                        form: {
                            mutators: { push }
                        }, // injected from final-form-arrays above
                        pristine,
                        submitting,
                        values,
                        invalid
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid verticalAlign="top" centered>
                                    <Grid.Column width={15}>
                                        <Field
                                            name="name"
                                            placeholder={i18n._(t`enter name of mailinglist`)}
                                            label={i18n._(t`name of mailinglist`)}
                                            isRequired={true}
                                            component={InputAdapter}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <Segment>
                                            <Label attached="top">
                                                <Trans>members of mailing list</Trans>
                                            </Label>
                                            <Segment basic>
                                                <Divider />
                                                <Button type="button" onClick={() => push("mailinglistmember_set", { email: "" })}>
                                                    <Trans>add member</Trans>
                                                </Button>
                                                <Divider />
                                            </Segment>
                                            <FieldArray name="mailinglistmember_set">
                                                {({ fields }) =>
                                                    fields.map((name, index) => {
                                                        return (
                                                            <Segment basic key={name}>
                                                                <Grid verticalAlign="bottom">
                                                                    <Grid.Row>
                                                                        <Grid.Column width={15}>
                                                                            <Field
                                                                                name={`${name}.email`}
                                                                                placeholder={i18n._(t`enter email of member`)}
                                                                                type="text"
                                                                                label={i18n._(t`email`)}
                                                                                component={InputAdapter}
                                                                                isRequired={true}
                                                                                defaultValue={""}
                                                                            />
                                                                        </Grid.Column>
                                                                        {_.chain(values).get("mailinglistmember_set").size().value() >= 2 && (
                                                                            <Grid.Column width={1}>
                                                                                <Button
                                                                                    icon="trash"
                                                                                    color="red"
                                                                                    onClick={() => fields.remove(index)}
                                                                                />
                                                                            </Grid.Column>
                                                                        )}
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Segment>
                                                        );
                                                    })
                                                }
                                            </FieldArray>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <Divider />
                                    </Grid.Column>
                                    {isLoading && (
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                message={i18n._(t`send mailinglist to server`)}
                                                level="info"
                                                iconName="info"
                                                isLoading={false}
                                                attached={false}
                                            />
                                        </Grid.Column>
                                    )}
                                    {isError && (
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                message={i18n._(err)}
                                                level="error"
                                                iconName="warning circle"
                                                isLoading={false}
                                                attached={false}
                                            />
                                        </Grid.Column>
                                    )}
                                    <Grid.Column width={15} textAlign="right">
                                        <Button type="button" negative onClick={() => onClose()}>
                                            <Trans>cancel</Trans>
                                        </Button>
                                        <Button type="submit" positive icon labelPosition="right" disabled={submitting || pristine || invalid}>
                                            <Icon name="check" />
                                            <Trans>validate</Trans>
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default MailingAddModalForm;
