import React from "react";
import PropTypes from "prop-types";
import { Header, Popup, Icon, Segment } from "semantic-ui-react";

const HeaderWHelper = ({ text, helper }) => {
    return (
        <Header as="h3" style={{ display: 'inline-flex', alignItems: 'center' }} size="small">
            {text}&nbsp;&nbsp;
            <Popup
                basic
                position="bottom center"
                trigger={<Icon circular inverted color="grey" name="question" size="tiny" />}
            >
                <Segment basic style={{ width: "50vh" }}>
                    {helper}
                </Segment>
            </Popup>
        </Header>
    );
};

HeaderWHelper.propTypes = {
    text: PropTypes.object.isRequired,
    helper: PropTypes.object.isRequired
};

export default HeaderWHelper;
