import React from "react";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import BarGauge from "modules/common/components/graphic/BarGauge";

const GaugeLinear = (props) => {
    const { width, height, ...rest } = props;

    const barGaugeWidth = width * 0.9; //reduce width to add some margin-[left,right]
    const barGaugeHeight = _.clamp(height, 5, 30); //limit bar height between 5 && 30 px

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width,
                height,
                filter: _.isFinite(rest.value) ? "none" : "grayscale(100%)"
            }}
        >
            <BarGauge
                value={{
                    text: `${_.isFinite(rest.value) ? i18n.number(rest.value, { maximumFractionDigits: 2 }) : "-"} ${rest.unit}`,
                    numeric: rest.value
                }}
                minValue={rest.minValue}
                maxValue={rest.maxValue}
                thresholds={rest.thresholds}
                height={barGaugeHeight}
                width={barGaugeWidth}
                itemSpacing={2}
            />
        </div>
    );
};

export default GaugeLinear;
