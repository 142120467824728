import _ from "lodash";
import Palette from "modules/common/components/graphic/Colors";
import { dynamicValueUnit } from "modules/data/utils";
import { roundedDate } from "modules/time/utils";
import moment from "moment";
import tinycolor from "tinycolor2";
import { dashboardApi } from "./dashboardService";

export const dashboardMiddleware = (store) => (next) => (action) => {
    if (dashboardApi.endpoints.createInstantExport.matchFulfilled(action)) {
        const current_lng = store.getState().i18n.current;
        const type_data = action.payload.params.format;
        const url = action.payload.data;
        const link = document.createElement("a");
        const extension = type_data === 4 ? "xlsx" : "csv";
        link.href = url;
        link.setAttribute("download", `Export_${moment().locale(current_lng).format("LLL")}.${extension}`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }
    if (dashboardApi.endpoints.getDetailComparison.matchFulfilled(action)) {
        const { selected_equipments: selected_equipments_ids, comparison_params } = store.getState().dash;

        const max_all_series = _.chain(action.payload)
            .reduce((res, serie) => {
                const max_measure = _.chain(serie).get("data").maxBy("[1]").last().value();
                res.push(max_measure);
                return res;
            }, [])
            .max()
            .value();
        if (_.size(selected_equipments_ids) === 1) {
            //One feed|Multiple periods
            const data = _.map(action.payload, (serie, index) => {
                const color = tinycolor(Palette.circles[index % Palette.circles.length])
                    .setAlpha(0.6)
                    .toString();
                const unit = _.get(serie, "measure.display_unit.intensive", null) || _.get(serie, "measure.display_unit.symbol", null);
                const { factor, new_unit } = dynamicValueUnit(max_all_series, unit);
                const title = `${_.get(serie, "measure.name", "-")} ${index === 0 ? "(ref)" : "(comp)"}`;
                let counter = 0;
                let process_data = [];

                let time_interval = 10; // minutes
                const diff_time =
                    index === 0
                        ? moment(comparison_params?.ref_end).diff(moment(comparison_params?.ref_start), "hours")
                        : moment(comparison_params?.comp_end).diff(moment(comparison_params?.comp_start), "hours");
                if (diff_time > 30 * 24 + 6) {
                    time_interval = 10 * 6 * 3; //3 hours
                } else if (diff_time > 7 * 24 + 6) {
                    time_interval = 10 * 3; //30 minutes
                }

                //Loop on each period (reference && comparison) to get 10minute points. Sync 2 series to same length of data
                const startRounded =
                    index === 0
                        ? roundedDate(moment(comparison_params?.ref_start), time_interval)
                        : roundedDate(moment(comparison_params?.comp_start), time_interval);
                const endRounded =
                    index === 0
                        ? roundedDate(moment(comparison_params?.ref_end), time_interval)
                        : roundedDate(moment(comparison_params?.comp_end), time_interval);
                let m = startRounded;
                for (startRounded; m.isBefore(endRounded); m.add(time_interval, "minutes")) {
                    const record = _.chain(serie)
                        .get("data")
                        .find((record) => record[0] === m.unix())
                        .value();
                    const y_val = !_.isUndefined(record) && _.isFinite(record[1]) ? record[1] * factor : null;
                    process_data.push({
                        title,
                        x: counter,
                        t: m.unix() * 1000,
                        y: y_val,
                        y_real: y_val,
                        color: tinycolor(color).setAlpha(1).toString(),
                        unit: new_unit
                    });
                    counter++;
                }
                return {
                    name: _.get(serie, "measure.name", "-"),
                    title,
                    disabled: false,
                    strokeWidth: 20,
                    data: process_data || [],
                    color,
                    unit: new_unit
                };
            });

            action.payload = data;
        } else if (_.size(selected_equipments_ids) > 1) {
            //Multiple feed|One period
            const data = _.reduce(
                action.payload,
                (res, serie, index) => {
                    if (serie) {
                        const color = tinycolor(Palette.circles[index % Palette.circles.length])
                            .setAlpha(0.6)
                            .toString();
                        const unit = _.get(serie, "measure.display_unit.intensive", null) || _.get(serie, "measure.display_unit.symbol", null);
                        const { factor, new_unit } = dynamicValueUnit(max_all_series, unit);
                        const title = `${_.get(serie, "measure.name", "-")} (${new_unit})`;
                        res.push({
                            name: _.get(serie, "measure.name", "-"),
                            title,
                            disabled: false,
                            strokeWidth: 20,
                            data:
                                _.chain(serie)
                                    .get("data")
                                    .map((value) => {
                                        const y_val = _.isFinite(value[1]) ? value[1] * factor : null;
                                        return {
                                            title,
                                            x: value[0] * 1000,
                                            t: value[0] * 1000,
                                            y: y_val,
                                            y_real: y_val,
                                            color,
                                            unit: new_unit
                                        };
                                    })
                                    .value() || [],
                            color,
                            unit: new_unit
                        });
                    }
                    return res;
                },
                []
            );
            action.payload = data;
        } else {
            action.payload = [];
        }
    }
    if (dashboardApi.endpoints.getConsoComparison.matchFulfilled(action)) {
        const { selected_equipments: selected_equipments_ids } = store.getState().dash;
        const max_all_series = _.chain(action.payload)
            .reduce((res, serie) => {
                const max_measure = _.chain(serie).get("data").maxBy("[1]").last().value();
                res.push(max_measure);
                return res;
            }, [])
            .max()
            .value();

        if (_.size(selected_equipments_ids) === 1) {
            const data = _.reduce(
                action.payload,
                (res, serie, index) => {
                    const color = tinycolor(Palette.circles[index % Palette.circles.length])
                        .setAlpha(0.6)
                        .toString();
                    const unit = _.get(serie, "measure.display_unit.symbol", null);
                    const { factor, new_unit } = dynamicValueUnit(max_all_series, unit);
                    const title = `${_.get(serie, "measure.name", "-")} ${index === 0 ? "(ref)" : "(comp)"}`;
                    res.push({
                        name: _.get(serie, "measure.name", "-"),
                        title,
                        disabled: false,
                        strokeWidth: 20,
                        data:
                            _.chain(serie)
                                .get("data")
                                .map((value, index) => {
                                    const y_val = _.isFinite(value[1]) ? value[1] * factor : null;
                                    return {
                                        title,
                                        x: index,
                                        t: moment(value[0]).unix() * 1000,
                                        y: y_val,
                                        y_real: y_val,
                                        color,
                                        unit: new_unit
                                    };
                                })
                                .value() || [],
                        color,
                        unit: new_unit
                    });
                    return res;
                },
                []
            );

            action.payload = data;
        } else if (_.size(selected_equipments_ids) > 1) {
            const data = _.reduce(
                action.payload,
                (res, serie, index) => {
                    if (serie) {
                        const color = tinycolor(Palette.circles[index % Palette.circles.length])
                            .setAlpha(0.6)
                            .toString();
                        const unit = _.get(serie, "measure.display_unit.symbol", null);
                        const { factor, new_unit } = dynamicValueUnit(max_all_series, unit);
                        const title = `${_.get(serie, "measure.name", "-")} (${new_unit})`;
                        res.push({
                            name: _.get(serie, "measure.name", "-"),
                            title,
                            disabled: false,
                            strokeWidth: 20,
                            data:
                                _.chain(serie)
                                    .get("data")
                                    .map((value, index) => {
                                        const y_val = _.isFinite(value[1]) ? value[1] * factor : null;
                                        return {
                                            title,
                                            x: moment(value[0]).unix() * 1000,
                                            t: moment(value[0]).unix() * 1000,
                                            y: y_val,
                                            y_real: y_val,
                                            color,
                                            unit: new_unit
                                        };
                                    })
                                    .value() || [],
                            color,
                            unit: new_unit
                        });
                    }
                    return res;
                },
                []
            );
            action.payload = data;
        } else {
            action.payload = [];
        }
    }
    if (dashboardApi.endpoints.getSummaryComparison.matchFulfilled(action)) {
        const { selected_equipments: selected_equipments_ids } = store.getState().dash;
        const max_all_series = _.chain(action.payload)
            .reduce((res, serie) => {
                const max_measure = _.chain(serie).get("data").maxBy("[3]").last().value();
                res.push(max_measure);
                return res;
            }, [])
            .max()
            .value();
        if (_.size(selected_equipments_ids) === 1) {
            const data = _.reduce(
                action.payload,
                (res, serie, index) => {
                    const color = tinycolor(Palette.circles[index % Palette.circles.length])
                        .setAlpha(0.6)
                        .toString();
                    const unit = _.get(serie, "measure.display_unit.intensive", null) || _.get(serie, "measure.display_unit.symbol", null);
                    const { factor, new_unit } = dynamicValueUnit(max_all_series, unit);
                    const title = `${_.get(serie, "measure.name", "-")} ${index === 0 ? "(ref)" : "(comp)"}`;

                    const process_min_max = _.chain(serie)
                        .get("data")
                        .reduce(
                            (res, record, idx) => {
                                const y_val_min = _.isFinite(record[1]) ? record[1] * factor : null;
                                res.min.push({
                                    title,
                                    x: idx,
                                    t: moment(record[0]).unix() * 1000,
                                    y: y_val_min,
                                    y_real: y_val_min,
                                    color,
                                    type: "min",
                                    unit: new_unit
                                });
                                const y_val_max = _.isFinite(record[3]) ? record[3] * factor : null;
                                res.max.push({
                                    title,
                                    x: idx,
                                    t: moment(record[0]).unix() * 1000,
                                    y: y_val_max,
                                    y_real: y_val_max,
                                    color,
                                    type: "max",
                                    unit: new_unit
                                });
                                const y_val_avg = _.isFinite(record[2]) ? record[2] * factor : null;
                                res.avg.push({
                                    title,
                                    x: idx,
                                    t: moment(record[0]).unix() * 1000,
                                    y: y_val_avg,
                                    y_real: y_val_avg,
                                    color,
                                    type: "avg",
                                    unit: new_unit
                                });
                                return res;
                            },
                            { min: [], max: [], avg: [] }
                        )
                        .value();

                    res.push({
                        name: _.get(serie, "measure.name", "-"),
                        title,
                        disabled: false,
                        strokeWidth: 20,
                        data: _.get(process_min_max, "min", []),
                        color,
                        type: "min",
                        unit: new_unit
                    });
                    res.push({
                        name: _.get(serie, "measure.name", "-"),
                        title,
                        disabled: false,
                        strokeWidth: 20,
                        data: _.get(process_min_max, "max", []),
                        color,
                        type: "max",
                        unit: new_unit
                    });
                    res.push({
                        name: _.get(serie, "measure.name", "-"),
                        title,
                        disabled: false,
                        strokeWidth: 20,
                        data: _.get(process_min_max, "avg", []),
                        color,
                        type: "avg",
                        unit: new_unit
                    });
                    return res;
                },
                []
            );

            action.payload = data;
        } else if (_.size(selected_equipments_ids) > 1) {
            const data = _.reduce(
                action.payload,
                (res, serie, index) => {
                    if (serie) {
                        const color = tinycolor(Palette.circles[index % Palette.circles.length])
                            .setAlpha(0.6)
                            .toString();
                        const unit = _.get(serie, "measure.display_unit.intensive", null) || _.get(serie, "measure.display_unit.symbol", null);
                        const { factor, new_unit } = dynamicValueUnit(max_all_series, unit);
                        const title = `${_.get(serie, "measure.name", "-")} (${new_unit})`;

                        const process_min_max = _.chain(serie)
                            .get("data")
                            .reduce(
                                (res, record, idx) => {
                                    const y_val_min = _.isFinite(record[1]) ? record[1] * factor : null;
                                    res.min.push({
                                        title,
                                        x: moment(record[0]).unix() * 1000,
                                        t: moment(record[0]).unix() * 1000,
                                        y: y_val_min,
                                        y_real: y_val_min,
                                        color,
                                        type: "min",
                                        unit: new_unit
                                    });
                                    const y_val_max = _.isFinite(record[3]) ? record[3] * factor : null;
                                    res.max.push({
                                        title,
                                        x: moment(record[0]).unix() * 1000,
                                        t: moment(record[0]).unix() * 1000,
                                        y: y_val_max,
                                        y_real: y_val_max,
                                        color,
                                        type: "max",
                                        unit: new_unit
                                    });
                                    const y_val_avg = _.isFinite(record[2]) ? record[2] * factor : null;
                                    res.avg.push({
                                        title,
                                        x: moment(record[0]).unix() * 1000,
                                        t: moment(record[0]).unix() * 1000,
                                        y: y_val_avg,
                                        y_real: y_val_avg,
                                        color,
                                        type: "avg",
                                        unit: new_unit
                                    });
                                    return res;
                                },
                                { min: [], max: [], avg: [] }
                            )
                            .value();
                        res.push({
                            name: _.get(serie, "measure.name", "-"),
                            title,
                            disabled: false,
                            strokeWidth: 20,
                            data: _.get(process_min_max, "min", []),
                            color,
                            type: "min",
                            unit: new_unit
                        });
                        res.push({
                            name: _.get(serie, "measure.name", "-"),
                            title,
                            disabled: false,
                            strokeWidth: 20,
                            data: _.get(process_min_max, "max", []),
                            color,
                            type: "max",
                            unit: new_unit
                        });
                        res.push({
                            name: _.get(serie, "measure.name", "-"),
                            title,
                            disabled: false,
                            strokeWidth: 20,
                            data: _.get(process_min_max, "avg", []),
                            color,
                            type: "avg",
                            unit: new_unit
                        });
                    }
                    return res;
                },
                []
            );
            action.payload = data;
        } else {
            action.payload = [];
        }
    }
    return next(action);
};
