import React, { useState, useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Form, Field } from "react-final-form";
import { Button, Modal, Grid, Icon, Table, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { identityNull } from "modules/common/utils/form";
import { categoriesOptions } from "./utils";

import { InputAdapter } from "modules/common/components/form";

const EditExceptionGlobal = (props) => {
    const { idx, item, update, categorie } = props;
    const [open, setOpen] = useState(false);

    const { duration, level_critical, level_warning } = _.get(item, `override.${categorie}`);
    const categorieText = _.chain(categoriesOptions)
        .find({ value: categorie })
        .get("text", t`health score categorie error`)
        .value();

    const initial = useMemo(() => {
        return {
            level_warning,
            level_critical,
            duration: duration / 60 // Convert "minutes" => "hours" for UI
        };
    }, [duration, level_critical, level_warning]);

    const onSubmitForm = async (data) => {
        const updateItem = {
            ...item,
            override: {
                ...item.override,
                [categorie]: _.chain(data)
                    .mapValues((item, key) => {
                        if (_.includes(keyToReparseInPack, key)) {
                            if (key === "duration") {
                                return reparseNumber(item) * 60;
                            }
                            return reparseNumber(item);
                        }
                        return item;
                    })
                    .value()
            }
        };
        await update("pack_exception", idx, updateItem);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            trigger={
                <Button
                    type="button"
                    icon="edit"
                    onClick={(event) => {
                        setOpen(true);
                    }}
                />
            }
        >
            <Form
                onSubmit={onSubmitForm}
                initialValues={initial}
                render={({ handleSubmit, form, submitting, pristine, invalid }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>Edit global exception</Trans>
                            </Modal.Header>
                            <Modal.Content scrolling={false}>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Table celled compact attached="top">
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan={2}>
                                                    <Trans>Health score category</Trans>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell colSpan={2}>
                                                    <Label>{i18n._(categorieText)}</Label>
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell colSpan={2}>
                                                    <Grid centered stackable columns={3}>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Field
                                                                    name="level_warning"
                                                                    label={i18n._(t`First threshold`)}
                                                                    component={InputAdapter}
                                                                    parse={identityNull}
                                                                    inputMode="decimal"
                                                                    placeholder={i18n._(t`Set value between 1 to 10`)}
                                                                    unitposition="left"
                                                                    unitcolor="yellow"
                                                                    unit={<Icon name="warning sign" />}
                                                                    isRequired={true}
                                                                    helperText={
                                                                        <Trans>
                                                                            <p>
                                                                                A first alert will be sent if the health score value exceeds this
                                                                                threshold
                                                                            </p>
                                                                        </Trans>
                                                                    }
                                                                    validate={(value, allValues) => {
                                                                        const warningValidate = validateNumber(value, i18n, false, false);
                                                                        if (warningValidate !== undefined) {
                                                                            return warningValidate;
                                                                        }
                                                                        const num = reparseNumber(value);
                                                                        if (num < 1 || num > 10) {
                                                                            return <Trans>First threshold must be between 1 and 10 maximum</Trans>;
                                                                        }
                                                                        if (reparseNumber(value) > reparseNumber(allValues?.level_critical)) {
                                                                            return (
                                                                                <Trans>First threshold can't be greater than second threshold</Trans>
                                                                            );
                                                                        }
                                                                        return undefined;
                                                                    }}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Field
                                                                    name="level_critical"
                                                                    label={i18n._(t`Second threshold`)}
                                                                    component={InputAdapter}
                                                                    parse={identityNull}
                                                                    inputMode="decimal"
                                                                    placeholder={i18n._(t`Set value between 1 to 10`)}
                                                                    unitposition="left"
                                                                    unitcolor="red"
                                                                    unit={<Icon name="fire" />}
                                                                    isRequired={true}
                                                                    helperText={
                                                                        <Trans>
                                                                            <p>
                                                                                A second alert will be sent if the health score value exceeds this
                                                                                threshold
                                                                            </p>
                                                                        </Trans>
                                                                    }
                                                                    validate={(value, allValues) => {
                                                                        const criticalValidate = validateNumber(value, i18n, false, false);
                                                                        if (criticalValidate !== undefined) {
                                                                            return criticalValidate;
                                                                        }
                                                                        const num = reparseNumber(value);
                                                                        if (num < 1 || num > 10) {
                                                                            return <Trans>Second threshold must be between 1 and 10 maximum</Trans>;
                                                                        }
                                                                        if (reparseNumber(value) < reparseNumber(allValues?.level_warning)) {
                                                                            return (
                                                                                <Trans>Second threshold can't be lower than first threshold</Trans>
                                                                            );
                                                                        }
                                                                        return undefined;
                                                                    }}
                                                                />
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Field
                                                                    name="duration"
                                                                    label={i18n._(t`Duration`)}
                                                                    component={InputAdapter}
                                                                    parse={identityNull}
                                                                    inputMode="decimal"
                                                                    placeholder={i18n._(t`Set value between 1 to 48`)}
                                                                    unitposition="right"
                                                                    unit={"h"}
                                                                    isRequired={true}
                                                                    helperText={
                                                                        <Trans>
                                                                            <p>
                                                                                The alert will be sent only if the health score value remains beyond
                                                                                the threshold for this duration
                                                                            </p>
                                                                        </Trans>
                                                                    }
                                                                    validate={(value) => {
                                                                        const durationValidate = validateNumber(value, i18n, false, false);
                                                                        if (durationValidate !== undefined) {
                                                                            return durationValidate;
                                                                        }
                                                                        const num = reparseNumber(value);
                                                                        if (num < 1 || num > 48) {
                                                                            return (
                                                                                <Trans>Duration must be between 1 hours and 48 hours maximum</Trans>
                                                                            );
                                                                        }
                                                                        return undefined;
                                                                    }}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

export default EditExceptionGlobal;
