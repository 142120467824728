import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

import cookie from "react-cookies";

export const importApi = defaultApi.injectEndpoints({
    reducerPath: "apiImport",
    endpoints: (build) => ({
        getImports: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `importsources?org=${current_org}&${refreshTimestampUrlParam()}`;
            },

            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Imports", id: arg.org.id }];
                return [];
            }
        }),
        getImport: build.query({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return `importsources/${id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "Import", id: arg.id }];
            }
        }),
        createImport: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importsources?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Imports", id: arg.org.id },
                        { type: "Import", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        updateImport: build.mutation({
            query: ({ org, id, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importsources/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Imports", id: arg.org.id },
                        { type: "Import", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        deleteImport: build.mutation({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importsources/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "Imports", id: arg.org.id },
                        { type: "Import", id: arg.id }
                    ];
                }
                return [];
            }
        }),
        getTestImport: build.mutation({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `importsources/${id}/test`,
                    method: "GET",
                    params: {
                        org: current_org,
                        _: cookie.load("refresh_timestamp")
                    }
                };
            }
        })
    })
});

export const {
    useGetImportsQuery,
    useGetImportQuery,
    useCreateImportMutation,
    useUpdateImportMutation,
    useDeleteImportMutation,
    useGetTestImportMutation
} = importApi;
