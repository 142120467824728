import React, { useState } from "react";
import _ from "lodash";
import { Button, Modal, Icon, Label, Image, Segment, Form } from "semantic-ui-react";
import { Trans } from "@lingui/macro";

import { updateEquipmentImage } from "modules/equipment/equipmentSlice";
import { useDispatch } from "react-redux";

const checkMimeType = (event) => {
    //getting file object
    let file = event.target.files[0];
    //define message container
    if (!file) {
        return "need an image";
    }
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif"];

    const err = _.every(types, (item) => {
        return file.type !== item;
    });

    if (err) {
        event.target.value = null; // discard selected file
        return "only .png, .jpeg or .gif are valid";
    }
    return "";
};

const EquipmentImageForm = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [err, setErr] = useState("");

    const { equipment: { image } } = props;

    const handleChange = async (e) => {
        const err = checkMimeType(e);
        if (err === "") {
            if (_.size(e.target.files) > 0) {
                const new_image = e.target.files[0];
                await setErr("");
                await setNewImage(new_image);
            } else {
                await setErr("");
                await setNewImage(null);
            }
        } else {
            await setErr(err);
            await setNewImage(null);
        }
    };

    const submitImage = () => {
        const data = new FormData();
        data.append("image", newImage);
        dispatch(updateEquipmentImage({ equipment: props.equipment, data }));
    };

    return (
        <Modal
            centered={false}
            trigger={
                <Label style={{ cursor: "pointer" }}>
                    <Icon name="edit" />
                    <Trans>update your image</Trans>
                </Label>
            }
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>
                <Trans>update image of machine</Trans>
            </Modal.Header>
            <Modal.Content>
                <Segment>
                    <Label attached="top left" color="blue">
                        <Trans>current image</Trans>
                    </Label>
                    <Image
                        src={image ? image : "/media/machine_pics/default_img.png"}
                        size="small"
                        centered
                    />
                </Segment>
                <Segment>
                    <Label attached="top left" color="blue">
                        <Trans>new image</Trans>
                    </Label>
                    <Form>
                        <Form.Input
                            type="file"
                            name="image"
                            placeholder={<Trans>select an image</Trans>}
                            onChange={handleChange}
                            error={err ? { content: err, pointing: "below" } : false}
                        />
                    </Form>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={async (e, data) => {
                        await setOpen(false);
                        await setNewImage(null);
                    }}
                >
                    <Trans>cancel</Trans>
                </Button>
                {
                    <Button
                        labelPosition="right"
                        disabled={newImage === null}
                        icon
                        positive
                        onClick={async (e) => {
                            await submitImage();
                            await setOpen(false);
                        }}
                    >
                        <Trans>update</Trans>
                        <Icon name="edit" inverted />
                    </Button>
                }
            </Modal.Actions>
        </Modal>
    );

};

export default EquipmentImageForm;
