import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Grid } from "semantic-ui-react";
import { t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetLastActivityQuery } from "../dashboardService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import DashboardRow from "./DashboardRow";

const pollingInterval = 1000 * 60 * 10;

const DashboardDisplayEquipments = (props) => {
    const { displayedEquipments } = props;
    const org = useSelector((state) => state.org);
    const [isScrolling, setIsScrolling] = useState(false);

    const equipments_list = useMemo(() => {
        return _.map(displayedEquipments, (item) => item.id);
    }, [displayedEquipments]);

    /* LAST ACTIVITY POLLING */
    const lastActivity = useGetLastActivityQuery(
        { org: org.current, data: _.join(equipments_list, ",") },
        { skip: !org.current || _.size(equipments_list) === 0 }
    );
    useEffect(() => {
        const lastActivityTimer = setInterval(() => {
            lastActivity.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(lastActivityTimer);
        };
    }, [equipments_list, lastActivity, org]);

    useEffect(() => {
        let scrollTimeout;
        const handleScroll = () => {
            // User start to scroll
            if (!isScrolling) {
                setIsScrolling(true);
            }
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                //Timeout to check stop scrolling
                setIsScrolling(false);
            }, 1000);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isScrolling]);

    return (
        <Grid.Row>
            <Grid.Column width={16}>
                {_.size(displayedEquipments) === 0 && (
                    <MessageDisplay message={i18n._(t`no equipments`)} level="warning" iconName="warning circle" isLoading={false} attached={false} />
                )}
                {_.map(displayedEquipments, (equipment, idx) => {
                    return <DashboardRow key={idx} equipment={equipment} lastActivity={lastActivity} isScrolling={isScrolling} />;
                })}
            </Grid.Column>
        </Grid.Row>
    );
};

export default React.memo(DashboardDisplayEquipments);
