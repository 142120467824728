import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { I18nProvider } from "@lingui/react";

import i18n_core from "modules/i18n/i18nConfig";

const i18nLoader = ({ children, i18n_store, ...rest }) => {
    const { current } = i18n_store;
    i18n_core.activate(current);

    return <I18nProvider i18n={i18n_core}>{children}</I18nProvider>;
};

i18nLoader.propTypes = {
    children: PropTypes.any.isRequired,
    i18n_store: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    i18n_store: state.i18n
});

export default connect(mapStateToProps)(i18nLoader);
