import { t } from "@lingui/macro";
import moment from "moment";
import _ from "lodash";

export const format_period = (period_start, period_end, i18n, current_lng) => {
    let render_period = "";
    const start = moment(period_start, "YYYY-MM-DD", true);
    const end = moment(period_end, "YYYY-MM-DD", true);
    if (start.isValid()) {
        render_period += `${i18n._(t`from`)} ${start.locale(current_lng).format("L")}`;
    } else {
        render_period += `${i18n._(t`from`)} -`;
    }
    if (end.isValid()) {
        render_period += ` ${_.lowerCase(i18n._(t`to`))} ${end.locale(current_lng).format("L")}`;
    } else {
        render_period += ` ${_.lowerCase(i18n._(t`to`))} -`;
    }
    return render_period;
};