import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Grid, Segment, Header, Container, Button, Divider, Table, Modal, Icon, List } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";

import i18n from "modules/i18n/i18nConfig";
import { useGetComponentsQuery } from "modules/machine/componentService";
import {
    useDeclareMaintenanceActionsMutation,
    useGetAvailableMaintenanceActionsQuery,
    useGetMachinesQuery,
    useGetMaintenanceActionTypesQuery
} from "modules/machine/machineService";
import { useGetComponenttypesQuery } from "modules/machine/componenttypeService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { setCurrentEvent, setEventForm } from "modules/machine/machineSlice";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { DropDownAdapter, ToggleAdapter } from "modules/common/components/form";
import { Media } from "App";
import history_app from "history_app";

export const Declaration = (props) => {
    const dispatch = useDispatch();
    const { id_machine } = useParams();
    const [open, setOpen] = useState(false);
    const { org, machine, notification } = useSelector((state) => state);

    const components = useGetComponentsQuery({ org: org.current }, { skip: !org.current });
    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current });
    const componenttypes = useGetComponenttypesQuery({ org: org.current }, { skip: !org.current });
    const m_action_types = useGetMaintenanceActionTypesQuery({ org: org.current }, { skip: !org.current });
    const declarations = useGetAvailableMaintenanceActionsQuery({ org: org.current, id_machine }, { skip: !org.current });

    const [declareMaintenance, declare] = useDeclareMaintenanceActionsMutation();
    //Retrieve event.
    //Case 1: From current if kinematic changed (event has pk)
    //Case 2: Directly from event form
    const event_form =
        machine?.event?.current && machine?.event?.current?.machine === parseInt(id_machine) ? machine.event.current : machine.event.form;

    const error_list = [components.isError, m_action_types.isError, declarations.isError, componenttypes.isError, machines.isError];
    const loading_list = [components.isLoading, m_action_types.isLoading, declarations.isLoading, componenttypes.isLoading, machines.isLoading];
    const status_list = [components.isSuccess, m_action_types.isSuccess, declarations.isSuccess, componenttypes.isSuccess, machines.isSuccess];

    const global_load = _.some([...loading_list]);
    const global_err = _.some([...error_list]);
    const global_success = _.every([...status_list]);

    useEffect(() => {
        if (declare.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (declare.isSuccess) {
            const is_confirmation = _.get(declare, "originalArgs.data.is_confirmation", false);
            if (is_confirmation) {
                toast.success(i18n._(t`successful declare maintenance actions`), toast_options);
                //Reset form after success submit on is_confirmation=True
                dispatch(setEventForm(null));
                dispatch(setCurrentEvent(null));
                history_app.push(`/machines/${id_machine}/events`);
            } else {
                setOpen(true);
            }
        }
        if (declare.isError) {
            const err_resp = _.get(declare, "error.data[0]", null);
            if (err_resp) {
                toast.error(i18n._(err_resp), toast_options_err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declare]);

    const submitForm = async (formData) => {
        try {
            //If needed, save formData in store if we want restore form with old values
            const data = {
                machine_event: event_form,
                comp_maintenances: _.reduce(
                    formData.comp_maintenances,
                    (res, comp) => {
                        _.each(comp.selected_action_types, (maintenance_type) => {
                            res.push({
                                component: comp.component.id,
                                maintenance_type
                            });
                        });
                        return res;
                    },
                    []
                ),
                is_confirmation: formData.is_confirmation
            };

            if (!_.isNull(event_form)) {
                await declareMaintenance({ org: org.current, data });
            }
        } catch (error) {}
    };

    const initialValues = useMemo(() => {
        return {
            comp_maintenances: _.chain(declarations)
                .get("data", [])
                .reduce((res, declaration) => {
                    const my_component = _.find(components.data, { id: declaration.component });
                    const comp_type = _.find(componenttypes.data, { id: _.get(my_component, "comp_type") });
                    const options = _.filter(m_action_types.data, (ma_type) => {
                        return _.includes(declaration.maintenance_types, ma_type.id);
                    });
                    if (my_component && comp_type && _.size(options) > 0) {
                        res.push({
                            component: { ...my_component, comp_type },
                            options,
                            selected_action_types: []
                        });
                    }
                    return res;
                }, [])
                .value(),
            has_no_actions: false,
            is_confirmation: false
        };
    }, [components.data, componenttypes.data, declarations, m_action_types.data]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>maintenance declaration</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {global_load && <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />}
                        {!global_load && global_err && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {global_success && (
                            <Container>
                                {_.isNull(event_form) && (
                                    <MessageDisplay
                                        message={i18n._(t`machine event is required for declaration`)}
                                        level="warning"
                                        iconName="warning circle"
                                        isLoading={false}
                                    />
                                )}
                                <Form
                                    keepDirtyOnReinitialize
                                    onSubmit={submitForm}
                                    mutators={{ ...arrayMutators }}
                                    initialValues={initialValues}
                                    render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                                        const allEmptySelected = _.every(
                                            _.map(values.comp_maintenances, "selected_action_types"),
                                            (elem) => _.size(elem) === 0
                                        );

                                        return (
                                            <form onSubmit={handleSubmit} className="ui form">
                                                <Table celled attached>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell />
                                                            <Table.HeaderCell textAlign="center">
                                                                <Trans>General maintenance</Trans>
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {/* Filter motor component and display it as global maintenance actions */}
                                                        <FieldArray name="comp_maintenances">
                                                            {(sm_field) => {
                                                                return sm_field.fields.map((s_name, s_index) => {
                                                                    const current_sm = sm_field.fields.value[s_index];
                                                                    if (current_sm?.component?.comp_type?.id !== 2) {
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <Table.Row key={s_name}>
                                                                            <Table.Cell
                                                                                width={2}
                                                                                style={{ borderBottom: "1px solid rgba(34, 36, 38, 0.1)" }}
                                                                            />
                                                                            <Table.Cell
                                                                                width={14}
                                                                                style={{ borderBottom: "1px solid rgba(34, 36, 38, 0.1)" }}
                                                                            >
                                                                                <Field
                                                                                    name={`${s_name}.selected_action_types`}
                                                                                    placeholder={i18n._(t`selected_action_types`)}
                                                                                    options={current_sm.options}
                                                                                    component={DropDownAdapter}
                                                                                    multipleselect={1}
                                                                                    validate={(value) => {
                                                                                        return undefined;
                                                                                    }}
                                                                                    renderLabel={(label) => ({
                                                                                        color: "blue",
                                                                                        content: i18n._(label.text)
                                                                                    })}
                                                                                    disabled={values.has_no_actions}
                                                                                />
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    );
                                                                });
                                                            }}
                                                        </FieldArray>
                                                    </Table.Body>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell />
                                                            <Table.HeaderCell textAlign="center">
                                                                <Trans>Maintenance by component</Trans>
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {/* Remove motor component and display others */}
                                                        <FieldArray name="comp_maintenances">
                                                            {(sm_field) => {
                                                                return sm_field.fields.map((s_name, s_index) => {
                                                                    const current_sm = sm_field.fields.value[s_index];
                                                                    if (current_sm?.component?.comp_type?.id === 2) {
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <Table.Row key={s_name}>
                                                                            <Table.Cell width={2}>
                                                                                {`${_.get(current_sm, "component.name", "-")} (${i18n._(
                                                                                    _.get(current_sm, "component.comp_type.text", "-")
                                                                                )})`}
                                                                            </Table.Cell>
                                                                            <Table.Cell width={14}>
                                                                                <Field
                                                                                    name={`${s_name}.selected_action_types`}
                                                                                    placeholder={i18n._(t`selected_action_types`)}
                                                                                    options={current_sm.options}
                                                                                    component={DropDownAdapter}
                                                                                    multipleselect={1}
                                                                                    validate={(value) => {
                                                                                        return undefined;
                                                                                    }}
                                                                                    renderLabel={(label) => ({
                                                                                        color: "blue",
                                                                                        content: i18n._(label.text)
                                                                                    })}
                                                                                    disabled={values.has_no_actions}
                                                                                />
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    );
                                                                });
                                                            }}
                                                        </FieldArray>
                                                    </Table.Body>
                                                    <Table.Footer>
                                                        <Table.Row>
                                                            <Table.HeaderCell colSpan="2">
                                                                <Field
                                                                    name="has_no_actions"
                                                                    label={i18n._(t`has no actions`)}
                                                                    labelposition="right"
                                                                    component={ToggleAdapter}
                                                                    defaultValue={false}
                                                                    customAction={(data) => {
                                                                        if (data.checked === true) {
                                                                            form.change("comp_maintenances", initialValues.comp_maintenances);
                                                                        }
                                                                    }}
                                                                />
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Footer>
                                                </Table>
                                                <Divider />
                                                {notification.srv_status.db_status === "rw" && (
                                                    <Button
                                                        type="submit"
                                                        content={i18n._(t`validate`)}
                                                        disabled={
                                                            _.isNull(event_form) ||
                                                            submitting ||
                                                            invalid ||
                                                            (values.has_no_actions === false && allEmptySelected)
                                                        }
                                                    />
                                                )}
                                                <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} centered={false}>
                                                    <Modal.Header>
                                                        <Trans>Confirmation</Trans>
                                                    </Modal.Header>
                                                    <Modal.Content>
                                                        {_.get(declare, "data.cluster_reset") === true && (
                                                            <p>
                                                                <Trans>All Health scores and machine clusters will be reset</Trans>
                                                            </p>
                                                        )}
                                                        {_.get(declare, "data.cluster_reset") === false && (
                                                            <>
                                                                <p>
                                                                    <Trans>Consequences</Trans>
                                                                </p>
                                                                {_.chain(declare).get("data.hs_modified", []).size().value() === 0 && (
                                                                    <p>
                                                                        <Trans>No health scores will be reset</Trans>
                                                                    </p>
                                                                )}
                                                                {_.chain(declare).get("data.hs_modified", []).size().value() > 0 && (
                                                                    <Table celled definition>
                                                                        <Table.Header>
                                                                            <Table.Row>
                                                                                <Table.HeaderCell />
                                                                                <Table.HeaderCell>
                                                                                    <Trans>Elements</Trans>
                                                                                </Table.HeaderCell>
                                                                            </Table.Row>
                                                                        </Table.Header>
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell collapsing>
                                                                                    <Trans>hs_modified</Trans>
                                                                                </Table.Cell>
                                                                                <Table.Cell>
                                                                                    <List>
                                                                                        {_.chain(declare)
                                                                                            .get("data.hs_modified", [])
                                                                                            .map((hs, idx) => {
                                                                                                return (
                                                                                                    <List.Item key={idx}>{`${i18n._(
                                                                                                        _.get(hs, "component__comp_type__name", "-")
                                                                                                    )} / ${i18n._(
                                                                                                        _.get(hs, "component__name", "-")
                                                                                                    )} / ${i18n._(
                                                                                                        _.get(hs, "healthscore_type__name", "-")
                                                                                                    )}`}</List.Item>
                                                                                                );
                                                                                            })
                                                                                            .value()}
                                                                                    </List>
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                )}
                                                            </>
                                                        )}
                                                        <p>
                                                            <Trans>If you agree with the changes, please confirm</Trans>
                                                        </p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Media greaterThanOrEqual="tablet">
                                                            {(mediaClassNames, renderChildren) =>
                                                                renderChildren && (
                                                                    <>
                                                                        <Button
                                                                            negative
                                                                            icon
                                                                            labelPosition="left"
                                                                            onClick={() => {
                                                                                setOpen(false);
                                                                                form.change("is_confirmation", false);
                                                                            }}
                                                                        >
                                                                            <Icon name="arrow left" />
                                                                            <Trans>cancel</Trans>
                                                                        </Button>
                                                                        <Button
                                                                            positive
                                                                            icon
                                                                            labelPosition="right"
                                                                            onClick={async () => {
                                                                                await setOpen(false);
                                                                                await form.change("is_confirmation", true);
                                                                                await form.submit();
                                                                            }}
                                                                        >
                                                                            <Icon name="send" />
                                                                            <Trans>Confirm</Trans>
                                                                        </Button>
                                                                    </>
                                                                )
                                                            }
                                                        </Media>
                                                        <Media lessThan="tablet">
                                                            {(mediaClassNames, renderChildren) =>
                                                                renderChildren && (
                                                                    <>
                                                                        <Button
                                                                            negative
                                                                            icon
                                                                            onClick={() => {
                                                                                setOpen(false);
                                                                                form.change("is_confirmation", false);
                                                                            }}
                                                                        >
                                                                            <Icon name="arrow left" />
                                                                        </Button>
                                                                        <Button
                                                                            positive
                                                                            icon
                                                                            onClick={async () => {
                                                                                await setOpen(false);
                                                                                await form.change("is_confirmation", true);
                                                                                await form.submit();
                                                                            }}
                                                                        >
                                                                            <Icon name="check" />
                                                                        </Button>
                                                                    </>
                                                                )
                                                            }
                                                        </Media>
                                                    </Modal.Actions>
                                                </Modal>
                                            </form>
                                        );
                                    }}
                                />
                            </Container>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Declaration;
