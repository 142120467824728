import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import { Container, Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { co2ConversionRate, priceConversionRate } from "modules/common/utils";
import { dynamicValueUnit } from "modules/data/utils";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetCategoriesQuery } from "modules/category/categoryService";
import { useGetSitesQuery } from "modules/site/siteService";

export const EnergyHeaderText = (props) => {
    const { total_consumption, standby_threshold, measure } = props;
    let columns = 1;
    const org = useSelector((state) => state.org);

    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const categories = useGetCategoriesQuery({ org: org.current }, { skip: !org.current });

    const { factor, new_unit, rate_co2, rate_price, site_conversions, conso } = useMemo(() => {
        const conso = _.get(total_consumption, "data.data", null);
        const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
        const site = _.find(sites.data, { id: dataflow?.site });
        const category = _.find(categories.data, { id: dataflow.dataflowspec });

        const { factor, new_unit } = dynamicValueUnit(conso, measure?.display_unit?.symbol, measure?.auto_unit);

        const site_conversions = site?.conversions;
        const category_name = category?.name;
        const mt_type_name = measure?.measurementtype?.name;

        const rate_price = priceConversionRate(conso, site_conversions, category_name, mt_type_name, i18n);
        const rate_co2 = co2ConversionRate(conso, site_conversions, category_name, mt_type_name, i18n);
        return { factor, new_unit, rate_co2, rate_price, site_conversions, conso };
    }, [dataflows, categories, sites, total_consumption, measure]);

    if (_.get(site_conversions, "currency") !== undefined && rate_price) {
        columns = columns + 1;
    }
    if (rate_co2) {
        columns = columns + 1;
    }
    if (_.isFinite(standby_threshold)) {
        columns = columns + 1;
    }

    return (
        <Container>
            <Grid stackable>
                <Grid.Row columns={columns}>
                    <Grid.Column>
                        <Trans>Period consumption</Trans>
                        {`: ${i18n.number(conso * factor, { maximumFractionDigits: 2 })} ${new_unit}`}
                    </Grid.Column>
                    {_.isFinite(standby_threshold) && (
                        <Grid.Column>
                            <Trans>Standby consumption</Trans>
                            {`: ${i18n.number(standby_threshold * factor, { maximumFractionDigits: 2 })} ${new_unit}`}
                        </Grid.Column>
                    )}
                    {rate_co2 && (
                        <Grid.Column>
                            <Trans>Co2 equivalent</Trans>
                            {` : ${rate_co2}`}
                        </Grid.Column>
                    )}
                    {_.get(site_conversions, "currency") !== undefined && rate_price && (
                        <Grid.Column>
                            <Trans>Cost</Trans>
                            {` : ${rate_price}`}
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default EnergyHeaderText;
