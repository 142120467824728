import { t } from "@lingui/macro";

export const isAdminRoleOptions = [
    { key: 1, value: 1, text: t`all members` },
    { key: 2, value: 2, text: t`administrators` },
    { key: 3, value: 3, text: t`not administrators` }
];

export const siteRoleOptions = [
    { key: 1, value: 1, text: t`Technician` },
    { key: 2, value: 2, text: t`Visualizer` }
];

export const deleteExclusion = [
    "organization.Membership",
    "organization.PasswordHistory",
    "organization.User",
    "organization.User_groups",
    "admin.LogEntry",
    "pwa_clients.LicenseLogEvent",
    "pwa_clients.MailingListMember",
    "pwa_clients.AdvancedReport_mailinglist",
    "pwa_clients.Alert_mailinglist",
    "pwa_clients.AlertPack_mailinglist",
    "pwa_clients.ScheduleExport_mailinglist",
    "pwa_clients.AnalysisSection_sites",
    "pwa_clients.AnalysisSection_zones",
    "pwa_clients.AnalysisSection_categories",
    "pwa_clients.AnalysisSection_tags",
    "pwa_clients.AnalysisSection_usages",
    "pwa_clients.AdvancedReportAnalysis",
    "pwa_clients.Alert_days",
    "pwa_clients.AlertMeasurement",
    "pwa_clients.TriggeredAlert",
    "pwa_clients.DiagramNode",
    "pwa_clients.ScheduleExport_measurements",
    "pwa_clients.ScheduleExportActivity"
];
export const modifiedExclusion = [];

export const status_list = [
    { key: 0, value: 0, text: t`All logs` },
    { key: 1, value: 1, text: t`Connected` },
    { key: 2, value: 2, text: t`Disconnected` },
    { key: 3, value: 3, text: t`Connection failure` }
];
