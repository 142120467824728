import React, { useCallback, useEffect, useMemo } from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { Grid, Image, Segment, Divider, Button, Menu } from "semantic-ui-react";

import logo from "images/logo-full.svg";
import i18n from "modules/i18n/i18nConfig";
import history from "history_app";
import version from "version";
import { regexEmail } from "modules/common/utils";
import { login as localLogin } from "../authSlice";
import { useLoginUserMutation } from "../authService";

import Maintenance from "modules/common/components/Maintenance";
import DropdownI18n from "modules/i18n/components/DropdownI18n";

const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = <Trans>email required</Trans>;
    } else if (!regexEmail.test(values.email)) {
        errors.email = <Trans>invalid email</Trans>;
    }
    if (!values.password) {
        errors.password = <Trans>password required</Trans>;
    }
    return errors;
};

export const Login = (props) => {
    const dispatch = useDispatch();
    const { auth, notification } = useSelector((state) => state);

    const [loginUser, login] = useLoginUserMutation();

    useEffect(() => {
        if (login.isSuccess) {
            dispatch(localLogin(login.data));
        }
    }, [dispatch, login.isSuccess, login.data]);

    useEffect(() => {
        if (auth.isAuthenticated) {
            if (localStorage.getItem("accessToken")) {
                history.push("/");
            }
        }
    }, [auth.isAuthenticated]);

    const renderErrorGeneral = () => {
        let msg = "incorrect login or password";
        if (login.error?.status === 429) {
            msg = "Account locked: too many login attempts. Please try again later";
        }
        return (
            <div className="ui negative message">
                <p>
                    <Trans>cant login</Trans>
                    <br />
                    {i18n._(msg)}
                </p>
            </div>
        );
    };

    const renderError = useCallback((meta) => {
        if (meta.touched && meta.error) {
            return <div className="ui fluid pointing above prompt label">{meta.error}</div>;
        }
    }, []);

    const renderInput = useCallback(
        ({ input, meta, placeholder }) => {
            return (
                <div className="field">
                    <div className="ui fluid left icon input">
                        <input {...input} autoComplete="off" placeholder={placeholder} />
                        <i aria-hidden="true" className={`${input.name === "email" ? "mail" : "lock"} icon`}></i>
                    </div>
                    {renderError(meta)}
                </div>
            );
        },
        [renderError]
    );

    const onSubmit = (formValues) => {
        loginUser({ data: formValues });
    };

    const initialValues = useMemo(() => ({ email: "", password: "" }), []);

    return (
        <Grid textAlign="center" verticalAlign="middle" id="pwaLogin">
            <Grid.Column>
                <Image src={logo} size="medium" centered />
                <Divider hidden />
                <Menu attached style={{ borderBottom: "none" }}>
                    <Menu.Menu position="right">
                        <DropdownI18n />
                    </Menu.Menu>
                </Menu>
                <Maintenance />
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={initialValues}
                    render={({ handleSubmit }) => {
                        return (
                            <form className="ui large form error" onSubmit={handleSubmit}>
                                <Segment padded attached>
                                    <Field name="email" type="email" placeholder={i18n._(t`email address`)} component={renderInput} />
                                    <Field name="password" type="password" placeholder={i18n._(t`password`)} component={renderInput} />
                                    {login.isError && renderErrorGeneral()}
                                    <Button fluid color="olive">
                                        <Trans>log in</Trans>
                                    </Button>
                                    {notification.srv_status.db_status === "rw" && (
                                        <Link to="/password/new">
                                            <Trans>forget password</Trans>
                                        </Link>
                                    )}
                                </Segment>
                                <Segment basic style={{ color: "#505050", fontSize: "0.7em" }}>
                                    <em>{`Version : ${version}`}</em>
                                </Segment>
                            </form>
                        );
                    }}
                />
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(Login);
