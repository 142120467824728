import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";
import cookie from "react-cookies";

export const fileImportApi = defaultApi.injectEndpoints({
    reducerPath: "apiFileImport",
    endpoints: (build) => ({
        getFileImports: build.query({
            keepUnusedDataFor: 600,
            query: ({ org }) => {
                const current_org = _.get(org, "name", null);
                return `fileimports?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "FileImports", id: arg.source }];
                return [];
            },
            transformResponse: (response, meta, arg) => {
                return _.filter(response, { source: arg?.source });
            }
        }),
        getFileImport: build.query({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return `fileimports/${id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 600,
            providesTags: (result, error, arg) => {
                return [{ type: "FileImport", id: arg.id }];
            }
        }),
        createFileImport: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `fileimports?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "FileImports", id: arg.source },
                        { type: "FileImport", id: arg.data.id }
                    ];
                }
                return [];
            }
        }),
        updateFileImport: build.mutation({
            query: ({ org, id, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `fileimports/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [
                        { type: "FileImports", id: arg.source },
                        { type: "FileImport", id: arg.id }
                    ];
                }
                return [];
            }
        }),
        deleteFileImport: build.mutation({
            query: ({ org, id }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `fileimports/${id}?org=${current_org}&${refreshTimestampUrlParam()}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "FileImports", id: arg.source }];
                }
                return [];
            }
        })
    })
});

export const {
    useGetFileImportsQuery,
    useGetFileImportQuery,
    useCreateFileImportMutation,
    useUpdateFileImportMutation,
    useDeleteFileImportMutation
} = fileImportApi;

export default fileImportApi;
