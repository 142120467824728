import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";

const initialState = {
    filter: {
        searchName: "",
        frequencyFilter: [],
    },
    pagination: {
        page: 1,
        itemsPerPage: 10
    },
};

const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        setSearchNameFilter: (state, action) => {
            state.filter.searchName = action.payload;
        },
        setFrequencyFilter: (state, action) => {
            state.filter.frequencyFilter = action.payload;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        setItemsPerPage: (state, action) => {
            state.pagination.page = 1;
            state.pagination.itemsPerPage = action.payload;
        },
        resetFilterWithPage: (state, action) => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setSearchNameFilter, setFrequencyFilter,
    setPage, setItemsPerPage, resetFilterWithPage,
} = reportSlice.actions;

export default reportSlice.reducer;