import React from "react";
import { Divider, Grid } from "semantic-ui-react";
import { Trans, t } from "@lingui/macro";
import { Field } from "react-final-form";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";

import { InputAdapter, ToggleAdapter } from "modules/common/components/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

export const ElecDysfunction = (props) => {
    const { form } = props;
    //We need form object of parent to check 'cycling_active'
    const cycling_active = _.result(form.getState(), "values.configuration.cycling_active", false);
    return (
        <>
            <Divider horizontal>
                <Trans>elec dysfunction</Trans>
            </Divider>
            <Grid verticalAlign="middle">
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name="configuration.thd_threshold"
                        placeholder={i18n._(t`thd_threshold placeholder`)}
                        label={i18n._(t`thd_threshold`)}
                        unit={"%"}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        helperText={i18n._(t`thd_threshold_helper`)}
                        defaultValue={30}
                        validate={(value) => {
                            return validateNumber(value, i18n, false, false);
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name="configuration.thd_duration"
                        placeholder={i18n._(t`thd_duration placeholder`)}
                        label={i18n._(t`thd_duration`)}
                        unit={"h"}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        defaultValue={3}
                        validate={(value) => {
                            const durationValidate = validateNumber(value, i18n, false, false);
                            if (durationValidate !== undefined) {
                                return durationValidate;
                            }
                            if (reparseNumber(value) < 1) return <Trans>thd_duration minimum 1h</Trans>;
                            return undefined;
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name="configuration.power_imbalance_threshold"
                        placeholder={i18n._(t`power_imbalance_threshold placeholder`)}
                        label={i18n._(t`power_imbalance_threshold`)}
                        unit={"%"}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        helperText={i18n._(t`power_imbalance_threshold_helper`)}
                        defaultValue={10}
                        validate={(value) => {
                            return validateNumber(value, i18n, false, false);
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <Field
                        name="configuration.power_imbalance_duration"
                        placeholder={i18n._(t`power_imbalance_duration placeholder`)}
                        label={i18n._(t`power_imbalance_duration`)}
                        unit={"h"}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="decimal"
                        isRequired={true}
                        defaultValue={3}
                        validate={(value) => {
                            const durationValidate = validateNumber(value, i18n, false, false);
                            if (durationValidate !== undefined) {
                                return durationValidate;
                            }
                            if (reparseNumber(value) < 1) return <Trans>power_imbalance_duration minimum 1h</Trans>;
                            return undefined;
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={3}>
                    <Field
                        name="configuration.cycling_active"
                        label={i18n._(t`cycling active`)}
                        labelposition="right"
                        component={ToggleAdapter}
                        defaultValue={true}
                        toggle={false}
                        customAction={(data) => {
                            if (data.checked === false) {
                                //set cycling to null if cycling active not set
                                form.change("configuration.cycling", null);
                            }
                        }}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={6}>
                    <Field
                        name="configuration.cycling"
                        placeholder={i18n._(t`cycling placeholder`)}
                        label={i18n._(t`cycling`)}
                        component={InputAdapter}
                        parse={identityNull}
                        inputMode="numeric"
                        isRequired={true}
                        defaultValue={5}
                        disabled={!cycling_active}
                        helperText={i18n._(t`cycling helper`)}
                        validate={(value, allValues) => {
                            if (allValues.configuration?.cycling_active === false) {
                                return undefined;
                            }
                            const cyclingValidate = validateNumber(value, i18n, false, false, true);
                            if (cyclingValidate !== undefined) {
                                return cyclingValidate;
                            }
                            if (reparseNumber(value) < 1) {
                                return <Trans>cycling is invalid</Trans>;
                            }
                            return undefined;
                        }}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default React.memo(ElecDysfunction);
