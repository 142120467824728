import React, { useEffect, useState } from "react";
import { t } from "@lingui/macro";

import _ from "lodash";
import { Button, Header } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetAnalysisSectionQuery, usePatchAdvancedAnalysisMutation } from "../analysisAdvancedService";

import MessageDisplay from "modules/common/components/MessageDisplay";
import AnalysisSectionView from "./AnalysisSectionView";
import AnalysisSectionDelete from "./AnalysisSectionDelete";
import AnalysisSectionUpdate from "./AnalysisSectionUpdate";

const AnalysisSection = (props) => {
    const {
        org,
        auth,
        notification,
        current_analysis,
        idx,
        analysis_section_id,
        section_list,
        categories_all,
        sites_all,
        zones_all,
        usages_all,
        tags_all,
        units_all,
        measurements_all,
        bottomRef,
        sectionAdded,
        setSectionAdded
    } = props;

    const section_list_count = _.size(section_list);
    const [openSection, setOpenSection] = useState(false);

    useEffect(() => {
        (async () => {
            if (sectionAdded && bottomRef && bottomRef.current) {
                if (idx + 1 === section_list_count) {
                    await bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest", alignTop: false });
                    setSectionAdded(false);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSectionAdded, idx, section_list_count]);

    //Retrieve analysis section from database / from cache
    const analysis_section = useGetAnalysisSectionQuery({ analysis_section_id, org }, { skip: !org || !analysis_section_id });
    const [changeAnalysisSectionOrder] = usePatchAdvancedAnalysisMutation();

    if (analysis_section.isError) {
        return (
            <>
                <Header as="h3" attached={idx === 0 ? "top" : true} block textAlign="center">
                    {analysis_section?.data?.name ?? "-"}
                </Header>
                <MessageDisplay
                    message={i18n._(t`error loading analysis section`)}
                    level="error"
                    iconName="warning circle"
                    isLoading={false}
                    attached={true}
                />
            </>
        );
    }
    if ((analysis_section.isSuccess && !analysis_section.isFetching) || !_.isFinite(analysis_section_id)) {
        const categories = _.chain(categories_all)
            .reduce((res, category) => {
                if (!_.includes([5, 8, 9, 11, 12, 27], category.id)) {
                    res.push(category);
                }
                return res;
            }, [])
            .orderBy((item) => i18n._(item.text)) //ordering text based on translation
            .value();

        const sites = sites_all;
        const zones = zones_all;
        const usages = usages_all;
        const tags = tags_all;
        const units = units_all;
        const measurements = measurements_all;

        const can_change = notification.srv_status.db_status === "rw" && current_analysis?.owner === auth.user.user_id;

        return (
            <>
                <Header
                    as="h3"
                    attached={idx === 0 ? "top" : true}
                    block
                    style={{ display: "flex", flexWrap: "wrap", alignItems: "center", breakBefore: idx === 0 ? "avoid" : "page" }}
                >
                    <div style={{ flex: 1 }} id="pwaAnalysisSectionActions">
                        <Button.Group>
                            <Button icon={can_change ? "edit" : "eye"} onClick={(e) => setOpenSection(true)} />
                            {can_change && (
                                <>
                                    {idx !== 0 && (
                                        <Button
                                            icon="arrow alternate circle up"
                                            onClick={(e, data) => {
                                                const swap_list = [...section_list];
                                                const temp = swap_list[idx - 1];
                                                swap_list[idx - 1] = swap_list[idx];
                                                swap_list[idx] = temp;
                                                changeAnalysisSectionOrder({
                                                    org,
                                                    data: {
                                                        id: analysis_section?.data?.advanced_analysis ?? null,
                                                        analysissection_set: swap_list
                                                    }
                                                });
                                            }}
                                        />
                                    )}
                                    {section_list_count !== idx + 1 && (
                                        <Button
                                            icon="arrow alternate circle down"
                                            onClick={(e, data) => {
                                                const swap_list = [...section_list];
                                                const temp = swap_list[idx + 1];
                                                swap_list[idx + 1] = swap_list[idx];
                                                swap_list[idx] = temp;
                                                changeAnalysisSectionOrder({
                                                    org,
                                                    data: {
                                                        id: analysis_section?.data?.advanced_analysis ?? null,
                                                        analysissection_set: swap_list
                                                    }
                                                });
                                            }}
                                        />
                                    )}
                                    <AnalysisSectionDelete analysis_section={analysis_section.data} org={org} notification={notification} />
                                </>
                            )}
                        </Button.Group>
                    </div>
                    <div style={{ flex: 2, textAlign: "center" }}>
                        <h3>{analysis_section?.data?.name ?? "-"}</h3>
                    </div>
                    <div style={{ flex: 1 }} />
                </Header>
                <AnalysisSectionView
                    {...props}
                    advanced_analysis_id={current_analysis?.id ?? null}
                    aggregation={current_analysis?.aggregation ?? 1}
                    analysis_section={analysis_section.data}
                    categories={categories}
                    sites={sites}
                    zones={zones}
                    tags={tags}
                    usages={usages}
                />
                {openSection && (
                    <AnalysisSectionUpdate
                        {...props}
                        categories={categories}
                        sites={sites}
                        zones={zones}
                        tags={tags}
                        units={units}
                        usages={usages}
                        measurements={measurements}
                        analysis_section={analysis_section.data}
                        can_change={can_change}
                        setOpen={setOpenSection}
                    />
                )}
            </>
        );
    }
    return (
        <>
            <Header as="h3" attached={idx === 0 ? "top" : true} block textAlign="center">
                {analysis_section?.data?.name ?? "-"}
            </Header>
            <MessageDisplay message={i18n._(t`loading analysis section`)} level="info" iconName="circle notched" isLoading={true} attached={true} />
        </>
    );
};

export default React.memo(AnalysisSection);
