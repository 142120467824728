import _ from "lodash";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";

import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { displayTitleFromUnit } from "./components/globalview/GraphicIntensive";
import { processAggregateTime } from "modules/time/utils";

const initialState = {
    global_filter: null,
    temperature: {
        status: "idle",
        data: [],
        error: null
    },
    hygrometry: {
        status: "idle",
        data: [],
        error: null
    },
    co2: {
        status: "idle",
        data: [],
        error: null
    },
    particle: {
        status: "idle",
        data: [],
        error: null
    },
    radialZone: {
        status: "idle",
        data: [],
        error: null
    },
    radialUsage: {
        status: "idle",
        data: [],
        error: null
    },
    synthesis: {
        status: "idle",
        data: [],
        error: null
    },
    consumption: {
        status: "idle",
        data: { ref: [], compare: [] },
        error: null
    }
};

export const getOverviewIntensive = createAsyncThunk("globalview/getOverviewIntensive", async (global_filter, thunkAPI) => {
    const {
        org,
        site,
        unit,
        time: { start, end }
    } = global_filter;
    const current_org = _.get(org, "name", null);
    let filtered_site = _.join(site, ",");

    try {
        const response = await PowerAdaptAPI.get(
            `/sites/intensive_overview?org=${current_org}&site_ids=${filtered_site}&unit=${unit.id}&start=${start.format(
                "YYYY-MM-DD"
            )}&end=${end.format("YYYY-MM-DD")}&${refreshTimestampUrlParam()}`
        );
        return {
            filter: { ...global_filter, time: { ...global_filter.time, start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") } },
            data: response.data
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

export const getOverviewConsumption = createAsyncThunk("globalview/getOverviewConsumption", async (global_filter, thunkAPI) => {
    const { org, site, unit, aggregate, time, compare_time, todo_compare, filter, sites } = global_filter;
    const current_org = _.get(org, "name", null);
    let filtered_site = _.join(site, ",");

    if (filter === "cost") {
        //Remove all sites where currency is not equal to selected 'unit'
        filtered_site = _.chain(site)
            .reduce((res, site_id) => {
                const full_site = _.find(sites, { id: site_id });
                if (full_site && _.get(full_site, "conversions.currency", null) === unit) {
                    res.push(site_id);
                }
                return res;
            }, [])
            .join(",")
            .value();
    }

    const query_filter = _.isNull(filter) ? `&site_ids=${_.join(site, ",")}&unit=${unit.id}` : `&site_ids=${filtered_site}&filter=${filter}`;
    try {
        const ref_response = await PowerAdaptAPI.get(
            `/sites/consumptions_calendar?org=${current_org}${query_filter}&start=${processAggregateTime(time.start, aggregate).format(
                "YYYY-MM-DD"
            )}&end=${time.end.clone().add(1, "days").format("YYYY-MM-DD")}&aggregate=${aggregate}&${refreshTimestampUrlParam()}`
        );

        if (todo_compare) {
            const compare_response = await PowerAdaptAPI.get(
                `/sites/consumptions_calendar?org=${current_org}${query_filter}&start=${processAggregateTime(compare_time.start, aggregate).format(
                    "YYYY-MM-DD"
                )}&end=${compare_time.end.clone().add(1, "days").format("YYYY-MM-DD")}&aggregate=${aggregate}&${refreshTimestampUrlParam()}`
            );

            return {
                filter: {
                    ...global_filter,
                    time: {
                        ...global_filter.time,
                        start: processAggregateTime(time.start, aggregate).format("YYYY-MM-DD"),
                        end: time.end.format("YYYY-MM-DD")
                    },
                    compare_time: {
                        ...global_filter.compare_time,
                        start: processAggregateTime(compare_time.start, aggregate).format("YYYY-MM-DD"),
                        end: compare_time.end.format("YYYY-MM-DD")
                    }
                },
                data: { ref: ref_response.data, compare: compare_response.data }
            };
        } else {
            return {
                filter: {
                    ...global_filter,
                    time: {
                        ...global_filter.time,
                        start: processAggregateTime(time.start, aggregate).format("YYYY-MM-DD"),
                        end: time.end.format("YYYY-MM-DD")
                    },
                    compare_time: {
                        ...global_filter.compare_time,
                        start: processAggregateTime(compare_time.start, aggregate).format("YYYY-MM-DD"),
                        end: compare_time.end.format("YYYY-MM-DD")
                    }
                },
                data: { ref: ref_response.data }
            };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

const globalviewSlice = createSlice({
    name: "globalview",
    initialState,
    reducers: {
        resetGlobalView: (state, action) => {
            return initialState;
        },
        setGlobalFilter: (state, action) => {
            state.global_filter = action.payload;
        },
        setDisabledSeries: (state, action) => {
            const size_disable = _.chain(state.consumption.data)
                .get("ref")
                .filter((item) => item.disabled === false)
                .size()
                .value();
            const update_series = _.chain(state.consumption.data)
                .reduce((res, serie_type, key) => {
                    res[key] = _.chain(serie_type)
                        .map((serie) => {
                            if (_.isEqual(serie.name, action.payload?.name))
                                return { ...serie, disabled: size_disable <= 1 ? false : !serie.disabled };
                            return serie;
                        })
                        .value();
                    return res;
                }, {})
                .value();
            state.consumption.data = update_series;
        },
        hideShowSeries: (state, action) => {
            const hide = action.payload;

            const update_series = _.chain(state.consumption.data)
                .reduce((res, serie_type, key) => {
                    res[key] = _.chain(serie_type)
                        .map((serie, idx) => {
                            if (hide) {
                                if (idx === 0) return { ...serie, disabled: false };
                                return { ...serie, disabled: true };
                            } else {
                                return { ...serie, disabled: false };
                            }
                        })
                        .value();
                    return res;
                }, {})
                .value();
            state.consumption.data = update_series;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getOverviewIntensive.pending, (state, action) => {
                const state_field = displayTitleFromUnit[action.meta.arg.unit.symbol].name;
                if (state_field) {
                    state[state_field].status = "loading";
                    state[state_field].data = [];
                    state[state_field].error = null;
                }
            })
            .addCase(getOverviewIntensive.rejected, (state, action) => {
                const state_field = displayTitleFromUnit[action.meta.arg.unit.symbol].name;
                if (state_field) {
                    state[state_field].status = "failed";
                    state[state_field].data = [];
                    state[state_field].error = null;
                }
            })
            .addCase(getOverviewIntensive.fulfilled, (state, action) => {
                if (action.payload.field) {
                    state[action.payload.field].status = "succeeded";
                    state[action.payload.field].data = action.payload.data;
                    state[action.payload.field].error = null;
                }
            })
            .addCase(getOverviewConsumption.pending, (state, action) => {
                state.radialZone.status = "loading";
                state.radialZone.data = [];
                state.radialZone.error = null;
                state.radialUsage.status = "loading";
                state.radialUsage.data = [];
                state.radialUsage.error = null;
                state.synthesis.status = "loading";
                state.synthesis.data = [];
                state.synthesis.error = null;
                state.consumption.status = "loading";
                state.consumption.data = { ref: [], compare: [] };
                state.consumption.error = null;
            })
            .addCase(getOverviewConsumption.rejected, (state, action) => {
                state.radialZone.status = "failed";
                state.radialZone.data = [];
                state.radialZone.error = action.payload;
                state.radialUsage.status = "failed";
                state.radialUsage.data = [];
                state.radialUsage.error = action.payload;
                state.synthesis.status = "failed";
                state.synthesis.data = [];
                state.synthesis.error = action.payload;
                state.consumption.status = "failed";
                state.consumption.data = { ref: [], compare: [] };
                state.consumption.error = action.payload;
            })
            .addCase(getOverviewConsumption.fulfilled, (state, action) => {
                state.radialZone.status = "succeeded";
                state.radialZone.error = null;
                state.radialZone.data = action.payload.radialZone;
                state.radialUsage.status = "succeeded";
                state.radialUsage.error = null;
                state.radialUsage.data = action.payload.radialUsage;
                state.synthesis.status = "succeeded";
                state.synthesis.error = null;
                state.synthesis.data = action.payload.synthesis;
                state.consumption.status = "succeeded";
                state.consumption.error = null;
                state.consumption.data = action.payload.graphic;
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const { setGlobalFilter, setDisabledSeries, hideShowSeries, resetGlobalView } = globalviewSlice.actions;

export default globalviewSlice.reducer;
