import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Dropdown, Flag } from "semantic-ui-react";
import { setLng } from "modules/i18n/i18nSlice";
import { Media } from "App";

const DropdownI18n = (props) => {
    const dispatch = useDispatch();

    const { current, options } = useSelector((state) => state.i18n);

    const onChangeI18n = (event, data) => {
        const selected = _.find(data.options, { value: data.value });
        if (selected) {
            dispatch(setLng(selected.value));
        }
    };

    const trigger = () => {
        return (
            <span>
                <Flag name={_.find(options, ["value", current]).flag} />
                <Media greaterThanOrEqual="tablet">
                    {(mediaClassNames, renderChildren) => {
                        return renderChildren ? (
                            <span>{_.find(options, ["value", current]).text}</span>
                        ) : null;
                    }}
                </Media>
            </span>
        );
    };

    return (
        <Dropdown
            inline
            item
            trigger={trigger()}
            options={options}
            onChange={onChangeI18n}
            value={current}
        />
    );
};

export default DropdownI18n;
