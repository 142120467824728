import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import { Grid, Label, Dropdown, Accordion } from "semantic-ui-react";

import { setGlobalFilter } from "modules/globalview/globalViewSlice";
import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";
import { Media } from "App";
import TimePanel from "modules/common/components/TimePanel";
import { dateRangeOptions } from "modules/time/utils";
import { calendarTypeOptions } from "modules/common/utils";

export const aggregate_options = [
    { key: "day", value: "day", text: t`by_day` },
    { key: "week", value: "week", text: t`by_week` },
    { key: "month", value: "month", text: t`by_month` }
];

const aggregate_type_options = [
    { key: 1, value: "category", text: t`by category` },
    { key: 2, value: "usage", text: t`by usage` },
    { key: 3, value: "tag", text: t`by tag` },
    { key: 4, value: "site", text: t`by site` },
    { key: 5, value: "zone", text: t`by zone` },
    { key: 6, value: null, text: t`no group` }
];

const GlobalViewFilter = (props) => {
    const { default_global_filter, usages } = props;
    const dispatch = useDispatch();
    const { tag, unit, zone } = useSelector((state) => state);

    const real_units = _.chain(unit.units)
        .orderBy("key")
        .filter((record) => _.includes(["kWh", "m³", "Nm³", "TV"], record.text))
        .value();
    const ambience = { key: -1, text: i18n._(t`ambience`), value: -1, type: "unit" };

    const rangeOptions = _.filter(dateRangeOptions(), (item) => !_.includes(["1d", "2d", "yesterday", "24h", "48h", "72h"], item.value));

    const [localSite, setLocalSite] = useState(default_global_filter.site);
    const [localUnit, setLocalUnit] = useState(default_global_filter.unit);
    const [localAggregate, setLocalAggregate] = useState(default_global_filter.aggregate);
    const [localAggregateType, setLocalAggregateType] = useState(null);
    const [localCalendarType, setLocalCalendarType] = useState(default_global_filter.calendar_type);
    const [todoCompare, setTodoCompare] = useState(false);
    const [time, setTime] = useState(default_global_filter.time);
    const [compareTime, setCompareTime] = useState(default_global_filter.compare_time);
    const [usageFilter, setUsageFilter] = useState(default_global_filter.usages);
    const [tagFilter, setTagFilter] = useState([]);
    const [zoneFilter, setZoneFilter] = useState([]);
    //used to enable/disable timePanel btn
    const [globalDisabled, setglobalDisabled] = useState(true);

    const site_conversions = useMemo(() => {
        return _.chain(localSite)
            .reduce(
                (res, site_id) => {
                    const full_site = _.find(props.sites, { id: site_id });
                    if (full_site === undefined) {
                        return res;
                    }
                    const site_currency = _.get(full_site, "conversions.currency", null);
                    if (site_currency) res.currency.push(site_currency);
                    const convert_keys = _.chain(full_site).get("conversions", {}).keys().value();
                    if (res.co2_counter === false) {
                        const has_co2 = _.some(convert_keys, (item) => _.includes(item, "co2"));
                        res.co2_counter = has_co2;
                    }
                    return res;
                },
                { co2_counter: false, currency: [] }
            )
            .value();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localSite]);

    let globalview_units = [...real_units, ambience];

    if (site_conversions.co2_counter) {
        globalview_units.push({ key: -2, text: "kgCo2e", value: "kgCo2e", type: "unit" });
    }

    _.chain(site_conversions)
        .get("currency", [])
        .uniq()
        .forEach((item, index) => {
            const idx = -3 - index;
            globalview_units.push({ key: idx, text: item, value: item, type: "unit" });
        })
        .value();

    const renderFilter = () => (
        <Grid centered>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <Dropdown
                        fluid
                        options={_.chain(props.sites)
                            .map(({ key, text, value }) => ({
                                key,
                                text,
                                value,
                                content: <Label color="blue">{i18n._(text)}</Label>
                            }))
                            .orderBy((item) => {
                                return removeAccents(item.text).toLowerCase();
                            }, "asc")
                            .value()}
                        search
                        noResultsMessage={i18n._(t`no result found`)}
                        multiple
                        placeholder={i18n._(t`select sites`)}
                        selection
                        onChange={async (e, { value }) => {
                            if (_.size(value) === 0) {
                                return;
                            }
                            setLocalSite(value);
                            setLocalUnit(default_global_filter.unit);
                            setZoneFilter([]);
                            setglobalDisabled(false);
                        }}
                        renderLabel={(label, index, defaultProps) => {
                            const labelProps = _.size(localSite) <= 1 ? { ...defaultProps, onRemove: null } : defaultProps;
                            return { ...labelProps, color: "blue", content: i18n._(label.text) };
                        }}
                        value={localSite}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <Dropdown
                        fluid
                        button
                        labeled
                        className="icon"
                        icon="fire"
                        options={globalview_units.sort((a, b) => a.text.localeCompare(b.text, i18n.language))}
                        placeholder={i18n._(t`select unit`)}
                        selection
                        onChange={(e, { value }) => {
                            setLocalUnit(value);
                            setglobalDisabled(false);
                        }}
                        value={localUnit}
                    />
                </Grid.Column>
                {localUnit !== -1 && (
                    <Grid.Column mobile={16} tablet={4} computer={4}>
                        <Dropdown
                            fluid
                            button
                            labeled
                            className="icon"
                            icon="cloudscale"
                            options={_.map(aggregate_options, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                            placeholder={i18n._(t`aggregate placeholder`)}
                            selection
                            onChange={(e, { value }) => {
                                setLocalAggregate(value);
                                setglobalDisabled(false);
                            }}
                            value={localAggregate}
                        />
                    </Grid.Column>
                )}
            </Grid.Row>
            {localUnit !== -1 && (
                <>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={4} computer={4}>
                            <Dropdown
                                fluid
                                options={_.chain(zone.zones)
                                    .filter((zone) => {
                                        return _.includes(localSite, zone.site_id);
                                    })
                                    .map(({ key, text, value }) => ({
                                        key,
                                        text,
                                        value,
                                        content: <Label color="violet">{i18n._(text)}</Label>
                                    }))
                                    .orderBy((item) => {
                                        return removeAccents(item.text).toLowerCase();
                                    }, "asc")
                                    .value()}
                                placeholder={i18n._(t`select zones`)}
                                multiple
                                selection
                                search
                                onChange={(e, { value }) => {
                                    setZoneFilter(value);
                                    setglobalDisabled(false);
                                }}
                                renderLabel={(label) => ({ color: "violet", content: i18n._(label.text) })}
                                value={zoneFilter}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={4} computer={4}>
                            <Dropdown
                                fluid
                                options={_.chain(usages)
                                    .map(({ key, text, value }) => ({
                                        key,
                                        text,
                                        value,
                                        content: <Label color="violet">{i18n._(text)}</Label>
                                    }))
                                    .orderBy((item) => {
                                        return removeAccents(item.text).toLowerCase();
                                    }, "asc")
                                    .value()}
                                placeholder={i18n._(t`select usages`)}
                                multiple
                                selection
                                search
                                onChange={(e, { value }) => {
                                    setUsageFilter(value);
                                    setglobalDisabled(false);
                                }}
                                renderLabel={(label) => ({ color: "violet", content: i18n._(label.text) })}
                                value={usageFilter}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={4} computer={4}>
                            <Dropdown
                                fluid
                                options={_.chain(tag.tags)
                                    .map(({ key, text, value }) => ({
                                        key,
                                        text,
                                        value,
                                        content: <Label color="grey">{i18n._(text)}</Label>
                                    }))
                                    .orderBy((item) => {
                                        return removeAccents(item.text).toLowerCase();
                                    }, "asc")
                                    .value()}
                                placeholder={i18n._(t`select tags`)}
                                multiple
                                selection
                                search
                                onChange={(e, { value }) => {
                                    setTagFilter(value);
                                    setglobalDisabled(false);
                                }}
                                renderLabel={(label) => ({ color: "grey", content: i18n._(label.text) })}
                                value={tagFilter}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={4} computer={4}>
                            <Dropdown
                                fluid
                                options={_.map(calendarTypeOptions, ({ key, text, value }) => ({
                                    key,
                                    text: i18n._(text),
                                    value,
                                    content: <Label color="grey">{i18n._(text)}</Label>
                                }))}
                                placeholder={i18n._(t`Select calendars`)}
                                multiple
                                selection
                                onChange={(e, { value }) => {
                                    setLocalCalendarType(value);
                                    setglobalDisabled(false);
                                }}
                                renderLabel={(label) => ({ color: "grey", content: i18n._(label.text) })}
                                value={localCalendarType}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={4} computer={4}>
                            <Dropdown
                                fluid
                                button
                                labeled
                                className="icon"
                                icon="gitter"
                                options={_.chain(aggregate_type_options)
                                    .map(({ key, text, value }) => ({ key, text: i18n._(text), value }))
                                    .sort((a, b) => a.text.localeCompare(b.text, i18n.language))
                                    .value()}
                                placeholder={i18n._(t`aggregate_type_placeholder`)}
                                selection
                                onChange={(e, { value }) => {
                                    setLocalAggregateType(value);
                                    setglobalDisabled(false);
                                }}
                                value={localAggregateType}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </>
            )}
            <Grid.Row>
                <Grid.Column width={15}>
                    <TimePanel
                        time={time}
                        timeFormat={false}
                        enableCompare={localUnit !== -1}
                        compareTime={compareTime}
                        todoCompare={todoCompare}
                        disable_compare_end={true}
                        rangeOptions={rangeOptions}
                        accordion={false}
                        globalDisabled={globalDisabled}
                        minimumDeltaDay={1}
                        action={(data) => {
                            //go globalview
                            dispatch(
                                setGlobalFilter({
                                    time: { start: data.start, end: data.end },
                                    compare_time: { start: data.compareStart, end: data.compareEnd },
                                    todo_compare: data.todoCompare,
                                    site: localSite,
                                    unit: localUnit,
                                    aggregate: localAggregate,
                                    aggregate_type: localAggregateType,
                                    calendar_type: localCalendarType,
                                    usages: usageFilter,
                                    tags: tagFilter,
                                    zones: zoneFilter
                                })
                            );
                            setTodoCompare(data.todoCompare);
                            setTime({ start: moment(data.start), end: moment(data.end), plage: data.plage });
                            if (data.todoCompare) setCompareTime({ start: moment(data.compareStart), end: moment(data.compareEnd), plage: "" });
                            setglobalDisabled(true);
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

    return (
        <Grid.Row>
            <Grid.Column width={15}>
                <Media lessThan="computer">
                    {(mediaClassNames, renderChildren) =>
                        renderChildren && (
                            <Accordion
                                panels={[
                                    {
                                        key: "filters",
                                        title: i18n._(t`filters`),
                                        content: { content: renderFilter() }
                                    }
                                ]}
                            />
                        )
                    }
                </Media>
                <Media greaterThanOrEqual="computer">{(mediaClassNames, renderChildren) => renderChildren && renderFilter()}</Media>
            </Grid.Column>
        </Grid.Row>
    );
};

export default GlobalViewFilter;
