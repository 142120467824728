import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Field, Form } from "react-final-form";
import { Modal, Button, Icon, Grid, Segment, Divider, Input, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { DropDownAdapter } from "modules/common/components/form";
import { dialect_decimal_list, dialect_delimiter_list } from "modules/import/utils";
import { useUploadProductionDataMutation } from "modules/importedDataset/importedDataSetsService";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";

const UploadDataSetModalForm = (props) => {
    const { item, setOpen } = props;
    const { org } = useSelector((state) => state);

    const [selectedFile, setSelectedFile] = useState(null);

    const [uploadProductionData, upload] = useUploadProductionDataMutation();

    //  upload
    useEffect(() => {
        if (upload.isSuccess) {
            toast.success(i18n._(t`Data uploaded successfully. Imported line(s): ${upload.data.imported_count}`), toast_options);
            setOpen(false);
        }
        if (upload.isError) {
            toast.error(i18n._(t`Error uploading file.${upload.error.data.non_field_errors[0]}`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upload.isSuccess, upload.isError]);

    const handleChange = (event) => {
        if (!event.target.files[0].name.endsWith(".csv")) {
            setSelectedFile("extension_error");
            event.target.value = null; // discard selected file
            return;
        }
        setSelectedFile(event.target.files[0]);
    };

    const initialValues = useMemo(() => {
        const defaultValues = { decimal_separator: "", csv_separator: "", import_file: "" };
        return {
            ...defaultValues,
            ...item
        };
    }, [item]);

    const validate = (values) => {
        const errors = {};
        if (!values.decimal_separator && values.decimal_separator !== 0) {
            errors.decimal_separator = i18n._(t`Required field`);
        }
        if (!values.csv_separator && values.csv_separator !== 0) {
            errors.csv_separator = i18n._(t`Required field`);
        }

        return errors;
    };

    const submitForm = async (data) => {
        await uploadProductionData({
            org: org.current,
            dataset_id: item.id,
            file: selectedFile,
            data
        });
    };

    const dialect_decimal_list_options = useMemo(() => {
        return _.chain(dialect_decimal_list)
            .map(({ key, text, value }) => ({ key, text, value }))
            .value();
    }, []);

    const dialect_delimiter_list_options = useMemo(() => {
        return _.chain(dialect_delimiter_list)
            .map(({ key, text, value }) => ({ key, text, value }))
            .value();
    }, []);

    return (
        <Modal
            centered={false}
            closeOnDimmerClick={false}
            onClose={() => {
                setOpen(false);
            }}
            open={true}
        >
            <Modal.Header>
                <Trans>Upload data from file for dataset {item.name}</Trans>
            </Modal.Header>
            <Modal.Content>
                <Segment basic>
                    <Form
                        onSubmit={submitForm}
                        initialValues={initialValues}
                        validate={validate}
                        render={({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid verticalAlign="top" centered>
                                        <Grid.Column computer={8} tablet={16}>
                                            <Field
                                                name="decimal_separator"
                                                label={i18n._(t`Decimal separator`)}
                                                placeholder={i18n._(t`select decimal separator`)}
                                                isRequired={true}
                                                options={dialect_decimal_list_options}
                                                component={DropDownAdapter}
                                                disabled={true}
                                            />
                                        </Grid.Column>
                                        <Grid.Column computer={8} tablet={16}>
                                            <Field
                                                name="csv_separator"
                                                label={i18n._(t`Field separator`)}
                                                placeholder={i18n._(t`select field separator`)}
                                                isRequired={true}
                                                options={dialect_delimiter_list_options}
                                                component={DropDownAdapter}
                                                disabled={true}
                                            />
                                        </Grid.Column>

                                        {selectedFile === "extension_error" && (
                                            <Grid.Column width={16}>
                                                <Label
                                                    style={{ display: "flex", alignItems: "baseline", marginBottom: "0px" }}
                                                    basic
                                                    color="red"
                                                    pointing="below"
                                                >
                                                    <Icon size="large" name="warning sign" />
                                                    <Trans>Unsupported file format, select a file with csv extension.</Trans>
                                                </Label>
                                            </Grid.Column>
                                        )}
                                        <Grid.Column width={16}>
                                            <Input fluid type="file" name="import_file" onChange={handleChange} />
                                        </Grid.Column>

                                        <Grid.Column width={16}>
                                            <Divider />
                                        </Grid.Column>

                                        <Grid.Column width={16} textAlign="right">
                                            <Button
                                                negative
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                <Trans>cancel</Trans>
                                            </Button>
                                            <Button
                                                disabled={selectedFile === "extension_error" || selectedFile === null}
                                                labelPosition="right"
                                                icon
                                                positive
                                            >
                                                <Icon name="send" />
                                                <Trans>validate</Trans>
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                </Segment>
            </Modal.Content>
        </Modal>
    );
};

export default UploadDataSetModalForm;
