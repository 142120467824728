import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t } from "@lingui/macro";
import { Header, Button, Popup } from "semantic-ui-react";
import { DiscreteColorLegend, Hint } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import { checkMobileAndTablet, removeAccents } from "modules/common/utils";

import RotatedLabelRadialChart from "modules/common/components/graphic/PieChart";
import MessageDisplay from "modules/common/components/MessageDisplay";

const LEGENDHEIGHT = 55; //value in pixel

const Radial = (props) => {
    const { verticalLegend, displayAsModal } = props;
    const [hint, setHint] = useState(null);
    const [series, setSeries] = useState(props.series || []);
    const [displaySeries, setDisplaySeries] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setSeries(props.series);
    }, [props.series]);

    const remap_series = useMemo(() => {
        return _.chain(series)
            .reduce((res, serie) => {
                //Process global total only on displayed serie
                const global_total = _.chain(series)
                    .filter((item) => item.disabled === false)
                    .sumBy("total")
                    .value();
                const repartition = serie.disabled === true ? null : _.defaultTo((serie?.total / Math.abs(global_total)) * 100, null);

                if (repartition === 0) {
                    return res;
                }

                const point = {
                    id: serie.id,
                    title: serie.title,
                    angle: repartition,
                    percent: repartition,
                    color: serie.color,
                    disabled: serie.disabled,
                    strokeWidth: 20
                };
                res.push(point);
                return res;
            }, [])
            .value();
    }, [series]);

    const hideShowSeries = (hide) => {
        const update_series = _.chain(series)
            .reduce((res, serie, idx) => {
                if (hide) {
                    if (idx === 0) {
                        res.push({ ...serie, disabled: false });
                    } else {
                        res.push({ ...serie, disabled: true });
                    }
                } else {
                    res.push({ ...serie, disabled: false });
                }
                return res;
            }, [])
            .value();

        setSeries(update_series);
    };

    const clickHandler = (serie_clk) => {
        const other_disabled = _.chain(series)
            .reject({ id: serie_clk?.id })
            .every((serie) => {
                return serie.disabled === true;
            })
            .value();

        const update_series = _.chain(series)
            .map((item) => {
                if (item.id === _.get(serie_clk, "id")) {
                    const update_serie = {
                        ...item,
                        disabled: other_disabled ? false : !item.disabled
                    };
                    return update_serie;
                } else {
                    return item;
                }
            })
            .value();

        setSeries(update_series);
    };

    let legendWidth = props.width;
    let legendHeight = LEGENDHEIGHT;
    let radialWidth = props.width;
    let radialHeight = props.height - LEGENDHEIGHT;
    if (verticalLegend) {
        radialWidth /= 2;
        legendWidth /= 3;
        radialHeight = props.height;
        legendHeight = props.height;
    }

    const radius = _.clamp(Math.min(props.width, props.height) / 4, 30, 150);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: verticalLegend ? "row" : "column",
                height: props.height,
                position: "relative"
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered && (
                <div className="no-print" style={{ position: "absolute", top: 0, right: 0, zIndex: 2 }}>
                    <Button.Group>
                        <Popup
                            flowing
                            trigger={<Button icon="info" />}
                            basic
                            style={checkMobileAndTablet() ? { position: "absolute", right: 0 } : null}
                        >
                            <Popup.Content>
                                <h3>{props.popupContent}</h3>
                            </Popup.Content>
                        </Popup>
                        <Popup
                            trigger={
                                <Button
                                    icon={displaySeries ? "eye" : "eye slash"}
                                    onClick={async (event, data) => {
                                        await setDisplaySeries(!displaySeries);
                                        hideShowSeries(!displaySeries);
                                    }}
                                />
                            }
                        >
                            <Popup.Content>{displaySeries ? i18n._(t`display series`) : i18n._(t`hide series`)}</Popup.Content>
                        </Popup>
                    </Button.Group>
                </div>
            )}
            <div style={{ flex: verticalLegend ? 2 : 1, display: "flex" }}>
                <RotatedLabelRadialChart
                    className="pwaWidgetRadial"
                    colorType="literal"
                    data={_.reject(remap_series, { disabled: true })}
                    onValueMouseOver={(v) => setHint(v)}
                    onSeriesMouseOut={(v) => setHint(null)}
                    radius={radius}
                    labelsRadiusMultiplier={1.2}
                    labelsStyle={{ fontSize: 12 }}
                    labelsRotation={-10}
                    showLabels
                    style={{ stroke: "#fff", strokeWidth: 2 }}
                    width={radialWidth}
                    height={radialHeight}
                    margin={{ top: 0, bottom: 0 }}
                    getLabel={(d) => {
                        if (_.isFinite(d.percent) && d.percent > 1) {
                            return `${_.isFinite(d.percent) ? i18n.number(d.percent, { maximumFractionDigits: 2 }) : "-"}%`;
                        } else {
                            return "...";
                        }
                    }}
                >
                    {hint !== null && (
                        <Hint value={hint}>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    zIndex: 2,
                                    position: "absolute",
                                    right: 0,
                                    bottom: 0
                                }}
                            >
                                <Header as="h4" attached="top" block>
                                    <svg height="10" width="14" style={{ marginRight: "5px" }}>
                                        <rect height="10" width="14" style={{ fill: hint.color }}></rect>
                                    </svg>
                                    {`${hint.title || "-"}`}
                                </Header>
                                <Header as="h4" attached>{`${
                                    _.isFinite(hint.percent) ? i18n.number(hint.percent, { maximumFractionDigits: 2 }) : "-"
                                } %`}</Header>
                            </div>
                        </Hint>
                    )}
                </RotatedLabelRadialChart>
            </div>
            <div style={displayAsModal ? (verticalLegend ? { flex: 1 } : null) : { flex: 1 }}>
                <DiscreteColorLegend
                    orientation={verticalLegend ? "vertical" : "horizontal"}
                    items={remap_series}
                    onItemClick={clickHandler}
                    style={{
                        fontSize: "0.8em",
                        overflowY: verticalLegend ? "auto" : "hidden",
                        width: legendWidth,
                        height: legendHeight
                    }}
                />
            </div>
        </div>
    );
};

const RadialPanel = (props) => {
    const {
        data,
        time_periods: { ref_time, comp_time },
        width,
        height,
        displayAsModal
    } = props;
    const [series, setSeries] = useState({ ref_series: [], comp_series: null });
    const current_lng = useSelector((state) => state.i18n.current);

    useEffect(() => {
        //trigger action when data change && current_lng change
        (async () => {
            const { ref_series, comp_series } = _.chain(data.series)
                .reduce((res, series, serie_type) => {
                    if (series === null) {
                        res[serie_type] = null;
                        return res;
                    }
                    res[serie_type] = _.chain(series)
                        .reduce((res, serie) => {
                            if (_.size(serie.data) > 0) {
                                const all_null = _.every(serie.data, (rec) => {
                                    return rec.y === null || rec.y === 0;
                                });
                                //if (all_null) return res; //remove serie where all data null or 0
                                res.push({
                                    ...serie,
                                    title: `${i18n._(serie.name)}`,
                                    data: _.map(serie.data, (item) => ({
                                        ...item,
                                        y: _.isFinite(item.y) ? item.y : 0,
                                        title: `${i18n._(serie.name)}`
                                    })),
                                    all_null
                                });
                            }
                            return res;
                        }, [])
                        .sortBy((item) => {
                            return removeAccents(item.title).toLowerCase();
                        })
                        .value();
                    return res;
                }, {})
                .value();
            await setSeries({ ref_series, comp_series });
        })();
    }, [data.series, current_lng]); //rewrite initial series if lng change or initial series change

    const renderLabel = useCallback(
        (label) => {
            const time_from_fmt = `${_.lowerCase(i18n._(t`from`))} ${moment(ref_time.start).clone().locale(current_lng).format("L")}`;
            const time_to_fmt = `${_.lowerCase(i18n._(t`to`))} ${moment(ref_time.end)
                .clone()
                .startOf("day")
                .subtract(1, "day")
                .locale(current_lng)
                .format("L")}`;
            return `${i18n._(label)} ${time_from_fmt} ${time_to_fmt}`;
        },
        [ref_time, current_lng]
    );

    const renderLabelComp = useCallback(
        (label) => {
            const comp_time_from_fmt = `${_.lowerCase(i18n._(t`from`))} ${
                comp_time ? moment(comp_time.start).clone().locale(current_lng).format("L") : "-"
            }`;
            const comp_time_to_fmt = `${_.lowerCase(i18n._(t`to`))} ${
                comp_time ? moment(comp_time.end).clone().startOf("day").locale(current_lng).format("L") : "-"
            }`;
            return `${i18n._(label)} (ref) ${comp_time_from_fmt} ${comp_time_to_fmt}`;
        },
        [comp_time, current_lng]
    );

    const radialWidth = comp_time !== null ? width / 2 : width;

    const refEmpty = _.every(series.ref_series, (rec) => {
        return rec.all_null;
    });
    const compEmpty = _.every(series?.comp_series ?? [], (rec) => {
        return rec.all_null;
    });

    return (
        <div
            style={{
                padding: 0,
                height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div style={{ padding: 0, margin: 0, flex: 1 }}>
                {refEmpty && (
                    <MessageDisplay
                        message={i18n._(t`no data`)}
                        level="warning"
                        iconName="warning circle"
                        isLoading={false}
                        attached={"bottom"}
                        customStyle={{ height }}
                    />
                )}
                {!refEmpty && (
                    <Radial
                        series={series.ref_series}
                        width={radialWidth}
                        height={height}
                        popupContent={renderLabel("global_distribution")}
                        verticalLegend={false}
                        displayAsModal={displayAsModal}
                    />
                )}
            </div>
            {comp_time !== null && (
                <div
                    style={{
                        padding: 0,
                        margin: 0,
                        flex: 1,
                        borderLeft: "1px solid rgba(34,36,38,.15)"
                    }}
                >
                    {compEmpty && (
                        <MessageDisplay
                            message={i18n._(t`no data`)}
                            level="warning"
                            iconName="warning circle"
                            isLoading={false}
                            attached={"bottom"}
                            customStyle={{ height }}
                        />
                    )}
                    {!compEmpty && (
                        <Radial
                            series={series.comp_series}
                            width={radialWidth}
                            height={height}
                            popupContent={renderLabelComp("global_distribution")}
                            verticalLegend={false}
                            displayAsModal={displayAsModal}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default React.memo(RadialPanel);
