import React, { useState, useMemo } from "react";
import _ from "lodash";
import { Modal, Button, Grid, Icon } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";
import { reparseNumber, validateNumber } from "modules/common/utils";
import { identityNull } from "modules/common/utils/form";
import { categoriesOptions } from "./utils/index";

import { DropDownAdapter, InputAdapter } from "modules/common/components/form";

const AlertPredictModalGlobal = (props) => {
    const { pack_exception, push, pack, update } = props;
    const [open, setOpen] = useState(false);

    const onSubmitForm = async (data) => {
        const { categories } = data;

        if (_.isEmpty(pack_exception)) {
            const new_global_exception = {
                object_type: 6,
                object_id: pack.id,
                pack: pack.id,
                override: _.reduce(
                    categories,
                    (res, category) => {
                        res[category] = _.chain(data)
                            .omit(["categories"])
                            .mapValues((item, key) => {
                                if (_.includes(keyToReparseInPack, key)) {
                                    if (key === "duration") {
                                        return reparseNumber(item) * 60;
                                    }
                                    return reparseNumber(item);
                                }
                                return item;
                            })
                            .value();
                        return res;
                    },
                    {}
                )
            };
            push("pack_exception", new_global_exception);
        } else {
            const pck_exception = pack_exception?.[0];
            if (pck_exception) {
                const update_pack_exception = {
                    ...pck_exception,
                    override: {
                        ...pck_exception.override,
                        ..._.reduce(
                            categories,
                            (res, category) => {
                                res[category] = _.chain(data)
                                    .omit(["categories"])
                                    .mapValues((item, key) => {
                                        if (_.includes(keyToReparseInPack, key)) {
                                            if (key === "duration") {
                                                return reparseNumber(item) * 60;
                                            }
                                            return reparseNumber(item);
                                        }
                                        return item;
                                    })
                                    .value();
                                return res;
                            },
                            {}
                        )
                    }
                };
                update("pack_exception", 0, update_pack_exception);
            }
        }
        setOpen(false);
    };

    const initial = useMemo(() => {
        return {
            categories: [],
            level_warning: 7,
            level_critical: 9,
            duration: 1
        };
    }, []);

    const validate = (values) => {
        const errors = {};
        if (_.size(values.categories) === 0) {
            errors.categories = <Trans>Health score categories is required</Trans>;
        }
        return errors;
    };

    return (
        <Modal
            open={open}
            trigger={
                <Button
                    icon="add"
                    type="button"
                    basic
                    onClick={(event) => {
                        setOpen(true);
                    }}
                />
            }
        >
            <Form
                onSubmit={onSubmitForm}
                initialValues={initial}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>Add global exception</Trans>
                            </Modal.Header>
                            <Modal.Content scrolling={false}>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Field
                                        name="categories"
                                        label={i18n._(t`Health score categories`)}
                                        placeholder={i18n._(t`Choose health score categories`)}
                                        options={_.map(categoriesOptions, ({ key, text, value }) => ({ key, value, text: i18n._(text) }))}
                                        component={DropDownAdapter}
                                        isRequired={true}
                                        multipleselect={1}
                                    />
                                    <Grid centered stackable columns={3}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Field
                                                    name="level_warning"
                                                    label={i18n._(t`First threshold`)}
                                                    component={InputAdapter}
                                                    parse={identityNull}
                                                    inputMode="decima"
                                                    placeholder={i18n._(t`Set value between 1 to 10`)}
                                                    unitposition="left"
                                                    unitcolor="yellow"
                                                    unit={<Icon name="warning sign" />}
                                                    isRequired={true}
                                                    helperText={
                                                        <Trans>
                                                            <p>A first alert will be sent if the health score value exceeds this threshold</p>
                                                        </Trans>
                                                    }
                                                    validate={(value, allValues) => {
                                                        const warningValidate = validateNumber(value, i18n, false, false);
                                                        if (warningValidate !== undefined) {
                                                            return warningValidate;
                                                        }
                                                        const num = reparseNumber(value);
                                                        if (num < 1 || num > 10) {
                                                            return <Trans>First threshold must be between 1 and 10 maximum</Trans>;
                                                        }
                                                        if (reparseNumber(value) > reparseNumber(allValues?.level_critical)) {
                                                            return <Trans>First threshold can't be greater than second threshold</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Field
                                                    name="level_critical"
                                                    label={i18n._(t`Second threshold`)}
                                                    component={InputAdapter}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    placeholder={i18n._(t`Set value between 1 to 10`)}
                                                    unitposition="left"
                                                    unitcolor="red"
                                                    unit={<Icon name="fire" />}
                                                    isRequired={true}
                                                    helperText={
                                                        <Trans>
                                                            <p>A second alert will be sent if the health score value exceeds this threshold</p>
                                                        </Trans>
                                                    }
                                                    validate={(value, allValues) => {
                                                        const criticalValidate = validateNumber(value, i18n, false, false);
                                                        if (criticalValidate !== undefined) {
                                                            return criticalValidate;
                                                        }
                                                        const num = reparseNumber(value);
                                                        if (num < 1 || num > 10) {
                                                            return <Trans>Second threshold must be between 1 and 10 maximum</Trans>;
                                                        }
                                                        if (reparseNumber(value) < reparseNumber(allValues?.level_warning)) {
                                                            return <Trans>Second threshold can't be lower than first threshold</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Field
                                                    name="duration"
                                                    label={i18n._(t`Duration`)}
                                                    component={InputAdapter}
                                                    parse={identityNull}
                                                    inputMode="decimal"
                                                    placeholder={i18n._(t`Set value between 1 to 48`)}
                                                    unitposition="right"
                                                    unit={"h"}
                                                    isRequired={true}
                                                    helperText={
                                                        <Trans>
                                                            <p>
                                                                The alert will be sent only if the health score value remains beyond the threshold for
                                                                this duration
                                                            </p>
                                                        </Trans>
                                                    }
                                                    validate={(value) => {
                                                        const durationValidate = validateNumber(value, i18n, false, false);
                                                        if (durationValidate !== undefined) {
                                                            return durationValidate;
                                                        }
                                                        const num = reparseNumber(value);
                                                        if (num < 1 || num > 48) {
                                                            return <Trans>Duration must be between 1 hours and 48 hours maximum</Trans>;
                                                        }
                                                        return undefined;
                                                    }}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

export default AlertPredictModalGlobal;
