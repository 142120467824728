import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { Grid, Icon, Divider } from "semantic-ui-react";

import BarGauge from "modules/common/components/graphic/BarGauge";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { dynamicValueUnit } from "modules/data/utils";
import i18n from "modules/i18n/i18nConfig";
import { mapper } from "../mapper";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import { useGetMeasurementLastValuesQuery, useGetMeasurementOverview24hQuery } from "modules/dashboard/dashboardService";

/**
 * Generate threshold values for BarGauge Component
 * between min and max value
 * @function makeThreshold
 * @params No params
 * @returns {Array} list of threshold value like {color: red, value: 0}
 */
const makeThreshold = (flow, minValue, maxValue) => {
    if (_.isUndefined(flow) || _.isUndefined(minValue) || _.isUndefined(maxValue) || minValue > maxValue) {
        return undefined;
    }

    let res = [];
    const delta = maxValue - minValue;

    for (var i = 0; i < mapper[flow.dataflowspec].threshold_num; i++) {
        res.push({
            value: parseFloat((minValue + (delta * i) / mapper[flow.dataflowspec].threshold_num).toFixed(2)),
            color: mapper[flow.dataflowspec].colors[i]
        });
    }
    return res;
};

const pollingInterval = 1000 * 60 * 10;

export const LastVal = (props) => {
    const { measure } = props;
    const { org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });
    const measurementOverview24h = useGetMeasurementOverview24hQuery(
        { org: org.current, measurement_id: measure?.id },
        { skip: !org.current || !_.isFinite(measure?.id) }
    );
    const measurementLastValues = useGetMeasurementLastValuesQuery(
        { org: org.current, measurement_id: measure?.id },
        { skip: !org.current || !_.isFinite(measure?.id) }
    );

    useEffect(() => {
        const measurementTimer = setInterval(() => {
            measurementOverview24h.refetch();
            measurementLastValues.refetch();
        }, pollingInterval);

        return () => {
            clearInterval(measurementTimer);
        };
    }, [measure, measurementOverview24h, measurementLastValues, org]);

    const { measurement_lv, thresholds, display_unit, auto_unit, gaugeNotUpdated } = useMemo(() => {
        if (measurementLastValues.isSuccess && dataflows.isSuccess && units.isSuccess && measurementOverview24h.isSuccess && measure) {
            const measurement_lv = measurementLastValues?.data;
            const dataflow = _.find(dataflows.data, { id: measure?.dataflow });
            const thresholds = makeThreshold(dataflow, measure?.minGauge, measure?.maxGauge);
            const display_unit = _.find(units.data, { id: measure?.display_unit || measure?.measurementtype?.unit });
            const auto_unit = measure?.display_unit === null;
            const gaugeNotUpdated = _.chain(measurementOverview24h)
                .get("data.datapoints", [])
                .map((item) => item.avg_24h)
                .every(_.isFinite)
                .defaultTo(false)
                .value();
            return { measurement_lv, thresholds, display_unit, auto_unit, gaugeNotUpdated };
        }
        //Here default value when request pending or not success
        return { measurement_lv: null, thresholds: [], display_unit: null, auto_unit: false, gaugeNotUpdated: false };
    }, [dataflows, measure, units, measurementOverview24h, measurementLastValues]);

    if (measurementLastValues.isFetching || measurementOverview24h.isFetching) {
        return <MessageDisplay message={i18n._(t`loading`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (
        (measurementLastValues.isFetching === false && measurementLastValues.isError) ||
        (measurementOverview24h === false && measurementOverview24h.isError)
    ) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }
    if (
        measurementLastValues.isFetching === false &&
        measurementOverview24h.isFetching === false &&
        measurementLastValues.isSuccess &&
        measurementOverview24h.isSuccess
    ) {
        const { intensive = null, extensive = null, is_import = false } = measurement_lv;
        if (intensive === null && extensive === null) {
            return (
                <MessageDisplay
                    message={i18n._(t`unable to get last value`)}
                    level="warning"
                    iconName="warning circle"
                    isLoading={false}
                    attached={false}
                />
            );
        }

        const { factor: intensive_factor, new_unit: intensive_new_unit } = dynamicValueUnit(
            intensive,
            display_unit?.intensive || display_unit?.symbol,
            auto_unit
        );
        const { factor: extensive_factor, new_unit: extensive_new_unit } = dynamicValueUnit(extensive, display_unit?.symbol, auto_unit);

        const mt_type_popup = () => {
            switch (_.get(measure, "measurementtype.name")) {
                case "e_act_counter":
                case "p_act_import":
                    return i18n.use(current_lng)._(t`p_act`);
                case "water_import":
                    return i18n.use(current_lng)._(t`water`);
                default:
                    return i18n.use(current_lng)._(_.get(measure, "measurementtype.name"));
            }
        };

        return (
            <Grid
                centered
                stretched
                style={{
                    filter: `${gaugeNotUpdated ? "grayscale(100%) " : "none"}`
                }}
            >
                <Grid.Column width={16} className="unpadded">
                    <BarGauge
                        value={{
                            text: _.isFinite(intensive)
                                ? `${i18n.number(intensive * intensive_factor, {
                                      maximumFractionDigits: 1
                                  })} ${intensive_new_unit || "N/A"}`
                                : "N/A",
                            numeric: _.isFinite(intensive) ? Math.round((intensive * intensive_factor + Number.EPSILON) * 100) / 100 : null
                        }}
                        minValue={measure.minGauge * intensive_factor}
                        maxValue={measure.maxGauge * intensive_factor}
                        thresholds={_.map(thresholds, (item) => ({
                            ...item,
                            value: item.value * intensive_factor
                        }))}
                        height={40}
                        width={200}
                        itemSpacing={2}
                        popupTitle={
                            <>
                                <Trans>
                                    <span>last</span>&nbsp;{mt_type_popup}&nbsp;<span>value</span>
                                </Trans>
                                {gaugeNotUpdated && (
                                    <>
                                        <Divider />
                                        <div
                                            style={{
                                                filter: "grayscale(0%)"
                                            }}
                                        >
                                            <Icon color="red" size="small" name="power off" />
                                            {is_import === false && <Trans>the sensor is disconnected</Trans>}
                                            {is_import === true && <Trans>No imported measurement for 24 hours</Trans>}
                                        </div>
                                    </>
                                )}
                            </>
                        }
                    />
                </Grid.Column>
                {_.isFinite(extensive) && (
                    <Grid.Column width={16} textAlign="center" className="unpadded">
                        {`Index : ${i18n.number(extensive * extensive_factor, { maximumFractionDigits: 0 })} ${extensive_new_unit || "N/A"}`}
                    </Grid.Column>
                )}
            </Grid>
        );
    }
    return null;
};

LastVal.propTypes = {
    measure: PropTypes.object.isRequired
};

export default LastVal;
