import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { refreshTimestampUrlParam } from "apis/PowerAdapt";
import cookie from "react-cookies";
import _ from "lodash";
import { defaultLng } from "modules/i18n/i18nConfig";

export const reportApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const language = getState().i18n.current || defaultLng;
            if (language) {
                headers.set("Accept-Language", language);
            }
            const token = getState().auth.token || localStorage.getItem("accessToken");
            if (token) {
                // include token in req header
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        }
    }),
    reducerPath: "API_report",
    keepUnusedDataFor: 600,
    tagTypes: ["Reports", "Report", "GenerateReport"],
    endpoints: (build) => ({
        getReports: build.query({
            query: (data) => {
                const current_org = _.get(data, "org.name", "");
                return `advancedreports?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                if (result) return [{ type: "Reports", id: arg.org.id }];
                return [];
            }
        }),
        getReport: build.query({
            query: ({ org, report_id }) => {
                const current_org = _.get(org, "name", "");
                return `advancedreports/${report_id}?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            providesTags: (result, error, arg) => {
                return [{ type: "Report", id: arg.report_id }];
            },
            transformResponse: (response) => {
                return {
                    ...response,
                    hours: `${response.hour}:${response.minute}` //Add 'hours' params for React-datetime
                };
            }
        }),
        createReport: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `advancedreports?org=${current_org}`,
                    method: "POST",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: ["Reports"]
        }),
        updateReport: build.mutation({
            query: ({ org, data }) => {
                const current_org = _.get(org, "name", null);
                return {
                    url: `advancedreports/${data.id}?org=${current_org}`,
                    method: "PUT",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include",
                    body: data
                };
            },
            invalidatesTags: (result, error, arg) => {
                return [
                    { type: "Reports" },
                    { type: "Report", id: arg.data.id.toString() } //refresh specific report
                ];
            }
        }),
        deleteReport: build.mutation({
            query: ({ org, report_id }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `advancedreports/${report_id}?org=${current_org}`,
                    method: "DELETE",
                    headers: {
                        "X-CSRFTOKEN": cookie.load("csrftoken")
                    },
                    credentials: "include"
                };
            },
            invalidatesTags: (result, error, arg) => {
                if (!error) {
                    return [{ type: "Reports", id: arg.org.id }];
                }
                return [];
            }
        }),
        generateReport: build.mutation({
            query: ({ org, report_id, end, userOnly }) => {
                const current_org = _.get(org, "name", "");
                return {
                    url: `advancedreports/${report_id}/generate`,
                    method: "GET",
                    params: {
                        org: current_org,
                        end,
                        useronly: userOnly
                    }
                };
            }
        })
    })
});

export const {
    useGetReportsQuery,
    useGetReportQuery,
    useDeleteReportMutation,
    useCreateReportMutation,
    useUpdateReportMutation,
    useGenerateReportMutation
} = reportApi;
