import { defaultApi, refreshTimestampUrlParam } from 'apis/PowerAdapt';
import _ from 'lodash';

export const equipmenttypeApi = defaultApi.injectEndpoints({
    reducerPath: 'apiEquipmenttype',
    endpoints: (build) => ({
        getEquipmenttypes: build.query({
            query: ({ org }) => {
                const current_org = _.get(org, 'name', null);
                return `equipmenttypes?org=${current_org}&${refreshTimestampUrlParam()}`;
            },
            keepUnusedDataFor: 120,
            transformResponse: (response) => {
                return _.reduce(
                    response,
                    (result, equipmenttype, key) => {
                        result.push({
                            ...equipmenttype,
                            key: equipmenttype.id,
                            text: equipmenttype.name,
                            value: equipmenttype.id,
                            type: "equipmenttype"
                        });
                        return result;
                    },
                    []
                );
            }
        }),
    }),
    overrideExisting: false,
});

export const { useGetEquipmenttypesQuery } = equipmenttypeApi;