import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";
import moment from 'moment';
import { Trans, t } from '@lingui/macro';
import { Header, Icon, Segment } from 'semantic-ui-react';

import i18n from 'modules/i18n/i18nConfig';
import { removeAccents } from 'modules/common/utils';

import TableEnhanced from "modules/common/components/TableEnhanced";

const AlertPanel = (props) => {
    const {
        analysis_section: {
            threshold_max,
            threshold_min,
        },
        data,
        data: { config },
        aggregation
    } = props;

    const current_lng = useSelector(state => state.i18n.current);
    const [series, setSeries] = useState({ ref_series: [], comp_series: [] });

    useEffect(() => {
        //trigger action when data change && current_lng change
        (async () => {
            const { ref_series, comp_series } = _.chain(data.series)
                .reduce((res, series, serie_type) => {
                    res[serie_type] = _.chain(series)
                        .reduce((res, serie) => {
                            if (_.size(serie.data) > 0) {
                                res.push({
                                    ...serie,
                                    title: `${i18n._(serie.name)}`,
                                    data: _.map(serie.data, (item) => ({ ...item, title: `${i18n._(serie.name)}` }))
                                });
                            }
                            return res;
                        }, [])
                        .sortBy((item) => { return removeAccents(item.title).toLowerCase(); })
                        .value();
                    return res;
                }, {})
                .value();
            await setSeries({ ref_series, comp_series });
        })();
    }, [data.series, current_lng]);

    //Process dynamic total when enable/disable series
    const { ref_repartition } = useMemo(() => {
        return _.chain(series)
            .reduce((res, serie_type, key) => {
                const available_series_wrap = _.chain(serie_type)
                    .filter({ disabled: false });
                const all_total_null = available_series_wrap
                    .every((serie) => serie.total === null)
                    .value();
                if (all_total_null) return res;
                switch (key) {
                    case "ref_series":
                        res.ref_repartition = available_series_wrap
                            .flatMap('data')
                            .groupBy('x')
                            .map((values) => {
                                const all_data = _.flatMap(values, 'y');
                                const all_data_null = _.every(all_data, (data) => data === null);
                                if (all_data_null) return null;
                                return _.sumBy(values, 'y');
                            })
                            .reduce((res, conso, idx) => {
                                let overrun = 1;
                                if (_.isFinite(threshold_min) && threshold_min > conso) overrun = 0;
                                if (_.isFinite(threshold_max) && threshold_max < conso) overrun = 2;
                                if (overrun === 1) {
                                    return res;
                                }
                                //retrieve data from config param extract from 'processing'
                                const day_fmt = config?.xFormatTmst?.[idx] ?? null;
                                const day = moment(day_fmt);
                                let render_day = "-";
                                if (day.isValid()) {
                                    switch (aggregation) {
                                        case 1:
                                            render_day = day.locale(current_lng).format('L');
                                            break;
                                        case 2:
                                            render_day = `${i18n._(t`week number`)} ${day.locale(current_lng).format('WW')}`;
                                            break;
                                        case 3:
                                            render_day = day.locale(current_lng).format('MMMM YYYY');
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                res.push({
                                    id: { render: null, value: idx + 1, textAlign: "left", datatype: "number" },
                                    day: {
                                        render: render_day,
                                        value: day.isValid() ? day : null,
                                        textAlign: "right",
                                        datatype: "date"
                                    },
                                    conso: {
                                        render: _.isFinite(conso) ? i18n.number(conso, { maximumFractionDigits: 2 }) : "-",
                                        value: conso,
                                        textAlign: "right",
                                        datatype: "number"
                                    },
                                    overrun: {
                                        render:
                                            overrun === 2 ? (
                                                <Icon name="level up" color="red" />
                                            ) : overrun === 0 ? (
                                                <Icon name="level down" color="red" />
                                            ) : (
                                                "-"
                                            ),
                                        value: overrun,
                                        textAlign: "center",
                                        datatype: "number"
                                    }

                                });
                                return res;
                            }, [])
                            .value();
                        break;
                    case "comp_series":
                        res.comp_repartition = available_series_wrap
                            .flatMap('data')
                            .groupBy('x')
                            .map((values) => {
                                const all_data = _.flatMap(values, 'y');
                                const all_data_null = _.every(all_data, (data) => data === null);
                                if (all_data_null) return null;
                                return _.sumBy(values, 'y');
                            })
                            .value();
                        break;
                    default:
                        break;
                }
                return res;
            }, { ref_repartition: [], comp_repartition: null })
            .value();
    }, [series, threshold_min, threshold_max, current_lng, config.xFormatTmst, aggregation]);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "day", label: i18n._(t`date`), textAlign: "right" },
        { id: "conso", label: i18n._(t`total`), textAlign: "right" },
        { id: "overrun", label: i18n._(t`overrun status`), textAlign: "center" }
    ];

    return (
        <>
            <Header as="h3" textAlign="center" attached>
                <Trans>list of alerts</Trans> ({config.yUnit})
            </Header>
            <Segment attached textAlign='center'>
                <div className='pwaTablePaginatedContainer'>
                    <TableEnhanced
                        headCells={headCells}
                        rows={ref_repartition}
                        textItemPerPages={i18n._(t`items per page`)}
                        orderBy="day"
                    />
                </div>
            </Segment>
        </>
    );
};

export default React.memo(AlertPanel);
