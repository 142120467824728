import React from "react";
import PropTypes from "prop-types";
import { Pagination, Icon, Dropdown, List } from "semantic-ui-react";

export const GenericPaginate = (props) => {
    const { onPageChange, onChangeItemPageSize, page, rowPerPage, rowsLength } = props;

    return (
        <List horizontal size="mini">
            <List.Item>
                <Pagination
                    size="mini"
                    activePage={page + 1}
                    onPageChange={onPageChange}
                    totalPages={Math.ceil(rowsLength / rowPerPage)}
                    siblingRange={0}
                    boundaryRange={1}
                    prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true
                    }}
                    nextItem={{
                        content: <Icon name="angle right" />,
                        icon: true
                    }}
                />
            </List.Item>
            <List.Item>
                <Dropdown
                    size="mini"
                    as={List.Content}
                    selection
                    compact
                    defaultValue={rowPerPage}
                    onChange={onChangeItemPageSize}
                    options={[
                        { value: 5, text: "5" },
                        { value: 20, text: "20" },
                        { value: 50, text: "50" }
                    ]}
                />
            </List.Item>
        </List>
    );
};

GenericPaginate.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    onChangeItemPageSize: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowPerPage: PropTypes.number.isRequired,
    rowsLength: PropTypes.number.isRequired
};

export default GenericPaginate;
