import { t } from "@lingui/macro";

export const severity_options = [
    { key: -1, value: -1, text: t`All logs` },
    { key: 1, value: 2, text: t`Failures` } //Errors
];

export const contentTtype_options = [
    { key: 0, value: 0, text: t`All activity tasks` },
    { key: 1, value: 1, text: t`File import` },
    { key: 2, value: 2, text: t`Schedule export` },
    { key: 3, value: 3, text: t`Advanced report` },
    { key: 5, value: 5, text: t`Api import` }
];

export const ContentType = {
    FILEIMPORT: 1,
    SCHEDULEEXPORT: 2,
    ADVANCEDREPORT: 3,
    APIIMPORT: 5
};
