import _ from "lodash";
import axios from "axios";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { machineApi } from "./machineService";

const initialState = {
    machines: [],
    machine: {
        status: "idle",
        error: null,
        current: null,
        default: {}
    },
    event: {
        events: [],
        filter: {
            searchName: "",
            evtTypeFilter: []
        },
        pagination: {
            page: 1,
            itemsPerPage: 10,
            // Used to get lastPage read
            stickPage: false
        },
        form: null, //set by user
        current: null //retrieve from kinematic/change. has id field
    }
};

/**
 * Retrieve specific machine from server for current organization
 * @function getMachine
 */
export const getMachine = createAsyncThunk("machine/getMachine", async ({ org, id }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(`/machines/${id}?org=${current_org}&${refreshTimestampUrlParam()}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

const machineSlice = createSlice({
    name: "machine",
    initialState,
    reducers: {
        setEventSearchNameFilter: (state, action) => {
            state.event.filter.searchName = action.payload;
        },
        setEvtTypeFilter: (state, action) => {
            state.event.filter.evtTypeFilter = action.payload;
        },
        setEventPage: (state, action) => {
            state.event.pagination.page = action.payload;
        },
        setEventItemsPerPage: (state, action) => {
            state.event.pagination.page = 1;
            state.event.pagination.itemsPerPage = action.payload;
        },
        setEventStickPage: (state, action) => {
            state.event.pagination.stickPage = action.payload;
        },
        resetEventFilterWithPage: (state, action) => {
            state.event.filter = initialState.event.filter;
            state.event.pagination = initialState.event.pagination;
        },
        setEventForm: (state, action) => {
            state.event.form = action.payload;
        },
        setCurrentEvent: (state, action) => {
            state.event.current = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getMachine.pending, (state, action) => {
                state.machine.status = "loading";
                state.machine.current = null;
                state.machine.error = null;
            })
            .addCase(getMachine.fulfilled, (state, action) => {
                state.machine.status = "succeeded";
                state.machine.current = action.payload;
                state.machine.error = null;
            })
            .addCase(getMachine.rejected, (state, action) => {
                state.machine.status = "failed";
                state.machine.error = action.payload;
                state.machine.current = null;
            })
            .addMatcher(machineApi.endpoints.getMachines.matchFulfilled, (state, action) => {
                state.machines = action.payload;
            })
            .addMatcher(machineApi.endpoints.getMachineEvents.matchFulfilled, (state, action) => {
                state.event.events = action.payload;
            })
            .addMatcher(machineApi.endpoints.deleteMachineEvent.matchFulfilled, (state, action) => {
                const id = _.get(action, "meta.arg.originalArgs.data");
                state.event.events = _.filter(state.event.events, (obj) => obj.id !== id);
            })
            .addMatcher(machineApi.endpoints.kinematicChange.matchFulfilled, (state, action) => {
                const is_confirmation = _.get(action, "meta.arg.originalArgs.kinematic.is_confirmation", false);
                const last_kinematic_version = _.get(action, "meta.arg.originalArgs.machine.last_kinematic_version", null);
                if (is_confirmation === true) {
                    const machine_event = _.get(action, "payload.machine_event", null);
                    if (machine_event && last_kinematic_version > 0) {
                        state.event.current = machine_event;
                    }
                }
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const {
    setEventSearchNameFilter,
    setEvtTypeFilter,
    setEventPage,
    setEventItemsPerPage,
    setEventStickPage,
    resetEventFilterWithPage,
    setEventForm,
    setCurrentEvent
} = machineSlice.actions;

export default machineSlice.reducer;
