import _ from "lodash";
import moment from "moment";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import axios from "axios";

import PowerAdaptAPI, { refreshTimestampUrlParam } from "apis/PowerAdapt";
import { logout } from "modules/auth/authSlice";
import { setCurrentOrg } from "modules/organization/orgSlice";
import { roundedDate } from "modules/time/utils";

const initialState = {
    last_activity: {
        status: "idle",
        error: null,
        equipments: []
    },
    last_values: {
        status: "idle",
        error: null,
        data: []
    },
    predicttime: {
        time: null
    },
    powertime: {
        time: null
    },
    pumptime: {
        time: null
    },
    ape: {
        time: null
    },
    tabtemp: {
        detail: { status: "idle", data: [], error: null },
        summary: { status: "idle", data: [], error: null }
    },
    tabco2: {
        detail: { status: "idle", data: [], error: null },
        summary: { status: "idle", data: [], error: null }
    },
    tabhygro: {
        detail: { status: "idle", data: [], error: null },
        summary: { status: "idle", data: [], error: null }
    },
    tabpm25: {
        detail: { status: "idle", data: [], error: null },
        summary: { status: "idle", data: [], error: null }
    },
    tabenergy: {
        detail: { status: "idle", data: [], error: null },
        summary: { status: "idle", data: [], error: null },
        consumption: { status: "idle", data: [], error: null },
        total_consumption: { status: "idle", data: null, error: null }
    },
    tabelecdistri: {
        voltageimbalance: { status: "idle", data: [], error: null },
        sag: { status: "idle", data: [], error: null },
        swell: { status: "idle", data: [], error: null },
        trip: { status: "idle", data: [], error: null },
        i_moy_max: { status: "idle", data: [], error: null }
    },
    tabelecstate: {
        currentimbalance: { status: "idle", data: [], error: null },
        thd: { status: "idle", data: [], error: null },
        powerimbalance: { status: "idle", data: [], error: null },
        i0_ratio: { status: "idle", data: [], error: null },
        i_peak: { status: "idle", data: [], error: null }
    },
    tabelecoperationnal: {
        operatinghours: { status: "idle", data: [], error: null },
        distribution: { status: "idle", data: { edges: [], weights: [], data: [] }, error: null }
    },
    tabpredictsupplyaggregate: {
        frequency: { status: "idle", data: [], error: null }, //operating map
        current: { status: "idle", data: [], error: null }
    },
    tabpredictsupplydetail: {
        time: null,
        frequency: { status: "idle", data: [], error: null }, //operating raw detail
        current: { status: "idle", data: [], error: null }
    },
    tabpredictoperatingpoints: {
        freq_rms: { status: "idle", data: [], error: null },
        rms_slip: { status: "idle", data: [], error: null }
    },
    tabpredicthealthscore: {
        clusters: { status: "idle", data: [], error: null, selected_clusters: [] }
    }
};

/**
 * Get last activity sensors for selected equipments from server for current organization
 * @function getLastActivity
 */
export const getLastActivity = createAsyncThunk("overview/getLastActivity", async ({ equipment_list }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, "name", null);

    try {
        const response = await PowerAdaptAPI.post(`/equipments/last_activity_sensors?org=${current_org}&${refreshTimestampUrlParam()}`, {
            equipments: _.isEmpty(equipment_list) ? "" : equipment_list
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Get last activity sensors for selected equipments from server for current organization
 * @function getLastValues
 */
export const getLastValues = createAsyncThunk("overview/getLastValues", async ({ dataflow_list }, thunkAPI) => {
    const current_org = _.get(thunkAPI.getState().org.current, "name", null);

    const df_string = _.isEmpty(dataflow_list) ? "" : `&pk_list=${dataflow_list}`;
    try {
        const response = await PowerAdaptAPI.get(`/dataflows/last_values?org=${current_org}${df_string}&${refreshTimestampUrlParam()}`);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve Detail for specific measurement from server for current organization
 * @function getDetail
 */
export const getDetail = createAsyncThunk("overview/getDetail", async ({ org, measures, start, end }, thunkAPI) => {
    const current_org = _.get(org, "name", null);

    try {
        const response_measurements_detail = await Promise.all(
            _.map(measures, async (measure) => {
                try {
                    const response = await PowerAdaptAPI.get(
                        `/measurements/${measure.id}/detail?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`
                    );
                    return { data: response.data, measure };
                } catch (error) {
                    return null;
                }
            })
        );
        return response_measurements_detail;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve total consumption for specific measurement from server for current organization
 * @function getTotalConsumption
 */
export const getTotalConsumption = createAsyncThunk("overview/getTotalConsumption", async ({ org, measure, start, end }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(
            `/measurements/${measure.id}/total_consumption?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`
        );
        return { measure, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve summary for specific measurement from server for current organization
 * @function getSummary
 */
export const getSummary = createAsyncThunk("overview/getSummary", async ({ org, measure, start, end }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(
            `/measurements/${measure.id}/summary?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`
        );
        return { measure, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve consumptions for specific measurement from server for current organization
 * @function getConsumption
 */
export const getConsumption = createAsyncThunk("overview/getConsumption", async ({ org, measure, start, end }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(
            `/measurements/${measure.id}/consumptions?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`
        );
        return { measure, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve operating hours for specific measurement from server for current organization
 * @function getOperatingHours
 */
export const getOperatingHours = createAsyncThunk("overview/getOperatingHours", async ({ org, measure, start, end }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(
            `/measurements/${measure.id}/consumptions?org=${current_org}&start=${start}&end=${end}&operating&${refreshTimestampUrlParam()}`
        );
        return { measure, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve elec distribution for specific measurement from server for current organization
 * @function getElecDistribution
 */
export const getElecDistribution = createAsyncThunk("overview/getElecDistribution", async ({ org, measure, start, end }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(
            `/measurements/${measure.id}/histogram?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`
        );
        return { measure, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve operating map for specific machine from server for current organization
 * @function getOperatingMap
 */
export const getOperatingMap = createAsyncThunk("overview/getOperatingMap", async ({ org, machine, start, end, type }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(
            `/machines/${machine.id}/operating_map?org=${current_org}&start=${start}&end=${end}&type=${type}&${refreshTimestampUrlParam()}`
        );
        return { machine, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve operating raw for specific machine from server for current organization
 * @function getOperatingRaw
 */
export const getOperatingRaw = createAsyncThunk("overview/getOperatingRaw", async ({ org, machine, start, end, type }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    const rounded_start = roundedDate(moment(start), 60).toISOString();
    try {
        const response = await PowerAdaptAPI.get(
            `/machines/${
                machine.id
            }/operating_raw_detail?org=${current_org}&start=${rounded_start}&end=${end}&type=${type}&${refreshTimestampUrlParam()}`
        );
        return { machine, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve operating points for specific machine from server for current organization
 * @function getOperatingPoints
 */
export const getOperatingPoints = createAsyncThunk("overview/getOperatingPoints", async ({ org, machine, start, end, type }, thunkAPI) => {
    const current_org = _.get(org, "name", null);

    const rounded_start = roundedDate(moment(start), 60).toISOString();
    const rounded_end = roundedDate(moment(end), 60).toISOString();
    try {
        const response = await PowerAdaptAPI.get(
            `/machines/${machine.id}/operating_${type}?org=${current_org}&start=${rounded_start}&end=${rounded_end}&${refreshTimestampUrlParam()}`
        );
        return { machine, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

/**
 * Retrieve clusters for specific machine from server for current organization
 * @function getClusters
 */
export const getClusters = createAsyncThunk("overview/getClusters", async ({ org, machine }, thunkAPI) => {
    const current_org = _.get(org, "name", null);
    try {
        const response = await PowerAdaptAPI.get(`/clusters?org=${current_org}&machine_id=${machine.id}&${refreshTimestampUrlParam()}`);
        return {
            machine,
            data: response.data
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue("An unexpected error occurred");
        }
    }
});

const overviewSlice = createSlice({
    name: "overview",
    initialState,
    reducers: {
        setTime: (state, action) => {
            if (action.payload.tab) {
                const { start, end, plage } = action.payload;
                state[action.payload.tab].time = { start, end, plage };
            }
        },
        clearTime: (state, action) => {
            if (action.payload.tab) {
                state[action.payload.tab].time = null;
            }
        },
        setSelectedClusters: (state, action) => {
            state.tabpredicthealthscore.clusters.selected_clusters = action.payload;
        },
        resetOverview: (state, action) => {
            return initialState;
        },
        resetTab: (state, action) => {
            state[action.payload] = initialState[action.payload];
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getLastActivity.pending, (state, action) => {
                state.last_activity.status = "loading";
                state.last_activity.error = null;
                state.last_activity.equipments = {};
            })
            .addCase(getLastActivity.fulfilled, (state, action) => {
                state.last_activity.status = "succeeded";
                state.last_activity.error = null;
                state.last_activity.equipments = action.payload;
            })
            .addCase(getLastActivity.rejected, (state, action) => {
                state.last_activity.status = "failed";
                state.last_activity.error = action.payload;
                state.last_activity.equipments = {};
            })
            .addCase(getLastValues.pending, (state, action) => {
                state.last_values.status = "loading";
                state.last_values.error = null;
                state.last_values.data = [];
            })
            .addCase(getLastValues.fulfilled, (state, action) => {
                state.last_values.status = "succeeded";
                state.last_values.error = null;
                state.last_values.data = action.payload;
            })
            .addCase(getLastValues.rejected, (state, action) => {
                state.last_values.status = "failed";
                state.last_values.error = action.payload;
                state.last_values.data = [];
            })
            .addCase(getSummary.pending, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].summary.status = "loading";
                    state[action.meta.arg.tab].summary.data = [];
                    state[action.meta.arg.tab].summary.error = null;
                }
            })
            .addCase(getSummary.rejected, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].summary.status = "failed";
                    state[action.meta.arg.tab].summary.data = [];
                    state[action.meta.arg.tab].summary.error = action.payload;
                }
            })
            .addCase(getSummary.fulfilled, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].summary.status = "succeeded";
                    state[action.meta.arg.tab].summary.data = action.payload;
                    state[action.meta.arg.tab].summary.error = null;
                }
            })
            .addCase(getConsumption.pending, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].consumption.status = "loading";
                    state[action.meta.arg.tab].consumption.data = [];
                    state[action.meta.arg.tab].consumption.error = null;
                }
            })
            .addCase(getConsumption.rejected, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].consumption.status = "failed";
                    state[action.meta.arg.tab].consumption.data = [];
                    state[action.meta.arg.tab].consumption.error = action.payload;
                }
            })
            .addCase(getConsumption.fulfilled, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].consumption.status = "succeeded";
                    state[action.meta.arg.tab].consumption.data = action.payload;
                    state[action.meta.arg.tab].consumption.error = null;
                }
            })
            .addCase(getTotalConsumption.pending, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].total_consumption.status = "loading";
                    state[action.meta.arg.tab].total_consumption.data = null;
                    state[action.meta.arg.tab].total_consumption.error = null;
                }
            })
            .addCase(getTotalConsumption.rejected, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].total_consumption.status = "failed";
                    state[action.meta.arg.tab].total_consumption.data = null;
                    state[action.meta.arg.tab].total_consumption.error = action.payload;
                }
            })
            .addCase(getTotalConsumption.fulfilled, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].total_consumption.status = "succeeded";
                    state[action.meta.arg.tab].total_consumption.data = action.payload;
                    state[action.meta.arg.tab].total_consumption.error = null;
                }
            })
            .addCase(getElecDistribution.pending, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].distribution.status = "loading";
                    state[action.meta.arg.tab].distribution.error = null;
                    state[action.meta.arg.tab].distribution.data = { edges: [], weights: [], data: [] };
                }
            })
            .addCase(getElecDistribution.rejected, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].distribution.status = "failed";
                    state[action.meta.arg.tab].distribution.error = action.payload;
                    state[action.meta.arg.tab].distribution.data = { edges: [], weights: [], data: [] };
                }
            })
            .addCase(getElecDistribution.fulfilled, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab].distribution.status = "succeeded";
                    state[action.meta.arg.tab].distribution.error = null;
                    state[action.meta.arg.tab].distribution.data = action.payload;
                }
            })
            .addCase(getClusters.pending, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "loading";
                    state[action.meta.arg.tab][action.meta.arg.type].error = null;
                    state[action.meta.arg.tab][action.meta.arg.type].data = [];
                    state[action.meta.arg.tab][action.meta.arg.type].selected_clusters = [];
                }
            })
            .addCase(getClusters.rejected, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "failed";
                    state[action.meta.arg.tab][action.meta.arg.type].error = action.payload;
                    state[action.meta.arg.tab][action.meta.arg.type].data = [];
                    state[action.meta.arg.tab][action.meta.arg.type].selected_clusters = [];
                }
            })
            .addCase(getClusters.fulfilled, (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "succeeded";
                    state[action.meta.arg.tab][action.meta.arg.type].error = null;
                    state[action.meta.arg.tab][action.meta.arg.type].data = action.payload;
                    state[action.meta.arg.tab][action.meta.arg.type].selected_clusters = _.map(action.payload, (cluster) => cluster.value);
                }
            })
            .addMatcher(isAnyOf(getOperatingMap.pending, getOperatingRaw.pending, getOperatingPoints.pending), (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "loading";
                    state[action.meta.arg.tab][action.meta.arg.type].error = null;
                    state[action.meta.arg.tab][action.meta.arg.type].data = null;
                }
            })
            .addMatcher(isAnyOf(getOperatingMap.rejected, getOperatingRaw.rejected, getOperatingPoints.rejected), (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "failed";
                    state[action.meta.arg.tab][action.meta.arg.type].error = action.payload;
                    state[action.meta.arg.tab][action.meta.arg.type].data = null;
                }
            })
            .addMatcher(isAnyOf(getOperatingMap.fulfilled, getOperatingRaw.fulfilled, getOperatingPoints.fulfilled), (state, action) => {
                if (_.get(action, "meta.arg.tab")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "succeeded";
                    state[action.meta.arg.tab][action.meta.arg.type].error = null;
                    state[action.meta.arg.tab][action.meta.arg.type].data = action.payload;
                }
            })
            .addMatcher(isAnyOf(getDetail.pending, getOperatingHours.pending), (state, action) => {
                if (_.get(action, "meta.arg.tab") && _.get(action, "meta.arg.type")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "loading";
                    state[action.meta.arg.tab][action.meta.arg.type].data = [];
                    state[action.meta.arg.tab][action.meta.arg.type].error = null;
                }
            })
            .addMatcher(isAnyOf(getDetail.rejected, getOperatingHours.rejected), (state, action) => {
                if (_.get(action, "meta.arg.tab") && _.get(action, "meta.arg.type")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "failed";
                    state[action.meta.arg.tab][action.meta.arg.type].data = [];
                    state[action.meta.arg.tab][action.meta.arg.type].error = action.payload;
                }
            })
            .addMatcher(isAnyOf(getDetail.fulfilled, getOperatingHours.fulfilled), (state, action) => {
                if (_.get(action, "meta.arg.tab") && _.get(action, "meta.arg.type")) {
                    state[action.meta.arg.tab][action.meta.arg.type].status = "succeeded";
                    state[action.meta.arg.tab][action.meta.arg.type].data = action.payload;
                    state[action.meta.arg.tab][action.meta.arg.type].error = null;
                }
            })
            .addMatcher(isAnyOf(setCurrentOrg, logout), (state, action) => {
                return initialState;
            });
    }
});

export const { setTime, setSelectedClusters, resetOverview, resetTab, clearTime } = overviewSlice.actions;

export default overviewSlice.reducer;
