//Don't forget to update this object when dataflowspec django update and/or add
export const mapper = {
    1: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 12000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    2: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    3: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    4: {
        threshold_num: 3,
        colors: ["blue", "green", "red"],
        initialState: {
            minValue: -50,
            maxValue: 100,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    5: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 500,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    6: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 3000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    7: {
        threshold_num: 3,
        colors: ["red", "green", "blue"],
        initialState: {
            minValue: 0,
            maxValue: 100,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    8: {
        threshold_num: 3,
        colors: ["green", "green", "green"],
        initialState: {
            minValue: 0,
            maxValue: 100,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    9: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 10,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    10: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 50,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    11: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 3000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    12: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 12000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    13: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    14: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    15: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1000,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    16: {
        threshold_num: null,
        colors: null,
        initialState: {
            minValue: 0,
            maxValue: 1,
            data: {},
            isFetching: true,
            errors: {}
        }
    },
    28: {
        threshold_num: 3,
        colors: ["green", "orange", "red"],
        initialState: {
            minValue: 0,
            maxValue: 1000,
            data: {},
            isFetching: true,
            errors: {}
        }
    }
};
