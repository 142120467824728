import React, { useMemo, useState } from "react";
import { Modal, Button, Segment, Grid } from "semantic-ui-react";
import _ from "lodash";
import { Form, Field } from "react-final-form";
import { Trans, t } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { identityNull } from "modules/common/utils/form";
import { reparseNumber, validateNumber } from "modules/common/utils";

import { DropDownAdapter, InputAdapter, ToggleAdapter } from "modules/common/components/form";
import { keyToReparseInPack } from "modules/alert/alert_pack/utils";

export const ElecDistributionModal = (props) => {
    const { pack, push, dataflows, dataflows_exception } = props;
    const [open, setOpen] = useState(false);

    const onSubmitForm = async (data) => {
        _.each(data.dataflows, (dataflow_id) => {
            const df = _.find(dataflows, { id: dataflow_id });
            const item = {
                object_type: 4, //cf. django alert override object_type
                object_id: dataflow_id,
                name: df.name,
                override: _.chain(data)
                    .omit(["dataflows"])
                    .mapValues((item, key) => {
                        if (_.includes(keyToReparseInPack, key)) {
                            return reparseNumber(item);
                        }
                        return item;
                    })
                    .value(),
                pack: pack.id
            };
            push("dataflows_exception", item);
        });
        setOpen(false);
    };

    const dataflowOptionsFilter = _.filter(dataflows, (item) => {
        return _.chain(dataflows_exception).find({ object_id: item.id }).isUndefined().value();
    });

    const initial = useMemo(() => {
        return {
            dataflows: [],
            elapsed_hour: 3,
            i_moy_max_threshold: 100,
            imbalance_threshold: 2,
            auto_sag: true,
            auto_swell: true
        };
    }, []);

    return (
        <Modal
            open={open}
            trigger={
                <Button
                    type="button"
                    icon="add"
                    basic
                    onClick={(event) => {
                        setOpen(true);
                    }}
                />
            }
        >
            <Form
                onSubmit={onSubmitForm}
                initialValues={initial}
                render={({ handleSubmit, form, submitting, pristine, invalid, values }) => {
                    return (
                        <>
                            <Modal.Header>
                                <Trans>Add dataflow exception</Trans>
                            </Modal.Header>
                            <Modal.Content scrolling={false}>
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid verticalAlign="top">
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Field
                                                name="dataflows"
                                                label={i18n._(t`dataflows`)}
                                                placeholder={i18n._(t`select dataflows`)}
                                                options={dataflowOptionsFilter}
                                                component={DropDownAdapter}
                                                multipleselect={1}
                                                isRequired={true}
                                                validate={(value) => (_.size(value) === 0 ? <Trans>dataflows is required</Trans> : undefined)}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Field
                                                name="i_moy_max_threshold"
                                                placeholder={i18n._(t`i_moy_max_threshold placeholder`)}
                                                label={i18n._(t`i_moy_max_threshold`)}
                                                unit={"A"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                helperText={
                                                    <Trans>
                                                        <p>
                                                            Le système peut estimer l’état thermique des cables de puissance en se basant sur les
                                                            informations du type de cable et de la section des cables.
                                                        </p>
                                                        <p>
                                                            Ainsi, si un courant excessif circule pendant une durée trop importante, entrainant un
                                                            échauffement excessif, le système sera en mesure de le détecter.
                                                        </p>
                                                    </Trans>
                                                }
                                                isRequired={true}
                                                defaultValue={100}
                                                validate={(value) => {
                                                    return validateNumber(value, i18n, false, false);
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="imbalance_threshold"
                                                placeholder={i18n._(t`voltage_imbalance_threshold placeholder`)}
                                                label={i18n._(t`voltage_imbalance_threshold`)}
                                                unit={"%"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                helperText={i18n._(t`voltage_imbalance_helper`)}
                                                isRequired={true}
                                                validate={(value) => {
                                                    return validateNumber(value, i18n, false, false);
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Field
                                                name="elapsed_hour"
                                                placeholder={i18n._(t`voltage_elapsed_hour placeholder`)}
                                                label={i18n._(t`voltage_imbalance_duration`)}
                                                unit={"h"}
                                                component={InputAdapter}
                                                parse={identityNull}
                                                inputMode="decimal"
                                                isRequired={true}
                                                validate={(value) => {
                                                    const elapsedValidate = validateNumber(value, i18n, false, false);
                                                    if (elapsedValidate !== undefined) {
                                                        return elapsedValidate;
                                                    }
                                                    if (reparseNumber(value) < 1) {
                                                        return <Trans>period time minimum 1h</Trans>;
                                                    }
                                                    return undefined;
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Segment.Group horizontal style={{ border: "None", boxShadow: "None" }}>
                                                <Segment basic compact>
                                                    <Field
                                                        name="auto_sag"
                                                        label={i18n._(t`auto_sag`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        defaultValue={true}
                                                        toggle={false}
                                                    />
                                                </Segment>
                                                <Segment basic compact>
                                                    <Field
                                                        name="auto_swell"
                                                        label={i18n._(t`auto_swell`)}
                                                        labelposition="right"
                                                        component={ToggleAdapter}
                                                        defaultValue={true}
                                                        toggle={false}
                                                    />
                                                </Segment>
                                            </Segment.Group>
                                        </Grid.Column>
                                    </Grid>
                                </form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type="button"
                                    negative
                                    onClick={(e, data) => {
                                        setOpen(false);
                                    }}
                                >
                                    <Trans>cancel</Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    positive
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    disabled={submitting || pristine || invalid}
                                >
                                    <Trans>validate</Trans>
                                </Button>
                            </Modal.Actions>
                        </>
                    );
                }}
            />
        </Modal>
    );
};

export default React.memo(ElecDistributionModal);
