import React from "react";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Grid } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetWidgetsQuery } from "../dashboardDndService";
import MessageDisplay from "modules/common/components/MessageDisplay";
import WidgetsGrid from "./WidgetsGrid";

const WidgetsPanel = (props) => {
    const { current_dashboard, org } = props;
    const widgets = useGetWidgetsQuery(
        { org: org.current, dashboard_id: current_dashboard?.id },
        { skip: !org.current || !current_dashboard }
    );

    return (
        <>
            {widgets.isError && (
                <Grid.Column width={15}>
                    <MessageDisplay
                        message={i18n._(t`error loading data`)}
                        level="error"
                        iconName="warning circle"
                        isLoading={false}
                        attached={false}
                    />
                </Grid.Column>
            )}
            {widgets.isLoading && (
                <Grid.Column width={15}>
                    <MessageDisplay
                        message={i18n._(t`loading data`)}
                        level="info"
                        iconName="circle notched"
                        isLoading={true}
                    />
                </Grid.Column>
            )}
            {widgets.isSuccess && (
                <>
                    {current_dashboard === undefined && (
                        <Grid.Column width={15} style={{ marginTop: "10px" }}>
                            <MessageDisplay
                                attached={false}
                                level="warning"
                                message={i18n._(
                                    t`The dashboard does not exist or is not accessible`
                                )}
                                isLoading={false}
                                iconName="info circle"
                            />
                        </Grid.Column>
                    )}
                    {current_dashboard && _.size(widgets.data) === 0 && (
                        <Grid.Column width={15} style={{ marginTop: "10px" }}>
                            <MessageDisplay
                                attached={false}
                                level="warning"
                                message={i18n._(t`no widgets`)}
                                isLoading={false}
                                iconName="info circle"
                            />
                        </Grid.Column>
                    )}
                    {current_dashboard && _.size(widgets.data) > 0 && (
                        <WidgetsGrid {...props} widgets={widgets.data} />
                    )}
                </>
            )}
        </>
    );
};

export default React.memo(WidgetsPanel);
