import React from "react";
import { t, Trans } from "@lingui/macro";
import { Field } from "react-final-form";
import { Icon, Grid, Segment, Header, List } from "semantic-ui-react";
import _ from "lodash";

import i18n from "modules/i18n/i18nConfig";
import { InputAdapter, DropDownAdapter } from "modules/common/components/form";
import { identity } from "modules/common/utils/form";
import { encoding_list, dialect_delimiter_list, post_process_list, dialect_quotechar_list, dialect_decimal_list } from "modules/import/utils";

const ConfigParamsForm = (props) => {
    const { can_change, values } = props;

    return (
        <>
            <Header as="h3" attached="top" block textAlign="center" className="pwaNoStretched">
                <Trans>File location</Trans>
            </Header>
            <Segment attached>
                <Grid verticalAlign="top" centered>
                    <Grid.Column width={16}>
                        <Field
                            helperText={i18n._(
                                t`Directory on the server where the file is located, and this path is relative to the directory defined in the source`
                            )}
                            name="subdirectory"
                            placeholder={
                                values.import_type === 2
                                    ? i18n._(t`Must be like IDENTIFIER/transactions/CONTRACT_CODE`)
                                    : i18n._(t`Enter a subdirectory`)
                            }
                            label={i18n._(t`subdirectory`)}
                            parse={identity}
                            component={InputAdapter}
                            disabled={!can_change}
                            isRequired={values.import_type === 2}
                            unitposition="left"
                            unit={<Icon name="folder" />}
                            unitcolor="secondary"
                            validate={(value) => {
                                if (values.import_type === 2) {
                                    if (!value) {
                                        return <Trans>Required field</Trans>;
                                    }
                                    if (!value.trim().match(/^[A-Z0-9]+\/transactions\/[A-Z0-9.]+/)) {
                                        return <Trans>Must be like IDENTIFIER/transactions/CONTRACT_CODE</Trans>;
                                    }
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            helperText={
                                <>
                                    <p>
                                        <Trans>Filter for listing files. Recognized sequences:</Trans>
                                    </p>
                                    <List as="ul">
                                        <List.Item as="li">
                                            <Trans>"*" matches 0 or more any characters.</Trans>
                                        </List.Item>
                                        <List.Item as="li">
                                            <Trans>"?" matches any 1 unique character.</Trans>
                                        </List.Item>
                                        <List.Item as="li">
                                            <Trans>"[seq]" matches any character in sequence.</Trans>
                                        </List.Item>
                                        <List.Item as="li">
                                            <Trans>"[!seq]" matches any character not in sequence</Trans>
                                        </List.Item>
                                    </List>
                                </>
                            }
                            name="filename"
                            placeholder={i18n._(t`Enter a filter on the file name (e.g., Export_*.csv)`)}
                            label={i18n._(t`Name filter`)}
                            isRequired={true}
                            parse={identity}
                            component={InputAdapter}
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            unitposition="left"
                            unit={<Icon name="file alternate outline" />}
                            unitcolor="secondary"
                            validate={(value) => {
                                if (!value) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    {!_.includes([1, 2], values.import_type) && (
                        <Grid.Column width={16}>
                            <Field
                                helperText={i18n._(t`(Optional) Extension to be appended to the filename to define a semaphore file`)}
                                name="semaphore_filename"
                                placeholder={i18n._(t`enter a semaphore_filename`)}
                                label={i18n._(t`semaphore_filename`)}
                                parse={identity}
                                component={InputAdapter}
                                disabled={!can_change || _.includes([1, 2], values.import_type)}
                                unitposition="left"
                                unit={<Icon name="file" />}
                                unitcolor="secondary"
                            />
                        </Grid.Column>
                    )}
                    {values.import_type === 1 && (
                        <Grid.Column width={16}>
                            <Field
                                helperText={i18n._(t`Contact channel decryption key provided by Enedis (64 hexadecimal characters)`)}
                                name="encryption_key"
                                placeholder={i18n._(t`Enter an encryption key`)}
                                label={i18n._(t`Encryption key`)}
                                parse={identity}
                                component={InputAdapter}
                                disabled={!can_change}
                                isRequired={true}
                                validate={(value) => {
                                    if (!value) {
                                        return <Trans>Required field</Trans>;
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                    <Grid.Column style={{ marginTop: "1.5rem" }} width={16}>
                        <Header as="h3" block textAlign="center" className="pwaNoStretched">
                            <Trans>Format</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            name="encoding"
                            placeholder={i18n._(t`enter a encoding`)}
                            label={i18n._(t`encoding`)}
                            isRequired={true}
                            component={DropDownAdapter}
                            search={false}
                            options={encoding_list}
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            validate={(value) => {
                                if (!value) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            label={i18n._(t`Field separator`)}
                            component={DropDownAdapter}
                            search={false}
                            name="dialect_delimiter"
                            placeholder={i18n._(t`Select a field separator`)}
                            compact
                            fluid
                            selection
                            options={dialect_delimiter_list}
                            isRequired={true}
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            validate={(value) => {
                                if (!value) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            label={i18n._(t`Text separator`)}
                            component={DropDownAdapter}
                            search={false}
                            name="dialect_quotechar"
                            placeholder={i18n._(t`Select a text separator`)}
                            compact
                            fluid
                            selection
                            options={dialect_quotechar_list}
                            isRequired={true}
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            validate={(value) => {
                                if (!_.isFinite(value)) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            label={i18n._(t`Decimal separator`)}
                            component={DropDownAdapter}
                            search={false}
                            name="dialect_decimal"
                            placeholder={i18n._(t`Select a decimal separator`)}
                            compact
                            fluid
                            selection
                            options={dialect_decimal_list}
                            isRequired={true}
                            disabled={!can_change || _.includes([1, 2], values.import_type)}
                            validate={(value) => {
                                if (!value) {
                                    return <Trans>Required field</Trans>;
                                }
                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column style={{ marginTop: "1.5rem" }} width={16}>
                        <Header as="h3" block textAlign="center" className="pwaNoStretched">
                            <Trans>Post-Processing</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Field
                            helperText={
                                <>
                                    <p>
                                        <Trans>After processing the file, you have several options:</Trans>
                                    </p>
                                    <List as="ul">
                                        <List.Item as="li">
                                            <Trans>Move the file to the folder named 'post process param'</Trans>
                                        </List.Item>
                                        <List.Item as="li">
                                            <Trans>Delete file</Trans>
                                        </List.Item>
                                        <List.Item as="li">
                                            <Trans>rename it by adding the post process param extension</Trans>
                                        </List.Item>
                                        <List.Item as="li">
                                            <Trans>
                                                Remember names: during an import, only files whose name is greater than the last previously processed
                                                will be imported. File names must be ordered in time.
                                            </Trans>
                                        </List.Item>
                                    </List>
                                </>
                            }
                            label={i18n._(t`post_process`)}
                            component={DropDownAdapter}
                            name="post_process"
                            placeholder={i18n._(t`select a post_process`)}
                            search={false}
                            compact
                            fluid
                            selection
                            options={post_process_list}
                            isRequired={true}
                            disabled={!can_change || values.import_type === 2}
                            validate={(value, allValues) => {
                                if (!_.isFinite(value)) {
                                    return <Trans>Required field</Trans>;
                                }

                                return undefined;
                            }}
                        />
                    </Grid.Column>
                    {_.includes([1, 3], values.post_process) && (
                        <Grid.Column width={16}>
                            <Field
                                name="post_process_param"
                                {...(values?.post_process === 1
                                    ? {
                                          label: i18n._(t`Folder name`),
                                          placeholder: i18n._(t`Enter folder name`),
                                          helperText: i18n._(
                                              t`Folder into which the file will be moved after processing (relative to the location of the file).`
                                          )
                                      }
                                    : {
                                          label: i18n._(t`Extension`),
                                          placeholder: i18n._(t`Enter extension`)
                                      })}
                                isRequired={true}
                                parse={identity}
                                component={InputAdapter}
                                unitposition="left"
                                unit={<Icon name="retweet" />}
                                unitcolor="secondary"
                                disabled={!can_change}
                                validate={(value, allValues) => {
                                    if (!value) {
                                        return <Trans>Required field</Trans>;
                                    }
                                    if (allValues.post_process === 3 && !/^\./.test(value)) {
                                        return (
                                            <Trans>
                                                post_process_param need start with a dot (.) when post process is set to rename with extension
                                            </Trans>
                                        );
                                    }
                                    return undefined;
                                }}
                            />
                        </Grid.Column>
                    )}
                </Grid>
            </Segment>
        </>
    );
};

export default React.memo(ConfigParamsForm);
