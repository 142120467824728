import React from "react";
import { Grid } from "semantic-ui-react";
import DropdownMeasurement from "./DropdownMeasurement";

const MeasureItem = (props) => {
    return (
        <Grid verticalAlign="middle">
            <Grid.Column width={16}>
                <DropdownMeasurement {...props} disable_category={true} disable_mttype={true} />
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(MeasureItem);
